<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
        <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
        <v-card
          class="mt-0 mb-4 ml-4 mr-4"
          :color="cssconsts.eigyo_bg_color_base_01"
          outlined
          elevation="10"
          height="100%"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-hammer-wrench
              </v-icon>
              工事ポータル
            </span>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-1"
              color="grey darken-1"
              dark
              width="100"
              @click="backPrevPage"
            >
              <v-icon left>
                mdi-keyboard-return
              </v-icon>
              戻る
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-1"></v-divider>

          <!-- 基本情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
            <div class="mx-2 my-auto">基本情報</div>
            <v-spacer></v-spacer>
              <v-btn
                class="mx-1 my-1"
                color="blue darken-1"
                dark
                small
                width="80"
                elevation="1"
                @click="showKoji"
              >
                <v-icon left>
                  mdi-hammer-wrench
                </v-icon>
                詳細
              </v-btn>
            </v-sheet>
              
            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客番号</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{kouji.cust_no}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
              </v-col>
              <v-col cols="5" class="yellow lighten-4">
                <span>
                  {{kouji.cust_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">工事番号</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{kouji.kouji_no}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">工事名</span>
              </v-col>
              <v-col cols="5" class="yellow lighten-4">
                <span>
                  {{kouji.kouji_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">工事場所</span>
              </v-col>
              <v-col cols="2">
                <div class="yellow lighten-4" style="width: 120px">
                  <span>
                  {{kouji.post_cd}}
                  </span>
                </div>
              </v-col>
              <v-col cols="6" class="yellow lighten-4">
                <div>
                  {{kouji.addr}}
                  <br>{{kouji.addr2}}
                </div>
              </v-col>
              <v-col cols="1">
                <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                  <input type="hidden" name="_token" :value="csrf">
                  <input type="hidden" name="mapdata" value="">
                </form>
                <v-btn
                  class="ml-2"
                  color="indigo darken-1"
                  small
                  dark
                  @click="showMap()"
                >
                  <v-icon left>
                    mdi-map-marker
                  </v-icon>
                  地図
                </v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">緯度</span>
              </v-col>
              <v-col cols="3" class="yellow lighten-4">
                <span v-if="kouji">
                  {{kouji.addr_lat}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">経度</span>
              </v-col>
              <v-col cols="3" class="yellow lighten-4">
                <span v-if="kouji">
                  {{kouji.addr_lng}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{kouji.area_name}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">施主種別</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{kouji.sesyu_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">工事種別</span>
              </v-col>
              <v-col cols="3" class="yellow lighten-4">
                <span>
                  {{kouji.kouji_type_name}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">施工面積</span>
              </v-col>
              <v-col cols="3" class="yellow lighten-4">
                <span>
                  {{editSekouArea(kouji.sekou_area)}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">営業担当</span>
              </v-col>
              <v-col cols="7" class="yellow lighten-4">
                <span>
                  {{kouji.htg_tanto_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">見積担当</span>
              </v-col>
              <v-col cols="7" class="yellow lighten-4">
                <span>
                  {{kouji.mtg_tanto_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">工事担当</span>
              </v-col>
              <v-col cols="7" class="yellow lighten-4">
                <span>
                  {{kouji.ktg_tanto_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">自社施工物件</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{kouji.ckk_sekou_name}}
                </span>
              </v-col>
              <template v-if="kouji.ckk_sekou != '1'">
                <v-col cols="1" class="d-flex">
                  <span class="ml-auto mr-2 my-auto text-body-2">業者</span>
                </v-col>
                <v-col cols="5" class="yellow lighten-4">
                  <span>
                    {{kouji.gyosya_name}}
                  </span>
                </v-col>
              </template>
            </v-row>
          </v-sheet>

          <!-- アラート一覧 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">アラート一覧</div>
              <v-spacer></v-spacer>
              <v-checkbox
                v-model="showComp"
                class="shrink mx-2 my-auto"
                color="cyan accent-1"
                value="1"
                hide-details
                true-value="true"
                false-value="false"
                @change='changeShowComp'
              >
                <template v-slot:label>
                  <span class="text-body-2">
                    対応済みも表示する
                  </span>
                </template>
              </v-checkbox>
              <v-btn
                class="mx-1 my-1"
                color="blue darken-1"
                dark
                small
                width="160"
                elevation="1"
                @click="changeTaio"
              >
                <v-icon left>
                  mdi-reload
                </v-icon>
                対応済み切り替え
              </v-btn>
            </v-sheet>
            <v-simple-table
              height="240px"
            >
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="alert in alerts"
                    :key="alert.alert_no"
                  >
                    <td class="px-2">
                      {{ alert.alert_naiyo }}
                    </td>
                    <td class="px-2">
                      <v-checkbox
                        v-model="alert.status"
                        class="my-auto mr-2 float-right"
                        hide-details
                        false-value="0"
                        true-value="1"
                      >
                        <template v-slot:label>
                          <div style="width: 120px">
                            {{alert.taiou}}
                          </div>
                        </template>
                      </v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-sheet>

          <!-- 契約情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">契約情報</div>
            </v-sheet>

            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">契約日</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{order.keiyaku_date}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">請負金額</span>
              </v-col>
              <v-col cols="1" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">税込</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span class="float-right">
                  {{numberToLocal(order.ukeoi_amt + order.ukeoi_tax)}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2">
              </v-col>
              <v-col cols="1" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">税抜</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span class="float-right">
                  {{numberToLocal(order.ukeoi_amt)}}
                </span>
              </v-col>
              <v-col cols="1" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">税額</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span class="float-right">
                  {{numberToLocal(order.ukeoi_tax)}}
                </span>
              </v-col>
              <v-col cols="1" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">税率</span>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <span class="float-right">
                  {{order.tax_rate}}
                </span>
              </v-col>
              <v-col cols="1">
                <span class="ml-1 text-body-2">%</span>
              </v-col>
            </v-row>
          </v-sheet>

          <!-- 入金予定・実績 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">入金予定・実績</div>
            </v-sheet>

            <v-row no-gutters class="mt-2 mb-0 mx-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-2 my-auto text-body-2">入金予定日</span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-2 my-auto text-body-2">入金種別</span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto my-auto text-body-2">金額</span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="mx-auto my-auto text-body-2">入金</span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-2 my-auto text-body-2">入金日</span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto my-auto text-body-2">入金額</span>
              </v-col>
            </v-row>
            <v-divider class="my-0"></v-divider>

            <v-row
              v-for="(nk, index) in nyukin"
              :key="index"
              no-gutters class="mt-2 mb-0 mx-2"
            >
              <v-col cols="2" class="yellow lighten-4">
                <div class="ml-2">
                  <span>
                    {{nk.yotei_date}}
                  </span>
                </div>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <div class="ml-2">
                  <span>
                    {{nk.nyukin_type}}
                  </span>
                </div>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <div class="ml-2 float-right">
                  <span>
                    {{numberToLocal(nk.yotei_kingaku)}}
                  </span>
                </div>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <div class="d-flex">
                  <span class="mx-auto">
                    {{nk.nyukin_state}}
                  </span>
                </div>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <div class="ml-2">
                  <span>
                    {{nk.nyukin_date}}
                  </span>
                </div>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <div class="ml-2 float-right">
                  <span>
                    {{numberToLocal(nk.nyukin_kingaku)}}
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-divider class="mt-2 mb-0"></v-divider>

            <v-row no-gutters class="mt-2 mb-0 mx-2">
              <v-col cols="4" class="d-flex">
                <div class="ml-auto mr-2 my-auto text-body-2">
                  <span>入金予定残高</span>
                </div>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <div class="ml-2 float-right">
                  {{numberToLocal(total_nyukin_zan)}}
                </div>
              </v-col>
            </v-row>
          </v-sheet>

          <!-- 工事予定・実績 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <v-btn
                class="my-auto mx-1"
                color="light-green darken-1"
                fab
                x-small
                @click="stretch('construction')"
              >
                <v-icon v-if="stretchConstruction === true">
                  mdi-arrow-collapse-vertical
                </v-icon>
                <v-icon v-else>
                  mdi-arrow-expand-vertical
                </v-icon>
              </v-btn>
              <div class="mx-2 my-auto">工事予定・実績</div>
              <!--
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-1 my-auto"
                  color="light-blue darken-1"
                  dark
                  small
                  width="80"
                  @click="regKoji"
                >
                  新規登録
                </v-btn>
              -->
            </v-sheet>
            <v-flex class="mt-2 mb-0 mx-2">
              <template v-if="stretchConstruction === true">
                <v-data-table
                  calculate-widths
                  :headers="constructionHeaders"
                  :items="kouji_list"
                  :items-per-page="10"
                  :sort-by.sync="constructionSortBy"
                  sort-desc
                  height="300"
                  class="elevation-1"
                  locale="jp-ja"
                  loading-text="読込中"
                  no-data-text="該当するデータがありません。"
                  @click:row="showKojiPortal"
                >
                  <template v-slot:[`header.ukeoi_kngk`]="{ header }">
                    <span>{{ header.text }}</span><br>
                    <span>（税込）</span>
                  </template>
                  <template v-slot:[`item.ukeoi_kngk`]="{ item }">
                    <span>
                      {{numberToLocal(item.ukeoi_kngk)}}
                    </span>
                  </template>
                </v-data-table>
              </template>
            </v-flex>
          </v-sheet>

          <!-- メンテナンス予定・実績 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <v-btn
                class="my-auto mx-1"
                color="light-green darken-1"
                fab
                x-small
                @click="stretch('maintenance')"
              >
                <v-icon v-if="stretchMaintenance === true">
                  mdi-arrow-collapse-vertical
                </v-icon>
                <v-icon v-else>
                  mdi-arrow-expand-vertical
                </v-icon>
              </v-btn>
              <div class="mx-2 my-auto">メンテナンス予定・実績</div>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-1 my-auto"
                color="light-blue darken-1"
                dark
                small
                width="80"
                @click="regMainte"
              >
                新規登録
              </v-btn>
            </v-sheet>
            <v-flex class="mt-2 mb-0 mx-2">
              <template v-if="stretchMaintenance === true">
                <v-data-table
                  calculate-widths
                  :headers="maintenanceHeaders"
                  :items="mainte_list"
                  :items-per-page="10"
                  :sort-by.sync="maintenanceSortBy"
                  sort-desc
                  height="300"
                  class="elevation-1"
                  dense
                  locale="jp-ja"
                  loading-text="読込中"
                  no-data-text="該当するデータがありません。"
                  @click:row="showMainte"
                ></v-data-table>
              </template>
            </v-flex>
          </v-sheet>

          <!-- 点検予定・実績 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <v-btn
                class="my-auto mx-1"
                color="light-green darken-1"
                fab
                x-small
                @click="stretch('inspection')"
              >
                <v-icon v-if="stretchInspection === true">
                  mdi-arrow-collapse-vertical
                </v-icon>
                <v-icon v-else>
                  mdi-arrow-expand-vertical
                </v-icon>
              </v-btn>
              <div class="mx-2 my-auto">点検予定・実績</div>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-1 my-auto"
                color="light-blue darken-1"
                dark
                small
                width="80"
                @click="regInspect"
              >
                新規登録
              </v-btn>
            </v-sheet>
            <v-flex class="mt-2 mb-0 mx-2">
              <template v-if="stretchInspection === true">
                <v-data-table
                  calculate-widths
                  :headers="inspectionHeaders"
                  :items="inspect_list"
                  :items-per-page="10"
                  :sort-by.sync="inspectionSortBy"
                  sort-desc
                  height="300"
                  class="elevation-1"
                  dense
                  locale="jp-ja"
                  loading-text="読込中"
                  no-data-text="該当するデータがありません。"
                  @click:row="showInspect"
                ></v-data-table>
              </template>
            </v-flex>
          </v-sheet>
        </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import dbkouji from './js/common/dbkouji.js'
import gmap from './js/common/gmap.js'
import dbcommon from './js/common/dbalertkouji.js'

//export
export default {
  mixins: [dbkouji, gmap, dbcommon],

  props:{
      "parameter": {},
  },
  created: function() {
    // 工事番号をthis.parameter.viewparamから取得
    var kouji_no = this.parameter.viewparam;
    // 工事番号が一致するデータ（基本情報）を読み出す
    //以下に格納
    //⇒this.kouji
    this.getKoujiBasic(kouji_no);
    // 工事番号が一致するメンテナンスリストを取得
    //以下に格納
    //⇒this.mainte_list
    this.getKoujiMainteList(kouji_no);
    // 工事番号が一致する点検リストを取得
    //以下に格納
    //⇒this.inspect_list
    this.getKoujiInspectList(kouji_no);

    this.getAlertList(this.showComp,kouji_no);
    this.m_createBreads();
  },
  computed: {
  },
  watch: {
    kouji: function(data) {
      // 顧客番号が一致する工事リストを取得
      //以下に格納
      //⇒this.kouji_list
      this.getCustomerKoujiList(data.cust_no);
    },
    nyukin: function(data) {
      this.total_nyukin_zan = 0;
      for (var elem of data) {
        this.total_nyukin_zan += (elem.yotei_kingaku - elem.nyukin_kingaku)
      }
    },
  },

  methods: {
    stretch(list) {
      if (list == "construction") {
        this.stretchConstruction = !this.stretchConstruction;
      } else if (list == "maintenance") {
        this.stretchMaintenance = !this.stretchMaintenance;
      } else if (list == "inspection") {
        this.stretchInspection = !this.stretchInspection;
      }
    },

    backPrevPage() {
      // 前画面に戻る
      this.$emit('viewBack');
    },
    showKoji() {
      //「工事詳細情報」画面へ遷移
      this.$emit('viewChange', 'KojiDetail', this.kouji.kouji_no);
    },
    showMap() {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）
      // 顧客住所
      var cust_addr = ((this.kouji.cust_addr!=null)?this.kouji.cust_addr:'') + ((this.kouji.cust_addr2!=null)?this.kouji.cust_addr2:'');

      // 地図表示情報
      this.sendMapInfo(cust_addr);
    },
    // 地図表示情報
    sendMapInfo(cust_addr) {
      var searchaddress = ((this.kouji.addr!=null)?this.kouji.addr:'') + ((this.kouji.addr2!=null)?this.kouji.addr2:'')
      var title_juusyo = '現住所'
      var title_kouji = '工事場所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var lat_kouji = (this.kouji.addr_lat)?this.kouji.addr_lat:null
      var lng_kouji = (this.kouji.addr_lng)?this.kouji.addr_lng:null
      var lat_juusyo = (this.kouji.cust_addr_lat)?this.kouji.cust_addr_lat:null
      var lng_juusyo = (this.kouji.cust_addr_lng)?this.kouji.cust_addr_lng:null
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      i_data.value = JSON.stringify({
        'view_name': this.open_view_name,
        'cust_no': (this.kouji.cust_no)?this.kouji.cust_no:'',
        'cust_name': (this.kouji.cust_name)?this.kouji.cust_name:'',
        'markercount': (cust_addr)?2:1,
        'editindex': -1, // 編集禁止
        'markertitle': [title_kouji,title_juusyo],
        'markeraddress': [searchaddress,cust_addr],
        'markerlat': [lat_kouji,lat_juusyo],
        'markerlng': [lng_kouji,lng_juusyo],
      });
      postform.submit();

    },

    showKojiPortal: function(event, row) {
      //「工事ポータル」画面へ遷移
      this.$emit('viewChange', 'KojiPortal', row.item.kouji_no);
    },
    showMainte: function(event, row) {
      //「メンテナンス情報」画面へ遷移
      this.$emit('viewChange', 'Maintenance', row.item.mainte_no, 3);
    },
    showInspect: function(event, row) {
      //「点検情報」画面へ遷移
      this.$emit('viewChange', 'Inspection', row.item.insp_no, 4);
    },
/*
    regKoji() {
      //「工事情報登録・編集」画面へ遷移
      this.$emit('viewChange', 'KojiEdit', 0);    //新規登録
    },
*/
    regMainte() {
      var param = {
        mainte_no: 0,
        kouji_no: this.kouji.kouji_no,
        kouji_name: this.kouji.kouji_name,
        cust_name: this.kouji.cust_name
      };
      //「メンテナンス登録・編集」画面へ遷移
      this.$emit('viewChange', 'MainteEdit', param, 3);   //新規登録
    },
    regInspect() {
      //「点検登録・編集」画面へ遷移
      var param = {
        insp_no: 0,
        kouji_no: this.kouji.kouji_no,
        kouji_name: this.kouji.kouji_name,
        cust_name: this.kouji.cust_name
      };
      this.$emit('viewChange', 'InspectEdit', param, 4);    //新規登録
    },

    editSekouArea(area) {
      //施工面積編集
      var sekouArea = ""
      if (area != null) {
        sekouArea = Number(area).toLocaleString(undefined, {
          minimumFractionDigits: 2, maximumFractionDigits: 2});
        sekouArea += "㎡"
        //坪変換
        var tubo = 0;
        if (area != 0) {
          var tubo = area / 3.30579;
        }
        sekouArea += "（"
        sekouArea += Number(tubo).toLocaleString(undefined, {
          minimumFractionDigits: 2, maximumFractionDigits: 2});
        sekouArea += "坪）"
      }
      return sekouArea;
    },
    numberToLocal(num) {
      if (num == null || isNaN(num)) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
},

  data: function(){
    return {
      cssconsts: cssconsts,

      stretchConstruction: true,
      stretchMaintenance: true,
      stretchInspection: true,
      
      //工事
      kouji: {},
      //契約
      order: {},

      showComp: "false",
      
      //アラート一覧
      alerts: [],

      //入金予定・実績
      nyukin: [],
      total_nyukin_zan: 0,

      //工事予定・実績
      constructionSortBy: 'row_no',
      constructionHeaders: [
        { text: 'No.', value: 'row_no', width: '50px', class: 'px-1' },
        { text: '工事番号', value: 'kouji_no', width: '80px', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: '工事種別', value: 'kouji_type', width: '150px', class: 'px-1' },
        { text: '契約日', value: 'keiyaku_date', width: '100px', class: 'px-1' },
        { text: '引渡日', value: 'hndovr_date', width: '100px', class: 'px-1' },
        { text: '請負金額', value: 'ukeoi_kngk', align: 'end', width: '100px', class: 'px-1' },
        { text: '営業担当', value: 'htg_tanto_name', width: '100px', class: 'px-1' },
        { text: '工事担当', value: 'ktg_tanto_name', width: '100px', class: 'px-1' },
      ],
      kouji_list: [],

      //メンテナンス予定・実績
      maintenanceSortBy: 'row_no',
      maintenanceHeaders: [
        { text: 'No.', value: 'row_no', width: '50px', class: 'px-1' },
        { text: 'メンテナンス名', value: 'mainte_name', width: '150px', class: 'px-1' },
        { text: 'メンテナンス内容', value: 'mainte_naiyou', width: '200px', class: 'px-1' },
        { text: '対応内容', value: 'taiou', width: '200px', class: 'px-1' },
        { text: '受付日', value: 'recept_date', width: '100px', class: 'px-1' },
        { text: '完了日', value: 'comp_date', width: '100px', class: 'px-1' },
        { text: '処理担当', value: 'syori_tanto_name', width: '100px', class: 'px-1' },
      ],
      mainte_list: [],

      //点検予定・実績
      inspectionSortBy: 'row_no',
      inspectionHeaders: [
        { text: 'No.', value: 'row_no', width: '50px', class: 'px-1'  },
        { text: '点検名称', value: 'insp_name', width: '150px', class: 'px-1' },
        { text: '点検種別', value: 'insp_type', width: '150px', class: 'px-1' },
        { text: '予定日', value: 'yotei_date', width: '100px', class: 'px-1' },
        { text: '実施日', value: 'jissi_date', width: '100px', class: 'px-1' },
        { text: '実施担当', value: 'jissi_tanto_name', width: '100px', class: 'px-1' },
        { text: '対応内容', value: 'taiou', width: '200px', class: 'px-1' },
      ],
      inspect_list: [],
      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
  background-image: url("../../images/bg02.jpg");
  background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap {
   white-space: nowrap;
}

td.text-start,td.text-end {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

/* チェックボックスのラベル位置調整用 */
label {
  margin-bottom: 0 !important;
}
</style>
