export default {
  eigyo_menuItems: [
    {
      name: 'ホーム',
      viewName: 'eigyo-home',
      url: '#'
    },
    {
      name: '営業日報一覧',
      viewName: 'eigyo-nippou-list',
      url: '#'
    },
    {
      name: '顧客一覧',
      viewName: 'customer-list',
      url: '#'
    },
    {
      name: '引合一覧',
      viewName: 'inquiry-list',
      url: '#'
    },
    {
      name: '工事見積一覧',
      viewName: 'constr-est-list',
      url: '#'
    },
    {
      name: '受注一覧',
      viewName: 'order-list',
      url: '#'
    }
  ],
  koji_menuItems: [
    {
      name: 'ホーム',
      viewName: 'koji-home',
      url: '#'
    },
    {
      name: '工事日報一覧',
      viewName: 'koji-nippou-list',
      url: '#'
    },
    {
      name: '工事一覧',
      viewName: 'koji-list',
      url: '#'
    },
    {
      name: 'メンテナンス一覧',
      viewName: 'mainte-list',
      url: '#'
    },
    {
      name: '点検一覧',
      viewName: 'inspect-list',
      url: '#'
    },
  ],
  sys_menuItems: [
    {
      name: 'アラート検索',
      viewName: 'alert-srch',
      url: '#'
    },
    {
      name: '担当者マスタ',
      viewName: 'mst-user',
      url: '#'
    },
    {
      name: '汎用マスタ',
      viewName: 'mst-general',
      url: '#'
    },
  ],
  modeItems:
  [
    {
      text:'営業支援',
      value:'eigyo',
    },
    {
      text:'工事施工管理',
      value:'koji',
    },
    {
      text:'システム',
      value:'sys',
    },
  ],
  dispTiltes:
  [
    {
      view_name:'MenuHeader', 
      view_disp_name:'メニュー',
    },
    {
      view_name:'EigyoHome',
      view_disp_name:'営業担当ホーム',
    },
    {
      view_name:'CustomerList',
      view_disp_name:'顧客一覧',
    },
    {
      view_name:'CustomerPortal',
      view_disp_name:'顧客ポータル',
    },
    {
      view_name:'Customer', 
      view_disp_name:'顧客詳細情報'
    },
    {
      view_name:'CustomerEdit', 
      view_disp_name:'顧客情報登録・編集',
    },
    {
      view_name:'EigyoNippouList', 
      view_disp_name:'営業日報一覧',
    },
    {
      view_name:'EigyoNippou',
      view_disp_name:'営業日報',
    },
    {
      view_name:'EigyoNippouEdit',
      view_disp_name:'営業日報登録・編集',
    },
    {
      view_name:'InquiryList', 
      view_disp_name:'引合一覧',
    },
    {
      view_name:'InquiryDetail',
      view_disp_name:'引合情報詳細',
    },
    {
      view_name:'InquiryEdit', 
      view_disp_name:'引合情報登録・編集',
    },
    {
      view_name:'ConstrEstList', 
      view_disp_name:'工事見積一覧',
    },
    {
      view_name:'ConstrEst', 
      view_disp_name:'工事見積情報',
    },
    {
      view_name:'ConstrEstEdit', 
      view_disp_name:'工事見積登録・編集',
    },
    {
      view_name:'OrderList',
      view_disp_name:'受注一覧',
    },
    {
      view_name:'Order', 
      view_disp_name:'受注情報',
    },
    {
      view_name:'OrderEdit', 
      view_disp_name:'受注登録・編集',
    },
    {
      view_name:'KojiHome', 
      view_disp_name:'工事担当ホーム',
    },
    {
      view_name:'KojiNippouList', 
      view_disp_name:'工事日報一覧',
    },
    {
      view_name:'KojiNippou',
      view_disp_name:'工事日報',
    },
    {
      view_name:'KojiNippouEdit', 
      view_disp_name:'工事日報登録・編集',
    },
    {
      view_name:'KojiList', 
      view_disp_name:'工事一覧',
    },
    {
      view_name:'KojiPortal',
      view_disp_name:'工事ポータル',
    },
    {
      view_name:'KojiDetail', 
      view_disp_name:'工事詳細情報',
    },
    {
      view_name:'KojiEdit', 
      view_disp_name:'工事情報登録・編集',
    },
    {
      view_name:'MainteList', 
      view_disp_name:'メンテナンス一覧',
    },
    {
      view_name:'Maintenance',
      view_disp_name:'メンテナンス情報',
    },
    {
      view_name:'MainteEdit',
      view_disp_name:'メンテナンス登録・編集',
    },
    {
      view_name:'InspectList', 
      view_disp_name:'点検一覧',
    },
    {
      view_name:'Inspection',
      view_disp_name:'点検情報',
    },
    {
      view_name:'InspectEdit', 
      view_disp_name:'点検登録・編集',
    },
    {
      view_name:'SystemHome', 
      view_disp_name:'システム管理ホーム',
    },
    {
      view_name:'AlertSrch', 
      view_disp_name:'アラート検索',
    },
    {
      view_name:'MasterMan',
      view_disp_name:'マスタ管理メニュー',
    },
    {
      view_name:'MstGeneral',
      view_disp_name:'汎用マスタ',
    },
    {
      view_name:'MstUser',
      view_disp_name:'担当マスタ',
    },
  ]

}