<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-card
        class="mt-0 mb-4 ml-4 mr-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="10"
      >
        <v-card-actions>
          <div style="width: 200px">
            <v-icon>
              mdi-text-box-outline
            </v-icon>
            工事見積登録・編集
          </div>
          <v-spacer></v-spacer>
          <span class="text-body-2 mx-4">
            ※内容を変更した場合、登録ボタンを押してください
          </span>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="regMitumori"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            登録
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backBtn"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-1"></v-divider>
        <!-- 基本情報 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <div class="mx-2 my-auto">基本情報</div>
            <v-spacer></v-spacer>
            <!-- <v-btn -->
              <!-- class="mx-1 my-1" -->
              <!-- color="cyan darken-1" -->
              <!-- dark -->
              <!-- small -->
              <!-- @click:row="showOrder" -->
            <!-- > -->
              <!-- <v-icon left> -->
                <!-- mdi-pencil -->
              <!-- </v-icon> -->
              <!-- 受注情報表示／登録 -->
            <!-- </v-btn> -->
          </v-sheet>
            
          <v-form ref="basic_info" v-model="valid" lazy-validation>
            <v-row no-gutters class="mt-2 mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">見積番号</v-subheader>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="edit_mitumori_no_comp"
                  background-color="yellow lighten-4"
                  solo
                  dense
                  hide-details
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-subheader class="pb-2 red--text float-right">見積日</v-subheader>
              </v-col>
              <v-col cols="2">
                <div style="width: 180px">
                  <v-text-field
                    v-model="edit_mitumori_date" 
                    placeholder="日付を選択"
                    solo
                    readonly
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                    @click="dateClick('edit_mitumori_date')"
                  >
                    <template v-slot:append>
                      <date-picker ref="edit_mitumori_date" v-model="edit_mitumori_date"/>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">顧客番号</v-subheader>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="customer_select"
                  background-color="yellow lighten-4"
                  solo
                  dense
                  hide-details
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">顧客名</v-subheader>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="customer_select"
                  :items="cust_name_list"
                  item-value="id"
                  item-text="name"
                  placeholder="顧客を選択"
                  solo
                  clearable
                  dense
                  hide-details
                  @change="changeCustomerSelect"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 red--text float-right">引合内容</v-subheader>
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="hikiai_select"
                  placeholder="引合内容を選択"
                  :items="inquiry_list[customer_select]"
                  item-value="id"
                  item-text="name"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required]"
                  @change="changeHikiaiSelect"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col lg="2" md="2">
                <v-subheader class="pb-2 float-right">工事種別</v-subheader>
              </v-col>
              <v-col lg="4" md="6">
                <v-text-field
                  v-model="hikiai_info.kouji_type_name"
                  background-color="yellow lighten-4"
                  solo
                  dense
                  hide-details
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">引合備考</v-subheader>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="hikiai_info.bikou"
                  background-color="yellow lighten-4"
                  solo
                  dense
                  hide-details
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 red--text float-right">工事名</v-subheader>
              </v-col>
              <v-col lg="4" md="10">
                <v-text-field
                  v-model="edit_kouji_name"
                  placeholder="工事名を入力"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required]"
                ></v-text-field>
              </v-col>
              <v-col lg="2" md="2">
                <v-subheader class="pb-2 float-right">プラン名</v-subheader>
              </v-col>
              <v-col lg="4" md="10">
                <v-text-field
                  v-model="edit_plan_name"
                  placeholder="プラン名を入力"
                  solo
                  clearable
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">工事場所</v-subheader>
              </v-col>
              <v-col cols="4">
                <div class="d-flex">
                  <span class="mt-2">
                    〒
                  </span>
                  <v-text-field
                    v-model="edit_kouji_post_cd1"
                    class="mx-2"
                    solo
                    dense
                    maxlength="3"
                    hide-details="auto"
                    :rules="[validRules.isNumber, validRules.postCd3]"
                  ></v-text-field>
                  <span class="mt-2">
                    －
                  </span>
                  <v-text-field
                    v-model="edit_kouji_post_cd2"
                    class="mx-2"
                    solo
                    dense
                    maxlength="4"
                    hide-details="auto"
                    :rules="[validRules.isNumber, validRules.postCd4]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="6" class="d-frex">
                <v-btn
                  class="ml-1"
                  color="light-blue darken-1"
                  small
                  dark
                  @click="transcribeAddress"
                >
                  現住所転記
                </v-btn>
                <v-btn
                  class="ml-1"
                  color="green darken-1"
                  small
                  dark
                  @click="convertPostalCode()"
                >
                  〒 ⇔ 住所
                </v-btn>
                <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                  <input type="hidden" name="_token" :value="csrf">
                  <input type="hidden" name="mapdata" value="">
                </form>
                <v-btn
                  class="ml-1"
                  color="indigo darken-1"
                  small
                  dark
                  @click="showMap()"
                >
                  <v-icon left>
                    mdi-map-marker
                  </v-icon>
                  地図
                </v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
              </v-col>
              <v-col cols="10">
                <v-text-field class="mb-2"
                  v-model="edit_kouji_addr"
                  placeholder="住所を入力"
                  solo
                  clearable
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
              </v-col>
              <v-col lg="6" md="10">
                <v-text-field class="mb-2"
                  v-model="edit_kouji_addr2"
                  placeholder="建物名などを入力"
                  solo
                  clearable
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col lg="1" md="2">
                <v-subheader class="pb-2 float-right">地区</v-subheader>
              </v-col>
              <v-col lg="3" md="3">
                <v-autocomplete
                  v-model="edit_area_id"
                  :items="area_list"
                  item-value="id"
                  item-text="name"
                  placeholder="地区を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">緯度</v-subheader>
              </v-col>
              <v-col lg="4" md="10">
                <v-text-field
                  v-model="edit_kouji_lat"
                  placeholder="緯度を入力"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.isDecimalSigned]"
                ></v-text-field>
              </v-col>
              <v-col lg="2" md="2">
                <v-subheader class="pb-2 float-right">経度</v-subheader>
              </v-col>
              <v-col lg="4" md="10">
                <v-text-field
                  v-model="edit_kouji_lng"
                  placeholder="経度を入力"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.isDecimalSigned]"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">営業担当</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="hikiai_info.hikiai_tanto_name"
                  background-color="yellow lighten-4"
                  solo
                  dense
                  hide-details
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">見積担当</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-autocomplete
                  v-model="edit_mitumori_tanto"
                  :items="tanto_name_list"
                  item-value="user_id"
                  item-text="user_name"
                  placeholder="見積担当を選択"
                  multiple chips deletable-chips
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters class="mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">施工面積</v-subheader>
              </v-col>
              <v-col class="d-flex" cols="5">
                <div style="width: 120px">
                  <comma-number-field 
                    v-model="edit_sekou_area"
                    class="right-input"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.isDecimalCommaSigned]"
                  >
                  </comma-number-field>
                </div>
                <div class="mx-1 mt-2">㎡</div>
                <div style="width: 120px">
                  <comma-number-field 
                    class="right-input"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                    :value="area_tubo_comp"
                  >
                  </comma-number-field>
                </div>
                <div class="mx-1 mt-2">坪</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2 mr-2">
              <v-col cols="2">
                <v-subheader class="pb-2 float-right">提示額</v-subheader>
              </v-col>
              <v-col cols="10">
                <v-row no-gutters>
                  <v-col lg="1" md="1">
                    <v-subheader class="pb-2 pl-0 pr-2 float-right">税込</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <comma-number-field 
                      class="right-input"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                      :value="edit_pres_tax"
                    >
                    </comma-number-field>
                  </v-col>
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 pl-0 pr-2 float-right">税抜</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <comma-number-field 
                      class="right-input"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                      :value="edit_pres_amt"
                    >
                    </comma-number-field>
                  </v-col>
                  <v-col lg="auto" md="3">
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="pb-2 pl-0 pr-2 float-right">税額</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <comma-number-field 
                      class="right-input"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                      :value="edit_pres_tax_comp"
                    >
                    </comma-number-field>
                  </v-col>
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 pl-0 pr-2 float-right">税率</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3" class="d-flex">
                    <v-text-field style="width: 80px"
                      v-model="edit_tax_rate"
                      class="right-input"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.isDecimal]"
                    >
                    </v-text-field>
                    <div class="mx-1 mt-2">%</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>

        <!-- 見積明細 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-0"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <div class="mx-2 my-auto">見積明細</div>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-1 my-1"
              color="cyan darken-1"
              dark
              small
              @click="createMeisai()"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              追加
            </v-btn>
          </v-sheet>

          <v-row no-gutters class="mt-0 mb-0 mx-2">
            <v-col lg="3" md="4">
              <span class="ml-2 red--text text-body-2">工事部位</span>
            </v-col>
            <v-col cols="2">
              <span class="ml-2 red--text text-body-2">数量</span>
            </v-col>
            <v-col lg="2" md="3">
              <span class="ml-2 text-body-2">単位</span>
            </v-col>
            <v-col cols="2">
              <span class="ml-2 red--text text-body-2">単価</span>
            </v-col>
            <v-col cols="2" v-if="!$vuetify.breakpoint.smAndDown">
              <span class="ml-2 text-body-2">金額</span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-0 mb-0 mx-2">
            <v-col lg="3" md="4">
              <span class="ml-2 text-body-2">仕様・モデル</span>
            </v-col>
            <v-col lg="6" md="5">
              <span class="ml-2 text-body-2">備考</span>
            </v-col>
            <v-col cols="2" v-if="$vuetify.breakpoint.smAndDown">
              <span class="ml-2 text-body-2">金額</span>
            </v-col>
          </v-row>
          <v-divider class="mt-0 mb-0"></v-divider>

          <div 
            v-for="(mei_item, mei_index) in meisai_list"
            :key="mei_index"
            >
          <v-form :ref="'meisai_info_'+mei_index" v-model="valid" lazy-validation>
            <v-row no-gutters class="mt-2 mx-2">
              <v-col lg="3" md="4" class="pr-2">
                <v-autocomplete
                  v-model="meisai_list[mei_index].kouji_part"
                  :items="kj_part_list"
                  item-value="id"
                  item-text="name"
                  placeholder="工事部位を選択"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required]"
                  @change="changeKoujiPartSelect"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="pr-2">
                <v-text-field
                  v-model="meisai_list[mei_index].num"
                  class="right-input"
                  :placeholder="$vuetify.breakpoint.smAndDown ? '数量' : '数量を入力'"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required,validRules.isDecimal]"
                ></v-text-field>
              </v-col>
              <v-col lg="2" md="3" class="pr-2">
                <v-autocomplete
                  v-model="meisai_list[mei_index].unit"
                  :items="unit_list"
                  item-value="id"
                  item-text="name"
                  :placeholder="$vuetify.breakpoint.smAndDown ? '単位を選択' : '単位選択'"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col lg="2" md="3" class="pr-2">
                <comma-number-field 
                  v-model="meisai_list[mei_index].price"
                  class="right-input"
                  placeholder="単価を入力"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required,validRules.isDecimalCommaSigned]"
                >
                </comma-number-field>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.smAndDown"
                cols="2" class="pr-2"
              >
                <comma-number-field 
                  class="right-input"
                  background-color="yellow lighten-4"
                  solo
                  dense
                  hide-details
                  readonly
                  :value="meisai_list[mei_index].meisaiAmt"
                >
                </comma-number-field>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.smAndDown"
                cols="1" class="d-flex pr-2"
              >
                <v-btn
                  class="ml-auto my-auto"
                  color="red darken-1"
                  x-small
                  fab
                  dark
                  elevation="2"
                  @click="deleteMeisai(mei_index)"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-2 mx-2">
              <v-col lg="3" md="4" class="pr-2">
                <v-autocomplete
                  v-model="meisai_list[mei_index].kouji_spec_model"
                  :items="spec_model_list[meisai_list[mei_index].kouji_part]"
                  item-value="id"
                  item-text="name"
                  placeholder="仕様・モデルを選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col lg="6" md="5">
                <div class="d-flex">
                  <div class="mr-2" style="width: 100%">
                    <v-text-field
                      placeholder="備考を入力"
                      solo
                      clearable
                      dense
                      hide-details
                      v-model="meisai_list[mei_index].bikou"
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
              <v-col v-if="$vuetify.breakpoint.smAndDown"
                cols="2" class="pr-2"
              >
                <comma-number-field 
                  class="right-input"
                  background-color="yellow lighten-4"
                  solo
                  dense
                  hide-details
                  readonly
                  :value="meisai_list[mei_index].meisaiAmt"
                >
                </comma-number-field>
              </v-col>
              <v-col v-if="$vuetify.breakpoint.smAndDown"
                cols="1" class="d-flex pr-2"
              >
                <v-btn
                  class="ml-auto my-auto"
                  color="red darken-1"
                  x-small
                  fab
                  dark
                  elevation="2"
                  @click="deleteMeisai(mei_index)"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          </div>

          <v-divider class="my-2"></v-divider>

          <v-row no-gutters class="mt-0 mb-0 mx-2">
            <v-col cols="7">
            </v-col>
            <v-col cols="2">
              <v-subheader class="pb-2 float-right">合計金額</v-subheader>
            </v-col>
            <v-col cols="2" class="pr-2">
              <comma-number-field 
                class="right-input"
                background-color="yellow lighten-4"
                solo
                dense
                hide-details
                readonly
                :value="meisaiTotalAmt"
              ></comma-number-field>
            </v-col>
            <v-col cols="1">
              <v-btn
                class="my-auto"
                color="light-blue darken-1"
                dark
                :width="$vuetify.breakpoint.smAndDown ? 50 :84"
                @click="meisaiTotalAmt_btn"
              >
                <span v-if="$vuetify.breakpoint.smAndDown">
                  再計算
                </span>
                <span v-else>
                  <v-icon>
                    mdi-calculator
                  </v-icon>
                  再計算
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import DatePicker from "./DatePicker.vue";
import dbconstrest from './js/common/dbconstrest.js'
import gmap from './js/common/gmap.js'
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbconstrest, gmap],
  props:{
      "parameter": {},
  },
  computed:{
    edit_mitumori_no_comp()
    {
      if (this.edit_mitumori_no == null)
      {
        return "新規登録"
      }
      else 
      {
        return this.edit_mitumori_no
      }
    },

    edit_pres_tax_comp()
    {
      // 税額計算
      if ((this.edit_pres_tax != null) && (this.edit_pres_amt != null)){
        return this.edit_pres_tax - this.edit_pres_amt
      }
      else {
        return null
      }
    },

    area_tubo_comp()
    {
      if (this.edit_sekou_area != null)
      {
        return Number((this.edit_sekou_area * 0.3025).toFixed(2))
      }
    },
  },

  methods: {
    dateClick(dpickName) {
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    changeCustomerSelect(cust_no)
    {
      this.getInquiryNameList(cust_no);  // 引合リスト取得
      // 引合を選択解除
      this.hikiai_select = null
      this.hikiai_info.kouji_type_name = null
      this.hikiai_info.bikou = null
      this.hikiai_info.hikiai_tanto_name = null
      for(var i=0;i<this.meisai_list.length;i++)
      {
        this.meisai_list[i].kouji_part = null;
        this.meisai_list[i].kouji_spec_model = null;
      }
    },
    async changeHikiaiSelect(hikiai_no)
    {
      await this.getHikiaiData(hikiai_no);  // 引合データ取得
      await this.getKoujiPartList(this.hikiai_info.kouji_type); // 工事部位リスト取得
      for(var i=0;i<this.meisai_list.length;i++)
      {
        // if ((this.meisai_list[i].kouji_part in this.kj_part_list) == false)
        if (this.kj_part_list.find((v) => v.id === this.meisai_list[i].kouji_part) == undefined)
        {
          this.meisai_list[i].kouji_part = null;
          this.meisai_list[i].kouji_spec_model = null;
        }
        else
        {
          this.getSpecModelList(this.meisai_list[i].kouji_part);
        }
      }
    },
    transcribeAddress()
    {
      // 現住所転記
      this.getCustomerAddressData(this.customer_select)
    },

    createMeisai(){
      // 明細追加
      {
        var newMeisai = {
        }
        this.meisai_list.push(newMeisai)
      }
    },
    deleteMeisai(index){
      // 明細削除
      if (index < this.meisai_list.length)
      {
        var res = confirm('この見積明細を削除します。よろしいですか？');
        if (res == true) {
          this.meisai_list.splice(index, 1)
        }
      }
    },

    meisaiTotalAmt_btn()
    {
      if (this.inputCheckCalc() == false)
      {
        return;
      }
      this.meisaiTotalAmt_calc()

    },
    meisaiTotalAmt_calc()
    {
      var total = 0;
      if (this.meisai_list)
      {
        for (var i=0; i<this.meisai_list.length; i++)
        {
          var amt = 0;
          if (this.meisai_list[i] != undefined)
          {
            if ((this.meisai_list[i].price != undefined) && (this.meisai_list[i].num != undefined))
            {
              amt = Number(this.meisai_list[i].price) * Number(this.meisai_list[i].num);
              total += amt;
            }
            this.meisai_list[i].meisaiAmt = amt;
          }
        }
      }
      this.meisaiTotalAmt = Number(total); // 明細合計金額
      this.edit_pres_amt = Number(total)  //  提示額税抜
      this.edit_pres_tax = Number((total + total * Number(this.edit_tax_rate) / 100).toFixed(0)); // 提示額税込
    },


    showOrder() {
      //受注情報がある場合
      //「受注情報」画面へ遷移
      // this.$emit('viewChange', 'Order', null, 5, 'eigyo');
      //受注情報がない場合
      //「受注登録・編集」画面へ遷移
      //this.$emit('viewChange', 'OrderEdit', null, 5, 'eigyo');
    },

    convertPostalCode()
    {
      var postalcode = ((this.edit_kouji_post_cd1!=null)?this.edit_kouji_post_cd1:'') + ((this.edit_kouji_post_cd2!=null)?this.edit_kouji_post_cd2:'')
      var searchaddress = ((this.edit_kouji_addr!=null)?this.edit_kouji_addr:'') + ((this.edit_kouji_addr2!=null)?this.edit_kouji_addr2:'')

      this.searchPostalcodeAddress(postalcode+searchaddress,(newaddr,newpostcd)=>{
        this.edit_kouji_addr = newaddr;
        this.edit_kouji_lat = null;
        this.edit_kouji_lng = null;
        this.edit_kouji_post_cd1 = newpostcd.substring(0,3)
        this.edit_kouji_post_cd2 = newpostcd.substring(3,7)
      });
      this.edit_kouji_addr2 = '';

    },
    showMap() {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）

      // 顧客住所取得
      axios.get('constr_est/list?type=custaddr&cust_no=' + encodeURIComponent(this.customer_select))
        .then(response => {
          if (response.data.info)
          {
            // 顧客住所
            var cust_addr = ((response.data.info.addr!=null)?response.data.info.addr:'') + ((response.data.info.addr2!=null)?response.data.info.addr2:'');
            var cust_lat = response.data.info.addr_lat;
            var cust_lng = response.data.info.addr_lng;
            // 地図表示情報
            this.sendMapInfo(cust_addr, cust_lat, cust_lng);
          }
          else 
          {
            this.sendMapInfo(null, null, null);
          }
        })
        .catch(
          // response => console.log(response)
        );
    },
    // 地図表示情報
    sendMapInfo(cust_addr, cust_lat, cust_lng) {
      var searchaddress = ((this.edit_kouji_addr!=null)?this.edit_kouji_addr:'') + ((this.edit_kouji_addr2!=null)?this.edit_kouji_addr2:'')
      var title_kouji = '工事場所'
      var title_juusyo = '現住所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var custname = this.cust_name_list.find((v) => v.id === this.customer_select);
      var lat_kouji = this.edit_kouji_lat
      var lng_kouji = this.edit_kouji_lng
      var lat_juusyo = cust_lat
      var lng_juusyo = cust_lng
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      i_data.value = JSON.stringify({
        'view_name': this.open_view_name,
        'cust_no': (this.customer_select)?this.customer_select:'',
        'cust_name': (custname && custname.name)?custname.name:'',
        'markercount': (cust_addr)?2:1,
        'editindex': 0,
        'markertitle': [title_kouji,title_juusyo],
        'markeraddress': [searchaddress,cust_addr],
        'markerlat': [lat_kouji,lat_juusyo],
        'markerlng': [lng_kouji,lng_juusyo],
      });
      postform.submit();

    },
    backConstrEstList() {
      //「工事見積一覧」画面へ
      this.$emit('viewChange', 'ConstrEstList');
    },
    backBtn() {
      // 戻るボタン
      this.$emit('viewBack');
    },

    // 工事部位を選択
    changeKoujiPartSelect(id)
    {
      this.getSpecModelList(id) // 仕様モデルリスト取得
    },
    // 引合情報
    async getHikiaiData(hikiai_no)
    {
      let res=await axios.get('constr_est/list?type=hikiaiinfo&hikiai_no=' + encodeURIComponent(hikiai_no))
      this.hikiai_info = res.data.info;
    },

    // 顧客住所情報(現住所転記用)
    getCustomerAddressData(cust_no)
    {
      axios.get('constr_est/list?type=custaddr&cust_no=' + encodeURIComponent(cust_no))
        .then(response => {
          if (response.data.info)
          {
            // 工事場所郵便番号
            if ((response.data.info.post_cd != undefined) && (response.data.info.post_cd != null))
            {
              this.edit_kouji_post_cd1 = response.data.info.post_cd.substring(0,3)
              this.edit_kouji_post_cd2 = response.data.info.post_cd.substring(3,7)
            }
            // 工事場所住所
            this.edit_kouji_addr = response.data.info.addr
            this.edit_kouji_addr2 = response.data.info.addr2
            this.edit_kouji_lat = response.data.info.addr_lat;
            this.edit_kouji_lng = response.data.info.addr_lng;
          }
        })
        .catch(
          // response => console.log(response)
        );
    },

    inputCheckCalc()
    {
      var res;
      //入力チェック
      //--基本情報
      res = this.validRules.isDecimal(this.edit_tax_rate);
      if (res!=true) {
        alert("基本情報の税率に入力不足、または間違いがあります\n"+res);
        return false;
      }
      //--明細
      for (let i=0; i<this.meisai_list.length; i++)
      {
        res = this.validRules.isDecimal(this.meisai_list[i].num);
        if (res!=true) {
          alert("見積明細"+Number(i+1)+"行目の数量に入力不足、または間違いがあります\n"+res);
          return false;
        }
        res = this.validRules.isDecimalSigned(this.meisai_list[i].price);
        if (res!=true) {
          alert("見積明細"+Number(i+1)+"行目の単価に入力不足、または間違いがあります\n"+res);
          return false;
        }
      }

      return true;
    },

    regMitumori()
    {

      //入力チェック
      //--基本情報
      this.valid = this.$refs.basic_info.validate();
      if (!this.valid) {
        alert("基本情報に入力不足、または間違いがあります");
        return;
      }
      //--明細
      for (let index=0; index<this.meisai_list.length; index++)
      {
        var refname = 'meisai_info_'+index;
        // console.log(refname)
        this.valid = this.$refs[refname][0].validate();
        if (!this.valid) {
          alert("見積明細"+Number(index+1)+"行目に入力不足、または間違いがあります");
          return;
        }
      }

      var mitumoridata = {}
      var mitumoritanto = []
      var meisaidata = []
      // 見積
      mitumoridata.mitumori_no = this.edit_mitumori_no
      mitumoridata.hikiai_no = this.hikiai_select
      mitumoridata.mitumori_date = this.edit_mitumori_date
      mitumoridata.kouji_name = this.edit_kouji_name
      mitumoridata.plan_name = this.edit_plan_name
      var postalcode = "" + ((this.edit_kouji_post_cd1!=null)?this.edit_kouji_post_cd1:'') + ((this.edit_kouji_post_cd2!=null)?this.edit_kouji_post_cd2:'')
      mitumoridata.kouji_post_cd = postalcode
      mitumoridata.kouji_addr = this.edit_kouji_addr
      mitumoridata.kouji_addr2 = this.edit_kouji_addr2
      mitumoridata.kouji_addr_lat = this.edit_kouji_lat
      mitumoridata.kouji_addr_lng = this.edit_kouji_lng
      mitumoridata.area_id = this.edit_area_id
      mitumoridata.sekou_area = this.edit_sekou_area
      mitumoridata.pres_amt = this.edit_pres_amt
      mitumoridata.tax_rate = this.edit_tax_rate
      mitumoridata.pres_tax = this.edit_pres_tax

      // 見積担当
      this.edit_mitumori_tanto.forEach(function(elem, index) {
        var tantoitem = {};
        tantoitem.mitumori_no = this.edit_mitumori_no
        tantoitem.tanto_id = elem
        mitumoritanto.push(tantoitem);
      }.bind(this));

      // 明細
      this.meisai_list.forEach(function(elem, index) {
        var meisaiitem = {};
        meisaiitem.mitumori_mei_no = elem.mitumori_mei_no
        meisaiitem.mitumori_no = elem.mitumori_no
        meisaiitem.kouji_part = elem.kouji_part
        meisaiitem.kouji_spec_model = elem.kouji_spec_model
        meisaiitem.price = elem.price
        meisaiitem.num = elem.num
        meisaiitem.bikou = elem.bikou
        meisaiitem.unit = elem.unit
        meisaidata.push(meisaiitem);
      }.bind(this));

      this.setMitumori(mitumoridata, mitumoritanto, meisaidata)
    },

    // 見積登録
    setMitumori(mitumori, tanto, meisai) 
    {
      // データ
      // URL作成
      var uri = 'constr_est'
      // データ格納(store)
      axios.post(uri, {'mitumori':mitumori,'tanto':tanto,'meisai':meisai})
        .then(response => {
          // console.log(response.data);
          alert("工事見積情報を登録・更新しました");
          this.backConstrEstList();  // 成功したら一覧に戻る
        })
        .catch((error) => {
          alert("工事見積情報の登録・更新に失敗しました");
        });
    },
    setlatlngHandler(e)
    {
      if (this.open_view_name == e.detail.view_name)
      {
        if (e.detail.title == '工事場所')
        {
          // 工事場所緯度経度更新
          this.edit_kouji_lat = e.detail.lat
          this.edit_kouji_lng = e.detail.lng
        }
      }
    },
  },
  created(){
    window.document.addEventListener("setlatlng", this.setlatlngHandler); // 緯度経度更新用
    this.getEigyoTantoNameList(); // 営業担当者名リスト取得
    this.getCustomerNameKanaList(); // 顧客名（フリガナ付）リスト取得
    this.getAreaNameList(); // 地区リスト取得
    this.getUnitNameList(); // 単位リスト取得
    if (this.parameter.viewparam)
    {

      if (this.parameter.viewparam.mitumori)
      {
        // 営業担当名
        this.hikiai_info.hikiai_tanto_name = this.parameter.viewparam.mitumori.hikiai_tanto_name
        // 見積担当名
        if ((this.parameter.viewparam.mitumori.mitumori_tanto_id != undefined) && (this.parameter.viewparam.mitumori.mitumori_tanto_id != null))
        {
          var mitumoritanto = this.parameter.viewparam.mitumori.mitumori_tanto_id.split(",");
          for(var i=0;i<mitumoritanto.length;i++)
          {
            this.edit_mitumori_tanto[i] = parseInt(mitumoritanto[i])
          }
        }
        // 見積番号
        this.edit_mitumori_no = this.parameter.viewparam.mitumori.mitumori_no
        // 見積日
        this.edit_mitumori_date = this.parameter.viewparam.mitumori.mitumori_date
        // 工事名
        this.edit_kouji_name = this.parameter.viewparam.mitumori.kouji_name
        // プラン名
        this.edit_plan_name = this.parameter.viewparam.mitumori.plan_name
        // 工事場所郵便番号
        if ((this.parameter.viewparam.mitumori.kouji_post_cd != undefined) && (this.parameter.viewparam.mitumori.kouji_post_cd != null))
        {
          this.edit_kouji_post_cd1 = this.parameter.viewparam.mitumori.kouji_post_cd.substring(0,3)
          this.edit_kouji_post_cd2 = this.parameter.viewparam.mitumori.kouji_post_cd.substring(3,7)
        }
        // 工事場所住所
        this.edit_kouji_addr = this.parameter.viewparam.mitumori.kouji_addr
        this.edit_kouji_addr2 = this.parameter.viewparam.mitumori.kouji_addr2
        // 緯度経度
        this.edit_kouji_lat = this.parameter.viewparam.mitumori.kouji_addr_lat
        this.edit_kouji_lng = this.parameter.viewparam.mitumori.kouji_addr_lng
        // 地区
        this.edit_area_id = this.parameter.viewparam.mitumori.area_id
        // 提示額税抜
        this.edit_pres_amt = this.parameter.viewparam.mitumori.pres_amt
        // 提示額税込
        this.edit_pres_tax = this.parameter.viewparam.mitumori.pres_tax
        // 提示額税率
        this.edit_tax_rate = this.parameter.viewparam.mitumori.tax_rate
        // 施工面積(m2)
        this.edit_sekou_area = Number(this.parameter.viewparam.mitumori.sekou_area)


      }
      if (this.parameter.viewparam.hikiai)
      {
        // 工事種別
        this.hikiai_info.kouji_type = this.parameter.viewparam.hikiai.kouji_type
        // 工事種別名
        this.hikiai_info.kouji_type_name = this.parameter.viewparam.hikiai.kouji_type_name
        // 備考
        this.hikiai_info.bikou = this.parameter.viewparam.hikiai.bikou
        if (this.parameter.viewparam.hikiai.cust_no != undefined)
        {
          this.customer_select = this.parameter.viewparam.hikiai.cust_no
        }
        if (this.parameter.viewparam.hikiai.hikiai_no != undefined)
        {
          this.hikiai_select = this.parameter.viewparam.hikiai.hikiai_no
        }
      }

      if (this.parameter.viewparam.meisai)
      {
        this.meisai_list = this.parameter.viewparam.meisai.concat();
      }
    }
    // データ変換
    for(var i=0;i<this.meisai_list.length;i++)
    {
      this.meisai_list[i].kouji_part = Number(this.meisai_list[i].kouji_part)
      this.meisai_list[i].kouji_spec_model = Number(this.meisai_list[i].kouji_spec_model)
    }

    this.getInquiryNameList(this.customer_select);  // 引合リスト取得
    this.getKoujiPartList(this.hikiai_info.kouji_type); // 工事部位リスト取得
    for(var i=0;i<this.meisai_list.length;i++)
    {
      this.getSpecModelList(this.meisai_list[i].kouji_part);
    }

    this.meisaiTotalAmt_calc();
    this.m_createBreads();
  },
  mounted()
  {
    this.$refs.basic_info.resetValidation();
    for (let index=0; index<this.meisai_list.length; index++)
    {
      var refname = 'meisai_info_'+index;
      this.valid = this.$refs[refname][0].resetValidation();
    }
  },
  beforeDestroy() {
    window.document.removeEventListener("setlatlng", this.setlatlngHandler);  // 緯度経度更新用
  },  
  data: function() {
    return {
      cssconsts: cssconsts,

      tanto_name_list: [],  // 担当者選択
      cust_name_list: [],  // 顧客選択
      area_list: [], // 地区選択
      unit_list: [], // 単位選択
      kj_part_list: [], // 工事部位リスト
      spec_model_list: {}, // スペック・モデルリスト
      inquiry_list: {},  //引合リスト取得

      customer_select: null,
      hikiai_select: null,
      mitumori_tanto_select: null,
      meisai_list: [],  // 明細の編集データ
      hikiai_info: {},  // 引合情報
      hikiai_select: null,
      edit_mitumori_tanto: [],  // 見積担当の編集データ
      edit_mitumori_no: null,  // 見積番号の表示データ
      edit_mitumori_date: null,  // 見積日の編集データ
      edit_kouji_name: null,  // 工事名の編集データ
      edit_plan_name: null,  // プラン名の編集データ
      edit_kouji_post_cd1: null,  // 工事場所郵便番号の編集データ
      edit_kouji_post_cd2: null,  // 工事場所郵便番号の編集データ
      edit_kouji_addr: null,  // 工事場所住所の編集データ
      edit_kouji_addr2: null,  // 工事場所建物名等の編集データ
      edit_kouji_lat: null,  // 工事場所住所緯度の編集データ
      edit_kouji_lng: null,  // 工事場所住所経度の編集データ
      edit_area_id: null, // 地区の編集データ
      edit_pres_amt: null, // 提示額税抜の編集データ
      edit_pres_tax: null, // 提示額税込の編集データ
      edit_tax_rate: 10.00, // 税率の編集データ
      edit_sekou_area: null, // 施工面積(m2)の編集データ
      meisai_list: [],  // 明細の編集データ
      meisaiTotalAmt: null, // 明細の合計金額データ
      breads: [],
      open_view_name: null, // 地図画面オープン時の親画面名
    }
  }
}
</script>
<style>
.right-input input {
  text-align: right;
}
</style>
<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>
