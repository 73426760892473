<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-form id="srch_form" v-model="valid" lazy-validation>
        <!-- 検索条件 -->
        <v-card 
          :color="cssconsts.search_keys_bg_color_01"
          class="mt-0 mb-4 ml-4 mr-4"
          outlined
          elevation="10"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-text-box-search-outline
              </v-icon>
              検索条件
            </span>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4 mt-2">詳細検索</span>
            <v-switch
              v-model="dtl_srch"
              class="mt-1"
              color="primary"
              inset
              dense
              hide-details
              @change="dtl_srch_sw(dtl_srch)"
            ></v-switch>
            <v-btn
              class="mx-1"
              color="blue-grey darken-1"
              dark
              width="100"
              @click="srchCustomer"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              検索
            </v-btn>
            <v-btn
              class="mx-1"
              color="light-blue darken-1"
              dark
              width="100"
              @click="regCustomer"
            >
              新規登録
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-2"></v-divider>

          <!-- 1行目 -->
          <v-container mx-4 pa-0>
              <v-row no-gutters class="pr-3">
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">顧客名</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <v-text-field
                    v-model="name_input"
                    placeholder="顧客名かカナを入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">ＴＥＬ</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <div class="d-flex">
                    <v-text-field
                      v-model="tel_input1"
                      class="mr-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="tel_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="tel_input3"
                      class="ml-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          
            <!-- 2行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters class="pr-3">
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">住所</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <v-text-field
                    v-model="addr_input"
                    placeholder="住所を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">工事場所</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <v-text-field
                    v-model="kj_addr_input"
                    placeholder="工事場所を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
           


          <template v-if="dtl_srch === true">
            <!-- 3行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters class="pr-3">

                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">顧客担当</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <v-autocomplete
                    v-model="tanto_select"
                    :items="tanto_list"
                    item-value="id"
                    item-text="name"
                    placeholder="顧客担当を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">地区</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <v-autocomplete
                    v-model="area_select"
                    :items="area_list"
                    item-value="id"
                    item-text="name"
                    placeholder="地区を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>

          <!-- 4行目 -->
           <v-container mx-4 pa-0>
            <v-row no-gutters jastify class="pr-3">
              <v-col lg="1" md="2">
                <v-subheader class="pl-0 pb-2 float-right">顧客種別</v-subheader>
              </v-col>
              <v-col lg="3" md="4">
                <v-autocomplete
                  v-model="cust_type_select"
                  :items="cust_type_list"
                  item-value="id"
                  item-text="name"
                  placeholder="顧客種別を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col lg="auto" md="6">
              </v-col>
              <v-col lg="1" md="2">
                <v-subheader class="pb-2 float-right">ランク</v-subheader>
              </v-col>
              <v-col lg="4" md="5">
                <v-autocomplete
                  v-model="rank_select"
                  :items="rank_list"
                  item-value="id"
                  item-text="name"
                  placeholder="ランクを選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col lg="1" md="2">
                <v-subheader class="pb-2 float-right">ＤＭ</v-subheader>
              </v-col>
              <v-col lg="2" md="3">
                <v-autocomplete
                  v-model="dm_select"
                  :items="dmConds"
                  item-value="id"
                  item-text="name"
                  placeholder="ＤＭ対象"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
           </v-container>
          </template>

        </v-card>
      </v-form>
      <!-- 検索結果 -->
      <v-card
        :color="cssconsts.search_result_bg_color_01"
        class="ma-4"
        outlined
        elevation="10"
      >
        <div class="mt-2 ml-2">
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            検索結果
          </span>
        </div>
        <v-divider class="mt-1 mb-0"></v-divider>
        <v-flex class="mt-2 mb-4 mx-4">
          <CustomerTable v-bind:customer_list=customer_srch_list @RowClick="showPortal" ref="cust_table"/>
        </v-flex>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import DatePicker from "./DatePicker.vue";
import CustomerTable from "./CustomerTable.vue";
import dbcustomer from './js/common/dbcustomer.js'
//export
export default {
  components: {
    DatePicker,
    CustomerTable,
  },
  mixins: [dbcustomer],

  props:{
      "parameter": {},
  },

  created : function(){
    this.getIdNameList("custType");  // 顧客種別リスト取得
    this.getIdNameList("rank");      // ランクリスト取得
    this.getIdNameList("tanto");     // 営業担当者リスト取得
    this.getIdNameList("area");      // 地区リスト取得
    //全ての顧客を読み出す
    this.getCustomerList();
    this.m_createBreads();
  },
  mounted() {
  },

  methods: {
    dtl_srch_sw(sw) {
      //this.$refs["cust_table"].height = (sw ? 380 : 500);
    },
    dateClick(dpickName) {
      //alert('Date Clicked!');
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    srchCustomer()
    {
      if (!this.valid) {
        //条件入力エラーあり
        return;
      }
      //顧客を検索
      var cust_type = this.cust_type_select;
      var cust_rank = this.rank_select;
      var dm_flg = this.dm_select;
      var cust_name = this.name_input;
      var addr = this.addr_input;
      var kj_addr = this.kj_addr_input;
      var tel_no1 = this.tel_input1;
      var tel_no2 = this.tel_input2;
      var tel_no3 = this.tel_input3;
      var cust_tanto = this.tanto_select;
      var area_id = this.area_select;
      if (this.dtl_srch) {
        //詳細検索
        this.getCustomerList(
          cust_name,tel_no1, tel_no2, tel_no3, addr, kj_addr, cust_type, cust_rank, dm_flg, cust_tanto, area_id
        );
      } else {
        //簡易検索
        this.getCustomerList(
          cust_name,tel_no1, tel_no2, tel_no3, addr, kj_addr
        );
      }
    },
    regCustomer() {
        //「顧客情報登録・編集」画面へ遷移
        this.$emit('viewChange', 'CustomerEdit', 0);  //新規登録
    },
    showPortal(customer) {
        //alert("顧客番号：" + customer.cust_no);
        //「顧客ポータル」画面へ遷移
        this.$emit('viewChange', 'CustomerPortal', customer.cust_no);

        //test -> 顧客情報登録・編集
        //this.$emit('viewChange', 'CustomerEdit', customer.cust_no);
    }
  },

  data: function(){
    return {
      cssconsts: cssconsts,
      cust_type_list: [],
      rank_list: [],
      tanto_list: [],
      area_list: [],
      customer_srch_list: [],
      dtl_srch: false,

      cust_type_select: null,
      rank_select: null,
      dm_select: null,
      name_input: null,
      kana_input: null,
      addr_input: null,
      kj_addr_input: null,
      tel_input1: null,
      tel_input2: null,
      tel_input3: null,
      tanto_select: null,
      area_select: null,

      dmConds: [
        { id: '1', name: '対象' },
        { id: '0', name: '対象外' },
      ],
      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap{
   white-space: nowrap;
}

td.text-start,td.text-end{
    padding-right: 3px !important;
    padding-left: 3px !important;
}
</style>
