export default {
    methods: {
        // 各リスト取得
        getIdNameList: function (id) {
            axios.get('maintenance/list?type='+id)
                .then(response => {
                    if (id == "tanto") {
                        this.tanto_list = response.data;
                    } else if  (id == "customer") {
                        this.customer_list = response.data;
                    }
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客工事リスト取得
        getCustKoujiList: function (cust_no) {
            axios.get('maintenance/list?type=custKouji&custNo='+cust_no)
                .then(response => {
                    this.cust_kouji_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 工事部位取得
        getKoujiPartList: function (kouji_no, kouji_type_id = null) {
            // URL作成
            var uri = 'maintenance/list?type=koujiPart&kouji_no='+kouji_no;
            if (kouji_type_id != null) {
                uri += '&kouji_type_id='+kouji_type_id;
            }
            axios.get(uri)
                .then(response => {
                    this.kouji_part = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // メンテナンスリスト取得
        getMaintenanceList: function (
            mainte_tanto, mainte_name, cust_name, kouji_name,
            recept_date_from, recept_date_to, comp_date_from, comp_date_to, mainte_naiyou, only_alert)
        {
            // URL作成
            var uri = 'maintenance';
            var qparam = [];
            if (mainte_tanto != null) {
                qparam.push('mainte_tanto=' + encodeURIComponent(mainte_tanto));
            }
            if (mainte_name != null) {
                qparam.push('mainte_name=' + encodeURIComponent(mainte_name));
            }
            if (cust_name != null) {
                qparam.push('cust_name=' + encodeURIComponent(cust_name));
            }
            if (kouji_name != null) {
                qparam.push('kouji_name=' + encodeURIComponent(kouji_name));
            }
            if (recept_date_from != null) {
                qparam.push('recept_date_from=' + encodeURIComponent(recept_date_from));
            }
            if (recept_date_to != null) {
                qparam.push('recept_date_to=' + encodeURIComponent(recept_date_to));
            }
            if (comp_date_from != null) {
                qparam.push('comp_date_from=' + encodeURIComponent(comp_date_from));
            }
            if (comp_date_to != null) {
                qparam.push('comp_date_to=' + encodeURIComponent(comp_date_to));
            }
            if (mainte_naiyou != null) {
                qparam.push('mainte_naiyou=' + encodeURIComponent(mainte_naiyou));
            }
            if (only_alert != null && only_alert == "yes") {
                qparam.push('alert=yes');
            }

            uri += '?' + qparam.join('&');

            // データ取得
            axios.get(uri)
                .then(response => {
                    this.mainte_srch_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // メンテナンスデータ取得
        getMaintenance: function (mainte_no)
        {
            // データ取得
            axios.get('maintenance/' + mainte_no)
                .then(response => {
                    this.maintenance = response.data['maintenance'];
                    this.kouji_part = response.data['kouji_part'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // メンテナンス詳細データ（表示用）取得
        getMaintenanceDetail: function (mainte_no) 
        {
            // データ取得
            axios.get('maintenance/' + mainte_no + '?forDisplay=yes')
                .then(response => {
                    this.maintenance = response.data['maintenance'];
                    this.kouji_part = response.data['kouji_part'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // メンテナンスデータ登録
        setMaintenance: function (maintenance, koujiPart) 
        {
            // URL作成
            var uri = 'maintenance'
            // データ格納
            axios.post(uri, {'maintenance':maintenance, 'koujiPart':koujiPart})
                .then(response => {
                    if (this.mode == "reg") {
                        alert("メンテナンス情報を登録しました");
                    } else if (this.mode == "addReg") {
                        alert("メンテナンス情報を登録しました");
                    } else {
                        alert("メンテナンス情報を更新しました");
                    }
                    //前画面に戻る
                    this.$emit('viewBack');
                })
                .catch((error) => {
                    alert("メンテナンス情報の登録・更新に失敗しました");
                });
        },

        // メンテナンス削除
        deleteMaintenance: function (mainte_no) 
        {
            // データ削除
            axios.delete('maintenance/' + mainte_no)
                .then(response => {
                    this.backMainteList();  // 成功したら一覧に戻る
                })
                .catch((error) => {
                    alert("メンテナンスの削除に失敗しました");
                });
        },

    },
}
