<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
        <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
        <v-card
          color="rgb(255, 0, 0, 0)" 
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-breadcrumbs :items="breads"></v-breadcrumbs>
        </v-card>
        <v-card
          class="mt-0 mb-4 ml-4 mr-4"
          :color="cssconsts.eigyo_bg_color_base_01"
          outlined
          elevation="10"
          height="100%"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-cog-outline
              </v-icon>
              メインテナンス情報
            </span>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-1"
              color="indigo"
              dark
              width="100"
              @click="editMainte"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              編集
            </v-btn>
            <v-btn
              class="mx-1"
              color="orange darken-3"
              dark
              width="100"
              @click="delMainte"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              削除
            </v-btn>
            <v-btn
              class="mx-1"
              color="grey darken-1"
              dark
              width="100"
              @click="backMainteList"
            >
              <v-icon left>
                mdi-keyboard-return
              </v-icon>
              戻る
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-1"></v-divider>
          <!-- 基本情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">基本情報</div>
            </v-sheet>
              
            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
              </v-col>
              <v-col cols="7" class="yellow lighten-4">
                <span>
                  {{maintenance.cust_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">工事名</span>
              </v-col>
              <v-col cols="7" class="yellow lighten-4">
                <span>
                  {{maintenance.kouji_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">ﾒｲﾝﾃﾅﾝｽ名</span>
              </v-col>
              <v-col cols="7" class="yellow lighten-4">
                <span>
                  {{maintenance.mainte_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">ﾒｲﾝﾃﾅﾝｽ内容</span>
              </v-col>
              <v-col cols="10" class="yellow lighten-4">
                <div v-html="mainte_naiyou"></div>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">受付日</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{maintenance.recept_date}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">受付担当</span>
              </v-col>
              <v-col cols="3" class="yellow lighten-4">
                <span>
                  {{maintenance.recept_tanto_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">完了予定</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{maintenance.comp_yotei_date}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">処理担当</span>
              </v-col>
              <v-col cols="3" class="yellow lighten-4">
                <span>
                  {{maintenance.syori_tanto_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">完了日</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{maintenance.comp_date}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-0 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">対応内容</span>
              </v-col>
              <v-col cols="10" class="yellow lighten-4">
                <div v-html="mainte_taiou"></div>
              </v-col>
            </v-row>
          </v-sheet>

          <!-- 工事部位 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">工事部位</div>
            </v-sheet>
            <template
              v-for="kojiPart in kojiParts"
            >
              <v-row
                no-gutters class="mt-2 mb-0 mx-2"
                :key="kojiPart.partNo"
              >
                <v-col cols="2" class="d-flex">
                  <span class="ml-2 my-auto text-body-2">{{kojiPart.partName}}</span>
                </v-col>
                <v-col cols="10" class="d-flex flex-wrap">
                  <div
                    v-for="(model, index) in kojiPart.models"
                    :key="index"
                    class="ml-2 d-flex"
                  >
                    <v-icon
                      v-if="model.select === true"
                      class="mx-1"
                      color="grey darken-3"
                    >
                      mdi-checkbox-marked-outline
                    </v-icon>
                    <v-icon
                      v-else
                      class="mx-1"
                      color="grey lighten-1"
                    >
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <span class="my-auto text-body-2">{{model.name}}</span>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-0" :key="'d'+kojiPart.partNo"></v-divider>
            </template>
          </v-sheet>
        </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import cssconsts from './js/common/cssconsts';
import dbmaintenance from './js/common/dbmaintenance.js'
//export
export default {
  mixins: [dbmaintenance],

  props:{
      "parameter": {},
  },
  created : function(){
    // メンテナンス番号をthis.parameter.viewparamから取得
    var mainte_no = this.parameter.viewparam;
    // メンテナンス番号が一致するデータを読み出す
    //以下に格納
    //⇒this.maintenance
    //⇒this.kouji_part
    this.getMaintenanceDetail(mainte_no);
    this.m_createBreads();
  },
  mounted() {
  },

  watch: {
    maintenance: function(data) {
      if (data.mainte_naiyou != null) {
        this.mainte_naiyou = data.mainte_naiyou.replace("\n", "<br>");
      }
      if (data.taiou != null) {
        this.mainte_taiou = data.taiou.replace("\n", "<br>");
      }
    },
    kouji_part: function(data) {
      this.kojiParts = [];
      var kj_part_id = 0;
      for (var elem of data) {
        if (elem.kj_part_id != kj_part_id) {
          this.kojiParts.push({
            partNo: elem.kj_part_id,
            partName: elem.kj_part_name,
            models: [],
          });
          kj_part_id = elem.kj_part_id;
        }
        this.kojiParts[this.kojiParts.length - 1].models.push({
          name: elem.model_name,
          select: (elem.choice == "1")
        });
      }
    }
  },

  methods: {
    editMainte() {
      var param = {
        mainte_no: this.maintenance.mainte_no,
        kouji_name: this.maintenance.kouji_name,
        cust_name: this.maintenance.cust_name
      };
      //「メンテナンス登録・編集」画面へ遷移
      this.$emit('viewChange', 'MainteEdit', param);
    },
    delMainte() {
      // メンテナンス削除
      var res = confirm('当メンテナンスを削除します。よろしいですか？');
      if (res == true) {
        this.deleteMaintenance(this.maintenance.mainte_no);
      }
    },
    backMainteList() {
      // 戻るボタン
      this.$emit('viewBack');
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      //メンテナンスデータ
      maintenance: {},
      kouji_part: [],

      //編集項目
      mainte_naiyou: "",
      mainte_taiou: "",
      kojiParts: [], //工事部位
      breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap{
   white-space: nowrap;
}

td.text-start,td.text-end{
    padding-right: 3px !important;
    padding-left: 3px !important;
}
</style>