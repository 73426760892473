export default {
    methods: {
        // 各リスト取得
        getIdNameList: function (id) {
            axios.get('order/list?type='+id)
                .then(response => {
                    if (id == "custType") {
                        this.cust_type_list = response.data;
                    } else if  (id == "rank") {
                        this.rank_list = response.data;
                    } else if  (id == "tanto") {
                        this.tanto_list = response.data;
                    } else if  (id == "area") {
                        this.area_list = response.data;
                    } else if  (id == "koujiType") {
                        this.kouji_type_list = response.data;
                    } else if  (id == "nyukinType") {
                        this.nyukin_type_list = response.data;
                    } else if  (id == "customer") {
                        this.customer_list = response.data;
                    }
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 受注一覧取得
        getOrderList: function (
            cust_name, cust_type, cust_rank, inq_naiyou, kouji_type, kouji_name,
            mitu_date_from, mitu_date_to, order_date_from, order_date_to,
            eigyo_tanto, mitu_tanto, order_amt_from, order_amt_to, area_id)
        {
            // URL作成
            var uri = 'order';
            var qparam = [];
            if (cust_name != null) {
                qparam.push('cust_name=' + encodeURIComponent(cust_name));
            }
            if (cust_type != null) {
                qparam.push('cust_type=' + encodeURIComponent(cust_type));
            }
            if (cust_rank != null) {
                qparam.push('cust_rank=' + encodeURIComponent(cust_rank));
            }
            if (inq_naiyou != null) {
                qparam.push('inq_naiyou=' + encodeURIComponent(inq_naiyou));
            }
            if (kouji_type != null) {
                qparam.push('kouji_type=' + encodeURIComponent(kouji_type));
            }
            if (kouji_name != null) {
                qparam.push('kouji_name=' + encodeURIComponent(kouji_name));
            }
            if (mitu_date_from != null) {
                qparam.push('mitu_date_from=' + encodeURIComponent(mitu_date_from));
            }
            if (mitu_date_to != null) {
                qparam.push('mitu_date_to=' + encodeURIComponent(mitu_date_to));
            }
            if (order_date_from != null) {
                qparam.push('order_date_from=' + encodeURIComponent(order_date_from));
            }
            if (order_date_from != null) {
                qparam.push('order_date_from=' + encodeURIComponent(order_date_from));
            }
            if (order_date_to != null) {
                qparam.push('order_date_to=' + encodeURIComponent(order_date_to));
            }
            if (eigyo_tanto != null) {
                qparam.push('eigyo_tanto=' + encodeURIComponent(eigyo_tanto));
            }
            if (mitu_tanto != null) {
                qparam.push('mitu_tanto=' + encodeURIComponent(mitu_tanto));
            }
            if (order_amt_from != null) {
                qparam.push('order_amt_from=' + encodeURIComponent(order_amt_from));
            }
            if (order_amt_to != null) {
                qparam.push('order_amt_to=' + encodeURIComponent(order_amt_to));
            }
            if (area_id != null) {
                qparam.push('area_id=' + encodeURIComponent(area_id));
            }

            uri += '?' + qparam.join('&');

            // データ取得
            axios.get(uri)
                .then(response => {
                    this.order_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 見積データ取得
        getMitsumori:function (mitumori_no) 
        {
            // データ取得
            axios.get('constr_est/' + mitumori_no)
                .then(response => {
                    this.mitumori = response.data['mitumori'];
                    this.hikiai = response.data['hikiai'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客データ取得
        getCustomer: function (cust_no)
        {
            // データ取得
            axios.get('customer/' + cust_no)
                .then(response => {
                    this.customer = response.data['customer'];
                    this.tantoGrp = response.data['tantoGrp'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 受注データ取得
        getOrder: function (keiyaku_no)
        {
            // データ取得
            axios.get('order/' + keiyaku_no)
                .then(response => {
                    this.order = response.data['order'];
                    this.mitumori = response.data['mitumori'];
                    this.nyukin = response.data['nyukin'];
                    this.customer = response.data['customer'];
                    this.tantoGrp = response.data['tantoGrp'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 受注詳細情報（表示用）取得
        getOrderDetail: function (keiyaku_no)
        {
            // データ取得
            axios.get('order/detail?keiyaku_no=' + keiyaku_no)
                .then(response => {
                    this.order = response.data['order'];
                    this.customer = response.data['customer'];
                    this.nyukin = response.data['nyukin'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 受注データ登録
        setOrder: function (order, nyukin, customer, tantoGrp) 
        {
            // URL作成
            var uri = 'order'
            // データ格納
            axios.post(uri, {'order':order, 'nyukin':nyukin, 'customer':customer, 'tantoGrp':tantoGrp})
                .then(response => {
                    if (this.mode == "reg") {
                        alert("受注情報を登録しました");
                    } else {
                        alert("受注情報を更新しました");
                    }
                    //前画面に戻る
                    this.$emit('viewBack');
                })
                .catch((error) => {
                    // alert(error); 
                });
        },

        // 受注削除
        deleteOrder: function (keiyaku_no) 
        {
            // データ削除
            axios.delete('order/' + keiyaku_no)
                .then(response => {
                    this.backOderList();  // 成功したら一覧に戻る
                })
                .catch((error) => {
                    alert("受注の削除に失敗しました");
                });
        },

    },
}
