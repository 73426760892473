export default {    // exportする
    created() {
        //console.log('start! from mixins.')    // 起動後必ずログが出力される
    },
 
    data() {
        return {
            //serverPass: "https://localhost:10443/",    // サーバーサイドのパスを定義
        }
    },
 
    methods: {
        m_logging() {
            console.log('logging from mixins.')
        },
        m_mixinTest() {
            alert(this.m_twoBytwo);
        },

        //年齢を算出
        m_calcAge(birthDate) {
            var age = null;
            if (birthDate != null) {
                //誕生日から年月日を分解
                var [byear, bmonth, bday] = birthDate.split('/');
                //今日の日付から年月日を取得
                var today = new Date();
                var y = today.getFullYear().toString().padStart(4, '0');
                var m = (today.getMonth() + 1).toString().padStart(2, '0');
                var d = today.getDate().toString().padStart(2, '0');
                //年齢を算出
                age = Math.floor((Number(y + m + d) - Number(byear + bmonth + bday)) / 10000);
            }
            return age;
        },
        m_createBreads(){
            this.parameter.viewHistory.forEach(function (viewState) {
                this.breads.push(
                {
                    text: viewState.dispName,
                });
            },this);
            
            this.breads.push(
              {
                text: this.parameter.viewState.dispName,
            });
        }
    },
 
    computed: {
        m_twoBytwo() {
            return 2 * 2
        }
    }
 }