<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-form id="srch_form">
        <!-- 検索条件 -->
        <v-card
          color="green lighten-4"
          class="mt-0 mb-4 ml-4 mr-4"
          outlined
          elevation="3"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-text-box-search-outline
              </v-icon>
              検索条件
            </span>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4 mt-2">詳細検索</span>
            <v-switch
              v-model="dtl_srch"
              class="mt-1"
              color="primary"
              inset
              dense
              hide-details
              @change="dtl_srch_sw(dtl_srch)"
            ></v-switch>
            <v-btn
              class="mx-1"
              color="blue-grey darken-1"
              dark
              width="100"
              @click="srchNippou"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              検索
            </v-btn>
            <v-btn
              class="mx-1"
              color="light-blue darken-1"
              dark
              width="100"
              @click="regNippou"
            >
              新規登録
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-2"></v-divider>

          <!-- 1行目 -->
          <v-container mx-4 pa-0>
            <v-row no-gutters>
              <v-col lg="1" md="2">
                <v-subheader class="pb-2 float-right">担当者</v-subheader>
              </v-col>
              <v-col lg="3" md="5">
                <v-autocomplete
                  v-model="tanto_select"
                  :items="tanto_name_list"
                  item-value="user_id"
                  item-text="user_name"
                  placeholder="担当者を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col lg="8" md="12">
                <v-row no-gutters>
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">日付</v-subheader>
                  </v-col>
                  <v-col lg="4" md="3">
                  <v-text-field 
                      v-model="date1" 
                      placeholder="日付(From)"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('dpick1')"
                    >
                      <template v-slot:append>
                        <date-picker ref="dpick1" v-model="date1"/>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="px-0 pb-2">
                      <div class="mx-auto">～</div>
                    </v-subheader>
                  </v-col>
                  <v-col lg="4" md="3">
                  <v-text-field 
                      v-model="date2" 
                      placeholder="日付(To)"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('dpick2')"
                    >
                      <template v-slot:append>
                        <date-picker ref="dpick2" v-model="date2"/>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col lg="1" md="2">
                <v-subheader class="pl-0 pb-2 float-right">日報種別</v-subheader>
              </v-col>
              <v-col lg="3" md="4">
                <v-autocomplete
                  v-model="np_type_select"
                  :items="np_type_list"
                  item-value="id"
                  item-text="name"
                  placeholder="日報種別を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>

          <template v-if="dtl_srch === true">
            <!-- 2行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">顧客</v-subheader>
                </v-col>
                <v-col lg="4" md="5">
                  <v-autocomplete
                    v-model="customer_select"
                    :items="cust_name_list"
                    item-value="id"
                    item-text="name"
                    placeholder="顧客を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col lg="auto" md="5">
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">工事名</v-subheader>
                </v-col>
                <v-col lg="5" md="7">
                  <v-text-field
                    v-model="kjname_input"
                    placeholder="工事名を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <!-- 3行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">工事種別</v-subheader>
                </v-col>
                <v-col lg="4" md="5">
                  <v-autocomplete
                    v-model="kjtype_select"
                    :items="kj_type_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工事種別を選択"
                    solo
                    clearable
                    dense
                    hide-details
                    @change="changeKoujiType"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">工事部位</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <v-autocomplete
                    v-model="kjpart_select"
                    :items="kj_part_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工事部位を選択"
                    solo
                    clearable
                    dense
                    hide-details
                    @change="changeKoujiPart"
                  ></v-autocomplete>
                </v-col>
                <v-col lg="auto" md="6">
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">工程</v-subheader>
                </v-col>
                <v-col lg="5" md="7">
                  <v-autocomplete
                    v-model="kjproc_select"
                    :items="kj_proc_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工程を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>

            <!-- 4行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">名称</v-subheader>
                </v-col>
                <v-col lg="4" md="7">
                  <v-text-field
                    v-model="insp_mainte_name_input"
                    placeholder="メンテナンス名／点検名を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col lg="auto" md="3">
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">内容</v-subheader>
                </v-col>
                <v-col lg="5" md="7">
                  <v-text-field
                    v-model="naiyou_input"
                    placeholder="内容を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

        </v-card>
      </v-form>

      <!-- 検索結果 -->
      <v-card
        color="lime lighten-4"
        class="ma-4"
        outlined
        elevation="3"
      >
        <div class="mt-2 ml-2">
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            検索結果
          </span>
        </div>
        <v-divider class="mt-1 mb-0"></v-divider>
        <v-flex class="mt-2 mb-4 mx-4">
          <KojiNippouTable v-bind:nippou_list=kouji_nippou_list @RowClick="showNippou" ref="nptable"/>
        </v-flex>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import KojiNippouTable from "./KojiNippouTable.vue";
import DatePicker from "./DatePicker.vue";
import dbkoujinippou from './js/common/dbkoujinippou.js'
import cssconsts from './js/common/cssconsts';
//export
export default {
  components: {
    DatePicker,
    KojiNippouTable,
  },
  mixins: [dbkoujinippou],
  created() {
    this.getSearchConditionList();
    if (this.parameter.homeparam.loginuser != null)
    {
      var usrid = this.parameter.homeparam.loginuser.user_id
      this.tanto_select = usrid
      this.getKoujiNippouList(usrid)  // ログインユーザーの日報を読み出し
    }
    this.m_createBreads();
  },
  mounted() {
  },
  props:{
      "parameter": {},
  },
  methods: {
    dtl_srch_sw(sw) {
      //alert('dtl_srch:' + dtl_srch);
      this.$refs["nptable"].height = (sw ? 400 : 500);
    },
    dateClick(dpickName) {
      //alert('Date Clicked!');
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    srchNippou()
    {
      //工事日報を検索
      var tanto_id = this.tanto_select
      var datefrom = this.date1
      var dateto = this.date2
      var np_type = this.np_type_select
      var cust_no = this.customer_select
      var kjname = this.kjname_input
      var kjtype = this.kjtype_select
      var kjpart = this.kjpart_select
      var kjproc = this.kjproc_select
      var insp_mainte_name = this.insp_mainte_name_input
      var naiyou = this.naiyou_input

      this.getKoujiNippouList(tanto_id, datefrom, dateto, np_type, cust_no, kjname, kjtype, kjpart, kjproc, insp_mainte_name, naiyou)
    },
    regNippou() {
        //「工事日報登録・編集」画面へ遷移
        this.$emit('viewChange', 'KojiNippouEdit', null);
    },
    changeKoujiType(id)
    {
        //「工事種別」を選択した場合、工事部位と工事工程選択解除、リストを更新
        this.kjpart_select = null;
        this.kjproc_select = null;
        this.getKoujiPartList(id)
    },
    changeKoujiPart(id)
    {
        //「工事部位」を選択した場合、工事工程選択解除、リストを更新
        this.kjproc_select = null;
        this.getKoujiProcList(id)
    },
    showNippou(nippou) {
        //「工事日報」画面へ遷移
        this.$emit('viewChange', 'KojiNippou', nippou.kjnp_no);
    },
    // 工事日報リスト取得
    getKoujiNippouList(tanto_id, datefrom, dateto, np_type, cust_no, kjname, kjtype, kjpart, kjproc, insp_mainte_name, naiyou)
    {
      // URL作成
      var uri = 'kouji_nippou'
      var qparam = ''
      if (tanto_id != null) {
        qparam += 'tanto_id=' + encodeURIComponent(tanto_id) + '&'
      }
      if (datefrom != null) {
        qparam += 'datefrom=' + encodeURIComponent(datefrom) + '&'
      }
      if (dateto != null) {
        qparam += 'dateto=' + encodeURIComponent(dateto) + '&'
      }
      if (np_type != null) {
        qparam += 'np_type=' + encodeURIComponent(np_type) + '&'
      }
      if (cust_no != null) {
        qparam += 'cust_no=' + encodeURIComponent(cust_no) + '&'
      }
      if (kjname != null) {
        qparam += 'kjname=' + encodeURIComponent(kjname) + '&'
      }
      if (kjtype != null) {
        qparam += 'kjtype=' + encodeURIComponent(kjtype) + '&'
      }
      if (kjpart != null) {
        qparam += 'kjpart=' + encodeURIComponent(kjpart) + '&'
      }
      if (kjproc != null) {
        qparam += 'kjproc=' + encodeURIComponent(kjproc) + '&'
      }
      if (insp_mainte_name != null) {
        qparam += 'insp_mainte_name=' + encodeURIComponent(insp_mainte_name) + '&'
      }
      if (naiyou != null) {
        qparam += 'naiyou=' + encodeURIComponent(naiyou) + '&'
      }

      uri += '?' + qparam
      // データ取得
      axios.get(uri)
        .then(response => {
          this.kouji_nippou_list = response.data;
          this.nippouFromInspMenteData(); // メンテナンス・点検データを日報に反映

        })
        .catch(
          // response => console.log(response)
        );
    },
    // メンテナンス・点検データを日報明細に反映
    nippouFromInspMenteData() 
    {
      this.kouji_nippou_list.forEach(function(elem, index) {
        if (elem.kjnp_type == 1603)
        { //メンテナンス
          this.kouji_nippou_list[index].naiyou = elem.mainte_taiou;
          this.kouji_nippou_list[index].kj_proc_name = elem.mainte_name;
        }
        else if (elem.kjnp_type == 1604)
        { //点検
          this.kouji_nippou_list[index].naiyou = elem.insp_taiou;
          this.kouji_nippou_list[index].kj_proc_name = elem.insp_name;
        }
      }.bind(this));
    },

  },

  data: function() {
    return {
      cssconsts: cssconsts,
      kouji_nippou_list: [],
      tanto_name_list: [],
      np_type_list: [],
      cust_name_list: [],
      kj_type_list: [],
      kj_part_list: [],
      kj_proc_list: [],

      dtl_srch: false,
      tanto_select: null,
      np_type_select: null,
      customer_select: null,
      kjname_input: null,
      kjtype_select: null,
      kjpart_select: null,
      kjproc_select: null,
      insp_mainte_name_input: null,
      naiyou_input: null,

      date1: null,
      date2: null,

      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>
