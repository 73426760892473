<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <!-- 工事日報 ヘッダー部 -->
      <v-card
        class="mt-0 mb-4 ml-4 mr-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="10"
      >
        <v-card-actions>
          <div style="width: 200px">
            <v-icon>
              mdi-file-document-edit-outline
            </v-icon>
            工事日報登録・編集
          </div>
          <v-spacer></v-spacer>
          <span class="text-body-2 mx-4">
            ※内容を変更した場合、登録ボタンを押してください
          </span>
          <v-btn
            class="mx-1"
            color="blue darken-1"
            dark
            width="100"
            @click="regNippou"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            登録
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backBtn"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-2"></v-divider>

        <!-- 入力項目 -->
        <v-flex>
          <v-form ref="basic_info" v-model="valid" lazy-validation>
            <!-- 1行目 -->
            <v-row no-gutters class="mr-2">
              <v-col lg="1" md="1">
                <v-subheader class="pb-2 red--text float-right">日付</v-subheader>
              </v-col>
              <v-col lg="3" md="3">
                <!-- <div style="width: 180px"> -->
                <div>
                  <v-text-field 
                    v-model="editdate" 
                    placeholder="日付を選択"
                    solo
                    readonly
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                    @click="dateClickNippou('dpick1')"
                  >
                    <template v-slot:append>
                      <date-picker ref="dpick1" v-model="editdate"/>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
            </v-row>

            <!-- 2行目 -->
            <v-row no-gutters class="mr-2">
              <v-col lg="1" md="1">
                <v-subheader class="pl-0 pr-2 pb-2 red--text float-right">担当者</v-subheader>
              </v-col>
              <v-col lg="3" md="3">
                <v-autocomplete
                  v-model="edittanto"
                  :items="tanto_name_list"
                  item-value="user_id"
                  item-text="user_name"
                  placeholder="担当者を選択"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required]"
                ></v-autocomplete>
              </v-col>
              <v-col lg="3" md="3">
                <v-subheader class="pb-2 red--text float-right">全体ステータス</v-subheader>
              </v-col>
              <v-col lg="3" md="3">
                <v-autocomplete
                  v-model="editstatus"
                  :items="kouji_status_list"
                  item-value="id"
                  item-text="name"
                  :placeholder="$vuetify.breakpoint.smAndDown ? 'ステータス' : 'ステータスを選択'"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required]"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!-- 3行目 -->
            <v-row no-gutters class="mr-2">
              <v-col lg="1" md="1">
                  <v-subheader class="pb-2 red--text float-right">顧客</v-subheader>
              </v-col>
              <v-col lg="4" md="4">
                  <v-autocomplete
                  v-model="editcustomer"
                  :items="cust_name_list"
                  item-value="id"
                  item-text="name"
                  placeholder="顧客を選択"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required]"
                  @change="changeCustomer"
                  ></v-autocomplete>
              </v-col>
              <v-col lg="2" md="2">
                <v-subheader class="pb-2 float-right">変更情報</v-subheader>
              </v-col>
              <v-col lg="5" md="5">
                <v-textarea class="mb-2"
                  v-model="edithenkouinfo"
                  placeholder="変更情報を入力"
                  auto-grow
                  rows="1"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- 4行目 -->
            <v-row no-gutters class="mr-2">
              <v-col lg="1" md="1">
                  <v-subheader class="pb-2 red--text float-right">工事</v-subheader>
              </v-col>
              <v-col lg="4" md="4">
                  <v-autocomplete
                  v-model="editkouji"
                  :items="kouji_name_list"
                  item-value="id"
                  item-text="name"
                  placeholder="工事を選択"
                  solo
                  clearable
                  dense
                  return-object
                  hide-details="auto"
                  :rules="[validRules.objRequired]"
                  @change="changeKouji"
                  ></v-autocomplete>
              </v-col>
              <v-col lg="2" md="2">
                <v-subheader class="pb-2 float-right">備考</v-subheader>
              </v-col>
              <v-col lg="5" md="5">
                <v-textarea class="mb-2"
                  v-model="editbikou"
                  placeholder="備考を入力"
                  auto-grow
                  rows="1"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-flex>
      </v-card>

      <!-- 日報明細 -->
      <v-card
        class="ma-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="3"
      >
        <v-card-actions>
          <div style="width: 150px">
            <v-icon>
              mdi-view-list
            </v-icon>
            日報明細
          </div>
          <v-spacer></v-spacer>
          <!--
          <div class="mr-2">
            <span class="text-body-2">日報種別</span>
          </div>
          -->
          <v-sheet
            class="d-flex"
            outlined
            rounded
            elevation="1"
          >
            <v-radio-group
              v-model="detailType"
              class="mx-6 my-2 d-flex align-center"
              row
              hide-details
            >
              <v-radio
                v-for="nippouType in np_type_list"
                :key="nippouType.id"
                :label="nippouType.name"
                :value="nippouType.id"
              ></v-radio>
            </v-radio-group>
            <v-btn
              class="my-auto mr-1"
              color="cyan darken-1"
              dark
              width="110"
              @click="createMeisai(detailType)"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              明細追加
            </v-btn>
          </v-sheet>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider class="mt-0 mb-0"></v-divider>

        <!-- 明細 -->
        <div v-for="(mei_item, mei_index) in meisai_list" :key="'mei'+mei_index">
        <v-form :ref="'meisai_info_'+mei_index" v-model="valid" lazy-validation>
          <!-- test表示 -->
          <!-- <li>{{mei_item}}</li> -->
          <!-- test表示 -->

          <!-- 工事工程↓ -->
          <v-sheet v-if="mei_item.kjnp_type == 1601"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.eigyo_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【工事工程】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="4" md="10">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                          @click="timeClickMeisai('tpick_mei_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_mei_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="mx-2 mt-2">～</div>
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_to" 
                          placeholder="To"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_mei_time_to'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_mei_time_to'+mei_index" v-model="meisai_list[mei_index].time_to"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">業者</v-subheader>
                  </v-col>
                  <v-col lg="4" md="6">
                    <v-text-field
                      v-model="meisai_list[mei_index].gyosya" 
                      placeholder="業者を入力"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">工事部位</v-subheader>
                  </v-col>
                  <v-col lg="4" md="6">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].kj_part_id" 
                      :items="kj_part_list"
                      item-value="id"
                      item-text="name"
                      placeholder="工事部位を選択"
                      solo
                      clearable
                      dense
                      hide-details
                      @change="changeKjPart"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="auto" md="4">
                  </v-col>
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">工程</v-subheader>
                  </v-col>
                  <v-col lg="5" md="7">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].kj_proc_id" 
                      :items="kj_proc_list[meisai_list[mei_index].kj_part_id]"
                      item-value="id"
                      item-text="name"
                      placeholder="工程を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">作業人数</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].workers_num" 
                      :items="workersNum"
                      item-value="val"
                      item-text="name"
                      placeholder="作業人数"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">ステータス</v-subheader>
                      </v-col>
                      <v-col lg="4" md="3">
                        <v-autocomplete
                          v-model="meisai_list[mei_index].proc_status" 
                          :items="proc_status_list"
                          item-value="id"
                          item-text="name"
                          :placeholder="$vuetify.breakpoint.smAndDown ? 'ステータス' : 'ステータスを選択'"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">進捗率</v-subheader>
                      </v-col>
                      <v-col lg="4" md="3">
                        <v-autocomplete
                          v-model="meisai_list[mei_index].prog_rate" 
                          :items="progressRates"
                          item-value="val"
                          item-text="name"
                          placeholder="進捗率"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">内容</v-subheader>
                  </v-col>
                  <v-col lg="9" md="10">
                    <v-textarea class="mb-2"
                      v-model="meisai_list[mei_index].naiyou" 
                      placeholder="内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
                  
                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">備考</v-subheader>
                  </v-col>
                  <v-col lg="9" md="10">
                    <v-textarea class="mb-2"
                      v-model="meisai_list[mei_index].bikou" 
                      placeholder="備考を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="'att' + mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <v-row no-gutters class="mt-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">写真</v-subheader>
                  </v-col>
                  <v-col lg="4" md="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <label 
                        class="capture-label"
                        v-bind="attrs"
                        v-on="on"
                        >
                          <v-icon left>
                            mdi-camera
                          </v-icon>
                          写真追加
                          <input 
                          type="file" accept="image/jpeg" capture @change="captureImage($event,mei_item.storage_dirname_mei, null)" style="display:none">
                        </label>            
                      </template>
                      <span>撮影または画像追加</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-flex
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.storage_dirname_mei]"
                    :key="'img' + mei_item.kjnp_mei_no + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <v-text-field
                        v-model="img_file_list[mei_item.storage_dirname_mei][img_index]"
                        default="image.jpg"
                        solo
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                    <v-img
                      class="ma-1"
                      max-height="180"
                      max-width="240"
                      :src="img_data_list[mei_item.storage_dirname_mei][img_index]"
                    >
                      <div class="d-flex justify-end">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <label 
                            v-bind="attrs"
                            v-on="on"
                            >
                              <v-icon left>
                                mdi-camera
                              </v-icon>
                              <input type="file" accept="image/jpeg" capture @change="captureImage($event, mei_item.storage_dirname_mei, img_index)" style="display:none">
                            </label>            
                          </template>
                          <span>撮り直す</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon large
                              color="red darken-1"
                              @click="deleteImage(mei_item.storage_dirname_mei, img_index)"
                            >
                              <v-icon>
                                mdi-close-box
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>削除</span>
                        </v-tooltip>
                      </div>
                      <div class="d-flex mt-10 justify-space-between">
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == 0"
                          @click="shiftLeftImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-left-bold-circle
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == img_file_list[mei_item.storage_dirname_mei].length - 1"
                          @click="shiftRightImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-right-bold-circle
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-img>
                  </v-sheet>
                </v-flex>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- 工事工程↑ -->

          <!-- インシデント↓ -->
          <v-sheet v-if="mei_item.kjnp_type == 1602"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.eigyo_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【インシデント】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">発生時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="3" md="3">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                          @click="timeClickMeisai('tpick_mei_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_mei_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">工事部位</v-subheader>
                  </v-col>
                  <v-col lg="4" md="6">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].kj_part_id" 
                      :items="kj_part_list"
                      item-value="id"
                      item-text="name"
                      placeholder="工事部位を選択"
                      solo
                      clearable
                      dense
                      hide-details
                      @change="changeKjPart"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="auto" md="4">
                  </v-col>
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">工程</v-subheader>
                  </v-col>
                  <v-col lg="5" md="7">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].kj_proc_id" 
                      :items="kj_proc_list[meisai_list[mei_index].kj_part_id]"
                      item-value="id"
                      item-text="name"
                      placeholder="工程を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">内容</v-subheader>
                  </v-col>
                  <v-col lg="9" md="10">
                    <v-textarea class="mb-2"
                      v-model="meisai_list[mei_index].naiyou" 
                      placeholder="内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="'att' + mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <v-row no-gutters class="mt-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">写真</v-subheader>
                  </v-col>
                  <v-col lg="4" md="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <label 
                        class="capture-label"
                        v-bind="attrs"
                        v-on="on"
                        >
                          <v-icon left>
                            mdi-camera
                          </v-icon>
                          写真追加
                          <input 
                          type="file" accept="image/jpeg" capture @change="captureImage($event,mei_item.storage_dirname_mei, null)" style="display:none">
                        </label>            
                      </template>
                      <span>撮影または画像追加</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-flex
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.storage_dirname_mei]"
                    :key="'img' + mei_item.kjnp_mei_no + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <v-text-field
                        v-model="img_file_list[mei_item.storage_dirname_mei][img_index]"
                        default="image.jpg"
                        solo
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                    <v-img
                      class="ma-1"
                      max-height="180"
                      max-width="240"
                      :src="img_data_list[mei_item.storage_dirname_mei][img_index]"
                    >
                      <div class="d-flex justify-end">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <label 
                            v-bind="attrs"
                            v-on="on"
                            >
                              <v-icon left>
                                mdi-camera
                              </v-icon>
                              <input type="file" accept="image/jpeg" capture @change="captureImage($event, mei_item.storage_dirname_mei, img_index)" style="display:none">
                            </label>            
                          </template>
                          <span>撮り直す</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon large
                              color="red darken-1"
                              @click="deleteImage(mei_item.storage_dirname_mei, img_index)"
                            >
                              <v-icon>
                                mdi-close-box
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>削除</span>
                        </v-tooltip>
                      </div>
                      <div class="d-flex mt-10 justify-space-between">
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == 0"
                          @click="shiftLeftImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-left-bold-circle
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == img_file_list[mei_item.storage_dirname_mei].length - 1"
                          @click="shiftRightImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-right-bold-circle
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-img>
                  </v-sheet>
                </v-flex>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- インシデント↑ -->

          <!-- メンテナンス↓ -->
          <v-sheet v-if="mei_item.kjnp_type == 1603"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.eigyo_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【メンテナンス】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="4" md="6">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                          @click="timeClickMeisai('tpick_mei_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_mei_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="mx-2 mt-2">～</div>
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_to" 
                          placeholder="To"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_mei_time_to'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_mei_time_to'+mei_index" v-model="meisai_list[mei_index].time_to"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pr-2 pb-2 float-right">メンテナンス名</v-subheader>
                  </v-col>
                  <v-col lg="4" md="6">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].mainte_no" 
                      :items="maintenance_list"
                      item-value="id"
                      item-text="name"
                      placeholder="メンテナンス名を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="auto" md="3">
                  </v-col>
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">完了日</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <div style="width: 180px">
                      <v-text-field
                        v-model="meisai_list[mei_index].comp_date" 
                        placeholder="日付を選択"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClickMeisai('dpick_mei_date'+mei_index)"
                      >
                        <template v-slot:append>
                          <date-picker :ref="'dpick_mei_date'+mei_index" v-model="meisai_list[mei_index].comp_date" />
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">対応内容</v-subheader>
                  </v-col>
                  <v-col lg="9" md="9">
                    <v-textarea class="mb-2"
                      v-model="meisai_list[mei_index].naiyou" 
                      placeholder="対応内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="'att' + mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">写真</v-subheader>
                  </v-col>
                  <v-col lg="4" md="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <label 
                        class="capture-label"
                        v-bind="attrs"
                        v-on="on"
                        >
                          <v-icon left>
                            mdi-camera
                          </v-icon>
                          写真追加
                          <input
                          type="file" accept="image/jpeg" capture @change="captureImage($event,mei_item.storage_dirname_mei, null)" style="display:none">
                        </label>            
                      </template>
                      <span>撮影または画像追加</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-flex
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.storage_dirname_mei]"
                    :key="'img' + mei_item.kjnp_mei_no + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <v-text-field
                        v-model="img_file_list[mei_item.storage_dirname_mei][img_index]"
                        default="image.jpg"
                        solo
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                    <v-img
                      class="ma-1"
                      max-height="180"
                      max-width="240"
                      :src="img_data_list[mei_item.storage_dirname_mei][img_index]"
                    >
                      <div class="d-flex justify-end">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <label 
                            v-bind="attrs"
                            v-on="on"
                            >
                              <v-icon left>
                                mdi-camera
                              </v-icon>
                              <input type="file" accept="image/jpeg" capture @change="captureImage($event, mei_item.storage_dirname_mei, img_index)" style="display:none">
                            </label>            
                          </template>
                          <span>撮り直す</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon large
                              color="red darken-1"
                              @click="deleteImage(mei_item.storage_dirname_mei, img_index)"
                            >
                              <v-icon>
                                mdi-close-box
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>削除</span>
                        </v-tooltip>
                      </div>
                      <div class="d-flex mt-10 justify-space-between">
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == 0"
                          @click="shiftLeftImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-left-bold-circle
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == img_file_list[mei_item.storage_dirname_mei].length - 1"
                          @click="shiftRightImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-right-bold-circle
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-img>
                  </v-sheet>
                </v-flex>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- メンテナンス↑ -->

          <!-- 点検↓ -->
          <v-sheet v-if="mei_item.kjnp_type == 1604"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.eigyo_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【点検】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="4" md="6">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                          @click="timeClickMeisai('tpick_mei_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_mei_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="mx-2 mt-2">～</div>
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_to" 
                          placeholder="To"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_mei_time_to'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_mei_time_to'+mei_index" v-model="meisai_list[mei_index].time_to"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">点検名</v-subheader>
                  </v-col>
                  <v-col lg="4" md="6">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].insp_no" 
                      :items="inspection_list"
                      item-value="id"
                      item-text="name"
                      placeholder="点検名を選択"
                      solo
                      clearable
                      dense
                      hide-details
                      @change="changeInspection"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">点検種別</v-subheader>
                  </v-col>
                  <v-col lg="3" md="4">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].insp_mei_no" 
                      :items="insp_mei_list[meisai_list[mei_index].insp_no]"
                      item-value="id"
                      item-text="name"
                      placeholder="点検種別を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">完了日</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <div style="width: 180px">
                      <v-text-field 
                        v-model="meisai_list[mei_index].comp_date" 
                        placeholder="日付を選択"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClickMeisai('dpick_mei_date'+mei_index)"
                      >
                        <template v-slot:append>
                          <date-picker :ref="'dpick_mei_date'+mei_index" v-model="meisai_list[mei_index].comp_date" />
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">実施内容</v-subheader>
                  </v-col>
                  <v-col lg="9" md="10">
                    <v-textarea class="mb-2"
                      v-model="meisai_list[mei_index].naiyou" 
                      placeholder="実施内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="'att' + mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">写真</v-subheader>
                  </v-col>
                  <v-col lg="4" md="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <label 
                        class="capture-label"
                        v-bind="attrs"
                        v-on="on"
                        >
                          <v-icon left>
                            mdi-camera
                          </v-icon>
                          写真追加
                          <input 
                          type="file" accept="image/jpeg" capture @change="captureImage($event,mei_item.storage_dirname_mei, null)" style="display:none">
                        </label>            
                      </template>
                      <span>撮影または画像追加</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-flex
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.storage_dirname_mei]"
                    :key="'img' + mei_item.kjnp_mei_no + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <v-text-field
                        v-model="img_file_list[mei_item.storage_dirname_mei][img_index]"
                        default="image.jpg"
                        solo
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                    <v-img
                      class="ma-1"
                      max-height="180"
                      max-width="240"
                      :src="img_data_list[mei_item.storage_dirname_mei][img_index]"
                    >
                      <div class="d-flex justify-end">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <label 
                            v-bind="attrs"
                            v-on="on"
                            >
                              <v-icon left>
                                mdi-camera
                              </v-icon>
                              <input type="file" accept="image/jpeg" capture @change="captureImage($event, mei_item.storage_dirname_mei, img_index)" style="display:none">
                            </label>            
                          </template>
                          <span>撮り直す</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon large
                              color="red darken-1"
                              @click="deleteImage(mei_item.storage_dirname_mei, img_index)"
                            >
                              <v-icon>
                                mdi-close-box
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>削除</span>
                        </v-tooltip>
                      </div>
                      <div class="d-flex mt-10 justify-space-between">
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == 0"
                          @click="shiftLeftImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-left-bold-circle
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == img_file_list[mei_item.storage_dirname_mei].length - 1"
                          @click="shiftRightImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-right-bold-circle
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-img>
                  </v-sheet>
                </v-flex>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- 点検↑ -->

          <!-- その他↓ -->
          <v-sheet v-if="mei_item.kjnp_type == 1605"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.eigyo_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【その他】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="2" md="3">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                          @click="timeClickMeisai('tpick_mei_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_mei_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">工事部位</v-subheader>
                  </v-col>
                  <v-col lg="4" md="6">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].kj_part_id" 
                      :items="kj_part_list"
                      item-value="id"
                      item-text="name"
                      placeholder="工事部位を選択"
                      solo
                      clearable
                      dense
                      hide-details
                      @change="changeKjPart"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="auto" md="4">
                  </v-col>
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">工程</v-subheader>
                  </v-col>
                  <v-col lg="5" md="7">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].kj_proc_id" 
                      :items="kj_proc_list[meisai_list[mei_index].kj_part_id]"
                      item-value="id"
                      item-text="name"
                      placeholder="工程を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">内容</v-subheader>
                  </v-col>
                  <v-col lg="9" md="10">
                    <v-textarea class="mb-2"
                      v-model="meisai_list[mei_index].naiyou" 
                      placeholder="内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="'att' + mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <v-row no-gutters class="mt-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">写真</v-subheader>
                  </v-col>
                  <v-col lg="4" md="4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <label 
                        class="capture-label"
                        v-bind="attrs"
                        v-on="on"
                        >
                          <v-icon left>
                            mdi-camera
                          </v-icon>
                          写真追加
                          <input 
                          type="file" accept="image/jpeg" capture @change="captureImage($event,mei_item.storage_dirname_mei, null)" style="display:none">
                        </label>            
                      </template>
                      <span>撮影または画像追加</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-flex
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.storage_dirname_mei]"
                    :key="'img' + mei_item.kjnp_mei_no + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <v-text-field
                        v-model="img_file_list[mei_item.storage_dirname_mei][img_index]"
                        default="image.jpg"
                        solo
                        dense
                        hide-details
                      ></v-text-field>
                    </div>
                    <v-img
                      class="ma-1"
                      max-height="180"
                      max-width="240"
                      :src="img_data_list[mei_item.storage_dirname_mei][img_index]"
                    >
                      <div class="d-flex justify-end">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <label 
                            v-bind="attrs"
                            v-on="on"
                            >
                              <v-icon left>
                                mdi-camera
                              </v-icon>
                              <input type="file" accept="image/jpeg" capture @change="captureImage($event, mei_item.storage_dirname_mei, img_index)" style="display:none">
                            </label>            
                          </template>
                          <span>撮り直す</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon large
                              color="red darken-1"
                              @click="deleteImage(mei_item.storage_dirname_mei, img_index)"
                            >
                              <v-icon>
                                mdi-close-box
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>削除</span>
                        </v-tooltip>
                      </div>
                      <div class="d-flex mt-10 justify-space-between">
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == 0"
                          @click="shiftLeftImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-left-bold-circle
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon large
                          color="orange darken-1"
                          :disabled="img_index == img_file_list[mei_item.storage_dirname_mei].length - 1"
                          @click="shiftRightImage(mei_item.storage_dirname_mei, img_index)"
                        >
                          <v-icon>
                            mdi-arrow-right-bold-circle
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-img>
                  </v-sheet>
                </v-flex>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- その他↑ -->
        </v-form>

        </div>
      </v-card>

      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import DatePicker from "./DatePicker.vue";
import TimePicker from "./TimePicker.vue";
import dbkoujinippou from './js/common/dbkoujinippou.js'
import cssconsts from './js/common/cssconsts';
//export
export default {
  components: {
    DatePicker,
    TimePicker,
  },
  mixins: [dbkoujinippou],
  props:{
      "parameter": {},
  },
  computed:{
  },
  created(){
    this.getKoujiTantoNameList()  // 工事担当者名リスト取得
    this.getKoujiStatusList() // 全体ステータスリスト取得
    this.getCustomerNameList() // 顧客名リスト取得
    this.getNippouTypeList() // 日報種別リスト取得
    this.getProcStatusList() // 工程ステータスリスト取得

    if (this.parameter.viewparam)
    {
      if (this.parameter.viewparam.nippou)
      {
        // 工事日報番号
        this.editnippouid = this.parameter.viewparam.nippou.kjnp_no;
        // 日付
        this.editdate = this.parameter.viewparam.nippou.rept_date;
        // 担当者
        this.edittanto = this.parameter.viewparam.nippou.tanto_id;
        // 全体ステータス
        this.editstatus = this.parameter.viewparam.nippou.kouji_status;
        // 顧客
        this.editcustomer = this.parameter.viewparam.nippou.cust_no;
        // 変更情報
        this.edithenkouinfo = this.parameter.viewparam.nippou.henkou_info;
        // 工事
        this.editkouji.id = this.parameter.viewparam.nippou.kouji_no;
        // 備考
        this.editbikou = this.parameter.viewparam.nippou.bikou;

        // 顧客により工事リストを変更
        this.getKoujiNameList(this.parameter.viewparam.nippou.cust_no) // 工事名リスト取得
        // 工事種別により工事部位リストを変更
        this.getKoujiPartList(this.parameter.viewparam.nippou.kouji_type)
        this.getMaintenanceList(this.parameter.viewparam.nippou.kouji_no);
        this.getInspectionList(this.parameter.viewparam.nippou.kouji_no)
      }
      if (this.parameter.viewparam.meisai)
      {
        this.meisai_list = this.parameter.viewparam.meisai.concat();

        // 明細データ変換
        this.meisai_list.forEach(function(elem, index) {
          // 時刻
          if (elem.time_from) this.meisai_list[index].time_from = this.timeFormatHHMM(elem.time_from);
          if (elem.time_to) this.meisai_list[index].time_to = this.timeFormatHHMM(elem.time_to);
  
          // 検査
          if ((elem.insp_no != undefined) && (elem.insp_no != null))
          {
            this.getInspMeisaiList(elem.insp_no)
          }
          // 工程リスト
          this.getKoujiProcListbyParent(elem.kj_part_id)

          // 明細ストレージディレクトリ名
          if (elem.kjnp_mei_no) this.meisai_list[index].storage_dirname_mei = elem.kjnp_mei_no;
        }.bind(this));
      }
      if (this.parameter.viewparam.img_file)
      {
        this.img_file_list = this.parameter.viewparam.img_file
      }
      if (this.parameter.viewparam.img_data)
      {
        this.img_data_list = this.parameter.viewparam.img_data
      }
      if (this.parameter.viewparam.att_file)
      {
        this.att_file_list = this.parameter.viewparam.att_file
      }
    }
    // 日報ストレージディレクトリ名
    if (this.editnippouid)
    {
      this.storage_dirname_nippou = this.editnippouid;
    }
    else {
      var date = new Date();
      var timestamp = date.getTime();
      this.storage_dirname_nippou = 'np'+timestamp;
    }

    if (this.editdate == null)
    {
      // 日付未選択（新規作成）時は今日の日付を入力
      var today = new Date();
      var daystr = today.getFullYear() + "/" + ("0" + (today.getMonth()+1)).slice(-2) + "/" + ("0" + today.getDate()).slice(-2);
      this.editdate = daystr;
    }
    if (this.edittanto == null)
    {
      // 担当者未選択（新規作成）時はログインユーザーを選択
      this.edittanto = this.parameter.homeparam.loginuser.user_id;
    }
    this.m_createBreads();
  },
  methods: {
    backBtn() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    backNippouList() {
        //「工事日報一覧」画面へ戻る
        this.$emit('viewChange', 'KojiNippouList');
    },

    changeCustomer(id)
    {
      // 顧客選択
      if ((id != undefined) && (id != null))
      {
        // 顧客により工事リストを変更
        this.editkouji = {}; // 未選択にする
        this.getKoujiNameList(id) // 工事名リスト取得
      }
    },

    changeKouji(ev)
    {
      // 工事選択
      if (ev != undefined)
      {
        if (ev.kouji_type != undefined)
        {
          // 工事種別により工事部位リストを変更
          this.getKoujiPartList(ev.kouji_type)
        }
        if (ev.id != undefined)
        {
          // 工事番号によりメンテナンス、点検リストを変更
          this.getMaintenanceList(ev.id)
          this.getInspectionList(ev.id)
        }
      }
    },

    changeKjPart(id)
    {
      // 明細の工事部位選択
      this.getKoujiProcListbyParent(id)
    },

    changeInspection(id)
    {
      // 明細の点検選択
      this.getInspMeisaiList(id)
    },

    createMeisai(nptype_id){
      // 明細追加
      if (nptype_id)
      {
        var date = new Date();
        var timestamp = date.getTime();
        var newMeisai = {
          "kjnp_no": this.editnippouid, // 工事日報番号
          "kjnp_type": nptype_id, // 日報種別
          "storage_dirname_mei": 'mei'+timestamp // ストレージディレクトリ名(一時)
        }
        this.meisai_list.push(newMeisai)
      }
    },
    deleteMeisai(index){
      // 明細削除
      if (index < this.meisai_list.length)
      {
        var res = confirm('この日報明細を削除します。よろしいですか？');
        if (res == true) {
          var strdir = this.meisai_list[index].storage_dirname_mei
          if (strdir)
          {
            delete this.img_file_list[strdir]
            delete this.img_data_list[strdir]
          }
          this.meisai_list.splice(index, 1)
        }
      }
    },
    dateClickNippou(dpickName) {
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    dateClickMeisai(dpickName) {
      //DatePicker を開く
      this.$refs[dpickName][0].menu = true;
    },
    timeClickMeisai(tpickName) {
      //DatePicker を開く
      this.$refs[tpickName][0].menu = true;
    },
    async regNippou()
    {
      //入力チェック
      //--営業日報
      this.valid = this.$refs.basic_info.validate();
      if (!this.valid) {
        alert("工事日報に入力不足、または間違いがあります");
        return;
      }
      //--明細
      for (let index=0; index<this.meisai_list.length; index++)
      {
        var refname = 'meisai_info_'+index;
        // console.log(refname)
        this.valid = this.$refs['meisai_info_'+index][0].validate();
        if (!this.valid) {
          alert("日報明細"+Number(index+1)+"に入力不足、または間違いがあります");
          return;
        }
      }

      var nippoudata = {};
      var meisaidata = [];
      var maintedata = [];
      var inspdata = [];
      var storage_dirname_meisai = [];
      // 日報
      nippoudata.kjnp_no = this.editnippouid
      nippoudata.cust_no = this.editcustomer
      nippoudata.kouji_no = this.editkouji.id
      nippoudata.tanto_id = this.edittanto
      nippoudata.rept_date = this.editdate
      nippoudata.kouji_status = this.editstatus
      nippoudata.henkou_info = this.edithenkouinfo
      nippoudata.bikou = this.editbikou

      // 明細、メンテナンス、点検
      this.meisai_list.forEach(function(elem, index) {
        var meisaiitem = {};
        var mainteitem = {};
        var inspitem = {};

        meisaiitem.kjnp_mei_no = elem.kjnp_mei_no
        meisaiitem.kjnp_no = elem.kjnp_no
        meisaiitem.kjnp_type = elem.kjnp_type
        meisaiitem.time_from = elem.time_from
        meisaiitem.time_to = elem.time_to
        meisaiitem.kj_part_id = elem.kj_part_id
        meisaiitem.kj_proc_id = elem.kj_proc_id
        meisaiitem.gyosya = elem.gyosya
        meisaiitem.workers_num = elem.workers_num
        meisaiitem.proc_status = elem.proc_status
        meisaiitem.prog_rate = elem.prog_rate
        meisaiitem.att_file = elem.att_file
        meisaiitem.mainte_no = elem.mainte_no
        meisaiitem.insp_mei_no = elem.insp_mei_no
        meisaiitem.comp_date = elem.comp_date
        meisaiitem.naiyou = elem.naiyou
        meisaiitem.bikou = elem.bikou

        if (elem.kjnp_type == 1603)
        { // メンテナンスの実施日、実施担当、対応内容を更新
          var mainteupdate = false;
          if ((elem.mainte_no != undefined) && (elem.mainte_no != null))
          {
            if ((elem.mainte_comp_date != undefined) && (elem.mainte_comp_date != null))
            {
              // すでに完了済みのメンテナンスに日報を登録する場合は、「実施済のメンテナンス情報を更新しますか？」と確認する
              var res = confirm('実施済のメンテナンス情報を更新しますか？');
              if (res == true) {
                mainteupdate = true; // 更新する
              }
            }
            else {
              // 未完了メンテナンス
              mainteupdate = true; // 更新する
            }

            if (mainteupdate)
            {
              this.compdateTpInspMenteDate(index)
              mainteitem.mainte_no = elem.mainte_no
              mainteitem.comp_date = elem.comp_date
              mainteitem.syori_tanto_id = this.edittanto
              mainteitem.taiou = elem.naiyou
            }
          }
        }

        if (elem.kjnp_type == 1604)
        { // 点検の実施日、実施担当、対応内容を更新
          var inspupdate = false;
          if ((elem.insp_mei_no != undefined) && (elem.insp_mei_no != null))
          {
            if ((elem.insp_jissi_date != undefined) && (elem.insp_jissi_date != null))
            {
              // すでに完了済みの点検に日報を登録する場合は、「実施済の点検情報を更新しますか？」と確認する
              var res = confirm('実施済の点検情報を更新しますか？');
              if (res == true) {
                inspupdate = true; // 更新する
              }
            }
            else {
              // 未完了点検
              inspupdate = true; // 更新する
            }

            if (inspupdate)
            {
              this.compdateTpInspMenteDate(index)
              inspitem.insp_mei_no = elem.insp_mei_no
              inspitem.jissi_date = elem.comp_date
              inspitem.jissi_tanto_id = this.edittanto
              inspitem.taiou = elem.naiyou
            }
          }
        }

        meisaidata.push(meisaiitem);
        var maintelen = Object.keys(mainteitem).length;
        if (maintelen != 0)
        {
          maintedata.push(mainteitem);
        }
        var insplen = Object.keys(inspitem).length;
        if (insplen != 0)
        {
          inspdata.push(inspitem);
        }
        storage_dirname_meisai.push(elem.storage_dirname_mei)
      }.bind(this));

      const res1 = await this.uploadNippouImage(this.storage_dirname_nippou, this.img_file_list, this.img_data_list) // 画像をアップロード
      if (res1)
      {
        const res2 = await this.setKoujiNippou(nippoudata, meisaidata, maintedata, inspdata, this.storage_dirname_nippou, storage_dirname_meisai);
        if (res2)
        {
        }
      }
    },

    // 工事日報登録
    async setKoujiNippou(nippou, meisai, mainte, insp, storage_np, storage_mei) 
    {
      // データ
      // URL作成
      var uri = 'kouji_nippou'
      // データ格納(store)
      const response = await axios.post(uri, {'nippou':nippou,'meisai':meisai,'mainte':mainte,'insp':insp, 'storage_np':storage_np, 'storage_mei':storage_mei,})
        .catch((error) => {
          alert("工事日報の登録・更新に失敗しました");
          return null;
        });

      if ((response != undefined) && (response != null))
      {
        // console.log(response.data);
        alert("工事日報を登録・更新しました");
        this.backNippouList();  // 成功したら一覧に戻る
        return true;
      }
      else 
      {
        return false;
      }
    },    

    // 工事日報画像登録
    async uploadNippouImage(kjnp_no, file_list, data_list) 
    {
      // データ
      // URL作成
      var uri = 'kouji_nippou/image'
      // データ格納(store)
      let response = await axios.post(uri, {'kjnp_no':kjnp_no,'file_list':file_list,'data_list':data_list})
        .catch((error) => {
          alert("工事日報画像の登録・更新に失敗しました");
          return null;
        });
      if ((response != undefined) && (response != null))
      {
        return true;
      }
      else 
      {
        return false;
      }
    },    

    // 添付ファイルデータ登録
    uploadAttachFileData(kjnp_no, filename, data, kjnp_mei_no, file_index) 
    {
      // データ
      // URL作成
      var uri = 'kouji_nippou/file'
      // データ格納(store)
      axios.post(uri, {
        'kjnp_no':kjnp_no, 
        'filename':filename, 
        'data':data, 
        'kjnp_mei_no':kjnp_mei_no, 
        'file_index': file_index,
        })
        .then(response => {
          // console.log(response.data);
          // ファイル名リスト再読出し
          this.downloadAttachFileNameList(kjnp_no);
        })
        .catch((error) => {
          // alert(error); 
        });
    },    

    // 日報明細の完了日→メンテナンス・点検日付
    compdateTpInspMenteDate(mei_index) 
    {
        if (this.meisai_list[mei_index].kjnp_type == 1603)
        { //メンテナンス
          this.meisai_list[mei_index].mainte_comp_date = this.meisai_list[mei_index].comp_date
        }
        else if (this.meisai_list[mei_index].kjnp_type == 1604)
        { //点検
          this.meisai_list[mei_index].insp_jissi_date = this.meisai_list[mei_index].comp_date
        }

    },

    // 添付ファイルの選択
    selectAttachFile(ev,kjnp_mei_no)
    {
      const files = ev;
      if((files != undefined) && (files != null)) {
        var dataFile = files;
        this.$nextTick(() => {
          this.setFileData(dataFile,kjnp_mei_no)
        });
      }
    },

    setFileData(dataFile,kjnp_mei_no) {
      const reader = new FileReader();
      reader.onload = () => {
        // データバッファへ読み込み
        var datauri = reader.result
        var base64EncodedFile = datauri.replace(/^data:.*?;base64,/, '');
        this.att_file_data_buffer[kjnp_mei_no] = base64EncodedFile
        this.att_file_name_buffer[kjnp_mei_no] = dataFile.name
      }
      reader.readAsDataURL(dataFile);
    },

    // 添付ファイルの登録（アップロード送信）
    regAttachFile(kjnp_mei_no)
    {
      if ((this.att_file_name_buffer[kjnp_mei_no] == undefined) || (this.att_file_name_buffer[kjnp_mei_no] == null))
      { 
        // ファイル未選択の場合
        return
      }

      var file_index = null
      if ((this.att_file_list[kjnp_mei_no] == undefined) || (this.att_file_list[kjnp_mei_no] == null))
      {
        file_index = 0
      }
      else 
      {
        var keyarray = Object.keys(this.att_file_list[kjnp_mei_no]);
        var newindex = Math.max.apply(null,keyarray) + 1;
        file_index = newindex;
      }
      // アップロード
      this.uploadAttachFileData(this.storage_dirname_nippou, this.att_file_name_buffer[kjnp_mei_no], this.att_file_data_buffer[kjnp_mei_no], kjnp_mei_no, file_index) // 添付ファイルをアップロード
      // ファイル選択をリセット
      this.att_file_data_buffer[kjnp_mei_no] = null
      this.att_file_name_buffer[kjnp_mei_no] = null
      this.$set(this.selatt_disable, kjnp_mei_no, true)
      this.$nextTick(function () {
        this.$set(this.selatt_disable, kjnp_mei_no, false)
      });
    },

    deleteAttachFile(kjnp_mei_no, att_index)
    {
      var res = confirm('このファイルを削除します。よろしいですか？');
      if (res == true) {
        this.deleteAttachFileData(this.storage_dirname_nippou, kjnp_mei_no, att_index) 
      }
    },

    // 添付ファイルデータ削除
    deleteAttachFileData(kjnp_no, kjnp_mei_no, file_index) 
    {
      // URL作成
      var uri = 'kouji_nippou/file?kjnp_no='+kjnp_no+'&kjnp_mei_no='+kjnp_mei_no+'&file_index='+file_index
      // データ格納(store)
      axios.delete(uri)
        .then(response => {
          // console.log(response.data);
          // ファイル名リスト再読出し
          this.downloadAttachFileNameList(kjnp_no);
        })
        .catch((error) => {
          // alert(error); 
        });
    },    


    setPreviewImage(imageFile,kjnp_mei_no,index) {
      const reader = new FileReader();
      reader.onload = () => {
        // 写真追加
        if (index == null)
        {
          // ファイルリスト
          var newfilelist = []
          if ((this.img_file_list[kjnp_mei_no] != undefined) && (this.img_file_list[kjnp_mei_no] != null))
          {
            newfilelist = this.img_file_list[kjnp_mei_no]
          }
          newfilelist.push(imageFile.name)
          this.$set(this.img_file_list, kjnp_mei_no, newfilelist)
          // データリスト
          var newdatalist = []
          if ((this.img_data_list[kjnp_mei_no] != undefined) && (this.img_data_list[kjnp_mei_no] != null))
          {
            newdatalist = this.img_data_list[kjnp_mei_no]
          }
          newdatalist.push(reader.result)
          this.$set(this.img_data_list, kjnp_mei_no, newdatalist)
        }
        else 
        {
          this.img_file_list[kjnp_mei_no].splice(index, 1, imageFile.name)
          this.img_data_list[kjnp_mei_no].splice(index, 1, reader.result)
        }
      }
      reader.readAsDataURL(imageFile);
    },
    captureImage(ev,kjnp_mei_no,index)
    {
      const files = ev.target.files;
      if(files.length > 0) {
        var imageFile = files[0];
        this.$nextTick(() => {
          this.setPreviewImage(imageFile,kjnp_mei_no,index)
        });
      }
    },
    deleteImage(kjnp_mei_no,index)
    {
      var res = confirm('この写真を削除します。よろしいですか？');
      if (res == true) {
        this.img_file_list[kjnp_mei_no].splice(index, 1)
        this.img_data_list[kjnp_mei_no].splice(index, 1)
      }
    },
    shiftLeftImage(kjnp_mei_no,index)
    {
      this.img_file_list[kjnp_mei_no].splice(index-1, 2, this.img_file_list[kjnp_mei_no][index], this.img_file_list[kjnp_mei_no][index-1])
      this.img_data_list[kjnp_mei_no].splice(index-1, 2, this.img_data_list[kjnp_mei_no][index], this.img_data_list[kjnp_mei_no][index-1])
    },
    shiftRightImage(kjnp_mei_no,index)
    {
      this.img_file_list[kjnp_mei_no].splice(index, 2, this.img_file_list[kjnp_mei_no][index+1], this.img_file_list[kjnp_mei_no][index])
      this.img_data_list[kjnp_mei_no].splice(index, 2, this.img_data_list[kjnp_mei_no][index+1], this.img_data_list[kjnp_mei_no][index])
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      tanto_name_list: [],
      kouji_status_list: [],
      cust_name_list: [],
      kouji_name_list: [],
      np_type_list: [],
      selatt_disable: {},

      kj_part_list: [],
      kj_proc_list: {},
      proc_status_list: [],
      maintenance_list: [],
      inspection_list: [],
      insp_mei_list: {},

      editnippouid: null,  // 工事日報番号の編集データ
      editdate: null,  // 日付の編集データ
      edittanto: null,  // 担当者の編集データ
      editstatus: null,  // 全体ステータスの編集データ
      editcustomer: null,  // 顧客の編集データ
      edithenkouinfo: null,  // 変更情報の編集データ
      editkouji: {},  // 工事の編集データ
      editbikou: null,  // 備考の編集データ
      meisai_list: [],  // 明細の編集データ
      storage_dirname_nippou: null, // 日報ストレージディレクトリ名
      detailType: null,

      img_file_list: {}, // 画像ファイル名
      img_data_list: {}, // 画像データ
      att_file_list: {}, // 添付ファイル名

      att_file_name_buffer: {},  // 添付ファイル名送信用バッファ
      att_file_data_buffer: {},  // 添付ファイル送信用バッファ

      workersNum: [
        { val: 1, name: '1人' },
        { val: 2, name: '2人' },
        { val: 3, name: '3人' },
        { val: 4, name: '4人' },
        { val: 5, name: '5人' },
        { val: 6, name: '6人' },
        { val: 7, name: '7人' },
        { val: 8, name: '8人' },
        { val: 9, name: '9人' },
        { val: 10, name: '10人' },
        { val: 11, name: '11人' },
        { val: 12, name: '12人' },
        { val: 13, name: '13人' },
        { val: 14, name: '14人' },
        { val: 15, name: '15人' },
        { val: 16, name: '16人' },
        { val: 17, name: '17人' },
        { val: 18, name: '18人' },
        { val: 19, name: '19人' },
        { val: 20, name: '20人' },
        { val: 21, name: '21人' },
        { val: 22, name: '22人' },
        { val: 23, name: '23人' },
        { val: 24, name: '24人' },
        { val: 25, name: '25人' },
        { val: 26, name: '26人' },
        { val: 27, name: '27人' },
        { val: 28, name: '28人' },
        { val: 29, name: '29人' },
        { val: 30, name: '30人' },
        { val: 31, name: '31人' },
        { val: 32, name: '32人' },
        { val: 33, name: '33人' },
        { val: 34, name: '34人' },
        { val: 35, name: '35人' },
        { val: 36, name: '36人' },
        { val: 37, name: '37人' },
        { val: 38, name: '38人' },
        { val: 39, name: '39人' },
        { val: 40, name: '40人' },
        { val: 41, name: '41人' },
        { val: 42, name: '42人' },
        { val: 43, name: '43人' },
        { val: 44, name: '44人' },
        { val: 45, name: '45人' },
        { val: 46, name: '46人' },
        { val: 47, name: '47人' },
        { val: 48, name: '48人' },
        { val: 49, name: '49人' },
        { val: 50, name: '50人' },
        { val: 51, name: '51人' },
        { val: 52, name: '52人' },
        { val: 53, name: '53人' },
        { val: 54, name: '54人' },
        { val: 55, name: '55人' },
        { val: 56, name: '56人' },
        { val: 57, name: '57人' },
        { val: 58, name: '58人' },
        { val: 59, name: '59人' },
        { val: 60, name: '60人' },
        { val: 61, name: '61人' },
        { val: 62, name: '62人' },
        { val: 63, name: '63人' },
        { val: 64, name: '64人' },
        { val: 65, name: '65人' },
        { val: 66, name: '66人' },
        { val: 67, name: '67人' },
        { val: 68, name: '68人' },
        { val: 69, name: '69人' },
        { val: 70, name: '70人' },
        { val: 71, name: '71人' },
        { val: 72, name: '72人' },
        { val: 73, name: '73人' },
        { val: 74, name: '74人' },
        { val: 75, name: '75人' },
        { val: 76, name: '76人' },
        { val: 77, name: '77人' },
        { val: 78, name: '78人' },
        { val: 79, name: '79人' },
        { val: 80, name: '80人' },
        { val: 81, name: '81人' },
        { val: 82, name: '82人' },
        { val: 83, name: '83人' },
        { val: 84, name: '84人' },
        { val: 85, name: '85人' },
        { val: 86, name: '86人' },
        { val: 87, name: '87人' },
        { val: 88, name: '88人' },
        { val: 89, name: '89人' },
        { val: 90, name: '90人' },
        { val: 91, name: '91人' },
        { val: 92, name: '92人' },
        { val: 93, name: '93人' },
        { val: 94, name: '94人' },
        { val: 95, name: '95人' },
        { val: 96, name: '96人' },
        { val: 97, name: '97人' },
        { val: 98, name: '98人' },
        { val: 99, name: '99人' },
      ],
      progressRates: [
        { val: 0, name: '0%' },
        { val: 5, name: '5%' },
        { val: 10, name: '10%' },
        { val: 20, name: '20%' },
        { val: 30, name: '30%' },
        { val: 40, name: '40%' },
        { val: 50, name: '50%' },
        { val: 60, name: '60%' },
        { val: 70, name: '70%' },
        { val: 80, name: '80%' },
        { val: 90, name: '90%' },
        { val: 100, name: '100%' },
      ],
      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>

<style>
/* ラジオボタンのラベル位置調整用 */
label {
  margin-bottom: 0 !important;
}
.right-input input {
  text-align: right;
}

</style>

<style scoped>
/* 写真選択用 */
.capture-label {
  display: inline-block;
  cursor: pointer; /* カーソルを指に */
  padding: .5em 1em; /* 文字まわりの余白 */
  background: #3e8bff; /* 背景色 */
  color: #FFF; /* 文字色 */
  font-size: 0.95em; /* フォントサイズ */
  border-radius: 0.5em; /* 角の丸み */
  transition: 0.2s; /* ホバーをなめらかに */
}
.capture-label:hover {
  box-shadow: 0 8px 10px -2px rgba(0, 0, 0, 0.2); /* 影を表示 */
}
</style>
