<template>
  <v-app class="base">
    <v-main>
      <v-layout justify-center>
        <v-card
          color="rgb(255, 0, 0, 0)" 
          class="outline-card ma-0 pa-0"
          elevation="0"
          height="100%"
        >
          <v-card
            color="rgb(255, 0, 0, 0)" 
            elevation="0"
            class="ma-0 pa-0"
          >
            <v-breadcrumbs :items="breads"></v-breadcrumbs>
          </v-card>
          <v-card
            class="mt-0 mb-4 ml-4 mr-4"
            :color="cssconsts.eigyo_bg_color_base_01"
            outlined
            elevation="3"
          >
            <v-card-actions>
              <div class="ml-2">
                <span>
                  <v-icon>
                    mdi-home
                  </v-icon>
                  工事担当ホーム
                </span>
              </div>
              <v-spacer></v-spacer>
              <span class="my-auto text-body-2">ログインユーザー　部署：</span>
              <span class="mr-auto" style="width: 150px">{{user.user_dept}}</span>
              <span class="my-auto text-body-2">名前：</span>
              <span class="mr-auto" style="width: 150px">{{user.user_name}}</span>
            </v-card-actions>
            <v-divider class="mt-0 mb-1"></v-divider>

            <!-- アラート一覧 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              color="white"
              outlined
              rounded
              elevation="10"
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">アラート一覧</div>
                <v-spacer></v-spacer>
                <v-checkbox
                  v-model="showComp"
                  class="shrink mx-2 my-auto"
                  color="cyan accent-1"
                  hide-details
                  true-value="true"
                  false-value="false"
                  @change='changeShowComp'
                >
                >
                  <template v-slot:label>
                    <span class="text-body-2">
                      対応済みも表示する
                    </span>
                  </template>
                </v-checkbox>
                <v-btn
                  class="mx-1 my-1"
                  color="blue darken-1"
                  dark
                  small
                  width="140"
                  elevation="1"
                  @click="changeTaio"
                >
                  <v-icon left>
                    mdi-reload
                  </v-icon>
                  対応済み切り替え
                </v-btn>
              </v-sheet>
              <v-simple-table
                height="240px"
              >
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="alert in alerts"
                      :key="alert.alert_no"
                    >
                      <td class="px-2">
                        <span :class="cssconsts.alert_text_color" v-if="alert.my_alert === true">
                          顧客：{{ alert.cust_name }}　{{ alert.alert_naiyo }}
                        </span>
                        <span v-else>
                          顧客：{{ alert.cust_name }}　{{ alert.alert_naiyo }}
                        </span>
                      </td>
                      <td class="px-2">
                        <v-checkbox
                          v-model="alert.status"
                          class="my-auto mr-2 float-right"
                          hide-details
                          false-value="0"
                          true-value="1"
                        >
                          <template v-slot:label>
                            <div style="width: 120px">
                              {{alert.taiou}}
                            </div>
                          </template>
                        </v-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </v-sheet>

            <!-- 担当顧客一覧 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              outlined
              rounded
              elevation="10"
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">工事一覧</div>
                <v-spacer></v-spacer>
                <v-checkbox
                  v-model="onlyMyKouji"
                  class="shrink mx-2 my-auto"
                  color="cyan accent-1"
                  false-value="0"
                  true-value="1"
                  hide-details
                >
                  <template v-slot:label>
                    <span class="text-body-2">
                      工事担当＝ログインユーザー
                    </span>
                  </template>
                </v-checkbox>
                <v-btn
                  class="mx-1 my-1"
                  color="brown darken-1"
                  dark
                  small
                  width="20"
                  plain
                >
                </v-btn>
              </v-sheet>
              <v-flex class="ma-0">
                <v-data-table
                  calculate-widths
                  :headers="koujiHeaders"
                  :items="koujiList"
                  :items-per-page="10"
                  :sort-by.sync = 'koujiSortBy'
                  sort-desc
                  height="400"
                  class="elevation-1"
                  locale="jp-ja"
                  loading-text="読込中"
                  no-data-text="該当するデータがありません。"
                  :search="onlyMyKouji"
                  :custom-filter="filterMyKouji"
                  @click:row="showPortal"
                >
              <template v-slot:[`header.ukeoi_kngk`]="{ header }">
                <span>{{ header.text }}</span><br>
                <span>（税込）</span>
              </template>
                <template v-slot:[`item.ukeoi_kngk`]="{ item }">
                  <span>
                    {{numberToLocal(item.ukeoi_kngk)}}
                  </span>
                </template>
                </v-data-table>
              </v-flex>
            </v-sheet>
          </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts'
import dbkoujiuser from './js/common/dbkoujiuser.js'
import dbcommon from './js/common/dbalert.js'
//export
export default {
  methods: {
    filterMyKouji (value, search, item) {
      if (search == "1") {
        //自分の担当する工事か判定
        if (item.tanto_id != null) {
          var tantoIds = item.tanto_id.split(',');
          //console.log("tantoIds:"+tantoIds);
          return tantoIds.includes(this.user.user_id.toString());
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    numberToLocal(num) {
      if (num == null) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
    showPortal: function(event, row) {
      //「工事ポータル」画面へ遷移
      this.$emit('viewChange', 'KojiPortal', row.item.kouji_no, 2);
    },
  },

  mixins: [dbkoujiuser,dbcommon],
  created : function(){
    if (this.parameter.homeparam.loginuser != null)
    {
      var usrid = this.parameter.homeparam.loginuser.user_id
      //ログインユーザーのデータを取得
      this.getUserData(usrid);
      this.getAlertList(this.showComp,usrid);
    }
    this.m_createBreads();
  },
  mounted() {
  },

  props:{
      "parameter": {},
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      user: {},
      alertList: [],

      onlyMyKouji: "1",

      showComp: "false",

      alertMode: "koji",

      //アラート一覧
      alerts: [],

      //担当工事一覧
      koujiSortBy: 'keiyaku_date',
      koujiHeaders: [
        { text: '契約日', value: 'keiyaku_date', filterable: false, width: '100', class: 'px-1' },
        { text: '顧客名', value: 'cust_name', filterable: false, width: '150', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', filterable: false, width: '200', class: 'px-1' },
        { text: '契約金額', value: 'ukeoi_kngk', align: 'end', filterable: false, width: '120', class: 'px-1' },
        { text: '着工予定', value: 'start_yotei_date', filterable: false, width: '100', class: 'px-1' },
        { text: '着工実施', value: 'start_date', filterable: false, width: '100', class: 'px-1' },
        { text: '工事担当', value: 'tanto_name', width: '100', class: 'px-1' },
      ],
      koujiList: [],

      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap{
   white-space: nowrap;
}

td.text-start,td.text-end{
    padding-right: 3px !important;
    padding-left: 3px !important;
}

/* チェックボックスのラベル位置調整用 */
label {
  margin-bottom: 0 !important;
}
</style>
