<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn icon elevation="0" v-on="on">
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
    </template>
    <v-date-picker
      ref="picker"
      v-model="picker" 
      no-title
      locale="jp-ja"
      :day-format="date => new Date(date).getDate()"
      :max="maxDate"
      @click="menu = false"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    }
  },
  data() {
    return {
      menu: false,
      firstPicker: "DATE",
      maxDate: null
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = this.firstPicker));
    },
  },
  methods: {
    changePicker(disp) {
      this.firstPicker = disp;
      if (disp == "YEAR") {
        this.maxDate = new Date().toISOString().substr(0, 10);
      } else {
        this.maxDate = null;
      }
    }
  },
  computed: {
    picker: {
      get() {
        if (!this.value) return null;
        if(( this.value.match(/\//g ) || [] ).length != 2) return null; // yyyy/mm/ddでない場合
        //yyyy/mm/dd から yyyy-mm-dd に変換
        const [year, month, day] = this.value.split('/');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      },
      set(date) {
        if (!date) return null;
        this.menu = false;
        //yyyy-mm-dd から yyyy/mm/dd に変換
        const [year, month, day] = date.split('-');
        this.$emit("input", `${year}/${month}/${day}`);
      }
    }
  }
};
</script>