export default {
    methods: {
        // 警報リスト取得
        getAlertList:function (sumi_flg,tanto){
            // URL作成
            var uri = 'common/alert/list'
            var qparam = ''
            if (tanto != null) {
                qparam += 'tanto=' + encodeURIComponent(tanto) + '&'
            }
            if (sumi_flg != null) {
                qparam += 'sumi_flg=' + encodeURIComponent(sumi_flg) + '&'
            }
            if( this.alertMode != null){
              qparam += 'mode=' + encodeURIComponent(this.alertMode) + '&'
            }

            uri += '?' + qparam;
            // データ取得
            axios.get(uri)
                .then(response => {
                    this.alerts = response.data;
                })
                .catch(
                 response => console.log(response)
                );
        },
        changeShowComp: function() {
            var usrid = this.parameter.homeparam.loginuser.user_id
            this.getAlertList(this.showComp,this.user.user_id.toString());
        },
        changeTaio(){
          let alert_value_list = [];
          let alert_value = {};
          for (let row of this.alerts) {
            alert_value = {alert_no:row.alert_no,status:row.status}
            alert_value_list.push(alert_value);
          }
    
          // URL作成
          var uri = 'common/alert'
          // データ格納(store)
          axios.post(uri, {'alert_value_list':alert_value_list})
            .then(response => {
              // console.log(response.data);
              this.getAlertList(this.showComp,this.user.user_id.toString());  // 成功したら一覧更新
            })
            .catch((error) => {
              // alert(error); 
            });
        },
    },
}