<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-form id="srch_form" v-model="valid" lazy-validation>
        <!-- 検索条件 -->
        <v-card
          :color="cssconsts.search_keys_bg_color_01"
          class="mt-0 mb-4 ml-4 mr-4"
          outlined
          elevation="10"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-text-box-search-outline
              </v-icon>
              検索条件
            </span>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4 mt-2">詳細検索</span>
            <v-switch
              v-model="dtl_srch"
              class="mt-1"
              color="primary"
              inset
              dense
              hide-details
              @change="dtl_srch_sw(dtl_srch)"
            ></v-switch>
            <v-btn
              class="mx-1"
              color="blue-grey darken-1"
              dark
              width="100"
              @click="srchOrder"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              検索
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-2"></v-divider>

          <!-- 1行目 -->
          <v-container mx-4 pa-0>
            <v-row no-gutters jastify>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">顧客名</v-subheader>
                </v-col>
                <v-col  lg="4" md="6">
                  <v-text-field
                    v-model="cast_name_input"
                    placeholder="顧客名かカナを入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters jastify>
              <v-col  lg="1" md="2">
                <v-subheader class="pl-0 pb-2 float-right">顧客種別</v-subheader>
              </v-col>
              <v-col  lg="3" md="4">
                <v-autocomplete
                  v-model="cust_type_select"
                  :items="cust_type_list"
                  item-value="id"
                  item-text="name"
                  placeholder="顧客種別を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col lg="1" md="1">
                <v-subheader v-if="$vuetify.breakpoint.smAndDown"
                  class="pl-0 pr-2 pb-2 float-right"
                >ランク</v-subheader>
                <v-subheader v-else
                  class="pb-2 float-right"
                >ランク</v-subheader>
              </v-col>
              <v-col lg="4" md="5" class="pr-3">
                <v-autocomplete
                  v-model="rank_select"
                  :items="rank_list"
                  item-value="id"
                  item-text="name"
                  placeholder="ランクを選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>

          <template v-if="dtl_srch === true">
            <!-- 2行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">引合内容</v-subheader>
                </v-col>
                <v-col lg="4" md="9">
                  <v-text-field
                    v-model="inq_naiyou_input"
                    placeholder="引合内容を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col lg="auto" md="1">
                </v-col>
                <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">工事種別</v-subheader>
                </v-col>
                <v-col lg="4" md="5">
                  <v-autocomplete
                    v-model="kouji_type_select"
                    :items="kouji_type_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工事種別を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>

            <!-- 3行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">見積日</v-subheader>
                </v-col>
                <v-col lg="6" md="8">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-text-field 
                        v-model="mitu_date_from"
                        placeholder="日付(From)"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClick('dpick_est_f')"
                        >
                        <template v-slot:append>
                            <date-picker ref="dpick_est_f" v-model="mitu_date_from"/>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-subheader class="px-0 pb-2">
                        <div class="mx-auto">～</div>
                      </v-subheader>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field 
                        v-model="mitu_date_to"
                        placeholder="日付(To)"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClick('dpick_est_t')"
                        >
                        <template v-slot:append>
                          <date-picker ref="dpick_est_t" v-model="mitu_date_to"/>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col lg="auto" md="2">
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">工事名</v-subheader>
                </v-col>
                <v-col lg="4" md="9">
                  <v-text-field
                    v-model="kouji_name_input"
                    placeholder="工事名を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <!-- 4行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">契約日</v-subheader>
                </v-col>
                <v-col lg="6" md="8">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-text-field
                        v-model="order_date_from"
                        placeholder="日付(From)"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClick('dpick_cont_f')"
                        >
                        <template v-slot:append>
                            <date-picker ref="dpick_cont_f" v-model="order_date_from"/>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-subheader class="px-0 pb-2">
                        <div class="mx-auto">～</div>
                      </v-subheader>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="order_date_to"
                        placeholder="日付(To)"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClick('dpick_cont_t')"
                        >
                        <template v-slot:append>
                          <date-picker ref="dpick_cont_t" v-model="order_date_to"/>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>

            <!-- 5行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">営業担当</v-subheader>
                </v-col>
                <v-col lg="3" md="4" class="pr-3">
                  <v-autocomplete
                    v-model="eigyo_tanto_select"
                    :items="tanto_list"
                    item-value="id"
                    item-text="name"
                    placeholder="営業担当を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">見積担当</v-subheader>
                </v-col>
                <v-col lg="3" md="4" class="pr-3">
                  <v-autocomplete
                    v-model="mitu_tanto_select"
                    :items="tanto_list"
                    item-value="id"
                    item-text="name"
                    placeholder="見積担当を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>

            <!-- 6行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">請負金額</v-subheader>
                </v-col>
                <v-col lg="6" md="8">
                  <v-row no-gutters>
                    <v-col cols="5" >
                      <comma-number-field
                        v-model.number="order_amt_from"
                        class="right-input"
                        placeholder="金額(Min)を入力"
                        solo
                        clearable
                        dense
                        maxlength="12"
                        hide-details="auto"
                        :rules="[validRules.isNumberCommaSigned]"
                      ></comma-number-field>
                    </v-col>
                    <v-col cols="1">
                      <v-subheader class="px-0 pb-2">
                      <div class="mx-auto">～</div>
                      </v-subheader>
                    </v-col>
                    <v-col cols="5">
                      <comma-number-field
                        v-model.number="order_amt_to"
                        class="right-input"
                        placeholder="金額(Max)を入力"
                        solo
                        clearable
                        dense
                        maxlength="12"
                        hide-details="auto"
                        :rules="[validRules.isNumberCommaSigned]"
                      ></comma-number-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col lg="auto" md="2">
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">地区</v-subheader>
                </v-col>
                <v-col lg="3" md="4" class="pr-3">
                  <v-autocomplete
                    v-model="area_select"
                    :items="area_list"
                    item-value="id"
                    item-text="name"
                    placeholder="地区を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-card>
      </v-form>

      <!-- 検索結果 -->
      <v-card
        :color="cssconsts.search_result_bg_color_01"
        class="ma-4"
        outlined
        elevation="10"
        style="overflow: auto"
      >
        <div class="mt-2 ml-2">
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            検索結果
          </span>
        </div>
        <v-divider class="mt-1 mb-0"></v-divider>
        <v-flex class="mt-2 mb-4 mx-4">
          <v-data-table
            calculate-widths
            :height="estTableHeight"
            :headers="estimateHeaders"
            :items="order_list"
            :items-per-page="10"
            :sort-by.sync="estimateSortBy"
            sort-desc
            class="elevation-1"
            locale="jp-ja"
            loading-text="読込中"
            no-data-text="該当するデータがありません。"
            @click:row="showOrder"
          >
            <template v-slot:[`item.ukeoi_amt`]="{ item }">
              <span>
                {{numberToLocal(item.ukeoi_amt)}}
              </span>
            </template>
            <template v-slot:[`item.keiyaku_no`]="{ item }">
              <v-btn
                color="lime lighten-4"
                @click.stop="showMitumori(item)"
              >
                見積
              </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import DatePicker from "./DatePicker.vue";
import dborder from './js/common/dborder.js'
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dborder],

  props:{
      "parameter": {},
  },

  created : function(){
    this.getIdNameList("custType");       // 顧客種別リスト取得
    this.getIdNameList("rank");           // ランクリスト取得
    this.getIdNameList("tanto");          // 営業担当者リスト取得
    this.getIdNameList("area");           // 地区リスト取得
    this.getIdNameList("koujiType");      // 工事種別リスト取得
    //全ての受注を読み出す
    this.getOrderList();
    this.m_createBreads();
  },
  mounted() {
  },

  methods: {
    dtl_srch_sw(sw) {
      //this.estTableHeight = (sw ? 380 : 500);
    },
    dateClick(dpickName) {
      //alert('Date Clicked!');
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    numberToLocal(num) {
      if (num == null) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
    srchOrder()
    {
      if (!this.valid) {
        //条件入力エラーあり
        return;
      }
      //受注を検索
      var cust_name = this.cast_name_input;
      var cust_type = this.cust_type_select;
      var cust_rank = this.rank_select;
      var inq_naiyou = this.inq_naiyou_input;
      var kouji_type = this.kouji_type_select;
      var kouji_name = this.kouji_name_input;
      var mitu_date_from = this.mitu_date_from;
      var mitu_date_to = this.mitu_date_to;
      var order_date_from = this.order_date_from;
      var order_date_to = this.order_date_to;
      var eigyo_tanto = this.eigyo_tanto_select;
      var mitu_tanto = this.mitu_tanto_select;
      var order_amt_from = this.order_amt_from;
      var order_amt_to = this.order_amt_to;
      var area_id = this.area_select;
      if (this.dtl_srch) {
        //詳細検索
        this.getOrderList(
            cust_name, cust_type, cust_rank, inq_naiyou, kouji_type, kouji_name,
            mitu_date_from, mitu_date_to, order_date_from, order_date_to,
            eigyo_tanto, mitu_tanto, order_amt_from, order_amt_to, area_id
        );
      } else {
        //簡易検索
        this.getOrderList(
          cust_name, cust_type, cust_rank
        );
      }
    },
    showMitumori(item) {
      //「工事見積情報」画面へ遷移
      this.$emit('viewChange', 'ConstrEst', item.mitumori_no, 4);
    },
    showOrder: function(event, row) {
      //「受注情報」画面へ遷移
      this.$emit('viewChange', 'Order', row.item.keiyaku_no);
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      cust_type_list: [],
      rank_list: [],
      tanto_list: [],
      area_list: [],
      kouji_type_list: [],

      dtl_srch: false,
      estTableHeight: 500,

      cast_name_input: null,
      cust_type_select: null,
      rank_select: null,
      inq_naiyou_input: null,
      kouji_type_select: null,
      kouji_name_input: null,
      mitu_date_from: null,
      mitu_date_to: null,
      order_date_from: null,
      order_date_to: null,
      eigyo_tanto_select: null,
      mitu_tanto_select: null,
      order_amt_from: null,
      order_amt_to: null,
      area_select: null,

      //受注一覧
      estimateSortBy: 'mitumori_no',
      estimateHeaders: [
        { text: '見積番号', value: 'mitumori_no', width: '100px' , class: 'px-1' },
        { text: '顧客', value: 'cust_name', width: '100px' , class: 'px-1' },
        { text: '引合内容', value: 'hikiai_naiyou', width: '200px' , class: 'px-1' },
        { text: '工事種別', value: 'kouji_type', width: '120px' , class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px' , class: 'px-1' },
        { text: '見積日', value: 'mitumori_date', width: '100px' , class: 'px-1' },
        { text: '契約日', value: 'keiyaku_date', width: '100px' , class: 'px-1' },
        { text: '請負金額', value: 'ukeoi_amt', align: 'end', width: '100px' , class: 'px-1' },
        { text: '営業担当', value: 'htg_tanto_name', width: '100px' , class: 'px-1' },
        { text: '見積担当', value: 'mtg_tanto_name', width: '100px' , class: 'px-1' },
        { text: '地区', value: 'area', width: '80px' , class: 'px-1' },
        { text: '', value: 'keiyaku_no', sortable: false, width: '100px' , class: 'px-1' },
      ],
      order_list: [],
      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap{
   white-space: nowrap;
}

td.text-start,td.text-end{
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.right-input input {
  text-align: right;
}
</style>