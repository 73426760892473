export default {
    methods: {
        timeFormatHHMM(time){
            if (time)
            {
                //hhhh:mm:ss から hhhh:mm に変換
                const [hour, minute, second] = time.split(':');
                return `${hour}:${minute}`;
            }
        },
        getSearchConditionList() {
            axios.get('kouji_nippou/list?type=searchcond')
                .then(response => {
                    // 工事担当者名
                    this.tanto_name_list = response.data.tanto;
                    // 日報種別
                    this.np_type_list = response.data.nptype;
                    // 顧客名
                    this.cust_name_list = response.data.cust;
                    // 工事種別
                    this.kj_type_list = response.data.kjtype;
                    // 工事部位
                    // this.kj_part_list = response.data.kjpart;
                    // 工事工程
                    // this.kj_proc_list = response.data.kjproc;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事担当者名リスト取得
        getKoujiTantoNameList() {
            axios.get('kouji_nippou/list?type=tanto')
                .then(response => {
                    this.tanto_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 日報種別リスト取得
        getNippouTypeList() {
            axios.get('kouji_nippou/list?type=nptype')
                .then(response => {
                    this.np_type_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 顧客名リスト取得
        getCustomerNameList() {
            axios.get('kouji_nippou/list?type=cust')
                .then(response => {
                    this.cust_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事種別リスト取得
        getKoujiTypeList() {
            axios.get('kouji_nippou/list?type=kjtype')
                .then(response => {
                    this.kj_type_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事部位リスト取得
        getKoujiPartList(parent) {
            axios.get('kouji_nippou/list?type=kjpart&parent='+parent)
                .then(response => {
                    this.kj_part_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事工程リスト取得
        getKoujiProcList(parent) {
            axios.get('kouji_nippou/list?type=kjproc&parent='+parent)
                .then(response => {
                    this.kj_proc_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事工程リスト取得(親ID毎)
        getKoujiProcListbyParent(parent) {
            axios.get('kouji_nippou/list?type=kjproc&parent='+parent)
                .then(response => {
                    this.$set(this.kj_proc_list, parent, response.data)
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 全体ステータスリスト取得
        getKoujiStatusList() {
            axios.get('kouji_nippou/list?type=kjstatus')
                .then(response => {
                    this.kouji_status_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工程ステータスリスト取得
        getProcStatusList() {
            axios.get('kouji_nippou/list?type=procstatus')
                .then(response => {
                    this.proc_status_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事名リスト取得
        getKoujiNameList(cust_no) {
            axios.get('kouji_nippou/list?type=koujiname&cust_no='+cust_no)
                .then(response => {
                    this.kouji_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // メンテナンス名リスト取得(工事番号毎)
        getMaintenanceList(kouji_no) {
            axios.get('kouji_nippou/list?type=maintenance&kouji_no='+kouji_no)
                .then(response => {
                    this.maintenance_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 点検リスト取得(工事番号毎)
        getInspectionList(kouji_no) {
            axios.get('kouji_nippou/list?type=inspection&kouji_no='+kouji_no)
                .then(response => {
                    this.inspection_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 点検明細(種別)リスト取得
        getInspMeisaiList(insp_no) {
            axios.get('kouji_nippou/list?type=insp_mei&insp_no='+insp_no)
                .then(response => {
                    this.$set(this.insp_mei_list, insp_no, response.data)
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 添付ファイル名リスト読出
        downloadAttachFileNameList(kjnp_no) 
        {
            // データ
            // URL作成
            var uri = 'kouji_nippou/filelist?kjnp_no='+kjnp_no
            axios.get(uri)
                .then(response => {
                    if (response.data.file_list != null)
                    {
                        this.att_file_list = response.data.file_list;
                    }
                    else 
                    {
                        this.att_file_list = {};
                    }
                // console.log(response.data);
                })
                .catch((error) => {
                // alert(error); 
                });
        },    

    },
}