<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
            <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <!-- 営業日報 ヘッダー部 -->
      <v-card
        class="mt-0 mb-4 ml-4 mr-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="3"
      >
        <v-card-actions>
          <div style="width: 200px">
            <v-icon>
              mdi-file-document-edit
            </v-icon>
            営業日報登録・編集
          </div>
          <v-spacer></v-spacer>
          <span class="text-body-2 mx-4">
            ※内容を変更した場合、登録ボタンを押してください
          </span>
          <v-btn
            class="mx-1"
            color="blue darken-1"
            dark
            width="100"
            @click="regNippou"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            登録
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backBtn"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-2"></v-divider>

        <v-form ref="basic_info" v-model="valid" lazy-validation>
          <!-- 入力項目 -->
          <v-flex>
            <!-- 1行目 -->
            <v-row no-gutters class="mr-2">
              <v-col lg="1" md="1">
                <v-subheader class="pb-2 red--text float-right">日付</v-subheader>
              </v-col>
              <v-col lg="3" md="3">
                <!-- <div style="width: 180px"> -->
                <div>
                  <v-text-field 
                    v-model="editdate" 
                    placeholder="日付を選択"
                    solo
                    readonly
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                    @click="dateClickNippou('dpick1')"
                  >
                    <template v-slot:append>
                      <date-picker ref="dpick1" v-model="editdate"/>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
              <v-col lg="1" md="1">
                <v-subheader class="pb-2 float-right">目標</v-subheader>
              </v-col>
              <v-col lg="6" md="7">
                <v-textarea
                  v-model="editmokuhyou"
                  placeholder="目標を入力"
                  auto-grow
                  rows="1"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- 2行目 -->
            <v-row no-gutters class="mr-2">
              <v-col lg="1" md="1">
                <v-subheader class="pl-0 pr-2 pb-2 red--text float-right">担当者</v-subheader>
              </v-col>
              <v-col lg="3" md="3">
                <v-autocomplete
                  v-model="edittanto"
                  :items="tanto_name_list"
                  item-value="user_id"
                  item-text="user_name"
                  placeholder="担当者を選択"
                  solo
                  clearable
                  dense
                  hide-details="auto"
                  :rules="[validRules.required]"
                ></v-autocomplete>
              </v-col>
              <v-col lg="1" md="1">
                <v-subheader class="pb-2 float-right">成果</v-subheader>
              </v-col>
              <v-col lg="6" md="7">
                <v-textarea
                  v-model="editseika"
                  placeholder="成果を入力"
                  auto-grow
                  rows="1"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- 3行目 -->
            <v-row no-gutters class="mr-2">
              <v-col lg="4" md="4"></v-col>
              <v-col lg="1" md="1">
                <v-subheader class="pb-2 float-right">課題</v-subheader>
              </v-col>
              <v-col lg="6" md="7">
                <v-textarea
                  v-model="editkadai"
                  placeholder="課題を入力"
                  auto-grow
                  rows="1"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-flex>
        </v-form>
      </v-card>

      <!-- 日報明細 -->
      <v-card
        class="ma-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="3"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            日報明細
          </span>
          <v-spacer></v-spacer>
          <v-sheet
            class="d-flex"
            outlined
            rounded
            elevation="1"
          >
            <v-radio-group
              v-model="detailType"
              class="ml-4 my-2 d-flex align-center"
              row
              hide-details
            >
              <v-radio
                v-for="action in act_list"
                :key="action.id"
                :label="action.name"
                :value="action.id"
              ></v-radio>
            </v-radio-group>
            <v-btn
              class="my-auto mr-1"
              color="cyan darken-1"
              dark
              width="110"
              @click="createMeisai(detailType)"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              明細追加
            </v-btn>
          </v-sheet>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider class="mt-0 mb-0"></v-divider>

        <!-- 明細 -->
        <div v-for="(mei_item, mei_index) in meisai_list" :key="mei_index">
        <v-form :ref="'meisai_info_'+mei_index" v-model="valid" lazy-validation>
          <!-- test表示 -->
          <!-- <li>{{mei_item}}</li> -->
          <!-- test表示 -->

          <!-- ↓商談 -->
          <v-sheet v-if="mei_item.act_id == 703"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.eigyo_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <!-- 明細index -->
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【商談】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="4" md="6">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_sd_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_sd_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="mx-2 mt-2">～</div>
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_to" 
                          placeholder="To"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_sd_time_to'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_sd_time_to'+mei_index" v-model="meisai_list[mei_index].time_to"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col lg="7" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">相手　名前</span>
                          <span v-else>相手<br>名前</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_name"
                          placeholder="相手の名前を入力"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">部署・役職</span>
                          <span v-else>部署・<br>役職</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_post"
                          :placeholder="$vuetify.breakpoint.smAndDown ? '相手の部署・役職を入力' : '相手の部署・役職'"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 red--text float-right">顧客</v-subheader>
                  </v-col>
                  <v-col lg="3" md="5">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].cust_no"
                      :items="cust_name_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                      @change="changeSyodanCustNo"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="3" md="2">
                        <v-subheader class="pb-2 float-right">商談内容</v-subheader>
                      </v-col>
                      <v-col lg="9" md="10">
                        <v-textarea
                          v-model="meisai_list[mei_index].naiyou"
                          placeholder="商談内容を入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">地区</v-subheader>
                  </v-col>
                  <v-col lg="3" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].area_id"
                      :items="area_list"
                      item-value="id"
                      item-text="name"
                      placeholder="地区を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="3" md="2">
                        <v-subheader class="pl-0 pb-2 float-right">成果／見込み</v-subheader>
                      </v-col>
                      <v-col lg="9" md="10" >
                        <v-textarea
                          v-model="meisai_list[mei_index].seika"
                          placeholder="成果／見込みを入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">アプローチ</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].apr_id"
                      :items="apr_list"
                      item-value="id"
                      item-text="name"
                      placeholder="アプローチ"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="3" md="2">
                        <v-subheader class="pb-2 float-right">今後の対応</v-subheader>
                      </v-col>
                      <v-col lg="9" md="10">
                        <v-textarea
                          v-model="meisai_list[mei_index].yotei"
                          placeholder="今後の対応を入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">フェーズ</v-subheader>
                  </v-col>
                  <v-col lg="4" md="6">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].syodan_id"
                      :items="phase_list"
                      item-value="id"
                      item-text="name"
                      placeholder="フェーズを選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="6" md="12">
                    <v-row no-gutters>
                      <v-col lg="3" md="2">
                        <v-subheader class="pl-0 pb-2 float-right">次回対応日時</v-subheader>
                      </v-col>
                      <v-col class="d-flex" lg="9" md="10">
                        <div style="width: 180px">
                          <v-text-field 
                              v-model="meisai_list[mei_index].yotei_date"
                              placeholder="日付"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details
                              @click="dateClickMeisai('dpick_sd_yotei_date'+mei_index)"
                            >
                            <template v-slot:append>
                              <date-picker :ref="'dpick_sd_yotei_date'+mei_index" v-model="meisai_list[mei_index].yotei_date"/>
                            </template>
                          </v-text-field>
                        </div>
                        <div class="mx-1"></div>
                        <div style="width: 140px">
                          <v-text-field 
                              v-model="meisai_list[mei_index].yotei_time" 
                              placeholder="時刻"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details
                              @click="timeClickMeisai('tpick_sd_yotei_time'+mei_index)"
                          >
                            <template v-slot:append>
                              <time-picker :ref="'tpick_sd_yotei_time'+mei_index" v-model="meisai_list[mei_index].yotei_time"/>
                            </template>
                          </v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">引合情報</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].hikiai_no"
                      :items="inquiry_list[meisai_list[mei_index].cust_no]"
                      item-value="id"
                      item-text="name"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                    <!-- <v-btn -->
                      <!-- color="indigo" -->
                      <!-- dark -->
                      <!-- :disabled="!meisai_list[mei_index].hikiai_no" -->
                      <!-- @click="showInquiry(meisai_list[mei_index].hikiai_no)" -->
                    <!-- > -->
                      <!-- <v-icon left> -->
                        <!-- mdi-pencil -->
                      <!-- </v-icon> -->
                      <!-- 編集 -->
                    <!-- </v-btn> -->
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- ↑商談 -->
          <!-- ↓クレーム対応 -->
          <v-sheet v-if="mei_item.act_id == 704"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.edit_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <!-- 明細index -->
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【クレーム対応】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="4" md="6">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_cl_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_cl_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="mx-2 mt-2">～</div>
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_to" 
                          placeholder="To"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_cl_time_to'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_cl_time_to'+mei_index" v-model="meisai_list[mei_index].time_to"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col lg="7" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">クレーム者</span>
                          <span v-else>クレーム<br>者</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_name"
                          :placeholder="$vuetify.breakpoint.smAndDown ? 'ｸﾚｰﾑ者の名前を入力' : 'ｸﾚｰﾑ者の名前'"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">部署・役職</span>
                          <span v-else>部署・<br>役職</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_post"
                          :placeholder="$vuetify.breakpoint.smAndDown ? 'ｸﾚｰﾑ者の部署・役職' : 'ｸﾚｰﾑ者の部署役職'"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 red--text float-right">顧客</v-subheader>
                  </v-col>
                  <v-col lg="3" md="5">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].cust_no"
                      :items="cust_name_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="3" md="2">
                        <v-subheader class="pl-0 pb-2 float-right">クレーム内容</v-subheader>
                      </v-col>
                      <v-col lg="9" md="10">
                        <v-textarea
                          v-model="meisai_list[mei_index].naiyou"
                          placeholder="クレーム内容を入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">地区</v-subheader>
                  </v-col>
                  <v-col lg="3" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].area_id"
                      :items="area_list"
                      item-value="id"
                      item-text="name"
                      placeholder="地区を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="3" md="2">
                        <v-subheader class="pb-2 float-right">対応・結果</v-subheader>
                      </v-col>
                      <v-col lg="9" md="10" >
                        <v-textarea
                          v-model="meisai_list[mei_index].seika"
                          placeholder="対応・結果を入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">アプローチ</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].apr_id"
                      :items="apr_list"
                      item-value="id"
                      item-text="name"
                      placeholder="アプローチ"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">状況</v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-radio-group
                          v-model="meisai_list[mei_index].status"
                          class="ml-2 my-1 d-flex align-center"
                          background-color="white"
                          row
                          hide-details
                        >
                          <v-radio
                            class="mr-2"
                            label="処理中"
                            color="primary"
                            value="1"
                          ></v-radio>
                          <v-radio
                            class="mr-2"
                            label="処理済み"
                            color="success"
                            value="9"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col lg="2" md="2">
                        <v-subheader class="pl-0 pb-2 float-right">次回対応期日</v-subheader>
                      </v-col>
                      <v-col lg="3" md="3">
                        <div style="width: 180px">
                          <v-text-field 
                              v-model="meisai_list[mei_index].yotei_date"
                              placeholder="日付"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details
                              @click="dateClickMeisai('dpick_cl_yotei_date'+mei_index)"
                            >
                            <template v-slot:append>
                              <date-picker :ref="'dpick_cl_yotei_date'+mei_index" v-model="meisai_list[mei_index].yotei_date"/>
                            </template>
                          </v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- ↑クレーム対応 -->
          <!-- ↓その他 -->
          <v-sheet v-if="mei_item.act_id == 705"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.edit_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <!-- 明細index -->
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【その他】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="4" md="6">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_ot_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_ot_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="mx-2 mt-2">～</div>
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_to"  
                          placeholder="To"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_ot_time_to'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_ot_time_to'+mei_index" v-model="meisai_list[mei_index].time_to" />
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col lg="7" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">相手　名前</span>
                          <span v-else>相手<br>名前</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_name"
                          placeholder="相手の名前を入力"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">部署・役職</span>
                          <span v-else>部署・<br>役職</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_post"
                          :placeholder="$vuetify.breakpoint.smAndDown ? '相手の部署・役職を入力' : '相手の部署・役職'"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 red--text float-right">顧客</v-subheader>
                  </v-col>
                  <v-col lg="3" md="5">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].cust_no"
                      :items="cust_name_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="2" md="1">
                    <v-subheader class="pl-0 pb-2 float-right">分類</v-subheader>
                  </v-col>
                  <v-col lg="3" md="4">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].other_cat"
                      :items="othercat_list"
                      item-value="id"
                      item-text="name"
                      placeholder="分類を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">地区</v-subheader>
                  </v-col>
                  <v-col lg="3" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].area_id"
                      :items="area_list"
                      item-value="id"
                      item-text="name"
                      placeholder="地区を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="3" md="2">
                        <v-subheader class="pb-2 float-right">内容</v-subheader>
                      </v-col>
                      <v-col lg="9" md="10">
                        <v-textarea
                          v-model="meisai_list[mei_index].naiyou"
                          placeholder="内容を入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">アプローチ</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].apr_id"
                      :items="apr_list"
                      item-value="id"
                      item-text="name"
                      placeholder="アプローチ"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- ↑その他 -->
          <!-- ↓測量 -->
          <v-sheet v-if="mei_item.act_id == 702"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.edit_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >

              <!-- 明細index -->
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【測量】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="4" md="6">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_sk_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_sk_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="mx-2 mt-2">～</div>
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_to" 
                          placeholder="To"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_sk_time_to'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_sk_time_to'+mei_index" v-model="meisai_list[mei_index].time_to"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col lg="7" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">相手　名前</span>
                          <span v-else>相手<br>名前</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_name"
                          placeholder="相手の名前を入力"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">部署・役職</span>
                          <span v-else>部署・<br>役職</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_post"
                          :placeholder="$vuetify.breakpoint.smAndDown ? '相手の部署・役職を入力' : '相手の部署・役職'"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 red--text float-right">顧客</v-subheader>
                  </v-col>
                  <v-col lg="3" md="5">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].cust_no"
                      :items="cust_name_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="3" md="2">
                        <v-subheader class="pb-2 float-right">内容</v-subheader>
                      </v-col>
                      <v-col lg="9" md="10">
                        <v-textarea
                          v-model="meisai_list[mei_index].naiyou"
                          placeholder="内容を入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">地区</v-subheader>
                  </v-col>
                  <v-col lg="3" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].area_id"
                      :items="area_list"
                      item-value="id"
                      item-text="name"
                      placeholder="地区を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">アプローチ</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].apr_id"
                      :items="apr_list"
                      item-value="id"
                      item-text="name"
                      placeholder="アプローチ"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>
          <!-- ↑測量 -->
          <!-- ↓アポ -->
          <v-sheet v-if="mei_item.act_id == 701"
            class="d-flex align-stretch ma-2"
            :color="cssconsts.edit_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <!-- 明細index -->
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>
            <v-flex>
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【アポ】</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="my-auto mx-1"
                    color="red darken-1"
                    x-small
                    fab
                    dark
                    elevation="2"
                    @click="deleteMeisai(mei_index)"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">時刻</v-subheader>
                  </v-col>
                  <v-col class="d-flex" lg="4" md="6">
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_from" 
                          placeholder="From"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_ap_time_from'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_ap_time_from'+mei_index" v-model="meisai_list[mei_index].time_from"/>
                        </template>
                      </v-text-field>
                    </div>
                    <div class="mx-2 mt-2">～</div>
                    <div style="width: 140px">
                      <v-text-field 
                          v-model="meisai_list[mei_index].time_to" 
                          placeholder="To"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="timeClickMeisai('tpick_ap_time_to'+mei_index)"
                      >
                        <template v-slot:append>
                          <time-picker :ref="'tpick_ap_time_to'+mei_index" v-model="meisai_list[mei_index].time_to"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="pl-0 pb-2 float-right">結果</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <v-radio-group
                      v-model="meisai_list[mei_index].appo_res" 
                      class="mx-2 my-1 d-flex align-center"
                      background-color="white"
                      row
                      hide-details
                    >
                      <v-radio
                        label="OK"
                        color="success"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="NG"
                        color="red"
                        value="9"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 red--text float-right">顧客</v-subheader>
                  </v-col>
                  <v-col lg="3" md="5">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].cust_no"
                      :items="cust_name_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="7" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">相手　名前</span>
                          <span v-else>相手<br>名前</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_name"
                          placeholder="相手の名前を入力"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">
                          <span v-if="$vuetify.breakpoint.smAndDown">部署・役職</span>
                          <span v-else>部署・<br>役職</span>
                        </v-subheader>
                      </v-col>
                      <v-col lg="4" md="4">
                        <v-text-field
                          v-model="meisai_list[mei_index].person_post"
                          :placeholder="$vuetify.breakpoint.smAndDown ? '相手の部署・役職を入力' : '相手の部署・役職'"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">地区</v-subheader>
                  </v-col>
                  <v-col lg="3" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].area_id"
                      :items="area_list"
                      item-value="id"
                      item-text="name"
                      placeholder="地区を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">日時・場所</v-subheader>
                      </v-col>
                      <v-col class="d-flex" lg="10" md="6">
                        <div style="width: 180px">
                          <v-text-field 
                              v-model="meisai_list[mei_index].yotei_date"
                              placeholder="日付"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details
                              @click="dateClickMeisai('dpick_ap_yotei_date'+mei_index)"
                            >
                            <template v-slot:append>
                              <date-picker :ref="'dpick_ap_yotei_date'+mei_index" v-model="meisai_list[mei_index].yotei_date"/>
                            </template>
                          </v-text-field>
                        </div>
                        <div class="mx-1"></div>
                        <div style="width: 140px">
                          <v-text-field 
                              v-model="meisai_list[mei_index].yotei_time" 
                              placeholder="時刻"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details
                              @click="timeClickMeisai('tpick_ap_time_yotei'+mei_index)"
                          >
                            <template v-slot:append>
                              <time-picker :ref="'tpick_ap_time_yotei'+mei_index" v-model="meisai_list[mei_index].yotei_time"/>
                            </template>
                          </v-text-field>
                        </div>
                      </v-col>
                      <v-col lg="2" md="0">
                      </v-col>
                      <v-col lg="10" md="4">
                        <v-textarea  class="mb-2"
                          v-model="meisai_list[mei_index].yotei_place" 
                          placeholder="場所を入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">アプローチ</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <v-autocomplete
                      v-model="meisai_list[mei_index].apr_id"
                      :items="apr_list"
                      item-value="id"
                      item-text="name"
                      placeholder="アプローチ"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="8" md="12">
                    <v-row no-gutters>
                      <v-col lg="2" md="2">
                        <v-subheader class="pb-2 float-right">目的</v-subheader>
                      </v-col>
                      <v-col lg="10" md="10">
                        <v-textarea
                          v-model="meisai_list[mei_index].naiyou"
                          placeholder="目的を入力"
                          auto-grow
                          rows="1"
                          solo
                          clearable
                          dense
                          hide-details
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                  </v-col>
                  <v-col lg="8" md="8">
                    <v-file-input
                      v-if="selatt_disable[mei_item.storage_dirname_mei] != true"
                      placeholder="ファイル選択後［送信］をクリック"
                      solo
                      clearable
                      dense
                      hide-details
                      truncate-length="32"
                      show-size
                      prepend-icon="mdi-paperclip"
                      @change="selectAttachFile($event, mei_item.storage_dirname_mei)"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-btn
                      class="my-auto mx-2"
                      color="cyan darken-1"
                      dark
                      @click="regAttachFile(mei_item.storage_dirname_mei)"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>
                      送信
                    </v-btn>
                  </v-col>
                </v-row>

                <div 
                  v-for="(attfile, att_index) in att_file_list[mei_item.storage_dirname_mei]"
                  :key="mei_index + '_' + att_index"
                  >
                  <v-row no-gutters class="mr-2">
                    <v-col lg="2" md="2">
                    </v-col>
                    <v-col lg="8" md="8">
                      <div class="mr-4 yellow lighten-5">
                        <v-text-field
                          background-color="grey  lighten-5"
                          solo
                          dense
                          hide-details
                          readonly
                          prepend-icon=""
                          :value="att_file_list[mei_item.storage_dirname_mei][att_index]"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-btn
                      color="orange darken-1"
                      dark
                      @click="deleteAttachFile(mei_item.storage_dirname_mei,att_index)"
                    >
                      <v-icon left>
                        mdi-minus-circle
                      </v-icon>
                      削除
                    </v-btn>
                  </v-row>
                </div>

                <div class="mb-2"></div>
              </v-card>
            </v-flex>
          </v-sheet>

        <!-- ↑アポ -->
        </v-form>

        </div>
      </v-card>

      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import DatePicker from "./DatePicker.vue";
import TimePicker from "./TimePicker.vue";
import dbeigyonippou from './js/common/dbeigyonippou.js'
import cssconsts from './js/common/cssconsts';
//export
export default {
  components: {
    DatePicker,
    TimePicker,
  },
  mixins: [dbeigyonippou],

  props:{
      "parameter": {},
  },
  computed:{
  },
  methods: {
    backBtn() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    backNippouList() {
        //「営業日報一覧」画面へ戻る
        this.$emit('viewChange', 'EigyoNippouList');
    },
    showInquiry: function(event, row) {
      //「引合詳細情報」画面へ遷移
      this.$emit('viewChange', 'InquiryDetail', event, 3, 'eigyo');
    },

    createMeisai(act_id){
      // 明細追加
      if (act_id)
      {
        var date = new Date();
        var timestamp = date.getTime();

        var newMeisai = {
          "egnp_no": this.editnippouid, // 営業日報番号
          "act_id": act_id, // アクションID
          "cust_no": null,  // 顧客番号
          "apr_id": null,   // アプローチID
          "storage_dirname_mei": 'mei'+timestamp // ストレージディレクトリ名(一時)
        }
        this.meisai_list.push(newMeisai)
      }
    },
    deleteMeisai(index){
      // 明細削除
      if (index < this.meisai_list.length)
      {
        var res = confirm('この日報明細を削除します。よろしいですか？');
        if (res == true) {
          this.meisai_list.splice(index, 1)
        }
      }
    },

    dateClickNippou(dpickName) {
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    dateClickMeisai(dpickName) {
      //DatePicker を開く
      this.$refs[dpickName][0].menu = true;
    },
    timeClickMeisai(tpickName) {
      //DatePicker を開く
      this.$refs[tpickName][0].menu = true;
    },
    changeSyodanCustNo(cust_no)
    {
      this.getInquiryNameList(cust_no);  // 引合リスト取得
    },
    regNippou()
    {
      //入力チェック
      //--営業日報
      this.valid = this.$refs.basic_info.validate();
      if (!this.valid) {
        alert("営業日報に入力不足、または間違いがあります");
        return;
      }
      //--明細
      for (let index=0; index<this.meisai_list.length; index++)
      {
        var refname = 'meisai_info_'+index;
        // console.log(refname)
        this.valid = this.$refs['meisai_info_'+index][0].validate();
        if (!this.valid) {
          alert("日報明細"+Number(index+1)+"に入力不足、または間違いがあります");
          return;
        }

      }

      var nippoudata = {};
      var meisaidata = [];
      var storage_dirname_meisai = [];
      // 日報
      nippoudata.egnp_no = this.editnippouid;
      nippoudata.tanto_id = this.edittanto;
      nippoudata.rept_date = this.editdate;
      nippoudata.mokuhyou = this.editmokuhyou;
      nippoudata.seika = this.editseika;
      nippoudata.kadai = this.editkadai;
      // 明細
      this.meisai_list.forEach(function(elem, index) {
        var meisaiitem = {};
        meisaiitem.egnp_no = elem.egnp_no
        meisaiitem.egnp_mei_no = elem.egnp_mei_no
        meisaiitem.act_id = elem.act_id
        meisaiitem.time_from = elem.time_from
        meisaiitem.time_to = elem.time_to
        meisaiitem.cust_no = elem.cust_no
        meisaiitem.area_id = elem.area_id
        meisaiitem.person_name = elem.person_name
        meisaiitem.person_post = elem.person_post
        meisaiitem.apr_id = elem.apr_id
        meisaiitem.syodan_id = elem.syodan_id
        meisaiitem.hikiai_no = elem.hikiai_no
        meisaiitem.naiyou = elem.naiyou
        meisaiitem.seika = elem.seika
        meisaiitem.status = elem.status
        meisaiitem.appo_res = elem.appo_res
        meisaiitem.yotei = elem.yotei
        meisaiitem.yotei_date = elem.yotei_date;
        meisaiitem.yotei_time = elem.yotei_time
        meisaiitem.yotei_place = elem.yotei_place
        meisaiitem.other_cat = elem.other_cat
        meisaiitem.att_file = elem.att_file

        meisaidata.push(meisaiitem);
        storage_dirname_meisai.push(elem.storage_dirname_mei)
      }.bind(this));

      this.setEigyoNippou(nippoudata, meisaidata, this.storage_dirname_nippou, storage_dirname_meisai);
    },

    // 営業日報登録
    setEigyoNippou(nippou, meisai, storage_np, storage_mei) 
    {
      // データ
      // URL作成
      var uri = 'eigyo_nippou'
      // データ格納(store)
      axios.post(uri, {'nippou':nippou,'meisai':meisai, 'storage_np':storage_np, 'storage_mei':storage_mei})
        .then(response => {
          // console.log(response.data);
          alert("営業日報を登録・更新しました");
          this.backNippouList();  // 成功したら一覧に戻る
        })
        .catch((error) => {
          alert("営業日報の登録・更新に失敗しました");
        });
    },    

    // 添付ファイルデータ登録
    uploadAttachFileData(egnp_no, filename, data, egnp_mei_no, file_index) 
    {
      // データ
      // URL作成
      var uri = 'eigyo_nippou/file'
      // データ格納(store)
      axios.post(uri, {
        'egnp_no':egnp_no, 
        'filename':filename, 
        'data':data, 
        'egnp_mei_no':egnp_mei_no, 
        'file_index': file_index,
        })
        .then(response => {
          // console.log(response.data);
          // ファイル名リスト再読出し
          this.downloadAttachFileNameList(egnp_no);
        })
        .catch((error) => {
          // alert(error); 
        });
    },    

    // 添付ファイルの選択
    selectAttachFile(ev,egnp_mei_no)
    {
      const files = ev;
      if((files != undefined) && (files != null)) {
        var dataFile = files;
        this.$nextTick(() => {
          this.setFileData(dataFile,egnp_mei_no)
        });
      }
    },

    setFileData(dataFile,egnp_mei_no) {
      const reader = new FileReader();
      reader.onload = () => {
        // データバッファへ読み込み
        var datauri = reader.result
        var base64EncodedFile = datauri.replace(/^data:.*?;base64,/, '');
        this.att_file_data_buffer[egnp_mei_no] = base64EncodedFile
        this.att_file_name_buffer[egnp_mei_no] = dataFile.name
      }
      reader.readAsDataURL(dataFile);
    },

    // 添付ファイルの登録（アップロード送信）
    regAttachFile(egnp_mei_no)
    {
      if ((this.att_file_name_buffer[egnp_mei_no] == undefined) || (this.att_file_name_buffer[egnp_mei_no] == null))
      { 
        // ファイル未選択の場合
        return
      }

      var file_index = null
      if ((this.att_file_list[egnp_mei_no] == undefined) || (this.att_file_list[egnp_mei_no] == null))
      {
        file_index = 0
      }
      else 
      {
        var keyarray = Object.keys(this.att_file_list[egnp_mei_no]);
        var newindex = Math.max.apply(null,keyarray) + 1;
        file_index = newindex;
      }
      // アップロード
      this.uploadAttachFileData(this.storage_dirname_nippou, this.att_file_name_buffer[egnp_mei_no], this.att_file_data_buffer[egnp_mei_no], egnp_mei_no, file_index) // 添付ファイルをアップロード
      // ファイル選択をリセット
      this.att_file_data_buffer[egnp_mei_no] = null
      this.att_file_name_buffer[egnp_mei_no] = null
      this.$set(this.selatt_disable, egnp_mei_no, true)
      this.$nextTick(function () {
        this.$set(this.selatt_disable, egnp_mei_no, false)
      });
    },

    deleteAttachFile(egnp_mei_no, att_index)
    {
      var res = confirm('このファイルを削除します。よろしいですか？');
      if (res == true) {
        this.deleteAttachFileData(this.storage_dirname_nippou, egnp_mei_no, att_index) 
      }
    },

    // 添付ファイルデータ削除
    deleteAttachFileData(egnp_no, egnp_mei_no, file_index) 
    {
      // URL作成
      var uri = 'eigyo_nippou/file?egnp_no='+egnp_no+'&egnp_mei_no='+egnp_mei_no+'&file_index='+file_index
      // データ格納(store)
      axios.delete(uri)
        .then(response => {
          // console.log(response.data);
          // ファイル名リスト再読出し
          this.downloadAttachFileNameList(egnp_no);
        })
        .catch((error) => {
          // alert(error); 
        });
    },    

  },
  created(){
    this.getEigyoTantoNameList(); // 営業担当者名リスト取得
    this.getCustomerNameList(); // 顧客名リスト取得
    this.getApproachNameList(); // 営業アプローチリスト取得
    this.getActionNameList(); // 営業アクションリスト取得
    this.getAreaNameList(); // 地区リスト取得
    this.getPhaseNameList();  // 商談フェーズリスト取得
    this.getOtherCatNameList(); // その他分類リスト取得

    if (this.parameter.viewparam)
    {
      if (this.parameter.viewparam.nippou)
      {
        if (this.parameter.viewparam.nippou.egnp_no)
        {
          this.editnippouid = this.parameter.viewparam.nippou.egnp_no;
        }
        if (this.parameter.viewparam.nippou.tanto_id)
        {
          this.edittanto = this.parameter.viewparam.nippou.tanto_id;
        }
        if (this.parameter.viewparam.nippou.rept_date)
        {
          this.editdate = this.parameter.viewparam.nippou.rept_date;
        }
        if (this.parameter.viewparam.nippou.mokuhyou)
        {
          this.editmokuhyou = this.parameter.viewparam.nippou.mokuhyou;
        }
        if (this.parameter.viewparam.nippou.seika)
        {
          this.editseika = this.parameter.viewparam.nippou.seika;
        }
        if (this.parameter.viewparam.nippou.kadai)
        {
          this.editkadai = this.parameter.viewparam.nippou.kadai;
        }
      }
      if (this.parameter.viewparam.meisai)
      {
        // 先に引合リストを取得(v-selectのため)
        const paramcnt = this.parameter.viewparam.meisai.length
        for(var index=0; index<paramcnt; index++)
        {
          var elem=this.parameter.viewparam.meisai[index];
          if (elem.act_id == 703)
          {
            // 商談のみ
            if (elem.cust_no)
            {
              if ((elem.cust_no in this.inquiry_list) == false)
              {
                this.getInquiryNameList(elem.cust_no);  // 引合リスト取得
              }
            }
          }
        };

        this.meisai_list = this.parameter.viewparam.meisai.concat();
        // 明細データ変換
        this.meisai_list.forEach(function(elem, index) {
          // 時刻
          if (elem.time_from) this.meisai_list[index].time_from = this.timeFormatHHMM(elem.time_from);
          if (elem.time_to) this.meisai_list[index].time_to = this.timeFormatHHMM(elem.time_to);
          if (elem.yotei_time) this.meisai_list[index].yotei_time = this.timeFormatHHMM(elem.yotei_time);

          // 明細ストレージディレクトリ名
          if (elem.egnp_mei_no) this.meisai_list[index].storage_dirname_mei = elem.egnp_mei_no;
        }.bind(this));
      }
      if (this.parameter.viewparam.att_file)
      {
        this.att_file_list = this.parameter.viewparam.att_file
      }
    }
    // 日報ストレージディレクトリ名
    if (this.editnippouid)
    {
      this.storage_dirname_nippou = this.editnippouid;
    }
    else {
      var date = new Date();
      var timestamp = date.getTime();
      this.storage_dirname_nippou = 'np'+timestamp;
    }

    if (this.editdate == null)
    {
      // 日付未選択（新規作成）時は今日の日付を入力
      var today = new Date();
      var daystr = today.getFullYear() + "/" + ("0" + (today.getMonth()+1)).slice(-2) + "/" + ("0" + today.getDate()).slice(-2);
      this.editdate = daystr;
    }
    if (this.edittanto == null)
    {
      // 担当者未選択（新規作成）時はログインユーザーを選択
      this.edittanto = this.parameter.homeparam.loginuser.user_id;
    }
    this.m_createBreads();
  },
  mounted() {
    //エラーをリセット
    this.$refs.basic_info.resetValidation();
  },

  data: function(){
    return {
      cssconsts: cssconsts,
      tanto_name_list: [],  // 担当者選択
      cust_name_list: [],  // 顧客選択
      apr_list: [],  // アプローチ選択
      act_list: [],  // アクション選択
      area_list: [], // 地区選択
      phase_list: [], // フェーズ選択
      othercat_list: [],  // その他分類選択
      inquiry_list: {},  //引合リスト取得

      editnippouid: null,  // 営業日報番号の編集データ
      editmokuhyou: null,  // 目標の編集データ
      editseika: null,  // 成果の編集データ
      editkadai: null,  // 課題の編集データ
      editdate: null,  // 日付の編集データ
      edittanto: null,  // 担当者の編集データ
      meisai_list: [],  // 明細の編集データ
      storage_dirname_nippou: null, // 日報ストレージディレクトリ名
      detailType: null,

      att_file_list: {}, // 添付ファイル名
      selatt_disable: {},
      att_file_name_buffer: {},  // 添付ファイル名送信用バッファ
      att_file_data_buffer: {},  // 添付ファイル送信用バッファ

      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>

<style>
/* ラジオボタンのラベル位置調整用 */
label {
  margin-bottom: 0 !important;
}
</style>
