// google map用 共通処理
export default {
  methods: {
    // 郵便番号と住所 検索
    searchPostalcodeAddress(address, resultfunc) {
      const geocoder = new window.google.maps.Geocoder()
      var searchaddress = address;

      geocoder.geocode({
        address: searchaddress,
        language: 'ja',
        componentRestrictions: {country: 'jp'}

      }, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0].geometry) {
            var components = results[0].address_components;
            var resultaddr = '';
            var resultpostal = '';
            var addresstypes = [
              'administrative_area_level_1',  // 都道府県
              'administrative_area_level_2',  // 郡
              'locality', // 市区町村
              'sublocality_level_1',  // 政令指定都市の区
              'sublocality_level_2',  // 大字・町
              'sublocality_level_3',  // 小字・丁目
              'sublocality_level_4',  // 街区
              'sublocality_level_5',  // 
              'sublocality',
              'premise', // 地番、枝番、建物名
              'subpremise',
              'floor '
              ];
            var postalcdtypes = [
              'postal_code',  // 郵便番号
            ];
            // 結果を反映
            for(var i=components.length-1; i>=0; i--)
            {
              var longname = components[i].long_name;
              var types = components[i].types;
              // typesがaddresstypesに含まれる場合は住所に適用
              var typeinc = types.some((val, idx, obj) => {
                return(addresstypes.includes(val))
              });
              if (typeinc)
              {
                resultaddr += longname;
              }
              else 
              {
                var typeinc = types.some((val, idx, obj) => {
                  return(postalcdtypes.includes(val))
                });
                if (typeinc)
                {
                  resultpostal += longname.replace(/[^0-9]/g, ''); // 数字のみ
                }
              }
            }
            resultfunc(resultaddr, resultpostal);
          }
        } else if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
          // alert("住所が見つかりません");
        } else {
          console.log(status);
          alert("住所検索エラー");
        }
      });       
    },
  },
  data() {
    return { 
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
  },
}