<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-card
        class="mt-0 mb-4 ml-4 mr-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="10"
        height="100%"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-handshake-outline
            </v-icon>
            引合詳細情報
          </span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="editInquiry"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            編集
          </v-btn>
          <v-btn
            class="mx-1"
            color="orange darken-3"
            dark
            width="100"
            @click="delInquiry"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            削除
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backInquiryList"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-1"></v-divider>
        <v-flex>
          <!-- 引合情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">引合情報</div>
            </v-sheet>
              
            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客番号</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{hikiai.cust_no}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
              </v-col>
              <v-col cols="5" class="yellow lighten-4">
                <span>
                  {{hikiai.cust_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">引合日</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{hikiai.hikiai_date}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{hikiai.area}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">引合内容</span>
              </v-col>
              <v-col cols="10" class="yellow lighten-4">
                <span>
                  {{hikiai.hikiai_naiyou}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">工事種別</span>
              </v-col>
              <v-col cols="4" class="yellow lighten-4">
                <span>
                  {{hikiai.kouji_type_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">受注予定日</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{hikiai.order_exp_date}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">受注予定額</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{numberToLocal(hikiai.order_exp_amt)}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">結果</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{hikiai.status}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">営業担当</span>
              </v-col>
              <v-col cols="6" class="yellow lighten-4">
                <span>
                  {{hikiai.htg_tanto_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-0 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">備考</span>
              </v-col>
              <v-col cols="10" class="yellow lighten-4">
                <div v-html="bikou"></div>
              </v-col>
            </v-row>
          </v-sheet>

          <!-- 工事部位 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">工事部位</div>
            </v-sheet>
            <template
              v-for="kojiPart in kojiParts"
            >
              <v-row
                no-gutters class="mt-2 mb-0 mx-2"
                :key="kojiPart.partNo"
              >
                <v-col cols="2" class="d-flex">
                  <span class="ml-2 my-auto text-body-2">{{kojiPart.partName}}</span>
                </v-col>
                <v-col cols="10" class="d-flex flex-wrap">
                  <div
                    v-for="(model, index) in kojiPart.models"
                    :key="index"
                    class="ml-2 d-flex"
                  >
                    <v-icon
                      v-if="model.select === true"
                      class="mx-1"
                      color="grey darken-3"
                    >
                      mdi-checkbox-marked-outline
                    </v-icon>
                    <v-icon
                      v-else
                      class="mx-1"
                      color="grey lighten-1"
                    >
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <span class="my-auto text-body-2">{{model.name}}</span>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-0" :key="'d'+kojiPart.partNo"></v-divider>
            </template>
          </v-sheet>

          <!-- 工事見積 -->
          <v-sheet
            class="mx-2 mt-1 mb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">工事見積</div>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-1"
                color="light-blue darken-1"
                dark
                small
                width="110"
                @click="regConstrEst"
              >
                工事見積登録
              </v-btn>
            </v-sheet>

            <v-data-table
              calculate-widths
              height="300"
              :headers="estimateHeaders"
              :items="mitumori"
              :items-per-page="10"
              :sort-by.sync="estimateSortBy"
              sort-desc
              class="elevation-1"
              locale="jp-ja"
              loading-text="読込中"
              no-data-text="該当するデータがありません。"
              @click:row="showConstrEst"
            >
              <template v-slot:[`item.pres_amt`]="{ item }">
                <span>
                  {{numberToLocal(item.pres_amt)}}
                </span>
              </template>
              <template v-slot:[`item.ukeoi_amt`]="{ item }">
                <span>
                  {{numberToLocal(item.ukeoi_amt)}}
                </span>
              </template>
              <template v-slot:[`item.order`]="{ item }">
                <v-btn
                  color="blue lighten-4"
                  :disabled="item.keiyaku_no == 0"
                  @click.stop="showOrder(item)"
                >
                  受注
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-flex>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import cssconsts from './js/common/cssconsts';
import dbinquiry from './js/common/dbinquiry.js'
//export
export default {
  mixins: [dbinquiry],

  props:{
      "parameter": {},
  },
  created : function(){
    // 引合番号をthis.parameter.viewparamから取得
    var hikiai_no = this.parameter.viewparam;
    // 引合番号が一致するデータを読み出す
    //以下に格納
    //⇒this.hikiai
    //⇒this.kouji_part_list
    //⇒this.mitumori
    this.getInquiryDetail(hikiai_no);
    this.m_createBreads();
  },
  mounted() {
  },

  watch: {
    hikiai: function(data) {
      //alert("customer changed");
      if (data.bikou != null) {
        this.bikou = data.bikou.replace("\n", "<br>");
      }
    },
    kouji_part_list: function(data) {
      this.kojiParts = [];
      var kj_part_id = 0;
      for (var elem of data) {
        if (elem.kj_part_id != kj_part_id) {
          this.kojiParts.push({
            partNo: elem.kj_part_id,
            partName: elem.kj_part_name,
            models: [],
          });
          kj_part_id = elem.kj_part_id;
        }
        this.kojiParts[this.kojiParts.length - 1].models.push({
          name: elem.model_name,
          select: (elem.choice == "1")
        });
      }
    }
  },

  methods: {
    numberToLocal(num) {
      if (num == null) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
    editInquiry() {
      //「引合情報登録・編集」画面へ遷移
      var viewparam = {}
      viewparam.hikiai_no = this.hikiai.hikiai_no
      this.$emit('viewChange', 'InquiryEdit', viewparam);
    },
    regConstrEst() {
      var mitumori_data = null
      if (this.hikiai.cust_no){
        // 検索条件で選択中の顧客を編集画面のデフォルト選択とする
        mitumori_data = {}
        mitumori_data.hikiai = {}
        mitumori_data.hikiai.cust_no = this.hikiai.cust_no
      }
      //「工事見積登録・編集」画面へ遷移
      this.$emit('viewChange', 'ConstrEstEdit', mitumori_data, 4);
    },
    showConstrEst: function(event, row) {
      //「工事見積情報」画面へ遷移
      this.$emit('viewChange', 'ConstrEst', row.item.mitumori_no, 4);
    },
    showOrder(item) {
      //「受注情報」画面へ遷移
      this.$emit('viewChange', 'Order', item.keiyaku_no, 5);
    },
    delInquiry() {
      // 引合削除
      var res = confirm('当引合を削除します。よろしいですか？');
      if (res == true) {
        this.deleteInquiry(this.hikiai.hikiai_no);
      }
    },
    backInquiryList() {
      // 戻るボタン
      this.$emit('viewBack');
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      //引合データ
      hikiai: {},
      kouji_part_list: [],

      //編集項目
      bikou: "",
      kojiParts: [], //工事部位

      //見積一覧
      estimateSortBy: "mitumori_no",
      estimateHeaders: [
        { text: '見積番号', value: 'mitumori_no', width: '80px', class: 'px-1' },
        { text: '見積日', value: 'mitumori_date', width: '100px', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: 'プラン名', value: 'plan_name', width: '200px', class: 'px-1' },
        { text: '見積額', value: 'pres_amt', align: 'end', width: '100px', class: 'px-1' },
        { text: '受注金額', value: 'ukeoi_amt', align: 'end', width: '100px', class: 'px-1' },
        { text: '見積担当', value: 'mtg_tanto_name', width: '100px', class: 'px-1' },
        { text: '', value: 'order', sortable: false, width: '100px', class: 'px-1' },
      ],
      mitumori: [],
      breads: [],

    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap{
   white-space: nowrap;
}

td.text-start,td.text-end{
    padding-right: 3px !important;
    padding-left: 3px !important;
}
</style>