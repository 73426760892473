import { render, staticRenderFns } from "./KojiNippou.vue?vue&type=template&id=23d1d92b&scoped=true&"
import script from "./KojiNippou.vue?vue&type=script&lang=js&"
export * from "./KojiNippou.vue?vue&type=script&lang=js&"
import style1 from "./KojiNippou.vue?vue&type=style&index=1&id=23d1d92b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d1d92b",
  null
  
)

export default component.exports