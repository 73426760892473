<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-card
        class="mt-0 mb-4 ml-4 mr-4 outline-card"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="10"
        height="100%"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-account
            </v-icon>
            顧客ポータル
          </span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backPrevPage"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-1"></v-divider>

        <!-- 基本情報 -->
        <v-sheet
        class="mx-2 mt-1 mb-2 pb-2"
        color="white"
        outlined
        rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
          <div class="mx-2 my-auto">基本情報</div>
          <v-spacer></v-spacer>
            <v-btn
              class="mx-1 my-1"
              color="blue darken-1"
              dark
              small
              width="80"
              elevation="1"
              @click="showCustomer"
            >
              <v-icon left>
                mdi-account-details
              </v-icon>
              詳細
            </v-btn>
          </v-sheet>
            
          <v-row no-gutters class="mt-2 mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">顧客番号</span>
            </v-col>
            <v-col cols="2" class="yellow lighten-4">
              <span>
                {{customer.cust_no}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
            </v-col>
            <v-col cols="5" class="yellow lighten-4">
              <span>
                {{customer.cust_name}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">顧客種別</span>
            </v-col>
            <v-col cols="2" class="yellow lighten-4">
              <span>
                {{customer.cust_type_name}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">フリガナ</span>
            </v-col>
            <v-col cols="5" class="yellow lighten-4">
              <span>
                {{customer.cust_kana}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">現住所</span>
            </v-col>
            <v-col cols="2">
              <div class="yellow lighten-4" style="width: 120px">
                <span>
                  {{customer.post_cd}}
                </span>
              </div>
            </v-col>
            <v-col cols="6" class="yellow lighten-4">
              <div>
                {{customer.addr}}
                <br>{{customer.addr2}}
              </div>
            </v-col>
            <v-col cols="1">
              <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                <input type="hidden" name="_token" :value="csrf">
                <input type="hidden" name="mapdata" value="">
              </form>
              <v-btn
                class="ml-2"
                color="indigo darken-1"
                small
                dark
                @click="showMap()"
              >
                <v-icon left>
                mdi-map-marker
                </v-icon>
                地図
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">緯度</span>
            </v-col>
            <v-col cols="3" class="yellow lighten-4">
              <span v-if="customer">
                {{customer.addr_lat}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">経度</span>
            </v-col>
            <v-col cols="3" class="yellow lighten-4">
              <span v-if="customer">
                {{customer.addr_lng}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">TEL</span>
            </v-col>
            <v-col class="yellow lighten-4" cols="2">
              <span>
                {{customer.tel_no}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">FAX</span>
            </v-col>
            <v-col class="yellow lighten-4" cols="2">
              <span>
                {{customer.fax_no}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">携帯</span>
            </v-col>
            <v-col class="yellow lighten-4" cols="2">
              <span>
                {{customer.cell_no}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">Eメール</span>
            </v-col>
            <v-col cols="5" class="yellow lighten-4">
              <span>
                {{customer.email}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-0 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">生年月日</span>
            </v-col>
            <v-col cols="9">
              <v-row no-gutters>
                <v-col class="yellow lighten-4" cols="2">
                  {{customer.birth_date}}
                </v-col>
                <v-col cols="1" class="d-flex">
                  <span class="ml-auto mr-2 my-auto text-body-2">年齢</span>
                </v-col>
                <v-col cols="1" class="yellow lighten-4">
                  <span>
                    {{m_calcAge(customer.birth_date)}}
                  </span>
                </v-col>
                <v-col cols="1" class="d-flex">
                  <span class="ml-auto mr-2 my-auto text-body-2">性別</span>
                </v-col>
                <v-col cols="1" class="yellow lighten-4">
                  <span>
                    {{customer.sex}}
                  </span>
                </v-col>
                <v-col cols="1" class="d-flex">
                  <span class="ml-auto mr-2 my-auto text-body-2">ＤＭ</span>
                </v-col>
                <v-col cols="2" class="yellow lighten-4">
                  <span>
                    {{customer.dm}}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>

        <!-- アラート一覧 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <div class="mx-2 my-auto">アラート一覧</div>
            <v-spacer></v-spacer>
            <v-checkbox
              v-model="showComp"
              class="shrink mx-2 my-auto"
              color="cyan accent-1"
              value="1"
              hide-details
              true-value="true"
              false-value="false"
              @change='changeShowComp'
            >
              <template v-slot:label>
                <span class="text-body-2">
                  対応済みも表示する
                </span>
              </template>
            </v-checkbox>
            <v-btn
              class="mx-1 my-1"
              color="blue darken-1"
              dark
              small
              width="160"
              elevation="1"
              @click="changeTaio"
            >
              <v-icon left>
                mdi-reload
              </v-icon>
              対応済み切り替え
            </v-btn>
          </v-sheet>
          <v-simple-table
            height="240px"
          >
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="alert in alerts"
                  :key="alert.alert_no"
                >
                  <td class="px-2">
                    {{ alert.alert_naiyo }}
                  </td>
                  <td class="px-2">
                    <v-checkbox
                      v-model="alert.status"
                      class="my-auto mr-2 float-right"
                      hide-details
                      false-value="0"
                      true-value="1"
                    >
                      <template v-slot:label>
                        <div style="width: 120px">
                          {{alert.taiou}}
                        </div>
                      </template>
                    </v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-sheet>

        <!-- 引合情報 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <v-btn
              class="my-auto mx-1"
              color="light-green darken-1"
              fab
              x-small
              @click="stretch('inquiry')"
              >
              <v-icon v-if="stretchInquiry === true">
                mdi-arrow-collapse-vertical
              </v-icon>
              <v-icon v-else>
                mdi-arrow-expand-vertical
              </v-icon>
            </v-btn>
            <div class="mx-2 my-auto">引合情報</div>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-1"
              color="light-blue darken-1"
              dark
              small
              width="100"
              @click="regInquiry"
            >
              新規登録
            </v-btn>
          </v-sheet>
          <v-flex class="mt-2 mb-0 mx-2">
            <template v-if="stretchInquiry === true">
              <v-data-table ref="inquiry"
                calculate-widths
                :headers="inquiryHeaders"
                :items="inquiry_list"
                :items-per-page="10"
                :sort-by.sync="inquirySortBy"
                sort-desc
                height="300"
                class="elevation-1"
                locale="jp-ja"
                loading-text="読込中"
                no-data-text="該当するデータがありません。"
                @click:row="showInquiry"
              >
                <template v-slot:[`item.order_exp_amt`]="{ item }">
                  <span>
                    {{numberToLocal(item.order_exp_amt)}}
                  </span>
                </template>
                <template v-slot:[`item.estimate`]="{ item }">
                  <v-btn
                    color="lime lighten-4"
                    :disabled="item.mitumori_no == 0"
                    @click.stop="showConstrEst(item)"
                  >
                    見積
                  </v-btn>
                </template>
                <template v-slot:[`item.order`]="{ item }">
                  <v-btn
                    color="blue lighten-4"
                    :disabled="item.keiyaku_no == 0"
                    @click.stop="showOrder(item)"
                  >
                    受注
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </v-flex>
        </v-sheet>

        <!-- 日報一覧 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <v-btn
              class="my-auto mx-1"
              color="light-green darken-1"
              fab
              x-small
              @click="stretch('nippou')"
            >
              <v-icon v-if="stretchNippou === true">
                mdi-arrow-collapse-vertical
              </v-icon>
              <v-icon v-else>
                mdi-arrow-expand-vertical
              </v-icon>
            </v-btn>
            <div class="mx-2 my-auto">日報一覧</div>
          </v-sheet>
          <v-flex class="mt-2 mb-0 mx-2">
            <template v-if="stretchNippou === true">
              <v-data-table
                calculate-widths
                :headers="nippouHeaders"
                :items="nippou_list"
                :items-per-page="10"
                :sort-by.sync="nippouSortBy"
                sort-desc
                height="300"
                class="elevation-1"
                dense
                locale="jp-ja"
                loading-text="読込中"
                no-data-text="該当するデータがありません。"
                @click:row="showNippou"
              ></v-data-table>
            </template>
          </v-flex>
        </v-sheet>

        <!-- 工事予定・実績 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <v-btn
              class="my-auto mx-1"
              color="light-green darken-1"
              fab
              x-small
              @click="stretch('construction')"
            >
              <v-icon v-if="stretchConstruction === true">
                mdi-arrow-collapse-vertical
              </v-icon>
              <v-icon v-else>
                mdi-arrow-expand-vertical
              </v-icon>
            </v-btn>
            <div class="mx-2 my-auto">工事予定・実績</div>
          </v-sheet>
          <v-flex class="mt-2 mb-0 mx-2">
            <template v-if="stretchConstruction === true">
              <v-data-table
                calculate-widths
                :headers="constructionHeaders"
                :items="kouji_list"
                :items-per-page="10"
                :sort-by.sync="constructionSortBy"
                sort-desc
                height="300"
                class="elevation-1"
                locale="jp-ja"
                loading-text="読込中"
                no-data-text="該当するデータがありません。"
              >
              <template v-slot:[`header.ukeoi_kngk`]="{ header }">
                <span>{{ header.text }}</span><br>
                <span>（税込）</span>
              </template>
                <template v-slot:[`item.ukeoi_kngk`]="{ item }">
                  <span>
                    {{numberToLocal(item.ukeoi_kngk)}}
                  </span>
                </template>
                <template v-slot:[`item.payment`]="{ item }">
                  <v-btn
                    color="blue lighten-4"
                    :disabled="item.keiyaku_no == null"
                    @click.stop="editNyukin(item.keiyaku_no)"
                  >
                    入金
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </v-flex>
        </v-sheet>

        <!-- メンテナンス予定・実績 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <v-btn
              class="my-auto mx-1"
              color="light-green darken-1"
              fab
              x-small
              @click="stretch('maintenance')"
            >
              <v-icon v-if="stretchMaintenance === true">
                mdi-arrow-collapse-vertical
              </v-icon>
              <v-icon v-else>
                mdi-arrow-expand-vertical
              </v-icon>
            </v-btn>
            <div class="mx-2 my-auto">メンテナンス予定・実績</div>
          </v-sheet>
          <v-flex class="mt-2 mb-0 mx-2">
            <template v-if="stretchMaintenance === true">
              <v-data-table
                calculate-widths
                :headers="maintenanceHeaders"
                :items="mainte_list"
                :items-per-page="10"
                :sort-by.sync="maintenanceSortBy"
                sort-desc
                height="300"
                class="elevation-1"
                dense
                locale="jp-ja"
                loading-text="読込中"
                no-data-text="該当するデータがありません。"
              ></v-data-table>
            </template>
          </v-flex>
        </v-sheet>

        <!-- 点検予定・実績 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <v-btn
              class="my-auto mx-1"
              color="light-green darken-1"
              fab
              x-small
              @click="stretch('inspection')"
            >
              <v-icon v-if="stretchInspection === true">
                mdi-arrow-collapse-vertical
              </v-icon>
              <v-icon v-else>
                mdi-arrow-expand-vertical
              </v-icon>
            </v-btn>
            <div class="mx-2 my-auto">点検予定・実績</div>
          </v-sheet>
          <v-flex class="mt-2 mb-0 mx-2">
            <template v-if="stretchInspection === true">
              <v-data-table
                calculate-widths
                :headers="inspectionHeaders"
                :items="inspect_list"
                :items-per-page="10"
                :sort-by.sync="inspectionSortBy"
                sort-desc
                height="300"
                class="elevation-1"
                dense
                locale="jp-ja"
                loading-text="読込中"
                no-data-text="該当するデータがありません。"
              ></v-data-table>
            </template>
          </v-flex>
        </v-sheet>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import dbcustomer from './js/common/dbcustomer.js'
import gmap from './js/common/gmap.js'
import dbcommon from './js/common/dbalertcustomer.js'


//export
export default {
  mixins: [dbcustomer, gmap, dbcommon],
  
  props:{
      "parameter": {},
  },
  created: function() {
    // 顧客番号をthis.parameter.viewparamから取得
    var cust_no = this.parameter.viewparam;
    // 顧客番号が一致するデータ（基本情報）を読み出す
    //以下に格納
    //⇒this.customer
    this.getCustomerBasic(cust_no);
    // 顧客番号が一致する引合リストを取得
    //以下に格納
    //⇒this.inquiry_list
    this.getCustomerInquiryList(cust_no);
    // 顧客番号が一致する日報リストを取得
    //以下に格納
    //⇒this.nippou_list
    this.getCustomerNippouList(cust_no);
    // 顧客番号が一致する工事リストを取得
    //以下に格納
    //⇒this.kouji_list
    this.getCustomerKoujiList(cust_no);
    // 顧客番号が一致するメンテナンスリストを取得
    //以下に格納
    //⇒this.mainte_list
    this.getCustomerMainteList(cust_no);
    // 顧客番号が一致する点検リストを取得
    //以下に格納
    //⇒this.inspect_list
    this.getCustomerInspectList(cust_no);

    this.getAlertList(this.showComp,cust_no);
    this.m_createBreads();
  },
  computed: {
  },
  methods: {
    stretch(list) {
      if (list == "inquiry") {
        this.stretchInquiry = !this.stretchInquiry;
      } else if (list == "nippou") {
        this.stretchNippou = !this.stretchNippou;
      } else if (list == "construction") {
        this.stretchConstruction = !this.stretchConstruction;
      } else if (list == "maintenance") {
        this.stretchMaintenance = !this.stretchMaintenance;
      } else if (list == "inspection") {
        this.stretchInspection = !this.stretchInspection;
      }
    },

    numberToLocal(num) {
      if (num == null) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },

    backPrevPage() {
      // 前画面に戻る
      this.$emit('viewBack');
    },
    showCustomer() {
      //「顧客詳細情報」画面へ遷移
      this.$emit('viewChange', 'Customer', this.customer.cust_no);
    },
    regInquiry() {
      //「引合情報登録・編集」画面へ遷移
      var viewparam = {}
      if (this.customer && this.customer.cust_no)
      {
        viewparam.cust_no = this.customer.cust_no
      }
      this.$emit('viewChange', 'InquiryEdit', viewparam, 3);  //新規登録
    },
    showInquiry: function(event, row) {
      //「引合詳細情報」画面へ遷移
      this.$emit('viewChange', 'InquiryDetail', row.item.hikiai_no, 3);
    },
    showConstrEst(item) {
      //「工事見積情報」画面へ遷移
      this.$emit('viewChange', 'ConstrEst', item.mitumori_no, 4);
    },
    showOrder(item) {
      //「受注情報」画面へ遷移
      this.$emit('viewChange', 'Order', item.keiyaku_no, 5);
    },

    showNippou: function(event, row) {
      //「営業日報」画面へ遷移
      this.$emit('viewChange', 'EigyoNippou', row.item.egnp_no, 1);
    },
    editNyukin(keiyaku_no) {
      var param = {
        keiyaku_no: keiyaku_no
      };
      //「受注登録・編集」画面へ遷移
      this.$emit('viewChange', 'OrderEdit', param, 5, 'eigyo');
    },
    showMap() {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）
      // 顧客住所
      var cust_addr = ((this.customer.addr!=null)?this.customer.addr:'') + ((this.customer.addr2!=null)?this.customer.addr2:'');
      // 地図表示情報
      this.sendMapInfo(cust_addr);
    },
    // 地図表示情報
    sendMapInfo(cust_addr) {
      var title_juusyo = '現住所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var lat_juusyo = this.customer.addr_lat
      var lng_juusyo = this.customer.addr_lng
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      i_data.value = JSON.stringify({
        'view_name': this.open_view_name,
        'cust_no': (this.customer && this.customer.cust_no)?this.customer.cust_no:'',
        'cust_name': (this.customer && this.customer.cust_name)?this.customer.cust_name:'',
        'markercount': 1,
        'editindex': -1,
        'markertitle': [title_juusyo],
        'markeraddress': [cust_addr],
        'markerlat': [lat_juusyo],
        'markerlng': [lng_juusyo],
      });
      postform.submit();

    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      stretchInquiry: true,
      stretchNippou: true,
      stretchConstruction: true,
      stretchMaintenance: true,
      stretchInspection: true,

      //顧客
      customer: {},

      showComp: "false",

      //アラート一覧
      alerts: [],

      //引合情報
      inquirySortBy: 'hikiai_date',
      inquiryHeaders: [
        { text: '引合日', value: 'hikiai_date', width: '100px', class: 'px-1' },
        { text: '引合内容', value: 'hikiai_naiyou', width: '200px', class: 'px-1' },
        { text: '工事種別', value: 'kouji_type', width: '150px', class: 'px-1' },
        { text: '受注予定日', value: 'order_exp_date', width: '100px', class: 'px-1' },
        { text: '受注予定額', value: 'order_exp_amt', align: 'end', width: '100px', class: 'px-1'},
        { text: '営業担当', value: 'htg_tanto_name', width: '100px', class: 'px-1' },
        { text: '見積担当', value: 'mtg_tanto_name', width: '100px', class: 'px-1' },
        { text: '備考', value: 'bikou', width: '150px', class: 'px-1' },
        { text: '', value: 'estimate', sortable: false, align: 'end', width: '100px', class: 'px-1' },
        { text: '', value: 'order', sortable: false, width: '100px', class: 'px-1' },
      ],
      inquiry_list: [],

      //日報一覧
      nippouSortBy: 'rept_date',
      nippouHeaders: [
        { text: '日付', value: 'rept_date', width: '100px', class: 'px-1' },
        { text: 'アクション', value: 'action', width: '150px', class: 'px-1' },
        { text: 'アプローチ', value: 'approach', width: '100px', class: 'px-1' },
        { text: '相手の名前', value: 'person_name', width: '150px', class: 'px-1' },
        { text: '内容', value: 'naiyou', width: '300px', class: 'px-1' },
        { text: '担当者', value: 'tanto_name', width: '100px', class: 'px-1' },
      ],
      nippou_list: [],

      //工事予定・実績
      constructionSortBy: 'row_no',
      constructionHeaders: [
        { text: 'No.', value: 'row_no', width: '50px', class: 'px-1' },
        { text: '工事番号', value: 'kouji_no', width: '80px', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: '工事種別', value: 'kouji_type', width: '150px', class: 'px-1' },
        { text: '契約日', value: 'keiyaku_date', width: '100px', class: 'px-1' },
        { text: '引渡日', value: 'hndovr_date', width: '100px', class: 'px-1' },
        { text: '請負金額', value: 'ukeoi_kngk', align: 'end', width: '100px', class: 'px-1' },
        { text: '営業担当', value: 'htg_tanto_name', width: '100px', class: 'px-1' },
        { text: '工事担当', value: 'ktg_tanto_name', width: '100px', class: 'px-1' },
        { text: '', value: 'payment', sortable: false, width: '100px', class: 'px-1' },
      ],
      kouji_list: [],

      //メンテナンス予定・実績
      maintenanceSortBy: 'row_no',
      maintenanceHeaders: [
        { text: 'No.', value: 'row_no', width: '50px', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: 'メンテナンス内容', value: 'mainte_name', width: '200px', class: 'px-1' },
        { text: '対応内容', value: 'mainte_naiyou', width: '200px', class: 'px-1' },
        { text: '受付日', value: 'recept_date', width: '100px', class: 'px-1' },
        { text: '完了日', value: 'comp_date', width: '100px', class: 'px-1' },
        { text: '処理担当', value: 'syori_tanto_name', width: '100px', class: 'px-1' },
      ],
      mainte_list: [],

      //点検予定・実績
      inspectionSortBy: 'row_no',
      inspectionHeaders: [
        { text: 'No.', value: 'row_no', width: '50px', class: 'px-1'  },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: '点検名称', value: 'insp_name', width: '150px', class: 'px-1' },
        { text: '点検種別', value: 'insp_type', width: '150px', class: 'px-1' },
        { text: '予定日', value: 'yotei_date', width: '100px', class: 'px-1' },
        { text: '実施日', value: 'jissi_date', width: '100px', class: 'px-1' },
        { text: '実施担当', value: 'jissi_tanto_name', width: '100px', class: 'px-1' },
        { text: '対応内容', value: 'taiou', width: '200px', class: 'px-1' },
      ],
      inspect_list: [],
      breads: [],
    }
  }
};
</script>
<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap{
   white-space: nowrap;
}

td.text-start,td.text-end{
    padding-right: 3px !important;
    padding-left: 3px !important;
}

/* チェックボックスのラベル位置調整用 */
label {
  margin-bottom: 0 !important;
}
</style>
