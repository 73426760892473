export default {
    methods: {
        // 工事担当者データ取得
        getUserData: function (user_id) 
        {
            // データ取得
            axios.get('kouji_user/' + user_id)
                .then(response => {
                    this.user = response.data['user'];
                    this.alertList = response.data['alert'];
                    this.koujiList = response.data['kouji'];
                })
                .catch(
                    // response => console.log(response)
                );
        },
    },
}