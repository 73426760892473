<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card
        class="ma-4 outline-card"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="10"
        height="100%"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-account-details
            </v-icon>
            顧客詳細情報
          </span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="editCustomer"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            編集
          </v-btn>
          <v-btn
            class="mx-1"
            color="orange darken-3"
            dark
            width="100"
            @click="delCustomer"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            削除
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backPortal"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-1"></v-divider>
        <v-flex>
          <!-- 基本情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">基本情報</div>
            </v-sheet>
              
            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客番号</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{customer.cust_no}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
              </v-col>
              <v-col cols="5" class="yellow lighten-4">
                <span>
                  {{customer.cust_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客種別</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{customer.cust_type_name}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">フリガナ</span>
              </v-col>
              <v-col cols="5" class="yellow lighten-4">
                <span>
                  {{customer.cust_kana}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">現住所</span>
              </v-col>
              <v-col cols="2">
                <div class="yellow lighten-4" style="width: 120px">
                  <span>
                  〒{{editPostCd(customer.post_cd)}}
                  </span>
                </div>
              </v-col>
              <v-col cols="6" class="yellow lighten-4">
                <div>
                  {{customer.addr}}
                  <br>{{customer.addr2}}
                </div>
              </v-col>
              <v-col cols="1">
                <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                  <input type="hidden" name="_token" :value="csrf">
                  <input type="hidden" name="mapdata" value="">
                </form>
                <v-btn
                  class="ml-2"
                  color="indigo darken-1"
                  small
                  dark
                  @click="showMap()"
                >
                  <v-icon left>
                  mdi-map-marker
                  </v-icon>
                  地図
                </v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">緯度</span>
              </v-col>
              <v-col cols="3" class="yellow lighten-4">
                <span v-if="customer">
                  {{customer.addr_lat}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">経度</span>
              </v-col>
              <v-col cols="3" class="yellow lighten-4">
                <span v-if="customer">
                  {{customer.addr_lng}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{customer.area_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">TEL</span>
              </v-col>
              <v-col class="yellow lighten-4" cols="2">
                <span>
                  {{customer.tel_no}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">FAX</span>
              </v-col>
              <v-col class="yellow lighten-4" cols="2">
                <span>
                  {{customer.fax_no}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">携帯</span>
              </v-col>
              <v-col class="yellow lighten-4" cols="2">
                <span>
                  {{customer.cell_no}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">Eメール</span>
              </v-col>
              <v-col cols="5" class="yellow lighten-4">
                <span>
                  {{customer.email}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">生年月日</span>
              </v-col>
              <v-col cols="9">
                <v-row no-gutters>
                  <v-col cols="3" class="yellow lighten-4">
                    <div style="width: 100px">
                      {{customer.birth_date}}
                    </div>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">年齢</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span>
                      {{m_calcAge(customer.birth_date)}}
                    </span>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">性別</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span>
                      {{customer.sex}}
                    </span>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">ＤＭ</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{customer.dm}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-0 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">備考</span>
              </v-col>
              <v-col cols="9" class="yellow lighten-4">
                <div v-html="bikou"></div>
              </v-col>
            </v-row>
          </v-sheet>

          <!-- 勤務先情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">勤務先情報</div>
            </v-sheet>
              
            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">勤務先</span>
              </v-col>
              <v-col cols="9" class="yellow lighten-4">
                <span>
                  {{customer.kinmusaki}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">役職</span>
              </v-col>
              <v-col cols="5" class="yellow lighten-4">
                <span>
                  {{customer.kinmu_post_name}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">勤続年数</span>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <span class="float-right">
                  {{customer.kinmu_years}}
                </span>
              </v-col>
              <v-col cols="1" class="d-flex">
                <span class="ml-1 my-auto text-body-2">年</span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">勤務先住所</span>
              </v-col>
              <v-col cols="2">
                <div class="yellow lighten-4" style="width: 120px">
                  <span>
                  〒{{editPostCd(customer.kinmu_post_cd)}}
                  </span>
                </div>
              </v-col>
              <v-col cols="7" class="yellow lighten-4">
                <div>
                  {{customer.kinmu_addr}}
                  <br>{{customer.kinmu_addr2}}
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-0 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">TEL</span>
              </v-col>
              <v-col class="yellow lighten-4" cols="2">
                <span>
                  {{customer.kinmu_tel_no}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">FAX</span>
              </v-col>
              <v-col class="yellow lighten-4" cols="2">
                <span>
                  {{customer.kinmu_fax_no}}
                </span>
              </v-col>
            </v-row>
          </v-sheet>

          <!-- その他の情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">その他の情報</div>
            </v-sheet>

            <v-row no-gutters class="mt-2 mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">年収</span>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <span class="float-right">
                  {{numberToLocal(customer.own_income)}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-1 my-auto text-body-2">万円</span>
                <v-spacer></v-spacer>
                <span class="ml-auto mr-2 my-auto text-body-2">世帯年収</span>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <span class="float-right">
                  {{numberToLocal(customer.house_income)}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-1 mr-2 my-auto text-body-2">万円</span>
                <v-spacer></v-spacer>
                <span class="ml-auto mr-2 my-auto text-body-2">住居種別</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{customer.house_type_name}}
                </span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">居住／築年数</span>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <span class="float-right">
                  {{customer.house_age}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-1 mr-2 my-auto text-body-2">年</span>
                <v-spacer></v-spacer>
                <span class="ml-auto mr-2 my-auto text-body-2">家賃</span>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <span class="float-right">
                  {{numberToLocal(customer.rent_amt)}}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-1 mr-2 my-auto text-body-2">円</span>
                <v-spacer></v-spacer>
                <span class="ml-auto mr-2 my-auto text-body-2">土地所有</span>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <span>
                  {{customer.land_owner}}
                </span>
              </v-col>
            </v-row>
            <v-divider class="mt-0 mb-1"></v-divider>

            <v-row no-gutters class="mt-2 mr-2">
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mr-2 my-auto text-body-2">顧客担当</span>
              </v-col>
              <v-col cols="6" class="yellow lighten-4">
                <span>
                  {{tanto_name}}
                </span>
              </v-col>
            </v-row>
          </v-sheet>

          <!-- 家族情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">家族情報</div>
            </v-sheet>

            <v-row no-gutters class="mt-0 mb-0 mx-2">
              <v-col cols="3" class="d-flex">
                <span class="ml-2 mt-1 text-body-2">名前</span>
              </v-col>
              <v-col cols="1" class="d-flex">
                <span class="ml-2 mt-1 text-body-2">続柄</span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-2 mt-1 text-body-2">生年月日</span>
              </v-col>
              <v-col cols="1" class="d-flex">
                <span class="ml-2 mt-1 text-body-2">年齢</span>
              </v-col>
              <v-col cols="3" class="d-flex">
                <span class="ml-2 mt-1 text-body-2">勤務先／学校</span>
              </v-col>
              <v-col cols="2" class="d-flex">
                <span class="ml-auto mt-1 text-body-2">年収（万円）</span>
              </v-col>
            </v-row>
            <v-divider class="my-0"></v-divider>

            <v-row
              v-for="(fm, index) in family"
              :key="index"
              no-gutters class="mt-2 mb-0 mx-2"
            >
              <v-col cols="3" class="yellow lighten-4">
                <div class="ml-2">
                  <span>
                    {{fm.fm_name}}
                  </span>
                </div>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <div class="ml-2">
                  <span>
                    {{fm.relation_name}}
                  </span>
                </div>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <div class="ml-2">
                  <span>
                    {{fm.birth_date}}
                  </span>
                </div>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <div class="ml-2">
                  <span>
                    {{m_calcAge(fm.birth_date)}}
                  </span>
                </div>
              </v-col>
              <v-col cols="4" class="yellow lighten-4">
                <div class="ml-2 text-body-2">
                  <span>
                    {{fm.kinmusaki}}
                  </span>
                </div>
              </v-col>
              <v-col cols="1" class="yellow lighten-4">
                <div class="ml-2">
                  <span class="float-right">
                    {{numberToLocal(fm.own_income)}}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-flex>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import cssconsts from './js/common/cssconsts';
import dbcustomer from './js/common/dbcustomer.js'
import gmap from './js/common/gmap.js'

//export
export default {
  mixins: [dbcustomer, gmap],

  props:{
      "parameter": {},
  },

  created: function() {
    //顧客番号をthis.parameter.viewparamから取得
    var cust_no = this.parameter.viewparam;
    // 顧客番号が一致するデータを読み出す
    //以下に格納
    //⇒this.customer
    //⇒this.family
    //⇒this.tanto
    this.getCustomerDetail(cust_no);
  },
  computed: {
  },
  watch: {
    customer: function(data) {
      //alert("customer changed");
      if (data.bikou != null) {
        this.bikou = data.bikou.replace("\n", "<br>");
      }
    },
    tanto: function(data) {
      var tantoName = [];
      for (var elem of data) {
        tantoName.push(elem.tanto_name);
      }
      this.tanto_name = tantoName.join("、");
    },
  },
  methods: {
    editPostCd(postCd) {
      if (postCd == null) {
        return null;
      } else {
        return postCd.substr(0, 3) + " - " + postCd.substr(3, 4);
      }
    },
    numberToLocal(num) {
      if (num == null) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
    editCustomer() {
        //「顧客情報登録・編集」画面へ遷移
        this.$emit('viewChange', 'CustomerEdit', this.customer.cust_no);
    },
    showMap() {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）
      // 顧客住所
      var cust_addr = ((this.customer.addr!=null)?this.customer.addr:'') + ((this.customer.addr2!=null)?this.customer.addr2:'');
      // 地図表示情報
      this.sendMapInfo(cust_addr);
    },
    // 地図表示情報
    sendMapInfo(cust_addr) {
      var title_juusyo = '現住所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var lat_juusyo = this.customer.addr_lat
      var lng_juusyo = this.customer.addr_lng
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      i_data.value = JSON.stringify({
        'view_name': this.open_view_name,
        'cust_no': (this.customer && this.customer.cust_no)?this.customer.cust_no:'',
        'cust_name': (this.customer && this.customer.cust_name)?this.customer.cust_name:'',
        'markercount': 1,
        'editindex': -1,
        'markertitle': [title_juusyo],
        'markeraddress': [cust_addr],
        'markerlat': [lat_juusyo],
        'markerlng': [lng_juusyo],
      });
      postform.submit();

    },
    delCustomer() {
      // 顧客削除
      var res = confirm('当顧客を削除します。よろしいですか？');
      if (res == true) {
        this.deleteCustomer(this.customer.cust_no);
      }
    },
    backPortal() {
        //「顧客ポータル」画面へ遷移（戻るボタン）
        this.$emit('viewBack');
    },
    backCustomerList() {
        //「顧客一覧」画面へ戻る（削除時）
        this.$emit('viewChange', 'CustomerList');
    },
  },

  data: function() {
    return {
      cssconsts: cssconsts,

      //顧客データ
      customer: {},
      family: [],
      tanto: [],

      //編集項目
      bikou: "",
      tanto_name: "",
    }
  }
}
</script>
<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap{
   white-space: nowrap;
}
</style>
