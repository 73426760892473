<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-form id="srch_form">
        <!-- 検索条件 -->
        <v-card
          color="green lighten-4"
          class="mt-0 mb-4 ml-4 mr-4"
          outlined
          elevation="3"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-text-box-search-outline
              </v-icon>
              検索条件
            </span>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4 mt-2">詳細検索</span>
            <v-switch
              v-model="dtl_srch"
              class="mt-1"
              color="primary"
              inset
              dense
              hide-details
              @change="dtl_srch_sw(dtl_srch)"
            ></v-switch>
            <v-btn
              class="mx-1"
              color="blue-grey darken-1"
              dark
              width="100"
              @click="srchNippou"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              検索
            </v-btn>
            <v-btn
              class="mx-1"
              color="light-blue darken-1"
              dark
              width="100"
              @click="regNippou"
            >
              新規登録
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-2"></v-divider>

          <!-- 1行目 -->
          <v-container mx-4 pa-0>
            <v-row no-gutters>
              <v-col lg="1" md="2">
                <v-subheader class="pb-2 float-right">担当者</v-subheader>
              </v-col>
              <v-col lg="3" md="5">
                <v-autocomplete
                  v-model="tanto_select"
                  :items="tanto_name_list"
                  item-value="user_id"
                  item-text="user_name"
                  placeholder="担当者を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col lg="8" md="12">
                <v-row no-gutters>
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">日付</v-subheader>
                  </v-col>
                  <v-col lg="4" md="3">
                    <v-text-field 
                      v-model="date1" 
                      placeholder="日付(From)"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('dpick1')"
                    >
                      <template v-slot:append>
                        <date-picker ref="dpick1" v-model="date1"/>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="px-0 pb-2">
                      <div class="mx-auto">～</div>
                    </v-subheader>
                  </v-col>
                  <v-col lg="4" md="3">
                    <v-text-field 
                      v-model="date2" 
                      placeholder="日付(To)"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('dpick2')"
                    >
                      <template v-slot:append>
                        <date-picker ref="dpick2" v-model="date2"/>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

          <template v-if="dtl_srch === true">
            <!-- 2行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">顧客</v-subheader>
                </v-col>
                <v-col lg="3" md="5">
                  <v-autocomplete
                    v-model="customer_select"
                    :items="cust_name_list"
                    item-value="id"
                    item-text="name"
                    placeholder="顧客を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col lg="8" md="12">
                  <v-row no-gutters>
                    <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">アクション</v-subheader>
                    </v-col>
                    <v-col lg="4" md="4">
                      <v-autocomplete
                        v-model="act_select"
                        :items="act_list"
                        item-value="id"
                        item-text="name"
                        placeholder="アクションを選択"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="2" md="2">
                      <v-subheader class="pb-2 float-right">アプローチ</v-subheader>
                    </v-col>
                    <v-col lg="4" md="4">
                      <v-autocomplete style="width: 210px"
                        v-model="apr_select"
                        :items="apr_list"
                        item-value="id"
                        item-text="name"
                        placeholder="アプローチを選択"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>

            <!-- 3行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 pl-0 pr-2 float-right">相手の名前</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <v-text-field
                    v-model="person_input"
                    placeholder="相手の名前を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <!-- <v-col xl="0" lg="0" md="5" sm="5"> -->
                <!-- </v-col> -->
                <v-col lg="1" md="1">
                  <v-subheader class="pb-2 float-right">内容</v-subheader>
                </v-col>
                <v-col lg="5" md="5" class="pr-3">
                  <v-text-field
                    v-model="naiyou_input"
                    placeholder="内容を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

        </v-card>
      </v-form>

      <!-- 検索結果 -->
      <v-card
        color="lime lighten-4"
        class="ma-4"
        outlined
        elevation="3"
      >
        <div class="mt-2 ml-2">
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            検索結果
          </span>
        </div>
        <v-divider class="mt-1 mb-0"></v-divider>
        <v-flex class="mt-2 mb-4 mx-4">
          <NippouTable v-bind:nippou_list=eigyo_nippou_list @RowClick="showNippou" ref="nptable"/>
        </v-flex>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import NippouTable from "./NippouTable.vue";
import DatePicker from "./DatePicker.vue";
import dbeigyonippou from './js/common/dbeigyonippou.js'
import cssconsts from './js/common/cssconsts';

//export
export default {
  components: {
    DatePicker,
    NippouTable,
  },
  mixins: [dbeigyonippou],
  created : function(){
    this.getSearchConditionList(); // 検索条件リスト取得
    if (this.parameter.homeparam.loginuser != null)
    {
      var usrid = this.parameter.homeparam.loginuser.user_id
      this.tanto_select = usrid
      this.getEigyoNippouList(usrid)  // ログインユーザーの日報を読み出し
    }
    this.m_createBreads();
  },
  mounted() {
  },

  props:{
      "parameter": {},
  },
  methods: {
    dtl_srch_sw(sw) {
      //alert('dtl_srch:' + dtl_srch);
      this.$refs["nptable"].height = (sw ? 400 : 500);
    },
    dateClick(dpickName) {
      //alert('Date Clicked!');
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    srchNippou()
    {
      //営業日報を検索
      var tanto_id = this.tanto_select
      var datefrom = this.date1
      var dateto = this.date2
      var cust_no = this.customer_select
      var act_id = this.act_select;
      var apr_id = this.apr_select;
      var person_name = this.person_input
      var naiyou = this.naiyou_input;

      this.getEigyoNippouList(tanto_id, datefrom, dateto, cust_no, act_id, apr_id, person_name, naiyou)
    },
    regNippou() {
        //「営業日報登録・編集」画面へ遷移
        this.$emit('viewChange', 'EigyoNippouEdit',null);
    },
    showNippou(nippou) {
        //「営業日報」画面へ遷移
        this.$emit('viewChange', 'EigyoNippou', nippou.egnp_no);
    },

    // 営業日報リスト取得
    getEigyoNippouList:function (tanto_id, datefrom, dateto, cust_no, act_id, apr_id, person_name, naiyou) 
    {
      // URL作成
      var uri = 'eigyo_nippou'
      var qparam = ''
      if (tanto_id != null) {
        qparam += 'tanto_id=' + encodeURIComponent(tanto_id) + '&'
      }
      if (datefrom != null) {
        qparam += 'datefrom=' + encodeURIComponent(datefrom) + '&'
      }
      if (dateto != null) {
        qparam += 'dateto=' + encodeURIComponent(dateto) + '&'
      }
      if (cust_no != null) {
        qparam += 'cust_no=' + encodeURIComponent(cust_no) + '&'
      }
      if (act_id != null) {
        qparam += 'act_id=' + encodeURIComponent(act_id) + '&'
      }
      if (apr_id != null) {
        qparam += 'apr_id=' + encodeURIComponent(apr_id) + '&'
      }
      if (person_name != null) {
        qparam += 'person_name=' + encodeURIComponent(person_name) + '&'
      }
      if (naiyou != null) {
        qparam += 'naiyou=' + encodeURIComponent(naiyou) + '&'
      }

      uri += '?' + qparam
      // データ取得
      axios.get(uri)
        .then(response => {
          this.eigyo_nippou_list = response.data;
        })
        .catch(
          // response => console.log(response)
        );
    },
  },

  data: function() {
    return {
      cssconsts: cssconsts,
      tanto_name_list: [],
      cust_name_list: [],
      apr_list: [],
      act_list: [],
      eigyo_nippou_list: [],
      dtl_srch: false,
      drawer: true,
      mini: true,
      tanto_select: null,
      customer_select: null,
      act_select: null,
      apr_select: null,
      person_input: null,
      naiyou_input: null,
      date1: null,
      date2: null,
      breads: [],
    }
  }
};
</script>
<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>
