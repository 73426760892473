<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
        <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
        <v-card
          color="rgb(255, 0, 0, 0)" 
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-breadcrumbs :items="breads"></v-breadcrumbs>
        </v-card>
        <v-card
          class="mt-0 mb-4 ml-4 mr-4"
          :color="cssconsts.eigyo_bg_color_base_01"
          outlined
          elevation="10"
        >
          <v-card-actions>
            <div style="width: 180px">
              <v-icon>
                mdi-text-box-check-outline
              </v-icon>
              受注登録・編集
            </div>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4">
              ※内容を変更した場合、登録ボタンを押してください
            </span>
            <v-btn
              class="mx-1"
              color="indigo"
              dark
              width="100"
              @click="regOrder"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              登録
            </v-btn>
            <v-btn
              class="mx-1"
              color="grey darken-1"
              dark
              width="100"
              @click="backPrevPage"
            >
              <v-icon left>
                mdi-keyboard-return
              </v-icon>
              戻る
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-1"></v-divider>

          <v-flex>
            <!-- 基本情報 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              color="white"
              outlined
              rounded
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">基本情報</div>
              </v-sheet>

              <v-form ref="basic_info" v-model="valid" lazy-validation>
                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">見積番号</v-subheader>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="order.mitumori_no"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">見積日</v-subheader>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="mitumori.mitumori_date"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">引合内容</v-subheader>
                  </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="mitumori.hikiai_naiyou"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">工事種別</v-subheader>
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      v-model="order.kouji_type"
                      :items="kouji_type_list"
                      item-value="id"
                      item-text="name"
                      placeholder="工事種別を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">工事名</v-subheader>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      v-model="order.kouji_name"
                      placeholder="工事名を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">プラン名</v-subheader>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      v-model="order.plan_name"
                      placeholder="プラン名を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">工事場所</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <div class="d-flex">
                      <span class="mt-2">
                        〒
                      </span>
                      <v-text-field
                        v-model="kouji_postcd_input1"
                        class="mx-2"
                        solo
                        dense
                        maxlength="3"
                        hide-details="auto"
                        :rules="[validRules.isNumber, validRules.postCd3]"
                      ></v-text-field>
                      <span class="mt-2">
                        －
                      </span>
                      <v-text-field
                        v-model="kouji_postcd_input2"
                        class="mx-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber, validRules.postCd4]"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="order.kouji_addr"
                      placeholder="住所を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-btn
                      class="float-right"
                      color="light-blue darken-1"
                      small
                      dark
                      @click="copyAddr"
                    >
                      現住所転記
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <div class="d-frex ml-1">
                      <v-btn
                        class="ml-1"
                        color="green darken-1"
                        small
                        dark
                        @click="convertPostalCode(1)"
                      >
                        〒 ⇔ 住所
                      </v-btn>
                      <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                        <input type="hidden" name="_token" :value="csrf">
                        <input type="hidden" name="mapdata" value="">
                      </form>
                      <v-btn
                        class="ml-1"
                        color="indigo darken-1"
                        small
                        dark
                        @click="showMap(1)"
                      >
                        <v-icon left>
                          mdi-map-marker
                        </v-icon>
                        地図
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="order.kouji_addr2"
                      placeholder="建物名などを入力"
                      solo
                      clearable
                      dense
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2" align="center">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">緯度</v-subheader>
                  </v-col>
                  <v-col lg="4" md="10">
                    <v-text-field
                      v-model="order.kouji_addr_lat"
                      placeholder="緯度を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.isDecimalSigned]"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">経度</v-subheader>
                  </v-col>
                  <v-col lg="4" md="10">
                    <v-text-field
                      v-model="order.kouji_addr_lng"
                      placeholder="経度を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.isDecimalSigned]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">工事内容</v-subheader>
                  </v-col>
                  <v-col cols="10">
                    <v-textarea
                      v-model="order.kouji_naiyou"
                      placeholder="工事内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>

            <!-- 顧客情報 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              color="white"
              outlined
              rounded
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">顧客情報</div>
              </v-sheet>
                
              <v-form ref="customer_info" v-model="valid" lazy-validation>
                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">顧客番号</v-subheader>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="customer.cust_no"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">顧客名</v-subheader>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="customer.cust_name"
                      placeholder="顧客名を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">顧客種別</v-subheader>
                  </v-col>
                  <v-col cols="2">
                    <v-autocomplete
                      v-model="customer.cust_type"
                      :items="cust_type_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客種別"
                      solo
                      dense
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">フリガナ</v-subheader>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="customer.cust_kana"
                      placeholder="フリガナを入力"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">現住所</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <div class="d-flex">
                      <span class="mt-2">
                        〒
                      </span>
                      <v-text-field
                        v-model="cust_postcd_input1"
                        class="mx-2"
                        solo
                        dense
                        maxlength="3"
                        hide-details="auto"
                        :rules="[validRules.required, validRules.isNumber, validRules.postCd3]"
                      ></v-text-field>
                      <span class="mt-2">
                        －
                      </span>
                      <v-text-field
                        v-model="cust_postcd_input2"
                        class="mx-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.required, validRules.isNumber, validRules.postCd4]"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="customer.addr"
                      placeholder="住所を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                  </v-col>
                  <v-col cols="4">
                    <div class="d-frex ml-1">
                      <v-btn
                        class="ml-1"
                        color="green darken-1"
                        small
                        dark
                        @click="convertPostalCode(2)"
                      >
                        〒 ⇔ 住所
                      </v-btn>
                      <v-btn
                        class="ml-1"
                        color="indigo darken-1"
                        small
                        dark
                        @click="showMap(2)"
                      >
                        <v-icon left>
                          mdi-map-marker
                        </v-icon>
                        地図
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="customer.addr2"
                      placeholder="建物名などを入力"
                      solo
                      clearable
                      dense
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2" align="center">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">緯度</v-subheader>
                  </v-col>
                  <v-col lg="4" md="10">
                    <v-text-field
                      v-model="customer.addr_lat"
                      placeholder="緯度を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.isDecimalSigned]"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">経度</v-subheader>
                  </v-col>
                  <v-col lg="4" md="10">
                    <v-text-field
                      v-model="customer.addr_lng"
                      placeholder="経度を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.isDecimalSigned]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">地区</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="customer.area_id"
                      :items="area_list"
                      item-value="id"
                      item-text="name"
                      placeholder="地区を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">TEL</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <div class="d-flex">
                      <v-text-field
                        v-model="tel_input1"
                        class="mr-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                      <span class="mt-2">
                        -
                      </span>
                      <v-text-field
                        v-model="tel_input2"
                        class="mx-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                      <span class="mt-2">
                        -
                      </span>
                      <v-text-field
                        v-model="tel_input3"
                        class="ml-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <v-subheader class="pb-2 float-right">FAX</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <div class="d-flex">
                      <v-text-field
                        v-model="fax_input1"
                        class="mr-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                      <span class="mt-2">
                        -
                      </span>
                      <v-text-field
                        v-model="fax_input2"
                        class="mx-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                      <span class="mt-2">
                        -
                      </span>
                      <v-text-field
                        v-model="fax_input3"
                        class="ml-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">携帯</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <div class="d-flex">
                      <v-text-field
                        v-model="cell_input1"
                        class="mr-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                      <span class="mt-2">
                        -
                      </span>
                      <v-text-field
                        v-model="cell_input2"
                        class="mx-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                      <span class="mt-2">
                        -
                      </span>
                      <v-text-field
                        v-model="cell_input3"
                        class="ml-2"
                        solo
                        dense
                        maxlength="4"
                        hide-details="auto"
                        :rules="[validRules.isNumber]"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <v-subheader class="pl-0 pb-2 float-right">Eﾒｰﾙ</v-subheader>
                  </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="customer.email"
                        placeholder="Eメールアドレスを入力"
                        solo
                        clearable
                        dense
                        hide-details="auto"
                        :rules="[validRules.email]"
                      ></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">顧客担当</v-subheader>
                  </v-col>
                  <v-col cols="9">
                    <v-autocomplete
                      v-model="custTanto"
                      :items="tanto_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客担当を選択"
                      multiple chips deletable-chips
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>

            <!-- 契約情報 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              color="white"
              outlined
              rounded
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">契約情報</div>
              </v-sheet>

              <v-form ref="keiyaku_info" v-model="valid" lazy-validation>
                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">契約日</v-subheader>
                  </v-col>
                  <v-col cols="1">
                  </v-col>
                  <v-col cols="3">
                    <div style="width: 180px">
                      <v-text-field
                        v-model="order.keiyaku_date"
                        placeholder="日付を選択"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details="auto"
                        :rules="[validRules.required]"
                        @click="dateClick('keiyakuDate')"
                      >
                        <template v-slot:append>
                          <date-picker ref="keiyakuDate" v-model="order.keiyaku_date"/>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                    <v-subheader class="pb-2 red--text float-right">請負金額</v-subheader>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="pb-2 pl-0 pr-2 float-right">税抜</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <comma-number-field
                      v-model.number="order.ukeoi_amt"
                      class="right-input"
                      placeholder="税抜金額"
                      solo
                      clearable
                      dense
                      maxlength="10"
                      hide-details="auto"
                      :rules="[validRules.required, validRules.isNumberCommaSigned]"
                    ></comma-number-field>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="pb-2 pl-0 pr-2 float-right">税率</v-subheader>
                  </v-col>
                  <v-col lg="1" md="2">
                    <v-text-field
                      v-model.number="order.tax_rate"
                      class="right-input"
                      solo
                      dense
                      maxlength="5"
                      hide-details="auto"
                      :rules="[validRules.required, validRules.isDecimal]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="pb-2 pl-1">%</v-subheader>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col lg="2" md="2">
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="pb-2 pl-0 pr-2 float-right">税込</v-subheader>
                  </v-col>
                  <v-col lg="2" md="3">
                    <v-text-field
                      v-model="ukeoi_total_amt"
                      class="right-input"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col lg="1" md="1">
                    <v-subheader class="pb-2 pl-0 pr-2 float-right">税額</v-subheader>
                  </v-col>
                  <v-col lg="2" md="2">
                    <v-text-field
                      v-model="ukeoi_tax"
                      class="right-input"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>

            <!-- 入金予定・実績 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              color="white"
              outlined
              rounded
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">入金予定・実績</div>
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-1 my-1"
                  color="cyan darken-1"
                  dark
                  small
                  @click="addNyukin()"
                >
                  <v-icon left>
                    mdi-plus-circle
                  </v-icon>
                  追加
                </v-btn>
              </v-sheet>

              <v-row no-gutters class="mx-2">
                <v-col lg="7" md="12">
                  <v-row no-gutters class="mt-1 mb-0">
                    <v-col lg="4" md="4" class="d-flex">
                      <span class="ml-2 my-auto text-body-2 red--text">入金予定日</span>
                    </v-col>
                    <v-col lg="4" md="4" class="d-flex">
                      <span class="ml-2 my-auto text-body-2 red--text">入金種別</span>
                    </v-col>
                    <v-col lg="4" md="4" class="d-flex">
                      <span class="ml-2 my-auto text-body-2 red--text">入金予定額</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col lg="5" md="12">
                  <v-row no-gutters class="mt-1 mb-0">
                    <v-col lg="6" md="4" class="d-flex">
                      <span class="ml-2 my-auto text-body-2">入金日</span>
                    </v-col>
                    <v-col lg="5" md="4" class="d-flex">
                      <span class="ml-2 my-auto text-body-2">入金額</span>
                    </v-col>
                    <v-col lg="1" md="4" class="d-flex pr-2">
                      <span v-if="$vuetify.breakpoint.smAndDown"
                        class="ml-2 my-auto text-body-2">
                        入金状況
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="my-0"></v-divider>

              <v-form ref="nyukin_info" v-model="valid" lazy-validation>
                <v-row
                  v-for="(nk, index) in nyukin"
                  :key="index"
                  no-gutters class="mx-2"
                >
                  <v-col lg="7" md="12">
                    <v-row no-gutters class="mt-2 mb-0">
                      <v-col lg="4" md="4" class="pr-2">
                        <v-text-field
                          v-model="nk.yotei_date"
                          placeholder="日付を選択"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                          @click="dateClick('yotei_date', index)"
                        >
                          <template v-slot:append>
                            <date-picker ref="yotei_date" v-model="nk.yotei_date"/>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col lg="4" md="4" class="pr-2">
                        <v-autocomplete
                          v-model="nk.nyukin_type"
                          :items="nyukin_type_list"
                          item-value="id"
                          item-text="name"
                          :placeholder="$vuetify.breakpoint.smAndDown ? '入金種別を選択' : '入金種別'"
                          solo
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col lg="4" md="4" class="pr-2">
                        <comma-number-field
                          v-model.number="nk.yotei_kingaku"
                          class="right-input"
                          placeholder="入金予定額"
                          solo
                          clearable
                          dense
                          maxlength="10"
                          hide-details="auto"
                          :rules="[validRules.required, validRules.isNumberCommaSigned]"
                        ></comma-number-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col lg="5" md="12">
                    <v-row no-gutters class="mt-2 mb-0">
                      <v-col lg="6" md="4" class="pr-2">
                        <v-text-field
                          v-model="nk.nyukin_date"
                          placeholder="日付を選択"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="dateClick('nyukin_date', index)"
                        >
                          <template v-slot:append>
                            <date-picker ref="nyukin_date" v-model="nk.nyukin_date"/>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col lg="5" md="4" class="pr-2">
                        <comma-number-field
                          v-model.number="nk.nyukin_kingaku"
                          class="right-input"
                          placeholder="入金額"
                          solo
                          clearable
                          dense
                          maxlength="10"
                          hide-details="auto"
                          :rules="[validRules.isNumberCommaSigned]"
                          :hint="'（'+nyukinStatus(index)+'）'"
                          :persistent-hint="!$vuetify.breakpoint.smAndDown"
                        ></comma-number-field>
                      </v-col>
                      <v-col lg="1" md="4" class="d-flex">
                        <span class="pl-2 my-auto" v-if="$vuetify.breakpoint.smAndDown"
                        >
                          {{nyukinStatus(index)}}
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="ml-auto mt-1"
                          color="red darken-1"
                          x-small
                          fab
                          dark
                          elevation="2"
                          @click="delNyukin(index)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider class="my-2"></v-divider>
                <v-row no-gutters class="mx-2">
                  <v-col lg="7" md="12">
                    <v-row no-gutters class="mt-0 mb-0">
                      <v-col cols="8" class="d-flex">
                        <div class="ml-auto mr-2 my-auto text-body-2">
                          <span>入金予定残高</span>
                        </div>
                      </v-col>
                      <v-col cols="4" class="pr-2">
                        <v-text-field
                          v-model="total_nyukin_zan"
                          class="right-input"
                          background-color="yellow lighten-4"
                          solo
                          dense
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>
          </v-flex>

          <v-divider class="mt-0 mb-1"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4">
              ※内容を変更した場合、登録ボタンを押してください
            </span>
            <v-btn
              class="mx-1"
              color="indigo"
              dark
              width="100"
              @click="regOrder"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              登録
            </v-btn>
            <v-btn
              class="mx-1"
              color="grey darken-1"
              dark
              width="100"
              @click="backPrevPage"
            >
              <v-icon left>
                mdi-keyboard-return
              </v-icon>
              戻る
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import DatePicker from "./DatePicker.vue";
import dborder from './js/common/dborder.js'
import gmap from './js/common/gmap.js'
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dborder, gmap],

  props:{
      "parameter": {},
  },

  created: function() {
    window.document.addEventListener("setlatlng", this.setlatlngHandler); // 緯度経度更新用
    this.getIdNameList("koujiType");  // 工事種別リスト取得
    this.getIdNameList("custType");   // 顧客種別リスト取得
    this.getIdNameList("tanto");      // 顧客担当者リスト取得
    this.getIdNameList("area");       // 地区リスト取得
    this.getIdNameList("nyukinType"); // 入金種別リスト取得

    // 契約番号がthis.parameter.viewparamとして引き渡されてくる
    var param = this.parameter.viewparam;
    var keiyaku_no = param.keiyaku_no;
    if (keiyaku_no) {
      //契約番号あり
      //編集
      this.mode = "edit";
      //契約番号が一致するデータを取得
      //以下に格納
      //⇒this.order
      //⇒this.mitumori
      //⇒this.nyukin
      //⇒this.customer
      //⇒this.tantoGrp
      this.getOrder(keiyaku_no);
    } else {
      //新規登録
      this.mode = "reg";
      //見積データを取得
      //以下に格納
      //⇒this.mitumori
      //⇒this.hikiai
      this.getMitsumori(param.mitumori_no);
      //顧客データを取得
      //⇒this.customer
      //⇒this.tantoGrp
      this.getCustomer(param.cust_no);
    }
    this.m_createBreads();
  },
  mounted: function() {
    //エラーをリセット
    this.$refs.basic_info.resetValidation();
    this.$refs.customer_info.resetValidation();
    this.$refs.keiyaku_info.resetValidation();
    this.$refs.nyukin_info.resetValidation();
  },
  computed: {
    kouji_post_cd: {
      get: function() {
        var post_cd = "";
        post_cd += (this.kouji_postcd_input1 ? this.kouji_postcd_input1 : "");
        post_cd += (this.kouji_postcd_input2 ? this.kouji_postcd_input2 : "");
        return post_cd;
      },
      set: function(post_cd) {
        if (post_cd != null) {
          this.kouji_postcd_input1 = post_cd.substr(0, 3);
          this.kouji_postcd_input2 = post_cd.substr(3, 4);
        }
      }
    },
    cust_post_cd: {
      get: function() {
        var post_cd = "";
        post_cd += (this.cust_postcd_input1 ? this.cust_postcd_input1 : "");
        post_cd += (this.cust_postcd_input2 ? this.cust_postcd_input2 : "");
        return post_cd;
      },
      set: function(post_cd) {
        if (post_cd != null) {
          this.cust_postcd_input1 = post_cd.substr(0, 3);
          this.cust_postcd_input2 = post_cd.substr(3, 4);
        }
      }
    },
    cust_tel_no: {
      get: function() {
        var telNo = "";
        telNo += (this.tel_input1 ? this.tel_input1 : "");
        telNo += "-";
        telNo += (this.tel_input2 ? this.tel_input2 : "");
        telNo += "-";
        telNo += (this.tel_input3 ? this.tel_input3 : "");
        return telNo;
      },
      set: function(tel_no) {
        if (tel_no != null) {
          var telNos = tel_no.split("-");
          this.tel_input1 = telNos[0];
          this.tel_input2 = telNos[1];
          this.tel_input3 = telNos[2];
        }
      }
    },
    cust_fax_no: {
      get: function() {
        var faxNo = "";
        faxNo += (this.fax_input1 ? this.fax_input1 : "");
        faxNo += "-";
        faxNo += (this.fax_input2 ? this.fax_input2 : "");
        faxNo += "-";
        faxNo += (this.fax_input3 ? this.fax_input3 : "");
        return faxNo;
      },
      set: function(fax_no) {
        if (fax_no != null) {
          var faxNos = fax_no.split("-");
          this.fax_input1 = faxNos[0];
          this.fax_input2 = faxNos[1];
          this.fax_input3 = faxNos[2];
        }
      }
    },
    cust_cell_no: {
      get: function() {
        var cellNo = "";
        cellNo += (this.cell_input1 ? this.cell_input1 : "");
        cellNo += "-";
        cellNo += (this.cell_input2 ? this.cell_input2 : "");
        cellNo += "-";
        cellNo += (this.cell_input3 ? this.cell_input3 : "");
        return cellNo;
      },
      set: function(cell_no) {
        if (cell_no != null) {
          var cellNos = cell_no.split("-");
          this.cell_input1 = cellNos[0];
          this.cell_input2 = cellNos[1];
          this.cell_input3 = cellNos[2];
        }
      }
    },
    ukeoi_tax: {
      cache: false,
      get() {
        if ((this.order.ukeoi_amt) && (this.order.tax_rate)) {
          this.order.ukeoi_tax = Math.floor(this.order.ukeoi_amt * this.order.tax_rate / 100);
          return this.order.ukeoi_tax.toLocaleString();
        } else {
          return "";
        }
      }
    },
    ukeoi_total_amt: {
      cache: false,
      get() {
        if ((this.order.ukeoi_amt) && (this.order.tax_rate)) {
          this.order.ukeoi_tax = Math.floor(this.order.ukeoi_amt * this.order.tax_rate / 100);
          return (this.order.ukeoi_amt + this.order.ukeoi_tax).toLocaleString();
        } else {
          return "";
        }
      }
    },
    nyukinStatus: function () {
      return function(index) {
        var nyukinZan = 0;
        if (this.nyukin[index].yotei_kingaku != null) {
          nyukinZan = this.nyukin[index].yotei_kingaku;
        } else {
          this.nyukin[index].nyukin_state = null;
          return "";
        }
        if (this.nyukin[index].nyukin_kingaku != null) {
          nyukinZan -= this.nyukin[index].nyukin_kingaku;
        }
        var status = "";
        if (nyukinZan == this.nyukin[index].yotei_kingaku) {
          status = "未入金";
          this.nyukin[index].nyukin_state = "0";
        } else if (nyukinZan == 0) {
          status = "入金完了";
          this.nyukin[index].nyukin_state = "1";
        } else if (nyukinZan < 0) {
          status = "金額超過";
          this.nyukin[index].nyukin_state = "2";
        } else if (nyukinZan > 0) {
          status = "金額不足";
          this.nyukin[index].nyukin_state = "3";
        }
        return status;
      }
    },
    total_nyukin_zan: function() {
      var nyukinZan = 0;
      for (var nk of this.nyukin) {
        if (nk.yotei_kingaku) {
          nyukinZan += nk.yotei_kingaku;
        }
        if (nk.nyukin_kingaku) {
          nyukinZan -= nk.nyukin_kingaku;
        }
      }
      return nyukinZan.toLocaleString();
    },
  },
  watch: {
    order: function(data) {
      //データを分割して表示
      this.kouji_post_cd = data.kouji_post_cd;
    },
    mitumori: function(data) {
      if (this.mode == "reg") {
        this.order = {
          cust_no: this.parameter.viewparam.cust_no,
          mitumori_no: data.mitumori_no,
          kouji_name: data.kouji_name,
          plan_name: data.plan_name,
          kouji_post_cd: data.kouji_post_cd,
          kouji_addr: data.kouji_addr,
          kouji_addr2: data.kouji_addr2,
          kouji_addr_lat: data.kouji_addr_lat,
          kouji_addr_lng: data.kouji_addr_lng,
          ukeoi_amt: data.pres_amt,
          tax_rate: data.tax_rate,
          ukeoi_tax: data.pres_tax,
        }
      }
    },
    hikiai: function(data) {
      if (this.mode == "reg") {
        this.order.kouji_type = data.kouji_type;
        this.mitumori.hikiai_naiyou = data.hikiai_naiyou;
      }
    },
    customer: function(data) {
      //データを分割して表示
      this.cust_post_cd = data.post_cd;
      this.cust_tel_no = data.tel_no;
      this.cust_fax_no = data.fax_no;
      this.cust_cell_no = data.cell_no;
    },
    tantoGrp: function(data) {
      this.custTanto = [];
      for (var elem of data) {
        this.custTanto.push(elem.tanto_id)
      }
    }
  },
  methods: {
    dateClick(dpickName, index = null) {
      //DatePicker を開く
      if (index == null) {
        this.$refs[dpickName].menu = true;
      } else {
        //v-forで生成したDatePicker用
        this.$refs[dpickName][index].menu = true;
      }
    },
    copyAddr() {
      //住所転記
      this.kouji_post_cd = this.cust_post_cd;
      this.order.kouji_addr = this.customer.addr;
      this.order.kouji_addr2 = this.customer.addr2;
      this.order.kouji_addr_lat = this.customer.addr_lat;
      this.order.kouji_addr_lng = this.customer.addr_lng;
    },
    backPrevPage() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    convertPostalCode(addr_sel)
    {
      if (addr_sel == 1)
      {
        // 工事場所
        var postalcode = ((this.kouji_postcd_input1!=null)?this.kouji_postcd_input1:'') + ((this.kouji_postcd_input2!=null)?this.kouji_postcd_input2:'')
        var searchaddress = ((this.order.kouji_addr!=null)?this.order.kouji_addr:'') + ((this.order.kouji_addr2!=null)?this.order.kouji_addr2:'')
        this.searchPostalcodeAddress(postalcode+searchaddress,(newaddr,newpostcd)=>{
          this.$set(this.order, 'kouji_addr', newaddr)
          this.$set(this.order, 'kouji_addr_lat', null)
          this.$set(this.order, 'kouji_addr_lng', null)
          this.kouji_postcd_input1 = newpostcd.substring(0,3)
          this.kouji_postcd_input2 = newpostcd.substring(3,7)
        });
        this.order.kouji_addr2 = '';
      }
      else if (addr_sel == 2)
      {
        // 現住所
        var postalcode = ((this.cust_postcd_input1!=null)?this.cust_postcd_input1:'') + ((this.cust_postcd_input2!=null)?this.cust_postcd_input2:'')
        var searchaddress = ((this.customer.addr!=null)?this.customer.addr:'') + ((this.customer.addr2!=null)?this.customer.addr2:'')
        this.searchPostalcodeAddress(postalcode+searchaddress,(newaddr,newpostcd)=>{
          this.$set(this.customer, 'addr', newaddr)
          this.$set(this.customer, 'addr_lat', null)
          this.$set(this.customer, 'addr_lng', null)
          this.cust_postcd_input1 = newpostcd.substring(0,3)
          this.cust_postcd_input2 = newpostcd.substring(3,7)
        });
        this.customer.addr2 = '';
      }

    },
    showMap(map_sel) {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）
      // 顧客住所
      var cust_addr = ((this.customer.addr!=null)?this.customer.addr:'') + ((this.customer.addr2!=null)?this.customer.addr2:'');
      // 地図表示情報
      this.sendMapInfo(cust_addr, map_sel);
    },
    // 地図表示情報
    sendMapInfo(cust_addr, map_sel) {
      var searchaddress = ((this.order.kouji_addr!=null)?this.order.kouji_addr:'') + ((this.order.kouji_addr2!=null)?this.order.kouji_addr2:'')
      var title_kouji = '工事場所'
      var title_juusyo = '現住所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var lat_kouji = (this.order.kouji_addr_lat)?this.order.kouji_addr_lat:null
      var lng_kouji = (this.order.kouji_addr_lng)?this.order.kouji_addr_lng:null
      var lat_juusyo = (this.customer.addr_lat)?this.customer.addr_lat:null
      var lng_juusyo = (this.customer.addr_lng)?this.customer.addr_lng:null
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      if (map_sel == 1)
      {
        // 工事場所
        i_data.value = JSON.stringify({
          'view_name': this.open_view_name,
          'cust_no': (this.customer.cust_no)?this.customer.cust_no:'',
          'cust_name': (this.customer.cust_name)?this.customer.cust_name:'',
          'markercount': 1, //工事場所のみ
          'editindex': 0, // 編集可
          'markertitle': [title_kouji,title_juusyo],
          'markeraddress': [searchaddress,cust_addr],
          'markerlat': [lat_kouji,lat_juusyo],
          'markerlng': [lng_kouji,lng_juusyo],
        });
        postform.submit();
      }
      else if (map_sel == 2)
      {
        // 現住所
        i_data.value = JSON.stringify({
          'view_name': this.open_view_name,
          'cust_no': (this.customer.cust_no)?this.customer.cust_no:'',
          'cust_name': (this.customer.cust_name)?this.customer.cust_name:'',
          'markercount': 1, //現住所のみ
          'editindex': 0, // 編集可
          'markertitle': [title_juusyo, title_kouji],
          'markeraddress': [cust_addr, searchaddress],
          'markerlat': [lat_juusyo, lat_kouji],
          'markerlng': [lng_juusyo, lng_kouji],
        });
        postform.submit();
      }
    },
    setlatlngHandler(e)
    {
      if (this.open_view_name == e.detail.view_name)
      {
        if (e.detail.title == '工事場所')
        {
          // 工事場所緯度経度更新
          this.$set(this.order, 'kouji_addr_lat', e.detail.lat)
          this.$set(this.order, 'kouji_addr_lng', e.detail.lng)
        }
        else if (e.detail.title == '現住所')
        {
          this.$set(this.customer, 'addr_lat', e.detail.lat)
          this.$set(this.customer, 'addr_lng', e.detail.lng)
        }
      }
    },
    regOrder() {
      //入力チェック
      //--基本情報
      this.valid = this.$refs.basic_info.validate();
      if (!this.valid) {
        alert("基本情報に入力不足、または間違いがあります");
        return;
      }
      //--顧客情報
      this.valid = this.$refs.customer_info.validate();
      if (!this.valid) {
        alert("顧客情報に入力不足、または間違いがあります");
        return;
      }
      //--契約情報
      this.valid = this.$refs.keiyaku_info.validate();
      if (!this.valid) {
        alert("契約情報に入力不足、または間違いがあります");
        return;
      }
      //--入金予定・実績
      if (this.nyukin.length == 0) {
        alert("入金予定・実績を入力してください");
        return;
      }
      this.valid = this.$refs.nyukin_info.validate();
      if (!this.valid) {
        alert("入金予定・実績に入力不足、または間違いがあります");
        return;
      }

      //DB更新
      //--基本・契約情報
      var order = Object.assign({}, this.order)
      order.kouji_post_cd = this.kouji_post_cd;

      //--顧客
      var customer = Object.assign({}, this.customer)
      customer.post_cd = this.cust_post_cd
      customer.tel_no = this.cust_tel_no;
      customer.fax_no = this.cust_fax_no;
      customer.cell_no = this.cust_cell_no;

      //--顧客担当
      var tantoGrp = [];
      this.custTanto.forEach((element, index) => {
        var tanto = {};
        tanto.tanto_id = element;
        var hitTanto = this.tantoGrp.find((g) => g.tanto_id == tanto.tanto_id);
        if (hitTanto) {
          //顧客担当グループに該当担当者あり
          tanto.created_at = hitTanto.created_at;
        } else {
          tanto.created_at = null;
        }
        tantoGrp.push(tanto);
      });

      this.setOrder(order, this.nyukin, customer, tantoGrp);
    },
    addNyukin() {
      const keiyaku_kin = 22001;  //契約金（デフォルト）
      // 入金明細追加
      this.nyukin.push(
        {
          nkyotei_mei_no: null,
          keiyaku_no: null,
          yotei_date: null,
          nyukin_type: keiyaku_kin,
          yotei_kingaku: null,
          nyukin_date: null,
          nyukin_state: null,
          nyukin_date: null,
          nyukin_kingaku: null,
          created_at: null,
        }
      );
    },
    delNyukin(index){
      // 入金明細削除
      if (index < this.nyukin.length) {
        this.nyukin.splice(index, 1);
      }
    },
  },

  data: function() {
    return {
      cssconsts: cssconsts,
      mode: null,

      //select list
      kouji_type_list: [],
      cust_type_list: [],
      tanto_list: [],
      area_list: [],
      nyukin_type_list: [],

      //受注データ
      order: {},
      mitumori: {},
      hikiai: {},
      nyukin: [],
      customer: {},
      tantoGrp: [],

      kouji_postcd_input1: null,
      kouji_postcd_input2: null,
      cust_postcd_input1: null,
      cust_postcd_input2: null,
      tel_input1: null,
      tel_input2: null,
      tel_input3: null,
      fax_input1: null,
      fax_input2: null,
      fax_input3: null,
      cell_input1: null,
      cell_input2: null,
      cell_input3: null,
      custTanto: [],

      breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap{
   white-space: nowrap;
}
.right-input input {
  text-align: right;
}
</style>
