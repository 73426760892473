<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <!-- 工事日報 ヘッダー部 -->
      <v-card
        :color="cssconsts.eigyo_bg_color_base_01"
        class="mt-0 mb-4 ml-4 mr-4"
        outlined
        elevation="3"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-file-document-outline
            </v-icon>
            工事日報【
          </span>
          <span class="mx-1 yellow lighten-5">{{nippou_rept_date}}</span>
          <span>】</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="editNippou"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            編集
          </v-btn>
          <v-btn
            class="mx-1"
            color="orange darken-3"
            dark
            width="100"
            @click="deleteNippou"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            削除
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backBtn"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-2"></v-divider>

        <v-flex>
          <v-row no-gutters class="mb-2 pr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">担当者</span>
            </v-col>
            <v-col cols="3" class="yellow lighten-5">
            <span>{{nippou_user_name}}</span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">顧客</span>
            </v-col>
            <v-col cols="5" class="yellow lighten-5">
              <span>
              {{nippou_customer_name}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">工事</span>
            </v-col>
            <v-col cols="5" class="yellow lighten-5">
              <span>
              {{nippou_kouji_name}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">全体ステータス</span>
            </v-col>
            <v-col cols="1" class="yellow lighten-5">
              <span>
                {{nippou_kouji_status}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">変更情報</span>
            </v-col>
            <v-col cols="8" class="yellow lighten-5">
              <span>
                {{nippou_henkou_info}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">備考</span>
            </v-col>
            <v-col cols="8" class="yellow lighten-5">
              <span>
                {{nippou_bikou}}
              </span>
            </v-col>
          </v-row>
        </v-flex>
      </v-card>

      <!-- 日報明細 -->
      <v-card
        :color="cssconsts.eigyo_bg_color_base_01"
        class="ma-4"
        outlined
        elevation="3"
      >
        <div class="mt-2 ml-2">
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            日報明細
          </span>
        </div>
        <v-divider class="mt-1 mb-0"></v-divider>

        <!-- 明細 -->
        <div v-for="(mei_item, mei_index) in kouji_nippou_data.meisai" :key="mei_index">
          <!-- test表示 -->
          <!-- <li>{{mei_item}}</li> -->
          <!-- test表示 -->
          <v-sheet
            class="d-flex align-stretch ma-2"
            :color="cssconsts.eigyo_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex flex-shrink-0"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
              <!-- 明細index -->
              <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>

            <!-- 日報種別により表示形式選択 -->
            <!-- 工事工程↓ -->
            <v-flex v-if="mei_item.kjnp_type == 1601">
              <v-card
                color="white"
                class="ml-1 pr-2"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【工事工程】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}～{{timehhmm(mei_item.time_to)}}
                    </span>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">業者</span>
                  </v-col>
                  <v-col cols="4" class="yellow lighten-4">
                    <span>
                      {{mei_item.gyosya}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事部位</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span>
                      {{mei_item.kj_part_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工程</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{mei_item.kj_proc_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">作業人数</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span>
                      {{mei_item.workers_num}}人
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">ステータス</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span>
                      {{mei_item.proc_status_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">進捗率</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span>
                      {{mei_item.prog_rate}}%
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">内容</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">備考</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <span>
                      {{mei_item.bikou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.kjnp_mei_no] != undefined) && (att_file_list[mei_item.kjnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.kjnp_mei_no]"
                        :key="mei_item.kjnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'kouji_nippou/file?kjnp_no='+mei_item.kjnp_no+'&kjnp_mei_no='+mei_item.kjnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.kjnp_mei_no][att_index]">{{(att_file_list[mei_item.kjnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex mb-1">
                    <span class="ml-2 mr-auto my-auto text-body-2">工事写真</span>
                  </v-col>
                </v-row>

                <v-flex v-if="(img_file_list != null) && (img_file_list[mei_item.kjnp_mei_no] != undefined) && (img_file_list[mei_item.kjnp_mei_no] != null)"
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.kjnp_mei_no]"
                    :key="mei_index + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <span class="mx-auto my-auto text-caption">{{img_file_list[mei_item.kjnp_mei_no][img_index]}}</span>
                    </div>
                    <a :href="img_data_list[mei_item.kjnp_mei_no][img_index]" :download="img_file_list[mei_item.kjnp_mei_no][img_index]">
                      <v-img
                        class="ma-1"
                        max-height="180"
                        max-width="240"
                        :src="img_data_list[mei_item.kjnp_mei_no][img_index]"
                      >
                      </v-img>
                    </a>
                  </v-sheet>
                </v-flex>
              </v-card>
            </v-flex>
            <!-- 工事工程↑ -->

            <!-- インシデント↓ -->
            <v-flex v-if="mei_item.kjnp_type == 1602">
              <v-card
                color="white"
                class="ml-1 pr-2"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【インシデント】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">発生時刻</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事部位</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span>
                      {{mei_item.kj_part_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工程</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{mei_item.kj_proc_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">内容</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.kjnp_mei_no] != undefined) && (att_file_list[mei_item.kjnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.kjnp_mei_no]"
                        :key="mei_item.kjnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'kouji_nippou/file?kjnp_no='+mei_item.kjnp_no+'&kjnp_mei_no='+mei_item.kjnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.kjnp_mei_no][att_index]">{{(att_file_list[mei_item.kjnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="1" class="d-flex mb-1">
                    <span class="ml-2 mr-auto my-auto text-body-2">写真</span>
                  </v-col>
                </v-row>

                <v-flex v-if="(img_file_list != null) && (img_file_list[mei_item.kjnp_mei_no] != undefined) && (img_file_list[mei_item.kjnp_mei_no] != null)"
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.kjnp_mei_no]"
                    :key="mei_index + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <span class="mx-auto my-auto text-caption">{{img_file_list[mei_item.kjnp_mei_no][img_index]}}</span>
                    </div>
                    <a :href="img_data_list[mei_item.kjnp_mei_no][img_index]" :download="img_file_list[mei_item.kjnp_mei_no][img_index]">
                      <v-img
                        class="ma-1"
                        max-height="180"
                        max-width="240"
                        :src="img_data_list[mei_item.kjnp_mei_no][img_index]"
                      >
                      </v-img>
                    </a>
                  </v-sheet>
                </v-flex>

              </v-card>
            </v-flex>
            <!-- インシデント↑ -->

            <!-- メンテナンス↓ -->
            <v-flex v-if="mei_item.kjnp_type == 1603">
              <v-card
                color="white"
                class="ml-1 pr-2"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【メンテナンス】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}～{{timehhmm(mei_item.time_to)}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-1 my-auto text-body-2">メンテナンス名</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{mei_item.mainte_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">完了日</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.comp_date}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">対応内容</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.kjnp_mei_no] != undefined) && (att_file_list[mei_item.kjnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.kjnp_mei_no]"
                        :key="mei_item.kjnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'kouji_nippou/file?kjnp_no='+mei_item.kjnp_no+'&kjnp_mei_no='+mei_item.kjnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.kjnp_mei_no][att_index]">{{(att_file_list[mei_item.kjnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="1" class="d-flex mb-1">
                    <span class="ml-2 mr-auto my-auto text-body-2">写真</span>
                  </v-col>
                </v-row>

                <v-flex v-if="(img_file_list != null) && (img_file_list[mei_item.kjnp_mei_no] != undefined) && (img_file_list[mei_item.kjnp_mei_no] != null)"
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.kjnp_mei_no]"
                    :key="mei_index + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <span class="mx-auto my-auto text-caption">{{img_file_list[mei_item.kjnp_mei_no][img_index]}}</span>
                    </div>
                    <a :href="img_data_list[mei_item.kjnp_mei_no][img_index]" :download="img_file_list[mei_item.kjnp_mei_no][img_index]">
                      <v-img
                        class="ma-1"
                        max-height="180"
                        max-width="240"
                        :src="img_data_list[mei_item.kjnp_mei_no][img_index]"
                      >
                      </v-img>
                    </a>
                  </v-sheet>
                </v-flex>
              </v-card>
            </v-flex>
            <!-- メンテナンス↑ -->

            <!-- 点検↓ -->
            <v-flex v-if="mei_item.kjnp_type == 1604">
              <v-card
                color="white"
                class="ml-1 pr-2"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【点検】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}～{{timehhmm(mei_item.time_to)}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">点検名</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{mei_item.insp_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">点検種別</span>
                  </v-col>
                  <v-col cols="4" class="yellow lighten-4">
                    <span>
                      {{mei_item.insp_type_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">完了日</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.comp_date}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">実施内容</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.kjnp_mei_no] != undefined) && (att_file_list[mei_item.kjnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.kjnp_mei_no]"
                        :key="mei_item.kjnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'kouji_nippou/file?kjnp_no='+mei_item.kjnp_no+'&kjnp_mei_no='+mei_item.kjnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.kjnp_mei_no][att_index]">{{(att_file_list[mei_item.kjnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="1" class="d-flex mb-1">
                    <span class="ml-2 mr-auto my-auto text-body-2">写真</span>
                  </v-col>
                </v-row>

                <v-flex v-if="(img_file_list != null) && (img_file_list[mei_item.kjnp_mei_no] != undefined) && (img_file_list[mei_item.kjnp_mei_no] != null)"
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.kjnp_mei_no]"
                    :key="mei_index + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <span class="mx-auto my-auto text-caption">{{img_file_list[mei_item.kjnp_mei_no][img_index]}}</span>
                    </div>
                    <a :href="img_data_list[mei_item.kjnp_mei_no][img_index]" :download="img_file_list[mei_item.kjnp_mei_no][img_index]">
                      <v-img
                        class="ma-1"
                        max-height="180"
                        max-width="240"
                        :src="img_data_list[mei_item.kjnp_mei_no][img_index]"
                      >
                      </v-img>
                    </a>
                  </v-sheet>
                </v-flex>
              </v-card>
            </v-flex>
            <!-- 点検↑ -->

            <!-- その他↓ -->
            <v-flex v-if="mei_item.kjnp_type == 1605">
              <v-card
                color="white"
                class="ml-1 pr-2"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【その他】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事部位</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span>
                      {{mei_item.kj_part_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工程</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{mei_item.kj_proc_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">内容</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.kjnp_mei_no] != undefined) && (att_file_list[mei_item.kjnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.kjnp_mei_no]"
                        :key="mei_item.kjnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'kouji_nippou/file?kjnp_no='+mei_item.kjnp_no+'&kjnp_mei_no='+mei_item.kjnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.kjnp_mei_no][att_index]">{{(att_file_list[mei_item.kjnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2">
                  <v-col cols="1" class="d-flex mb-1">
                    <span class="ml-2 mr-auto my-auto text-body-2">写真</span>
                  </v-col>
                </v-row>

                <v-flex v-if="(img_file_list != null) && (img_file_list[mei_item.kjnp_mei_no] != undefined) && (img_file_list[mei_item.kjnp_mei_no] != null)"
                  class="d-inline-flex flex-shrink-1 mx-2 mb-2"
                  style="width: 85VW; overflow-x: scroll;"
                >
                  <v-sheet
                    v-for="(kojiPhoto, img_index) in img_file_list[mei_item.kjnp_mei_no]"
                    :key="mei_index + '_' + img_index"
                    color="grey lighten-3"
                  >
                    <div class="mt-1 ml-1" style="width: 240px">
                      <span class="mx-auto my-auto text-caption">{{img_file_list[mei_item.kjnp_mei_no][img_index]}}</span>
                    </div>
                    <a :href="img_data_list[mei_item.kjnp_mei_no][img_index]" :download="img_file_list[mei_item.kjnp_mei_no][img_index]">
                      <v-img
                        class="ma-1"
                        max-height="180"
                        max-width="240"
                        :src="img_data_list[mei_item.kjnp_mei_no][img_index]"
                      >
                      </v-img>
                    </a>
                  </v-sheet>
                </v-flex>
              </v-card>
            </v-flex>
            <!-- その他↑ -->

          </v-sheet>
        </div>
      </v-card>
      </v-card>
      </v-layout>

    </v-main>
  </v-app>
</template>

<script>
//import
import dbkoujinippou from './js/common/dbkoujinippou.js'
import cssconsts from './js/common/cssconsts';
//export
export default {
  mixins: [dbkoujinippou],
  props:{
      "parameter": {},
  },
  computed:{
    nippou_rept_date: function() {
      if (this.kouji_nippou_data.nippou)
      {
        return this.kouji_nippou_data.nippou.rept_date
      }
      else 
      {
        return null
      }
    },
    nippou_user_name: function() {
      if (this.kouji_nippou_data.nippou)
      {
        return this.kouji_nippou_data.nippou.user_name
      }
      else 
      {
        return null
      }
    },
    nippou_customer_name: function() {
      if (this.kouji_nippou_data.nippou)
      {
        return this.kouji_nippou_data.nippou.customer_name
      }
      else 
      {
        return null
      }
    },
    nippou_kouji_name: function() {
      if (this.kouji_nippou_data.nippou)
      {
        return this.kouji_nippou_data.nippou.kouji_name
      }
      else 
      {
        return null
      }
    },
    nippou_kouji_status: function() {
      if (this.kouji_nippou_data.nippou)
      {
        return this.kouji_nippou_data.nippou.kouji_status_name
      }
      else 
      {
        return null
      }
    },
    nippou_henkou_info: function() {
      if (this.kouji_nippou_data.nippou)
      {
        return this.kouji_nippou_data.nippou.henkou_info
      }
      else 
      {
        return null
      }
    },
    nippou_bikou: function() {
      if (this.kouji_nippou_data.nippou)
      {
        return this.kouji_nippou_data.nippou.bikou
      }
      else 
      {
        return null
      }
    },
    timehhmm: function() {
      return function(timevalue) {
        return this.timeFormatHHMM(timevalue)
      }
    },

  },

async created(){
    // 工事日報番号がthis.parameter.viewparamとして引き渡されてくる
    if (this.parameter.viewparam)
    {
      var kjnp_no = this.parameter.viewparam
      this.getKoujiNippouData(kjnp_no)  // 対象工事日報番号に該当するデータを読み出し

      // 工事日報画像読出
      await this.downloadNippouImage(kjnp_no)
      // 添付ファイル名リスト読出し
      this.downloadAttachFileNameList(kjnp_no)
      this.m_createBreads();
    }
  },

  methods: {
    backBtn() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    backNippouList() {
        //「工事日報一覧」画面へ戻る
        this.$emit('viewChange', 'KojiNippouList');
    },
    editNippou() {
        //「工事日報登録・編集」画面へ遷移
        var data = {}
        data.nippou = this.kouji_nippou_data.nippou
        data.meisai = this.kouji_nippou_data.meisai
        data.img_file = this.img_file_list
        data.img_data = this.img_data_list
        data.att_file = this.att_file_list
        this.$emit('viewChange', 'KojiNippouEdit', data);
    },
    deleteNippou() {
      // 工事日報削除
      var res = confirm('この工事日報を削除します。よろしいですか？');
      if (res == true) {
        var kjnp_no = this.parameter.viewparam
        this.deleteKoujiNippou(kjnp_no);
      }
    },
    // 工事日報データ取得
    getKoujiNippouData(kjnp_no) 
    {
      // URL作成
      var uri = 'kouji_nippou'
      if (kjnp_no != null) {
        uri += '/' + kjnp_no
      }
      // データ取得
      axios.get(uri)
        .then(response => {
          this.kouji_nippou_data = response.data;
          this.meisaiFromInspMenteData(); // 点検・メンテナンスデータを反映
        })
        .catch(
          // response => console.log(response)
        );
    },
    // 工事日報削除
    deleteKoujiNippou(kjnp_no)
    {
      // URL作成
      var uri = 'kouji_nippou'
      if (kjnp_no != null) {
        uri += '/' + kjnp_no
        // データ削除(destroy)
        axios.delete(uri)
          .then(response => {
            // console.log(response.data);
            this.backNippouList();  // 成功したら一覧に戻る
          })
          .catch((error) => {
            alert("工事日報の削除に失敗しました");
          });
      }
    },
    // 工事日報画像読出
    async downloadNippouImage(kjnp_no) 
    {
      // データ
      // URL作成
      var uri = 'kouji_nippou/image?kjnp_no='+kjnp_no
      let response = await axios.get(uri)
      this.img_file_list = response.data.file_list;
      this.img_data_list = response.data.data_list;
      // console.log(response.data);
    },    
    // メンテナンス・点検データを日報明細に反映
    meisaiFromInspMenteData() 
    {

      this.kouji_nippou_data.meisai.forEach(function(elem, index) {
        if (elem.kjnp_type == 1603)
        { //メンテナンス
          this.kouji_nippou_data.meisai[index].comp_date = elem.mainte_comp_date;
          this.kouji_nippou_data.meisai[index].naiyou = elem.mainte_taiou;
        }
        else if (elem.kjnp_type == 1604)
        { //点検
          this.kouji_nippou_data.meisai[index].comp_date = elem.insp_jissi_date;
          this.kouji_nippou_data.meisai[index].naiyou = elem.insp_taiou;
        }
      }.bind(this));
    },

  },
  data: function(){
    return {
      cssconsts: cssconsts,

      kouji_nippou_data: {},
      img_file_list: {}, // 画像ファイル名
      img_data_list: {}, // 画像データ
      att_file_list: {}, // 添付ファイル名
      breads: [],
    }
  }
};
</script>

<style>
</style>
<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>
<style>
</style>
