<template>
  <v-app class="base">
    <v-main>
      <v-layout justify-center>
        <v-card 
          color="rgb(255, 0, 0, 0)" 
          class="outline-card ma-0 pa-0"
          elevation="0"
          height="100%"
        >
        <v-card
          color="rgb(255, 0, 0, 0)" 
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-breadcrumbs :items="breads"></v-breadcrumbs>
        </v-card>
          <v-form id="srch_form">
            <!-- 検索条件 -->
            <v-card
              :color="cssconsts.search_keys_bg_color_01"
              class="mt-0 mb-4 ml-4 mr-4"
              outlined
              elevation="10"
            >
              <v-card-actions>
                <span>
                  <v-icon>
                    mdi-text-box-search-outline
                  </v-icon>
                  検索条件
                </span>
                <v-spacer></v-spacer>
                <span class="text-body-2 mx-4 mt-2">詳細検索</span>
                <v-switch
                  v-model="dtl_srch"
                  class="mt-1"
                  color="primary"
                  inset
                  dense
                  hide-details
                  @change="dtl_srch_sw(dtl_srch)"
                ></v-switch>
                <v-btn
                  class="mx-1"
                  color="blue-grey darken-1"
                  dark
                  width="100"
                  @click="srchKouji"
                >
                  <v-icon left>
                    mdi-magnify
                  </v-icon>
                  検索
                </v-btn>
                <v-btn
                  class="mx-1"
                  color="light-blue darken-1"
                  dark
                  width="100"
                  @click="regKoji"
                >
                  新規登録
                </v-btn>
              </v-card-actions>
              <v-divider class="mt-0 mb-2"></v-divider>

              <!-- 1行目 -->
              <v-container mx-4 pa-0>
                <v-row no-gutters>
                  <v-col lg="1" md="2">
                    <v-subheader class="pb-2 float-right">担当者</v-subheader>
                  </v-col>
                  <v-col lg="3" md="4">
                    <v-autocomplete
                      v-model="tanto_select"
                      :items="tanto_list"
                      item-value="id"
                      item-text="name"
                      placeholder="担当者を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="7" md="9">
                    <v-row no-gutters>
                      <v-col col="3">
                        <v-subheader class="pl-0 pr-2 pb-2 float-right">工事期間（予定）</v-subheader>
                      </v-col>
                      <v-col cols="4" class="pr-3">
                        <v-text-field 
                          v-model="kouji_kikan_from"
                          placeholder="日付(From)"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="dateClick('dpick_kikan_from')"
                        >
                          <template v-slot:append>
                            <date-picker ref="dpick_kikan_from" v-model="kouji_kikan_from"/>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-subheader class="px-0 pb-2">
                          <div class="mx-auto">～</div>
                        </v-subheader>
                      </v-col>
                      <v-col cols="4" class="pr-3">
                      <v-text-field 
                          v-model="kouji_kikan_to"
                          placeholder="日付(To)"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="dateClick('dpick_kikan_to')"
                        >
                          <template v-slot:append>
                            <date-picker ref="dpick_kikan_to" v-model="kouji_kikan_to"/>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>

              <template v-if="dtl_srch === true">
                <!-- 2行目 -->
                <v-container mx-4 pa-0>
                  <v-row no-gutters>
                    <v-col lg="1" md="2">
                      <v-subheader class="pb-2 float-right">顧客名</v-subheader>
                    </v-col>
                    <v-col lg="4" md="10" class="pr-3">
                      <v-text-field
                        v-model="cust_name_input"
                        placeholder="顧客名かカナを入力"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col lg="2" md="2">
                      <v-subheader class="pb-2 float-right">ＴＥＬ</v-subheader>
                    </v-col>
                    <v-col lg="4" md="10" class="pr-3">
                      <div class="d-flex">
                        <v-text-field
                          v-model="tel_input1"
                          class="mr-2"
                          solo
                          dense
                          maxlength="4"
                          hide-details="auto"
                          :rules="[validRules.isNumber]"
                        ></v-text-field>
                        <span class="mt-2">
                          -
                        </span>
                        <v-text-field
                          v-model="tel_input2"
                          class="mx-2"
                          solo
                          dense
                          maxlength="4"
                          hide-details="auto"
                          :rules="[validRules.isNumber]"
                        ></v-text-field>
                        <span class="mt-2">
                          -
                        </span>
                        <v-text-field
                          v-model="tel_input3"
                          class="ml-2"
                          solo
                          dense
                          maxlength="4"
                          hide-details="auto"
                          :rules="[validRules.isNumber]"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- 3行目 -->
                <v-container mx-4 pa-0>
                  <v-row no-gutters>
                    <v-col lg="1" md="2">
                      <v-subheader class="pb-2 float-right">工事名</v-subheader>
                    </v-col>
                    <v-col lg="4" md="10" class="pr-3">
                      <v-text-field
                        v-model="kouji_name_input"
                        placeholder="工事名を入力"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col lg="2" md="2">
                      <v-subheader class="pb-2 float-right">工事場所</v-subheader>
                    </v-col>
                    <v-col lg="4" md="10"  class="pr-3">
                      <v-text-field
                        v-model="kj_addr_input"
                        placeholder="工事場所を入力"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- 3行目 -->
                <v-container mx-4 pa-0>
                  <v-row no-gutters>
                    <v-col lg="1" md="2">
                      <v-subheader class="pl-0 pb-2 float-right">工事種別</v-subheader>
                    </v-col>
                    <v-col lg="4" md="4" class="pr-3">
                      <v-autocomplete
                        v-model="kouji_type_select"
                        :items="kouji_type_list"
                        item-value="id"
                        item-text="name"
                        placeholder="工事種別を選択"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="2" md="2">
                      <v-subheader class="pb-2 float-right">ステータス</v-subheader>
                    </v-col>
                    <v-col lg="4" md="3" class="pr-3">
                      <v-autocomplete
                        v-model="kouji_status_select"
                        :items="kouji_status_list"
                        item-value="cd"
                        item-text="name"
                        :placeholder="$vuetify.breakpoint.smAndDown ? 'ステータス' : 'ステータスを入力'"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- 4行目 -->
                <v-container mx-4 pa-0>
                  <v-row no-gutters>
                    <v-col lg="1" md="2">
                      <v-subheader class="pl-0 pb-2 float-right">自社施工</v-subheader>
                    </v-col>
                    <v-col lg="3" md="5">
                      <v-autocomplete
                        v-model="ckk_bukken_select"
                        :items="ckk_bukken_list"
                        item-value="id"
                        item-text="name"
                        placeholder="自社施工を選択"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="1" md="1">
                      <v-subheader class="pb-2 float-right">地区</v-subheader>
                    </v-col>
                    <v-col lg="3" md="4" class="pr-3">
                      <v-autocomplete
                        v-model="area_select"
                        :items="area_list"
                        item-value="id"
                        item-text="name"
                        placeholder="地区を選択"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="2" md="2">
                      <v-subheader class="pl-0 pb-2 float-right">アラート</v-subheader>
                    </v-col>
                    <v-col lg="2" md="4">
                      <v-checkbox
                        v-model="alert_ari"
                        class="mt-1"
                        color="red darken-1"
                        hide-details
                      >
                        <template v-slot:label>
                          <span>
                            あり
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>

              </template>

            </v-card>
          </v-form>

          <!-- 検索結果 -->
          <v-card
            :color="cssconsts.search_result_bg_color_01"
            class="ma-4"
            outlined
            elevation="3"
          >
            <div class="mt-2 ml-2">
              <span>
                <v-icon>
                  mdi-view-list
                </v-icon>
                検索結果
              </span>
            </div>
            <v-divider class="mt-1 mb-0"></v-divider>
            <v-flex class="mt-2 mb-4 mx-4">
              <v-data-table
                calculate-widths
                :height="kojiTableHeight"
                :headers="koujiHeaders"
                :items="kouji_srch_list"
                :items-per-page="10"
                class="elevation-1"
                locale="jp-ja"
                loading-text="読込中"
                no-data-text="該当するデータがありません。"
                @click:row="showKojiPortal"
              >
                <template v-slot:[`header.cust_name`]="{ header }">
                  <span>{{ header.text }}</span><br>
                  <span class="red--text">アラート</span>
                </template>
                <template v-slot:[`header.start_date`]="{ header }">
                  <span>{{ header.text }}</span><br>
                  <span>(着工予定)</span>
                </template>
                <template v-slot:[`header.comp_date`]="{ header }">
                  <span>{{ header.text }}</span><br>
                  <span>(完成予定)</span>
                </template>
                <template v-slot:[`item.cust_name`]="{ item }">
                  <span>{{ item.cust_name }}</span><br>
                  <span class="red--text">{{ item.alert_msg }}</span>
                </template>
                <template v-slot:[`item.start_date`]="{ item }">
                  <span>{{ editNullDate(item.start_date) }}</span><br>
                  <span>&#40;{{ editNullDate(item.start_yotei_date) }}&#41;</span>
                </template>
                <template v-slot:[`item.comp_date`]="{ item }">
                  <span>{{ editNullDate(item.comp_date) }}</span><br>
                  <span>&#40;{{ editNullDate(item.comp_yotei_date) }}&#41;</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <span>{{ getStatusName(item) }}</span>
                </template>
                <template v-slot:[`item.endProc`]="{ item }">
                  <span>{{ item.comp_num }} / {{ item.proc_num }}</span>
                </template>
              </v-data-table>
            </v-flex>
          </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import dbkouji from './js/common/dbkouji.js'
import DatePicker from "./DatePicker.vue";
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbkouji],

  props:{
      "parameter": {},
  },

  created: function() {
    if (this.parameter.homeparam.loginuser != null) {
      //ログインユーザーIDを取得
      this.loginUser = this.parameter.homeparam.loginuser.user_id
    }
    this.getIdNameList("koujiType");      // 工事種別リスト取得
    this.getIdNameList("koujiStatus");    // 工事ステータス取得
    this.getIdNameList("ckkBukken");      // 自社施工物件取得
    this.getIdNameList("tanto");          // 担当者リスト取得
    this.getIdNameList("area");           // 地区リスト取得

    //ログインユーザーの担当工事を読み出す
    this.getKoujiList(this.loginUser);
    this.m_createBreads();
  },
  mounted() {
  },

  watch: {
    tanto_list: function(data) {
      if (this.loginUser != null) {
        this.tanto_select = this.loginUser;
      }
    },
  },

  methods: {
    dtl_srch_sw(sw) {
      //this.kojiTableHeight = (sw ? 380 : 500);
    },
    dateClick(dpickName) {
      //alert('Date Clicked!');
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    srchKouji() {
      //工事を検索
      var kouji_tanto = this.tanto_select;
      var kouji_kikan_from = this.kouji_kikan_from;
      var kouji_kikan_to = this.kouji_kikan_to;
      var cust_name = this.cust_name_input;
      var kouji_name = this.kouji_name_input;
      var kouji_type = this.kouji_type_select;
      var kouji_status = this.kouji_status_select;
      var ckk_bukken = this.ckk_bukken_select;
      var area_id = this.area_select;
      var only_alert = (this.alert_ari == true ? "yes" : "no");
      var tel_no1 = this.tel_input1;
      var tel_no2 = this.tel_input2;
      var tel_no3 = this.tel_input3;
      var kj_addr = this.kj_addr_input;
      
      if (this.dtl_srch) {
        //詳細検索
        this.getKoujiList(
            kouji_tanto, kouji_kikan_from, kouji_kikan_to, cust_name, kouji_name,
            kouji_type, kouji_status, ckk_bukken, area_id, only_alert,
            tel_no1, tel_no2, tel_no3, kj_addr
        );
      } else {
        //簡易検索
        this.getKoujiList(
          kouji_tanto, kouji_kikan_from, kouji_kikan_to
        );
      }
    },
    getStatusName(item) {
      if (item.start_date == null) {
        return "未着工";
      } else if (item.start_date != null && item.comp_num == 0) {
        return "着工";
      } else if (item.start_date != null && item.comp_num > 0) {
        return "施工中";
      } else if (item.comp_date != null && item.comp_num == item.proc_num) {
        return "完成";
      } else {
        return "不明"
      }
    },
    editNullDate(src_date) {
      return (src_date == null ? "––––/––/––" : src_date);
    },
    regKoji() {
        //「工事情報登録・編集」画面へ遷移
        this.$emit('viewChange', 'KojiEdit', 0);  //新規登録
    },
    showKojiPortal: function(event, row) {
      //「工事ポータル」画面へ遷移
      this.$emit('viewChange', 'KojiPortal', row.item.kouji_no);
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      dtl_srch: false,
      kojiTableHeight: 500,

      loginUser: null,
      tanto_select: null,
      kouji_kikan_from: null,
      kouji_kikan_to: null,
      cust_name_input: null,
      kouji_name_input: null,
      kouji_type_select: null,
      kouji_status_select: null,
      ckk_bukken_select: null,
      area_select: null,
      alert_ari: false,
      kj_addr_input: null,
      tel_input1: null,
      tel_input2: null,
      tel_input3: null,

      //担当者
      tanto_list: [],
      //工事種別
      kouji_type_list: [],
      //ステータス
      kouji_status_list: [],
      //自社施工
      ckk_bukken_list: [],
      //地区
      area_list: [],

      //工事一覧
      koujiHeaders: [
        { text: '顧客', value: 'cust_name', width: '200px', class: 'px-1' },
        { text: '工事種別', value: 'kouji_type', width: '150px', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: '着工日', value: 'start_date', width: '100px', class: 'px-1' },
        { text: '完成日', value: 'comp_date', width: '100px', class: 'px-1' },
        { text: 'ステータス', value: 'status', width: '100px', class: 'px-1' },
        { text: '完了工程', value: 'endProc', width: '80px', class: 'px-1' },
        { text: '工事担当', value: 'tanto_name', width: '100px', class: 'px-1' },
      ],
      kouji_srch_list: [],
      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap {
   white-space: nowrap;
}

td.text-start,td.text-end {
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.right-input input {
  text-align: right;
}
</style>
