<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn icon elevation="0" v-on="on">
        <v-icon>mdi-clock-outline</v-icon>
      </v-btn>
    </template>
    <v-time-picker 
        v-model="picker" 
        format="24hr"
        scrollable
        @click="menu = false"
    ></v-time-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(11, 5)
    }
   //value: new Date().toISOString().substr(11, 5)
  },
  data() {
    return {
      menu: false
    };
  },
  computed: {
    picker: {
      get() {
        if (!this.value) return null;
        return this.value;
      },
      set(time) {
        if (!time) return null;
        this.menu = false;
        this.$emit("input", time);
      }
    }
  }
};
</script>