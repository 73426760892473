<template>
  <v-text-field
    v-bind="$attrs"
    :value="innerValue"
    :type="type"
    v-on="listeners"
    @focusin="edit"
    @focusout="display"
  >
    <template v-for="(value, name) in $slots" v-slot:[name]>
      <slot :name="name"/>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "CommaNumberField",
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      editing: false
    };
  },
  computed: {
    listeners() {
      const vm = this;
      return {
        ...vm.$listenres,
        input: event => (vm.innerValue = event)
      };
    },
    innerValue: {
      get() {
        if (this.editing) {
          return this.value != null ? this.value.toString() : "";
        } else {
          return this.value != null ? this.value.toLocaleString() : "";
        }
      },
      set(newValue) {
        const value = ((newValue != null) && (isNaN(newValue) == false) && (newValue.length > 0)) ? Number(newValue) : null;
        this.$emit("input", value);
      }
    },
    type() {
      if (this.editing) {
        // return "number";
        return "text";
      } else {
        return "text";
      }
    }
  },
  methods: {
    display() {
      this.editing = false;
    },
    edit() {
      if (this.$attrs.readonly == undefined)
      {
        this.editing = true;
      }
    }
  }
};
</script>

<style>
</style>
