<template>
  <v-data-table
    calculate-widths
    :headers="headers"
    :items="nippou_list"
    :height="height"
    :items-per-page="10"
    class="elevation-1"
    dense
    locale="jp-ja"
    loading-text="読込中"
    no-data-text="該当するデータがありません。"

    @click:row="clickRow"
  ></v-data-table>
</template>

<script>
  export default {
    props:{
      "nippou_list": {},
    },
    data: function() {
      return {
        height: 500,
        headers: [
          { text: '日付', value: 'rept_date', width: '100px', class: 'px-1' },
          { text: '日報種別', value: 'kjnp_type_name', width: '150px', class: 'px-1' },
          { text: '顧客', value: 'customer_name', width: '150px', class: 'px-1' },
          { text: '工事名', value: 'kouji_name', width: '150px', class: 'px-1' },
          { text: '工事部位', value: 'kj_part_name', width: '100px', class: 'px-1' },
          { text: '工程／名称', value: 'kj_proc_name', width: '150px', class: 'px-1' },
          { text: '内容', value: 'naiyou', width: '200px', class: 'px-1' },
          { text: '担当者', value: 'user_name', width: '100px', class: 'px-1' },
        ],
        /*
        details: [
          {
            rept_date: '2020/04/01',
            kjnp_type: '工事工程',
            customer_name: '富山 一郎',
            kouji_name: 'キッチンリフォーム',
            kj_part_name: 'キッチン',
            kj_proc_name: '水道の配管',
            naiyou: 'キッチン位置変更のための再配管',
            user_name: '建設 太郎',
          },
          {
            rept_date: '2020/04/05',
            kjnp_type: '工事工程',
            customer_name: '富山 次郎',
            kouji_name: '浴室リフォーム',
            kj_part_name: '浴室',
            kj_proc_name: '旧浴室解体',
            naiyou: '旧浴室の解体・搬出作業',
            user_name: '建設 太郎',
          },
          {
            rept_date: '2020/05/12',
            kjnp_type: 'インシデント',
            customer_name: '高岡 花子',
            kouji_name: '太陽光発電新規設置',
            kj_part_name: '屋根',
            kj_proc_name: '太陽光パネル設置',
            naiyou: '太陽光パネルの取付ミス発見⇒再設置作業',
            user_name: '建設 次郎',
          },
          {
            rept_date: '2020/05/25',
            kjnp_type: 'メンテナンス',
            customer_name: '高岡 花子',
            kouji_name: '高岡邸新築工事',
            kj_part_name: '',
            kj_proc_name: '外壁修理',
            naiyou: '外壁のひび割れ箇所の修繕',
            user_name: '建設 次郎',
          },
          {
            rept_date: '2020/05/28',
            kjnp_type: '点検',
            customer_name: '富山 一郎',
            kouji_name: '富山邸新築工事',
            kj_part_name: '',
            kj_proc_name: '３年点検',
            naiyou: '３年点検の実施',
            user_name: '建設 三郎',
          },
          {
            rept_date: '2020/06/01',
            kjnp_type: 'その他',
            customer_name: '富山 吾郎',
            kouji_name: '地下室消火設備設置',
            kj_part_name: '地下室',
            kj_proc_name: '',
            naiyou: '炭酸ガス消火設備の緊急点検',
            user_name: '建設 三郎',
          },
        ],
        */
      }
    },

    methods: {
      clickRow: function(event, row){
        //alert("顧客：" + row.item.customer);
        //画面遷移
        this.$emit('RowClick',event);
      }
    }
  }
</script>