<template>
  <v-app class="base">
    <v-main>
      <v-layout justify-center>
      <v-card
        class="outline-card ma-4"
        :color="cssconsts.system_bg_color_base_01"
        outlined
        elevation="10"
      >
        <v-card-actions>
          <div class="ml-2">
            <span>
              <v-icon>
                mdi-home
              </v-icon>
              システム管理ホーム
            </span>
          </div>
          <v-spacer></v-spacer>
          <span class="my-auto text-body-2">ログインユーザー　部署：</span>
          <div class="mr-4 yellow lighten-5" style="width: 150px">
            <span>管理部管理課</span>
          </div>
          <span class="my-auto text-body-2">名前：</span>
          <div class="mr-4 yellow lighten-5" style="width: 150px">
            <span>管理 太郎</span>
          </div>
        </v-card-actions>
        <v-divider class="mt-0 mb-2"></v-divider>

        <!-- アラート一覧 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.system_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <div class="mx-2 my-auto">アラート一覧</div>
            <v-spacer></v-spacer>
            <v-checkbox
              v-model="showDone"
              class="shrink mx-2 my-auto"
              color="cyan accent-1"
              hide-details
              @click="m_mixinTest()"
            >
              <template v-slot:label>
                <span class="mr-2 text-body-2">
                  対応済みも表示する
                </span>
              </template>
            </v-checkbox>
          </v-sheet>
          <v-simple-table height="600">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="alert in alerts"
                  :key="alert.no"
                >
                  <template v-if="showDone == true || alert.done == false">
                    <td class="px-2">
                      <span>
                        顧客：{{ alert.customer }}　{{ alert.msg }}
                      </span>
                    </td>
                    <td class="px-2">
                      <div class="mr-2 float-right" style="width: 80px">
                        <span v-if="alert.done == true">
                          対応済み
                        </span>
                        <span v-else class="red--text">
                          未対応
                        </span>
                      </div>
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import

import cssconsts from './js/common/cssconsts';
//export
export default {
  data: function(){
    return {
      cssconsts: cssconsts,
      stretchInquiry: true,
      stretchNippou: true,
      stretchConstruction: true,
      stretchMaintenance: true,
      stretchInspection: true,
      
      //アラート一覧
      alerts: [
        {
          customer: "北陸 新之助",
          msg: "工事完了がXX日遅延しています。",
          done: "0",
        },
        {
          customer: "石川 健太郎",
          msg: "定期点検実施がX日過ぎています。",
          done: "1",
        },
        {
          customer: "長野 幾多郎",
          msg: "入金予定日をX日過ぎています。",
          done: "0",
        },
        {
          customer: "富山 一郎",
          msg: "商談の対応期日をX日過ぎています。",
          done: "0",
        },
        {
          customer: "北陸 幸太郎",
          msg: "工事完了がXX日遅延しています。",
          done: "1",
        },
        {
          customer: "石川 健一",
          msg: "定期点検実施がX日過ぎています。",
          done: "0",
        },
        {
          customer: "長野 弥太郎",
          msg: "入金予定日をX日過ぎています。",
          done: "1",
        },
        {
          customer: "富山 次郎",
          msg: "商談の対応期日をX日過ぎています。",
          done: "0",
        },
        {
          customer: "北陸 海人",
          msg: "工事完了がXX日遅延しています。",
          done: "0",
        },
        {
          customer: "石川 健司",
          msg: "定期点検実施がX日過ぎています。",
          done: "1",
        },
        {
          customer: "長野 善治",
          msg: "入金予定日をX日過ぎています。",
          done: "0",
        },
        {
          customer: "富山 三郎",
          msg: "商談の対応期日をX日過ぎています。",
          done: "1",
        },
      ],
      //対応済み表示
      showDone: false,
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>

<style>
/* チェックボックスのラベル位置調整用 */
label {
  margin-bottom: 0 !important;
}
</style>
