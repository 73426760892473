<template>
  <v-app>
    <v-main>
      <v-card
        class="ma-4"
        color="light-blue lighten-4"
        outlined
        elevation="3"
        height="500"
      >
        <v-card-actions>
          <div class="ml-2">
            <span>
              <v-icon>
                mdi-database-cog
              </v-icon>
              マスタ管理メニュー
            </span>
          </div>
        </v-card-actions>
        <v-divider class="mt-0 mb-8"></v-divider>

        <v-container mx-4 pa-0>
          <v-row no-gutters class="mb-8">
            <v-col cols="2">
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-1"
                color="blue-grey darken-1"
                dark
                width="200"
                @click="changeView('user')"
              >
                <v-icon left>
                  mdi-database
                </v-icon>
                担当者マスタ
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-8">
            <v-col cols="2">
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-1"
                color="blue-grey darken-1"
                dark
                width="200"
                @click="changeView('general')"
              >
                <v-icon left>
                  mdi-database
                </v-icon>
                汎用マスタ
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-8">
            <v-col cols="2">
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-1"
                color="blue-grey darken-1"
                dark
                width="200"
                disabled
              >
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-8">
            <v-col cols="2">
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-1"
                color="blue-grey darken-1"
                dark
                width="200"
                disabled
              >
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-8">
            <v-col cols="2">
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-1"
                color="blue-grey darken-1"
                dark
                width="200"
                disabled
              >
              </v-btn>
            </v-col>
          </v-row>


          <v-row no-gutters class="mb-8">
            <v-col cols="2">
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-1"
                color="blue-grey darken-1"
                dark
                width="200"
                disabled
              >
              </v-btn>
            </v-col>
          </v-row>

        </v-container>

      </v-card>
    </v-main>
  </v-app>
</template>

<script>
//import
//export
export default {
  methods: {
    changeView(view) {
      if (view == "general") {
        //「汎用マスタ」画面へ遷移
        this.$emit('viewChange', 'MstGeneral');
      }else if(view == "user") {
        this.$emit('viewChange', 'MstUser');
      }
    },
  },
};
</script>

<style>
</style>
