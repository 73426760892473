<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
              <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-card
        class="mt-0 mb-4 ml-4 mr-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="10"
        height="100%"
      >
        <v-card-actions>
          <div style="width: 200px">
            <v-icon>
              mdi-handshake-outline
            </v-icon>
            引合情報登録・編集
          </div>
          <v-spacer></v-spacer>
          <span class="text-body-2 mx-4">
            ※内容を変更した場合、登録ボタンを押してください
          </span>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="regInquiry"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            登録
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backPrevPage"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-1"></v-divider>
        <v-flex>
          <!-- 引合情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">引合情報</div>
            </v-sheet>

            <v-form ref="inquiry_info" v-model="valid" lazy-validation>
              <v-row no-gutters class="mt-2 mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">顧客</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-select v-if="mode == 'reg'"
                    v-model="customer_area_select"
                    :items="customer_area_list"
                    item-value="id"
                    item-text="name"
                    return-object
                    placeholder="顧客を選択"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-select>
                  <v-text-field v-else
                    v-model="cust_no_name"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">引合日</v-subheader>
                </v-col>
                <v-col cols="3">
                  <v-text-field 
                    v-model="hikiai.hikiai_date"
                    placeholder="日付を選択"
                    solo
                    readonly
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                    @click="dateClick('dpick_inqData')"
                  >
                    <template v-slot:append>
                      <date-picker ref="dpick_inqData" v-model="hikiai.hikiai_date"/>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">地区</v-subheader>
                </v-col>
                <v-col cols="4" v-if="mode == 'reg'">
                  <v-select
                    v-model="area_id"
                    :items="area_list"
                    item-value="id"
                    item-text="name"
                    placeholder="地区を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="3" v-else>
                  <v-text-field
                    v-model="customer.area_name"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">引合内容</v-subheader>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="hikiai.hikiai_naiyou"
                    placeholder="引合内容を入力"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">工事種別</v-subheader>
                </v-col>
                <v-col cols="5">
                  <v-select
                    v-model="kouji_type_id"
                    :items="kouji_type_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工事種別を選択"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">受注予定日</v-subheader>
                </v-col>
                <v-col cols="3">
                  <v-text-field 
                    v-model="hikiai.order_exp_date"
                    placeholder="日付を選択"
                    solo
                    readonly
                    clearable
                    dense
                    hide-details
                    @click="dateClick('dpick_expOdData')"
                  >
                    <template v-slot:append>
                      <date-picker ref="dpick_expOdData" v-model="hikiai.order_exp_date"/>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">受注予定額</v-subheader>
                </v-col>
                <v-col cols="3">
                  <comma-number-field
                    v-model.number="hikiai.order_exp_amt"
                    class="right-input"
                    solo
                    clearable
                    dense
                    maxlength="12"
                    hide-details="auto"
                    :rules="[validRules.isNumberCommaSigned]"
                  >
                  </comma-number-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">結果</v-subheader>
                </v-col>
                <v-col cols="3" >
                  <v-select
                    v-model="hikiai.kekka"
                    :items="inq_status_list"
                    item-value="id"
                    item-text="name"
                    placeholder="結果を選択"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">営業担当</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-select
                    v-model="eigyoTanto"
                    :items="tanto_list"
                    item-value="id"
                    item-text="name"
                    placeholder="営業担当を選択"
                    multiple chips deletable-chips
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.arrRequired]"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">備考</v-subheader>
                </v-col>
                <v-col cols="10">
                  <v-textarea
                    v-model="hikiai.bikou"
                    placeholder="備考を入力"
                    auto-grow
                    rows="1"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-sheet>

          <!-- 工事部位 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">工事部位</div>
            </v-sheet>
            <template
              v-for="kojiPart in kojiParts"
            >
              <v-row
                no-gutters class="mt-2 mb-0 mx-2"
                :key="kojiPart.partNo"
              >
                <v-col cols="2" class="d-flex">
                  <span class="ml-2 my-auto text-body-2">{{kojiPart.partName}}</span>
                </v-col>
                <v-col cols="10" class="d-flex flex-wrap">
                  <div
                    v-for="(model, index) in kojiPart.models"
                    :key="index"
                    class="ml-4 d-flex"
                  >
                    <v-checkbox
                      v-model="model.select"
                      class="shrink my-auto"
                      color="blue darken-1"
                      hide-details
                    >
                      <template v-slot:label>
                        <span class="text-body-2">
                          {{model.name}}
                        </span>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-0" :key="'d'+kojiPart.partNo"></v-divider>
            </template>
          </v-sheet>

          <!-- 工事見積 -->
          <v-sheet v-if="mode == 'edit'"
            class="mx-2 mt-1 mb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">工事見積</div>
            </v-sheet>

            <v-data-table
              calculate-widths
              height="300"
              :headers="estimateHeaders"
              :items="mitumori"
              :items-per-page="10"
              :sort-by.sync="estimateSortBy"
              sort-desc
              class="elevation-1"
              locale="jp-ja"
              loading-text="読込中"
              no-data-text="該当するデータがありません。"
              @click:row="showConstrEst"
            >
              <template v-slot:[`item.pres_amt`]="{ item }">
                <span>
                  {{numberToLocal(item.pres_amt)}}
                </span>
              </template>
              <template v-slot:[`item.ukeoi_amt`]="{ item }">
                <span>
                  {{numberToLocal(item.ukeoi_amt)}}
                </span>
              </template>
              <template v-slot:[`item.order`]="{ item }">
                <v-btn
                  color="blue lighten-4"
                  :disabled="item.keiyaku_no == 0"
                  @click.stop="showOrder(item)"
                >
                  受注
                </v-btn>
              </template>
            </v-data-table>

          </v-sheet>
        </v-flex>

      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import DatePicker from "./DatePicker.vue";
import cssconsts from './js/common/cssconsts';
import dbinquiry from './js/common/dbinquiry.js'
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbinquiry],

  props:{
      "parameter": {},
  },
  created: async function() {
    this.getIdNameList("koujiType");      // 工事種別リスト取得
    this.getIdNameList("area");           // 地区リスト取得
    this.getIdNameList("inquiryStatus");  // 引合結果リスト取得
    this.getIdNameList("tanto");          // 担当者リスト取得

    // 引合番号がthis.parameter.viewparamとして引き渡されてくる
    if (this.parameter.viewparam && this.parameter.viewparam.hikiai_no) {
      var hikiai_no = this.parameter.viewparam.hikiai_no;
      //alert("hikiai_no" + hikiai_no);
      //編集
      this.mode = "edit";
      // 引合番号が一致するデータを読み出す
      //以下に格納
      //⇒this.hikiai
      //⇒this.customer
      //⇒this.kouji_part_list
      //⇒this.tantoGrp
      //⇒this.mitumori
      this.getInquiry(hikiai_no);
    } else {
      //新規登録
      this.mode = "reg";
      await this.getIdNameList("customer");   // 顧客リスト取得
      if (this.parameter.viewparam && this.parameter.viewparam.cust_no) {
        // 顧客デフォルト
        var customersel = this.customer_list.find((v) => v.id === this.parameter.viewparam.cust_no);
        this.customer_area_select = customersel
      }
    }
    this.m_createBreads();
  },
  mounted: function() {
    //エラーをリセット
    this.$refs.inquiry_info.resetValidation();
  },
  watch: {
    hikiai: function(data) {
      if (this.mode == 'edit') {
        this.kouji_type_id = data.kouji_type;
      }
    },
    customer: function(data) {
      this.cust_no_name = data.cust_no + " " + data.cust_name;
    },
    tantoGrp: function(data) {
      this.eigyoTanto = [];
      for (var elem of data) {
        this.eigyoTanto.push(elem.tanto_id)
      }
    },
    customer_list: function(data) {
      //顧客リストをコピー
      this.customer_area_list = data.slice();
    },
    area_id: function(data) {
      /*
      if (data) {
        //顧客リストを地区でフィルタリング
        this.customer_area_list = this.customer_list.filter(
          cust => cust.area_id == data
        );
      } else {
        //顧客リストを元に戻す
        this.customer_area_list = this.customer_list.slice();
      }
      */
    },
    customer_area_select: function(data) {
      //顧客番号、地区設定
      if (data) {
        this.hikiai.cust_no = data.id;
        this.area_id = data.area_id;
      } else {
        this.hikiai.cust_no = null;
        this.area_id = null;
      }
    },
    kouji_type_id: function(data) {
      if (this.mode == 'reg') {
        //工事部位読込
        this.getKoujiPartList(data);
      } else {
        if (data != this.hikiai.kouji_type) {
          //編集前と異なる工事種別のとき、工事部位読込
          this.getKoujiPartList(data);
        }
      }
    },
    kouji_part_list: function(data) {
      this.kojiParts = [];
      var kj_part_id = 0;
      for (var elem of data) {
        if (elem.kj_part_id != kj_part_id) {
          this.kojiParts.push({
            partNo: elem.kj_part_id,
            partName: elem.kj_part_name,
            models: [],
          });
          kj_part_id = elem.kj_part_id;
        }
        this.kojiParts[this.kojiParts.length - 1].models.push({
          no: elem.model_no,
          name: elem.model_name,
          select: (elem.choice == "1"),
          created_at: elem.created_at
        });
      }
    },
  },
  methods: {
    dateClick(dpickName, index = null) {
      //console.log(this.$refs);
      //DatePicker を開く
      if (index == null) {
        this.$refs[dpickName].menu = true;
      } else {
        //v-forで生成したDatePicker用
        this.$refs[dpickName][index].menu = true;
      }
    },
    numberToLocal(num) {
      if (num == null) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
    backPrevPage() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    showConstrEst: function(event, row) {
      //「工事見積情報」画面へ遷移
      this.$emit('viewChange', 'ConstrEst', row.item.mitumori_no, 4);
    },
    showOrder(item) {
      //「受注情報」画面へ遷移
      this.$emit('viewChange', 'Order', item.keiyaku_no, 5);
    },
    regInquiry() {
      //入力チェック
      //--引合情報
      this.valid = this.$refs.inquiry_info.validate();
      if (!this.valid) {
        alert("引合情報に入力不足、または間違いがあります");
        return;
      }
      this.hikiai.kouji_type = this.kouji_type_id

      //--工事部位
      var koujiPart = [];
      this.kojiParts.forEach(part => {
        part.models.forEach(model => {
          if (model.select == true) {
            koujiPart.push({
              kj_part_id: part.partNo,
              model_no: model.no,
              created_at: model.created_at
            });
          }
        });
      });
      if (koujiPart.length == 0) {
        alert("工事部位を選択してください");
        return;
      }

      //--営業担当
      var tantoGrp = [];
      this.eigyoTanto.forEach((element, index) => {
        var tanto = {};
        tanto.tanto_id = element;
        var hitTanto = this.tantoGrp.find((g) => g.tanto_id == tanto.tanto_id);
        if (hitTanto) {
          //営業担当グループに該当担当者あり
          tanto.created_at = hitTanto.created_at;
        } else {
          tanto.created_at = null;
        }
        tantoGrp.push(tanto);
      });

      //DB更新
      this.setInquiry(this.hikiai, koujiPart, tantoGrp);
    }
  },

  data: function(){
    return {
      cssconsts: cssconsts,
      mode: null,

      //select list
      kouji_type_list: [],
      tanto_list: [],
      area_list: [],
      inq_status_list: [],
      customer_list: [],
      customer_area_list: [],

      customer_area_select: {},   //選択した顧客・地区
      area_id: null,
      kouji_type_id: null,

      //引合情報
      hikiai: {},
      customer: {},
      cust_no_name: null,

      //営業担当
      tantoGrp: [],
      eigyoTanto: [],

      //工事部位
      kouji_part_list: [],
      kojiParts: [],

      //見積一覧
      estimateSortBy: "mitumori_no",
      estimateHeaders: [
        { text: '見積番号', value: 'mitumori_no', width: '80px', class: 'px-1' },
        { text: '見積日', value: 'mitumori_date', width: '100px', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: 'プラン名', value: 'plan_name', width: '200px', class: 'px-1' },
        { text: '見積額', value: 'pres_amt', align: 'end', width: '100px', class: 'px-1' },
        { text: '受注金額', value: 'ukeoi_amt', align: 'end', width: '100px', class: 'px-1' },
        { text: '見積担当', value: 'mtg_tanto_name', width: '100px', class: 'px-1' },
        { text: '', value: 'order', sortable: false, width: '100px', class: 'px-1' },
      ],
      mitumori: [],
      breads: [],

    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap{
   white-space: nowrap;
}

td.text-start,td.text-end{
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.right-input input {
  text-align: right;
}
</style>
