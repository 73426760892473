<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-form id="srch_form" v-model="valid" lazy-validation>
        <!-- 検索条件 -->
        <v-card
          color="green lighten-4"
          class="mt-0 mb-4 ml-4 mr-4"
          outlined
          elevation="3"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-text-box-search-outline
              </v-icon>
              検索条件
            </span>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4 mt-2">詳細検索</span>
            <v-switch
              v-model="dtl_srch"
              class="mt-1"
              color="primary"
              inset
              dense
              hide-details
              @change="dtl_srch_sw(dtl_srch)"
            ></v-switch>
            <v-btn
              class="mx-1"
              color="blue-grey darken-1"
              dark
              width="100"
              @click="srchMitumori"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              検索
            </v-btn>
            <v-btn
              class="mx-1"
              color="light-blue darken-1"
              dark
              width="100"
              @click="regMitumori"
            >
              新規登録
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-2"></v-divider>

          <!-- 1行目 -->
          <v-container mx-4 pa-0>
            <v-row no-gutters jastify>
              <v-col lg="1" md="2">
                <v-subheader class="pb-2 float-right">顧客</v-subheader>
              </v-col>
              <v-col lg="4" md="6">
                <v-autocomplete
                  v-model="customer_select"
                  :items="cust_name_list"
                  item-value="id"
                  item-text="name"
                  placeholder="顧客を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col lg="1" md="2">
                <v-subheader class="pl-0 pb-2 float-right">顧客種別</v-subheader>
              </v-col>
              <v-col lg="3" md="4">
                <div style="width: 200px">
                  <v-autocomplete
                    v-model="custtype_select"
                    :items="custtype_list"
                    item-value="id"
                    item-text="name"
                    placeholder="顧客種別を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col lg="1" md="1">
                <v-subheader class="pl-0 pb-2 float-right">ランク</v-subheader>
              </v-col>
              <v-col lg="4" md="5" class="pr-3">
                <v-autocomplete
                  v-model="custrank_select"
                  :items="custrank_list"
                  item-value="id"
                  item-text="name"
                  placeholder="ランクを選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>

          <template v-if="dtl_srch === true">
            <!-- 2行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">引合内容</v-subheader>
                </v-col>
                <v-col lg="4" md="10" class="pr-3">
                  <v-text-field
                    v-model="hikiai_naiyou"
                    placeholder="引合内容を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col lg="2" md="2">
                    <v-subheader class="pb-2 float-right">工事種別</v-subheader>
                </v-col>
                <v-col lg="4" md="5">
                  <v-autocomplete
                    v-model="koujitype_select"
                    :items="koujitype_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工事種別を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>

            <!-- 3行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">見積日</v-subheader>
                </v-col>
                <v-col lg="6" md="8">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-text-field 
                        v-model="date1" 
                        placeholder="日付(From)"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClick('dpick1')"
                        >
                        <template v-slot:append>
                            <date-picker ref="dpick1" v-model="date1"/>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-subheader class="px-0 pb-2">
                        <div class="mx-auto">～</div>
                      </v-subheader>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field 
                        v-model="date2" 
                        placeholder="日付(To)"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClick('dpick2')"
                        >
                        <template v-slot:append>
                          <date-picker ref="dpick2" v-model="date2"/>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col lg="auto" md="2">
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">工事名</v-subheader>
                </v-col>
                <v-col lg="4" md="10" class="pr-3">
                  <v-text-field
                    v-model="kouji_name"
                    placeholder="工事名を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <!-- 4行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">見積額</v-subheader>
                </v-col>
                <v-col lg="6" md="8">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <comma-number-field
                        v-model="mitumori_min"
                        class="right-input"
                        placeholder="金額(Min)を入力"
                        solo
                        clearable
                        dense
                        maxlength="12"
                        hide-details="auto"
                        :rules="[validRules.isDecimalCommaSigned]"
                      ></comma-number-field>
                    </v-col>
                    <v-col cols="1">
                      <v-subheader class="px-0 pb-2">
                        <div class="mx-auto">～</div>
                      </v-subheader>
                    </v-col>
                    <v-col cols="5" >
                      <comma-number-field
                        v-model="mitumori_max"
                        class="right-input"
                        placeholder="金額(Max)を入力"
                        solo
                        clearable
                        dense
                        maxlength="12"
                        hide-details="auto"
                        :rules="[validRules.isDecimalCommaSigned]"
                      ></comma-number-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>

            <!-- 5行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">営業担当</v-subheader>
                </v-col>
                <v-col lg="3" md="4" class="pr-3">
                <v-autocomplete
                  v-model="eigyo_tanto_select"
                  :items="tanto_name_list"
                  item-value="user_id"
                  item-text="user_name"
                  placeholder="営業担当を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">見積担当</v-subheader>
                </v-col>
                <v-col lg="3" md="4" class="pr-3">
                  <v-autocomplete
                    v-model="mitumori_tanto_select"
                    :items="tanto_name_list"
                    item-value="user_id"
                    item-text="user_name"
                    placeholder="見積担当を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">地区</v-subheader>
                </v-col>
                <v-col lg="3" md="4" class="pr-3">
                  <v-autocomplete
                    v-model="area_select"
                    :items="area_list"
                    item-value="id"
                    item-text="name"
                    placeholder="地区を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </template>

        </v-card>
      </v-form>

      <!-- 検索結果 -->
      <v-card
        color="lime lighten-4"
        class="ma-4"
        outlined
        elevation="3"
      >
        <div class="mt-2 ml-2">
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            検索結果
          </span>
        </div>
        <v-divider class="mt-1 mb-0"></v-divider>
        <v-flex class="mt-2 mb-4 mx-4">
          <v-data-table
            calculate-widths
            :height="estTableHeight"
            :headers="estimateHeaders"
            :items="mitumori_list"
            :items-per-page="10"
            :sort-by.sync="estimateSortBy"
            sort-desc
            class="elevation-1"
            locale="jp-ja"
            loading-text="読込中"
            no-data-text="該当するデータがありません。"
            @click:row="showConstrEst"
                  >
            <template v-slot:[`item.pres_amt`]="{ item }">
              {{ item.pres_amt.toLocaleString()}}
            </template>           
            <template v-slot:[`item.order`]="{ item }" >
              <v-btn
                color="blue lighten-4"
                :disabled="!item.keiyaku_no"
                @click.stop="showOrder(item.keiyaku_no)"
              >
                受注
              </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import CustomerTable from "./CustomerTable.vue";
import DatePicker from "./DatePicker.vue";
import dbconstrest from './js/common/dbconstrest.js'
//export
export default {
  components: {
    DatePicker,
    CustomerTable,
  },
  mixins: [dbconstrest],
  props:{
      "parameter": {},
  },
  created: function()
  {
    this.getSearchConditionList(); // 検索条件リスト一括取得
    this.getMitumoriList(); // 見積一覧
    this.m_createBreads();
  },

  computed:{
  },
  methods: {
    dtl_srch_sw(sw) {
      this.estTableHeight = (sw ? 380 : 500);
    },
    dateClick(dpickName) {
      //alert('Date Clicked!');
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    showConstrEst: function(event, row) {
      //「工事見積情報」画面へ遷移
      this.$emit('viewChange', 'ConstrEst', event.mitumori_no);
    },
    showOrder(event) {
      //「受注情報」画面へ遷移
      this.$emit('viewChange', 'Order', event, 5, 'eigyo');

    },
    srchMitumori()
    {
      // 検索ボタン
      if (!this.valid) {
        //条件入力エラーあり
        alert("検索条件の入力に間違いがあります");
        return;
      }
      var customer = this.customer_select
      var custtype = this.custtype_select
      var custrank = this.custrank_select
      var hikiai_naiyou = this.hikiai_naiyou
      var koujitype = this.koujitype_select
      var datefrom = this.date1
      var dateto = this.date2
      var kouji_name = this.kouji_name
      var mitumori_min = this.mitumori_min
      var mitumori_max = this.mitumori_max
      var eigyo_tanto = this.eigyo_tanto_select
      var mitumori_tanto = this.mitumori_tanto_select
      var area = this.area_select

      this.getMitumoriList(customer,custtype,custrank,hikiai_naiyou,koujitype,
        datefrom,dateto,kouji_name,mitumori_min,mitumori_max,eigyo_tanto,
        mitumori_tanto,area)
    },
    regMitumori() {
      //「工事見積登録・編集」画面へ遷移
      this.$emit('viewChange', 'ConstrEstEdit', null);
    },

    // 営業日報リスト取得
    getMitumoriList(customer,custtype,custrank,hikiai_naiyou,koujitype,
    datefrom,dateto,kouji_name,mitumori_min,mitumori_max,eigyo_tanto,
    mitumori_tanto,area)
    {
      // URL作成
      var uri = 'constr_est'
      var qparam = ''

      if (customer) qparam += 'customer=' + encodeURIComponent(customer) + '&'
      if (custtype) qparam += 'custtype=' + encodeURIComponent(custtype) + '&'
      if (custrank) qparam += 'custrank=' + encodeURIComponent(custrank) + '&'
      if (hikiai_naiyou) qparam += 'hikiai_naiyou=' + encodeURIComponent(hikiai_naiyou) + '&'
      if (koujitype) qparam += 'koujitype=' + encodeURIComponent(koujitype) + '&'
      if (datefrom) qparam += 'datefrom=' + encodeURIComponent(datefrom) + '&'
      if (dateto) qparam += 'dateto=' + encodeURIComponent(dateto) + '&'
      if (kouji_name) qparam += 'kouji_name=' + encodeURIComponent(kouji_name) + '&'
      if (mitumori_min) qparam += 'mitumori_min=' + encodeURIComponent(mitumori_min) + '&'
      if (mitumori_max) qparam += 'mitumori_max=' + encodeURIComponent(mitumori_max) + '&'
      if (eigyo_tanto) qparam += 'eigyo_tanto=' + encodeURIComponent(eigyo_tanto) + '&'
      if (mitumori_tanto) qparam += 'mitumori_tanto=' + encodeURIComponent(mitumori_tanto) + '&'
      if (area) qparam += 'area=' + encodeURIComponent(area) + '&'

      uri += '?' + qparam
      // データ取得
      axios.get(uri)
        .then(response => {
          this.mitumori_list = response.data;
        })
        .catch(
          // response => console.log(response)
        );
    },

  },

  data: function(){
    return {
      mitumori_list: [],
      cust_name_list: [],
      tanto_name_list: [],
      area_list: [],
      custtype_list: [],
      custrank_list: [],
      koujitype_list: [],

      customer_select: null,
      custtype_select: null,
      custrank_select: null,
      hikiai_naiyou: null,
      koujitype_select: null,
      date1: null,
      date2: null,
      kouji_name: null,
      mitumori_min: null,
      mitumori_max: null,
      eigyo_tanto_select: null,
      mitumori_tanto_select: null,
      area_select: null,

      dtl_srch: false,
      estTableHeight: 500,


      //見積一覧
      estimateSortBy: 'mitumori_no',
      estimateHeaders: [
        { text: '見積番号', value: 'mitumori_no', width: '100px', class: 'px-1' },
        { text: '顧客', value: 'cust_name', width: '150px', class: 'px-1' },
        { text: '引合内容', value: 'hikiai_naiyou', width: '200px', class: 'px-1' },
        { text: '工事種別', value: 'kouji_type_name', width: '120px', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: '見積日', value: 'mitumori_date', width: '100px', class: 'px-1' },
        { text: '見積額', value: 'pres_amt', align: 'end', width: '100px', class: 'px-1' },
        { text: '営業担当', value: 'hikiai_tanto_name', width: '100px', class: 'px-1' },
        { text: '見積担当', value: 'mitumori_tanto_name', width: '100px', class: 'px-1' },
        { text: '地区', value: 'area_name', width: '100px', class: 'px-1' },
        { text: '　　', value: 'order', sortable: false, width: '100px', class: 'px-1' },
      ],
      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>
<style>
.right-input input {
  text-align: right;
}
</style>
