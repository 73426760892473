<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card
        class="ma-4 outline-card"
        :color="cssconsts.edit_bg_color_base_01"
        outlined
        elevation="10"
        height="100%"
      >
        <v-card-actions>
          <div style="width: 200px">
            <v-icon>
              mdi-account-details
            </v-icon>
            顧客情報登録・編集
          </div>
          <v-spacer></v-spacer>
          <span class="text-body-2 mx-4">
            ※内容を変更した場合、登録ボタンを押してください
          </span>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="regCustomer"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            登録
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backPrevPage"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-1"></v-divider>
        <v-flex>
          <!-- 基本情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.edit_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">基本情報</div>
            </v-sheet>
              
            <v-form ref="basic_info" v-model="valid" lazy-validation>
              <v-row no-gutters class="mt-2 mr-2">
                <v-col  lg="2" md="2">
                  <v-subheader class="pb-2 float-right">顧客番号</v-subheader>
                </v-col>
                <v-col lg="2" md="2" >
                  <v-text-field v-if="mode === 'reg'"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                    value="新規登録"
                  >
                  </v-text-field>
                  <v-text-field v-else
                    v-model="customer.cust_no"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col  lg="2" md="2" >
                  <v-subheader class="pb-2 red--text float-right">顧客名</v-subheader>
                </v-col>
                <v-col  lg="6" md="6" >
                  <v-text-field
                    v-model="customer.cust_name"
                    placeholder="顧客名を入力"
                    solo
                    dense
                    clearable
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">顧客種別</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete
                    v-model="customer.cust_type"
                    :items="cust_type_list"
                    item-value="id"
                    item-text="name"
                    placeholder="顧客種別"
                    solo
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">フリガナ</v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="customer.cust_kana"
                    placeholder="フリガナを入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2 mb-2">
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 red--text float-right">現住所</v-subheader>
                </v-col>
                <v-col lg="3" md="3">
                  <div class="d-flex">
                    <span class="mt-2">
                      〒
                    </span>
                    <v-text-field
                      v-model="post_cd_input1"
                      class="mx-2"
                      solo
                      dense
                      maxlength="3"
                      hide-details="auto"
                      :rules="[validRules.required, validRules.isNumber, validRules.postCd3]"
                    ></v-text-field>
                    <span class="mt-2">
                      －
                    </span>
                    <v-text-field
                      v-model="post_cd_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.required, validRules.isNumber, validRules.postCd4]"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col lg="7" md="7">
                  <div class="mt-2 ml-2">
                    <v-btn
                      class="ml-2"
                      color="green darken-1"
                      small
                      dark
                      @click="convertPostalCode(1)"
                    >
                      〒 ⇔ 住所
                    </v-btn>
                    <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                      <input type="hidden" name="_token" :value="csrf">
                      <input type="hidden" name="mapdata" value="">
                    </form>
                    <v-btn
                      class="ml-1"
                      color="indigo darken-1"
                      small
                      dark
                      @click="showMap()"
                    >
                      <v-icon left>
                        mdi-map-marker
                      </v-icon>
                      地図
                    </v-btn>
                  </div>
                </v-col>
                <v-col offset='2' lg="10" md="10">
                  <v-text-field
                    v-model="customer.addr"
                    placeholder="住所を入力"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="customer.addr2"
                    class="mt-2"
                    placeholder="建物名などを入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">緯度</v-subheader>
                </v-col>
                <v-col lg="4" md="10">
                  <v-text-field
                    v-model="customer.addr_lat"
                    placeholder="緯度を入力"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.isDecimalSigned]"
                  ></v-text-field>
                </v-col>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">経度</v-subheader>
                </v-col>
                <v-col lg="4" md="10">
                  <v-text-field
                    v-model="customer.addr_lng"
                    placeholder="経度を入力"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.isDecimalSigned]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">地区</v-subheader>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="customer.area_id"
                    :items="area_list"
                    item-value="id"
                    item-text="name"
                    placeholder="地区を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">TEL</v-subheader>
                </v-col>
                <v-col  lg="4" md="4">
                  <div class="d-flex">
                    <v-text-field
                      v-model="tel_input1"
                      class="mr-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="tel_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="tel_input3"
                      class="ml-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">FAX</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <div class="d-flex">
                    <v-text-field
                      v-model="fax_input1"
                      class="mr-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="fax_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="fax_input3"
                      class="ml-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">携帯</v-subheader>
                </v-col>
                <v-col lg="4" md="4">
                  <div class="d-flex">
                    <v-text-field
                      v-model="cell_input1"
                      class="mr-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="cell_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="cell_input3"
                      class="ml-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col lg="0" md="6"/>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">Eﾒｰﾙ</v-subheader>
                </v-col>
                  <v-col lg="4" md="10">
                    <v-text-field
                      v-model="customer.email"
                      placeholder="Eメールアドレスを入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.email]"
                    ></v-text-field>
                  </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">生年月日</v-subheader>
                </v-col>
                <v-col lg="4" md="10">
                  <div class="d-flex">
                    <div style="width: 180px">
                      <v-text-field
                        v-model="customer.birth_date"
                        placeholder="日付を選択"
                        solo
                        readonly
                        clearable
                        dense
                        hide-details
                        @click="dateClick('bDate')"
                      >
                        <template v-slot:append>
                          <date-picker ref="bDate" v-model="customer.birth_date"/>
                        </template>
                      </v-text-field>
                    </div>
                    <span class="mt-2 ml-2">
                      年齢：
                    </span>
                    <span class="mt-2">
                      {{ownAge}}
                    </span>
                </div>
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">性別</v-subheader>
                </v-col>
                <v-col lg="2" md="4">
                  <v-autocomplete
                    v-model="customer.sex"
                    :items="sex"
                    item-value="id"
                    item-text="name"
                    placeholder="性別を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pb-2 float-right">ＤＭ</v-subheader>
                </v-col>
                <v-col lg="2" md="4">
                  <v-autocomplete
                    v-model="customer.dm_flg"
                    :items="dmConds"
                    item-value="id"
                    item-text="name"
                    placeholder="ＤＭを選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">備考</v-subheader>
                </v-col>
                <v-col cols="10">
                  <v-textarea
                    v-model="customer.bikou"
                    placeholder="備考を入力"
                    auto-grow
                    rows="1"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-sheet>
        </v-flex>

        <v-flex style="width: 100%">
          <!-- 勤務先情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.edit_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">勤務先情報</div>
            </v-sheet>

            <v-form ref="kinmusaki_info" v-model="valid" lazy-validation>
              <v-row no-gutters class="mt-2 mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">勤務先</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="customer.kinmusaki"
                    placeholder="勤務先を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">役職</v-subheader>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="customer.kinmu_post_name"
                    placeholder="役職を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">勤続年数</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model.number="customer.kinmu_years"
                    class="right-input"
                    placeholder="勤続年数"
                    solo
                    clearable
                    dense
                    maxlength="3"
                    hide-details="auto"
                    :rules="[validRules.isNumber]"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-subheader class="pb-2">年</v-subheader>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2 mb-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">勤務先住所</v-subheader>
                </v-col>
                <v-col cols="3">
                  <div class="d-flex">
                    <span class="mt-2">
                      〒
                    </span>
                    <v-text-field
                      v-model="kinmu_post_cd_input1"
                      class="mx-2"
                      solo
                      dense
                      maxlength="3"
                      hide-details="auto"
                      :rules="[validRules.isNumber, validRules.postCd3]"
                    ></v-text-field>
                    <span class="mt-2">
                      －
                    </span>
                    <v-text-field
                      v-model="kinmu_post_cd_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber, validRules.postCd4]"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="7">
                  <div class="mt-2 ml-2">
                    <v-btn
                      class="ml-2"
                      color="green darken-1"
                      small
                      dark
                      @click="convertPostalCode(2)"
                    >
                      〒 ⇔ 住所
                    </v-btn>
                  </div>
                </v-col>
                <v-col offset="2" cols="10">
                  <v-text-field
                    v-model="customer.kinmu_addr"
                    placeholder="勤務先住所を入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col offset="2" cols="10">
                  <v-text-field
                    v-model="customer.kinmu_addr2"
                    class="mt-2"
                    placeholder="建物名などを入力"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">TEL</v-subheader>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex">
                    <v-text-field
                      v-model="kinmu_tel_input1"
                      class="mr-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="kinmu_tel_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="kinmu_tel_input3"
                      class="ml-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="1">
                  <v-subheader class="pb-2 float-right">FAX</v-subheader>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex">
                    <v-text-field
                      v-model="kinmu_fax_input1"
                      class="mr-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="kinmu_fax_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                    <span class="mt-2">
                      -
                    </span>
                    <v-text-field
                      v-model="kinmu_fax_input3"
                      class="ml-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.isNumber]"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-sheet>
        </v-flex>

        <v-flex style="width: 100%">
          <!-- その他の情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.edit_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">その他の情報</div>
            </v-sheet>

            <v-form ref="other_info" v-model="valid" lazy-validation>
              <v-row no-gutters class="mt-2 mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">年収</v-subheader>
                </v-col>
                <v-col cols="2">
                  <comma-number-field
                    v-model.number="customer.own_income"
                    class="right-input"
                    :placeholder="$vuetify.breakpoint.smAndDown ? '年収' : '年収を入力'"
                    solo
                    clearable
                    dense
                    maxlength="8"
                    hide-details="auto"
                    :rules="[validRules.isNumberCommaSigned]"
                  ></comma-number-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2">万円</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">世帯年収</v-subheader>
                </v-col>
                <v-col cols="2">
                  <comma-number-field
                    v-model.number="customer.house_income"
                    class="right-input"
                    placeholder="世帯年収"
                    solo
                    clearable
                    dense
                    maxlength="8"
                    hide-details="auto"
                    :rules="[validRules.isNumberCommaSigned]"
                  ></comma-number-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2">万円</v-subheader>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">住居種別</v-subheader>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    v-model="customer.house_type"
                    :items="house_type_list"
                    item-value="id"
                    item-text="name"
                    :placeholder="$vuetify.breakpoint.smAndDown ? '住居種別' : '住居種別を選択'"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-subheader class="pb-2 float-right">居住／築年数</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model.number="customer.house_age"
                    class="right-input"
                    :placeholder="$vuetify.breakpoint.smAndDown ? '住築年数' : '居住／築年数'"
                    solo
                    clearable
                    dense
                    maxlength="4"
                    hide-details="auto"
                    :rules="[validRules.isNumber]"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2">年</v-subheader>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">家賃</v-subheader>
                </v-col>
                <v-col cols="2">
                  <comma-number-field
                    v-model.number="customer.rent_amt"
                    class="right-input"
                    :placeholder="$vuetify.breakpoint.smAndDown ? '家賃' : '家賃を入力'"
                    solo
                    clearable
                    dense
                    maxlength="8"
                    hide-details="auto"
                    :rules="[validRules.isNumberCommaSigned]"
                  ></comma-number-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2">円</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">土地所有</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete
                    v-model="customer.land_owner_flg"
                    :items="landOwn"
                    item-value="id"
                    item-text="name"
                    :placeholder="$vuetify.breakpoint.smAndDown ? '所有' : '土地所有'"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-divider class="mt-0 mb-1"></v-divider>

              <v-row no-gutters class="mt-2 mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">顧客担当</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="custTanto"
                    :items="tanto_list"
                    item-value="id"
                    item-text="name"
                    placeholder="顧客担当を選択"
                    multiple chips deletable-chips
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-sheet>
        </v-flex>
        <v-flex class="d-flex">
          <!-- 家族情報 -->
          <v-sheet
            class="mx-2 mt-0 mb-2 pb-2"
            color="white"
            outlined
            rounded
            style="width: 100%"
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.edit_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">家族情報</div>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-1 my-1"
                color="cyan darken-1"
                dark
                small
                @click="addFam()"
              >
                <v-icon left>
                  mdi-plus-circle
                </v-icon>
                追加
              </v-btn>
            </v-sheet>
            <v-form ref="family_info" v-model="valid" lazy-validation>
              <template v-for="(fam_member, index) in family" >
                <v-row no-gutters class="mt-2 mx-2" :key="'row'+index">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right red-text">名前</v-subheader>
                  </v-col>
                  <v-col cols="4" class="pr-2">
                    <v-text-field :key="'name'+index"
                      v-model="fam_member.fm_name"
                      placeholder="名前を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right red-text">続柄</v-subheader>
                  </v-col>
                  <v-col cols="3" class="pr-2">
                      <div>
                        <v-autocomplete :key="'relation'+index"
                          v-model="fam_member.relation"
                          :items="relation_list"
                          item-value="id"
                          item-text="name"
                          placeholder="続柄を選択"
                          solo
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                        ></v-autocomplete>
                      </div>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="my-auto mx-1 float-right"
                      color="red darken-1"
                      x-small
                      fab
                      dark
                      elevation="2"
                      @click="deleteFam(index)"
                    >
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">生年月日</v-subheader>
                  </v-col>
                  <v-col lg="4" md="5">
                    <div class="d-flex">
                      <div style="width: 180px">
                        <v-text-field :key="'fbDate'+index"
                          v-model="fam_member.birth_date"
                          placeholder="日付を選択"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="dateClick('fbDate', index)"
                        >
                          <template v-slot:append>
                            <date-picker ref="fbDate" v-model="fam_member.birth_date"/>
                          </template>
                        </v-text-field>
                      </div>
                      <span class="mt-2 ml-2">
                        年齢：
                      </span>
                      <span class="mt-2">
                        {{famAge(index)}}
                      </span>
                    </div>
                  </v-col>
                  <v-col lg="auto" md="5">
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">勤務先／学校</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field :key="'kinmusaki'+index"
                      v-model="fam_member.kinmusaki"
                      placeholder="勤務先／学校を入力"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">年収</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <div class="d-flex">
                      <span>
                        <comma-number-field :key="'income'+index"
                          v-model.number="fam_member.own_income"
                          class="right-input"
                          placeholder="年収を入力"
                          solo
                          clearable
                          dense
                          maxlength="8"
                          hide-details="auto"
                          :rules="[validRules.isNumberCommaSigned]"
                        ></comma-number-field>
                      </span>
                      <span class="pl-2 pt-2 nowrap">
                        万円
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="my-0" :key="'div'+index"></v-divider>
              </template>
            </v-form>
          </v-sheet>
        </v-flex>
        <v-divider class="mt-0 mb-1"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="text-body-2 mx-4">
            ※内容を変更した場合、登録ボタンを押してください
          </span>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="regCustomer"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            登録
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backPrevPage"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import DatePicker from "./DatePicker.vue";
import cssconsts from './js/common/cssconsts';
import dbcustomer from './js/common/dbcustomer.js'
import gmap from './js/common/gmap.js'
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbcustomer, gmap],
  
  props:{
      "parameter": {},
  },
  created: function() {
    window.document.addEventListener("setlatlng", this.setlatlngHandler); // 緯度経度更新用
    this.getIdNameList("custType");  // 顧客種別リスト取得
    this.getIdNameList("tanto");     // 営業担当者リスト取得
    this.getIdNameList("area");      // 地区リスト取得
    this.getIdNameList("relation");  // 続柄リスト取得
    this.getIdNameList("houseType"); // 住居種別リスト取得

    // 顧客番号がthis.parameter.viewparamとして引き渡されてくる
    if (this.parameter.viewparam) {
      var cust_no = this.parameter.viewparam;
      //alert("cust_no：" + cust_no);
      //編集
      this.mode = "edit";
      // 顧客番号が一致するデータを読み出す
      //以下に格納
      //⇒this.customer
      //⇒this.family
      //⇒this.tantoGrp
      this.getCustomer(cust_no);
    } else {
      //新規登録
      this.mode = "reg";
    }
  },
  mounted: function() {
    //エラーをリセット
    this.$refs.basic_info.resetValidation();
    this.$refs.family_info.resetValidation();
    this.$refs.other_info.resetValidation();
    this.$refs.kinmusaki_info.resetValidation();
  },
  computed: {
    ownAge: function () {
      return this.calcAge(this.customer.birth_date);
    },
    famAge: function () {
      return function(index) {
        return this.calcAge(this.family[index].birth_date);
      }
    },
    cust_post_cd: {
      get: function() {
        var post_cd = "";
        post_cd += (this.post_cd_input1 ? this.post_cd_input1 : "");
        post_cd += (this.post_cd_input2 ? this.post_cd_input2 : "");
        return post_cd;
      },
      set: function(post_cd) {
        if (post_cd != null) {
          this.post_cd_input1 = post_cd.substr(0, 3);
          this.post_cd_input2 = post_cd.substr(3, 4);
        }
      }
    },
    cust_kinmu_post_cd: {
      get: function() {
        var post_cd = "";
        post_cd += (this.kinmu_post_cd_input1 ? this.kinmu_post_cd_input1 : "");
        post_cd += (this.kinmu_post_cd_input2 ? this.kinmu_post_cd_input2 : "");
        return post_cd;
      },
      set: function(post_cd) {
        if (post_cd != null) {
          this.kinmu_post_cd_input1 = post_cd.substr(0, 3);
          this.kinmu_post_cd_input2 = post_cd.substr(3, 4);
        }
      }
    },
    cust_tel_no: {
      get: function() {
        var telNo = "";
        telNo += (this.tel_input1 ? this.tel_input1 : "");
        telNo += (this.tel_input2 ? `-${this.tel_input2}` : "");
        telNo += (this.tel_input3 ? `-${this.tel_input3}` : "");
        return telNo;
      },
      set: function(tel_no) {
        if (tel_no != null) {
          var telNos = tel_no.split("-");
          this.tel_input1 = telNos[0];
          this.tel_input2 = telNos[1];
          this.tel_input3 = telNos[2];
        }
      }
    },
    cust_fax_no: {
      get: function() {
        var faxNo = "";
        faxNo += (this.fax_input1 ? this.fax_input1 : "");
        faxNo += (this.fax_input2 ? `-${this.fax_input2}` : "");
        faxNo += (this.fax_input3 ? `-${this.fax_input3}` : "");
        return faxNo;
      },
      set: function(fax_no) {
        if (fax_no != null) {
          var faxNos = fax_no.split("-");
          this.fax_input1 = faxNos[0];
          this.fax_input2 = faxNos[1];
          this.fax_input3 = faxNos[2];
        }
      }
    },
    cust_cell_no: {
      get: function() {
        var cellNo = "";
        cellNo += (this.cell_input1 ? this.cell_input1 : "");
        cellNo += (this.cell_input2 ? `-${this.cell_input2}` : "");
        cellNo += (this.cell_input3 ? `-${this.cell_input3}` : "");
        return cellNo;
      },
      set: function(cell_no) {
        if (cell_no != null) {
          var cellNos = cell_no.split("-");
          this.cell_input1 = cellNos[0];
          this.cell_input2 = cellNos[1];
          this.cell_input3 = cellNos[2];
        }
      }
    },
    cust_kinmu_tel_no: {
      get: function() {
        var telNo = "";
        telNo += (this.kinmu_tel_input1 ? this.kinmu_tel_input1 : "");
        telNo += (this.kinmu_tel_input2 ? `-${this.kinmu_tel_input2}` : "");
        telNo += (this.kinmu_tel_input3 ? `-${this.kinmu_tel_input3}` : "");
        return telNo;
      },
      set: function(tel_no) {
        if (tel_no != null) {
          var telNos = tel_no.split("-");
          this.kinmu_tel_input1 = telNos[0];
          this.kinmu_tel_input2 = telNos[1];
          this.kinmu_tel_input3 = telNos[2];
        }
      }
    },
    cust_kinmu_fax_no: {
      get: function() {
        var faxNo = "";
        faxNo += (this.kinmu_fax_input1 ? this.kinmu_fax_input1 : "");
        faxNo += (this.kinmu_fax_input2 ? `-${this.kinmu_fax_input2}` : "");
        faxNo += (this.kinmu_fax_input3 ? `-${this.kinmu_fax_input3}` : "");
        return faxNo;
      },
      set: function(fax_no) {
        if (fax_no != null) {
          var faxNos = fax_no.split("-");
          this.kinmu_fax_input1 = faxNos[0];
          this.kinmu_fax_input2 = faxNos[1];
          this.kinmu_fax_input3 = faxNos[2];
        }
      }
    },

    family_len: function() {
      return this.family.length;
    },
  },
  watch: {
    customer: function(data) {
      //alert("customer changed");
      //データを分割して表示
      this.cust_post_cd = data.post_cd;
      this.cust_tel_no = data.tel_no;
      this.cust_fax_no = data.fax_no;
      this.cust_cell_no = data.cell_no;
      this.cust_kinmu_post_cd = data.kinmu_post_cd;
      this.cust_kinmu_tel_no = data.kinmu_tel_no;
      this.cust_kinmu_fax_no = data.kinmu_fax_no;
    },
    tantoGrp: function(data) {
      this.custTanto = [];
      for (var elem of data) {
        this.custTanto.push(elem.tanto_id)
      }
    }
  },
  methods: {
    backPrevPage() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    dateClick(dpickName, index = null) {
      //DatePicker を開く
      //年を先に表示させるように変更
      if (index == null) {
        this.$refs[dpickName].changePicker('YEAR');
        this.$refs[dpickName].menu = true;
      } else {
        //v-forで生成したDatePicker用
        this.$refs[dpickName][index].changePicker('YEAR');
        this.$refs[dpickName][index].menu = true;
      }
    },
    calcAge(birthDate) {
      var age = null;
      if (birthDate != null) {
        //誕生日から年月日を分解
        var [byear, bmonth, bday] = birthDate.split('/');
        //今日の日付から年月日を取得
        var today = new Date();
        var y = today.getFullYear().toString().padStart(4, '0');
        var m = (today.getMonth() + 1).toString().padStart(2, '0');
        var d = today.getDate().toString().padStart(2, '0');
        //年齢を算出
        age = Math.floor((Number(y + m + d) - Number(byear + bmonth + bday)) / 10000);
      }
      return age;
    },
    convertPostalCode(addr_sel)
    {
      if (addr_sel == 1)
      {
        // 現住所
        var postalcode = ((this.post_cd_input1!=null)?this.post_cd_input1:'') + ((this.post_cd_input2!=null)?this.post_cd_input2:'')
        var searchaddress = ((this.customer.addr!=null)?this.customer.addr:'') + ((this.customer.addr2!=null)?this.customer.addr2:'')
        this.searchPostalcodeAddress(postalcode+searchaddress,(newaddr,newpostcd)=>{
          this.$set(this.customer, 'addr', newaddr)
          this.$set(this.customer, 'addr_lat', null)
          this.$set(this.customer, 'addr_lng', null)
          this.post_cd_input1 = newpostcd.substring(0,3)
          this.post_cd_input2 = newpostcd.substring(3,7)
        });
        this.customer.addr2 = '';
      }
      else if (addr_sel == 2)
      {
        // 勤務先住所
        var postalcode = ((this.kinmu_post_cd_input1!=null)?this.kinmu_post_cd_input1:'') + ((this.kinmu_post_cd_input2!=null)?this.kinmu_post_cd_input2:'')
        var searchaddress = ((this.customer.kinmu_addr!=null)?this.customer.kinmu_addr:'') + ((this.customer.kinmu_addr2!=null)?this.customer.kinmu_addr2:'')
        this.searchPostalcodeAddress(postalcode+searchaddress,(newaddr,newpostcd)=>{
          this.$set(this.customer, 'kinmu_addr', newaddr)
          this.kinmu_post_cd_input1 = newpostcd.substring(0,3)
          this.kinmu_post_cd_input2 = newpostcd.substring(3,7)
        });
        this.customer.kinmu_addr2 = '';
      }

    },
    showMap() {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）
      // 顧客住所
      var cust_addr = ((this.customer.addr!=null)?this.customer.addr:'') + ((this.customer.addr2!=null)?this.customer.addr2:'');
      // 地図表示情報
      this.sendMapInfo(cust_addr);
    },
    // 地図表示情報
    sendMapInfo(cust_addr) {
      var title_juusyo = '現住所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var lat_juusyo = this.customer.addr_lat;
      var lng_juusyo = this.customer.addr_lng;
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      i_data.value = JSON.stringify({
        'view_name': this.open_view_name,
        'cust_no': (this.customer && this.customer.cust_no)?this.customer.cust_no:'',
        'cust_name': (this.customer && this.customer.cust_name)?this.customer.cust_name:'',
        'markercount': 1,
        'editindex': 0,
        'markertitle': [title_juusyo],
        'markeraddress': [cust_addr],
        'markerlat': [lat_juusyo],
        'markerlng': [lng_juusyo],
      });
      postform.submit();

    },
    setlatlngHandler(e)
    {
      if (this.open_view_name == e.detail.view_name)
      {
        if (e.detail.title == '現住所')
        {
          this.$set(this.customer, 'addr_lat', e.detail.lat)
          this.$set(this.customer, 'addr_lng', e.detail.lng)
        }
      }
    },
    regCustomer() {
      //入力チェック
      //--基本情報
      this.valid = this.$refs.basic_info.validate();
      if (!this.valid) {
        alert("基本情報に入力不足、または間違いがあります");
        return;
      }
      //--勤務先情報
      this.valid = this.$refs.kinmusaki_info.validate();
      if (!this.valid) {
        alert("勤務先情報に入力間違いがあります");
        return;
      }
      //--その他の情報
      this.valid = this.$refs.other_info.validate();
      if (!this.valid) {
        alert("その他の情報に入力間違いがあります");
        return;
      }
      //--家族情報
      this.valid = this.$refs.family_info.validate();
      if (!this.valid) {
        alert("家族情報に入力不足、または間違いがあります");
        return;
      }

      //DB更新
      var customer = {};
      var family = [];
      var tantoGrp = [];

      if (this.mode == "edit") {
        customer.cust_no = this.customer.cust_no;
      }
      customer.cust_type = this.customer.cust_type;
      customer.cust_name = this.customer.cust_name;
      customer.cust_kana = this.customer.cust_kana;
      customer.post_cd = this.cust_post_cd
      customer.addr = this.customer.addr;
      customer.addr2 = this.customer.addr2;
      customer.addr_lat = this.customer.addr_lat;
      customer.addr_lng = this.customer.addr_lng;
      customer.area_id = this.customer.area_id;
      customer.tel_no = this.cust_tel_no;
      customer.fax_no = this.cust_fax_no;
      customer.cell_no = this.cust_cell_no;
      customer.email = this.customer.email;
      customer.birth_date = this.customer.birth_date;
      customer.sex = this.customer.sex;
      customer.dm_flg = this.customer.dm_flg;
      customer.bikou = this.customer.bikou;
      customer.kinmusaki = this.customer.kinmusaki;
      customer.kinmu_post_name = this.customer.kinmu_post_name;
      customer.kinmu_years = this.customer.kinmu_years;
      customer.kinmu_post_cd = this.cust_kinmu_post_cd;
      customer.kinmu_addr = this.customer.kinmu_addr;
      customer.kinmu_addr2 = this.customer.kinmu_addr2;
      customer.kinmu_tel_no = this.cust_kinmu_tel_no;
      customer.kinmu_fax_no = this.cust_kinmu_fax_no;
      customer.own_income = this.customer.own_income;
      customer.house_income = this.customer.house_income;
      customer.house_type = this.customer.house_type;
      customer.house_age = this.customer.house_age;
      customer.rent_amt = this.customer.rent_amt;
      customer.land_owner_flg = this.customer.land_owner_flg;
      customer.created_at = this.customer.created_at;

      this.family.forEach(element => {
        var fam_member = {};
        fam_member.fm_name = element.fm_name;
        fam_member.relation = element.relation;
        fam_member.birth_date = element.birth_date;
        fam_member.kinmusaki = element.kinmusaki;
        fam_member.own_income = element.own_income;
        fam_member.created_at = element.created_at;
        family.push(fam_member);
      });

      this.custTanto.forEach((element, index) => {
        var tanto = {};
        tanto.tanto_id = element;
        var hitTanto = this.tantoGrp.find((g) => g.tanto_id == tanto.tanto_id);
        if (hitTanto) {
          //顧客担当グループに該当担当者あり
          tanto.created_at = hitTanto.created_at;
        } else {
          tanto.created_at = null;
        }
        tantoGrp.push(tanto);
      });

      this.setCustomer(customer, family, tantoGrp);
    },

    addFam() {
      // 明細追加
      this.family.push(
        {
          fm_name: null,
          relation: null,
          birth_date: null,
          kinmusaki: null,
          own_income: null,
          created_at: null,
        }
      );
    },
    deleteFam(index){
      // 明細削除
      if (index < this.family_len)
      {
        this.family.splice(index, 1);
        this.family_msg.splice(index, 1);
      }
    },

  },

  data: function() {
    return {
      cssconsts: cssconsts,
      mode: null,
      //select list
      cust_type_list: [],
      tanto_list: [],
      area_list: [],
      relation_list: [],
      house_type_list: [],

      //顧客
      customer: {
        cust_no: null,
        cust_type: null,
        cust_name: null,
        cust_kana: null,
        post_cd: null,
        addr: null,
        addr2: null,
        area_id: null,
        tel_no: null,
        fax_no: null,
        cell_no: null,
        email: null,
        birth_date: null,
        sex: null,
        dm_flg: null,
        bikou: null,
        kinmusaki: null,
        kinmu_post_name: null,
        kinmu_years: null,
        kinmu_post_cd: null,
        kinmu_addr: null,
        kinmu_addr2: null,
        kinmu_tel_no: null,
        kinmu_fax_no: null,
        own_income: null,
        house_income: null,
        house_type: null,
        house_age: null,
        rent_amt: null,
        land_owner_flg: null,
        created_at: null,
      },
      post_cd_input1: null,
      post_cd_input2: null,
      tel_input1: null,
      tel_input2: null,
      tel_input3: null,
      fax_input1: null,
      fax_input2: null,
      fax_input3: null,
      cell_input1: null,
      cell_input2: null,
      cell_input3: null,
      kinmu_post_cd_input1: null,
      kinmu_post_cd_input2: null,
      kinmu_tel_input1: null,
      kinmu_tel_input2: null,
      kinmu_tel_input3: null,
      kinmu_fax_input1: null,
      kinmu_fax_input2: null,
      kinmu_fax_input3: null,
      custTanto: [],

      //家族情報
      family: [],

      //顧客担当
      tantoGrp: [],

      //性別
      sex: [
        { id: '1', name: '男' },
        { id: '2', name: '女' },
      ],
      //DM対象
      dmConds: [
        { id: '1', name: '対象' },
        { id: '0', name: '対象外' },
      ],
      //土地所有
      landOwn: [
        { id: '0', name: 'なし' },
        { id: '1', name: 'あり' },
      ],

    }
  }
};
</script>
<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap{
   white-space: nowrap;
}
.right-input input {
  text-align: right;
}
</style>