<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)"
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-form id="srch_form">
        <!-- 検索条件 -->
        <v-card
          :color="cssconsts.search_keys_bg_color_01"
          class="ma-4"
          outlined
          elevation="10"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-text-box-search-outline
              </v-icon>
              検索条件
            </span>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4 mt-2">詳細検索</span>
            <v-switch
              v-model="dtl_srch"
              class="mt-1"
              color="primary"
              inset
              dense
              hide-details
            ></v-switch>
            <v-btn
              class="mx-1"
              color="blue-grey darken-1"
              dark
              width="100"
              @click="srchAlert"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              検索
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-2"></v-divider>

          <!-- 1行目 -->
          <v-container mx-4 pa-0>
            <v-row no-gutters>
              <v-col lg="2" md="2">
                <v-subheader class="pb-2 float-right">アラート種類</v-subheader>
              </v-col>
              <v-col lg="3" md="4">
                <v-autocomplete
                  v-model="alert_kind"
                  :items="alert_type_list"
                  item-value="id"
                  item-text="name"
                  placeholder="アラート種類を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col lg="1" md="1">
                <v-subheader class="pb-2 float-right">顧客</v-subheader>
              </v-col>
              <v-col lg="4" md="5" class="pr-3">
                <v-autocomplete
                  v-model="cust_no"
                  :items="cust_name_list"
                  item-value="id"
                  item-text="name"
                  placeholder="顧客を選択"
                  solo
                  clearable
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>

          <template v-if="dtl_srch === true">
            <!-- 2行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">工事種別</v-subheader>
                </v-col>
                <v-col lg="4" md="5" class="pr-3">
                  <v-autocomplete
                    v-model="kouji_type"
                    :items="kouji_type_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工事種別を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col lg="1" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">対応状況</v-subheader>
                </v-col>
                <v-col lg="2" md="3">
                  <v-checkbox
                    v-model="sumi_flg"
                    class="mt-1"
                    color="blue darken-1"
                    hide-details
                  >
                    <template v-slot:label>
                      <span>
                        対応済を含む
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>

            <!-- 3行目 -->
            <v-container mx-4 pa-0>
              <v-row no-gutters>
                <v-col lg="2" md="2">
                  <v-subheader class="pl-0 pb-2 float-right">
                    <span v-if="$vuetify.breakpoint.smAndDown">
                      アフター・<br>メンテ種別
                    </span>
                    <span v-else>
                      アフター・メンテ種別
                    </span>
                  </v-subheader>
                </v-col>
                <v-col lg="3" md="4" class="d-flex">
                  <v-checkbox
                    v-model="mainte_flg"
                    class="mt-1 mr-4"
                    color="blue darken-1"
                    hide-details
                  >
                    <template v-slot:label>
                      <span>
                        メンテナンス
                      </span>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    v-model="tenken_flg"
                    class="mt-1"
                    color="blue darken-1"
                    hide-details
                  >
                    <template v-slot:label>
                      <span>
                        点検
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">入金予定日</v-subheader>
                </v-col>
                <v-col lg="3" md="4" class="d-flex">
                  <div style="width: 180px">
                    <v-text-field 
                      v-model="nyukin_yotei_date" 
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('dpick1')"
                    >
                      <template v-slot:append>
                        <date-picker ref="dpick1" v-model="nyukin_yotei_date"/>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-card>
      </v-form>

      <!-- 検索結果 -->
      <v-card
        color="lime lighten-4"
        class="ma-4"
        outlined
        elevation="3"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            検索結果
          </span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            color="blue darken-1"
            dark
            small
            width="160"
            elevation="1"
            @click="changeTaio"
          >
            <v-icon left>
              mdi-reload
            </v-icon>
            対応済み切り替え
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-0"></v-divider>
        <v-flex class="mt-2 mb-4 mx-4">
          <v-data-table
            v-model="selected"
            calculate-widths
            :headers="alertHeaders"
            :items="alert_list"
            :items-per-page="10"
            show-select
            item-key="alert_no"
            class="elevation-1"
            locale="jp-ja"
            loading-text="読込中"
            no-data-text="該当するデータがありません。"
          >
            <template v-slot:[`header.after_mainte_type`]="{ header }">
              <span>{{ header.text }}<br>メンテナンス</span>
            </template>
          </v-data-table>
        </v-flex>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import DatePicker from "./DatePicker.vue";
import dbsystem from './js/common/dbsystem.js'
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbsystem],
  created : function(){
    // 検索用リスト取得
    this.getSearchConditionList();
    
    // アラート一覧取得
    this.getAlertList();
  },
  mounted() {
  },
  props:{
      "parameter": {},
  },
  methods: {
    dateClick(dpickName) {
      //alert('Date Clicked!');
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    srchAlert() {
      // アラート検索
      var alert_kind = this.alert_kind;
      var cust_no = this.cust_no;
      var sumi_flg = this.sumi_flg;
      var kouji_type = this.kouji_type;
      var mainte_flg = this.mainte_flg;
      var tenken_flg = this.tenken_flg;
      
      var nyukin_yotei_date = this.nyukin_yotei_date;

      this.getAlertList(alert_kind,cust_no,sumi_flg,kouji_type,mainte_flg,tenken_flg,nyukin_yotei_date);
    },
    changeTaio(){
      var target_array = [];
      if(this.selected.length == 0){
        alert("切り替え対象を選択してください。");
        return;
      }
      for (const row of this.selected) {
        if (row.alert_no) {
            target_array.push(row.alert_no);
        }
      }
      // URL作成
      var uri = 'system_alert'
      // データ格納(store)
      axios.post(uri, {'target_array':target_array})
        .then(response => {
          // console.log(response.data);
          this.srchAlert();  // 成功したら一覧更新
          this.selected = []; // チェックボックスクリア
        })
        .catch((error) => {
          // alert(error); 
        });
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,
      nyukinYotei: null,
      dtl_srch: false,
      
      alert_kind: null,
      cust_no: null,
      sumi_flg: null,
      kouji_type: null,
      mainte_flg: true,
      tenken_flg: true,
      nyukin_yotei_date: null,
      
      //date1: null,
      
      alert_type_list:[],
      kouji_type_list:[],
      cust_name_list:[],
      
      alert_list:[],
      selected: [],

      //alertTableHeight: 500,

      height: 500,
      //アラート一覧
      alertHeaders: [
        { text: '対応', value: 'taiou_short', width: '50', class: 'px-1' },
        { text: 'アラート種類', value: 'alert_type', width: '120', class: 'px-1' },
        { text: '顧客', value: 'cust_name', width: '150', class: 'px-1' },
        { text: '工事種別', value: 'kouji_type_name', width: '120', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '150', class: 'px-1' },
        { text: 'アフター・', value: 'after_mainte_type', width: '120', class: 'px-1' },
        { text: 'アラート内容', value: 'alert_naiyo', width: '220', class: 'px-1' },
      ],

    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
  background-image: url("../../images/bg02.jpg");
  background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

td.text-start,td.text-end {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.right-input input {
  text-align: right;
}
</style>