<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
        <v-card 
          color="rgb(255, 0, 0, 0)" 
          class="outline-card ma-0 pa-0"
          elevation="0"
          height="100%"
        >
          <v-card
            color="rgb(255, 0, 0, 0)" 
            elevation="0"
            class="ma-0 pa-0"
          >
            <v-breadcrumbs :items="breads"></v-breadcrumbs>
          </v-card>
          <v-card
            class="mt-0 mb-4 ml-4 mr-4"
            :color="cssconsts.eigyo_bg_color_base_01"
            outlined
            elevation="10"
          >
            <v-card-actions>
              <span>
                <v-icon>
                  mdi-home-search-outline
                </v-icon>
                点検情報
              </span>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-1"
                color="indigo"
                dark
                width="100"
                @click="editInspection"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                編集
              </v-btn>
              <v-btn
                class="mx-1"
                color="orange darken-3"
                dark
                width="100"
                @click="delInspection"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                削除
              </v-btn>
              <v-btn
                class="mx-1"
                color="grey darken-1"
                dark
                width="100"
                @click="backInspectList"
              >
                <v-icon left>
                  mdi-keyboard-return
                </v-icon>
                戻る
              </v-btn>
            </v-card-actions>
            <v-divider class="mt-0 mb-1"></v-divider>
            <v-flex>
              <!-- 顧客・工事 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{inspection.cust_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-0 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事名</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{inspection.kouji_name}}
                    </span>
                  </v-col>
                </v-row>
              </v-sheet>

              <!-- 点検対象 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">点検対象</div>
                </v-sheet>
                  
                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">名称</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{inspection.insp_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-0 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">点検内容</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div v-html="insp_naiyou"></div>
                  </v-col>
                </v-row>
              </v-sheet>

              <!-- 点検予定 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">点検予定</div>
                </v-sheet>

                <v-row no-gutters class="mt-2 mb-0 mx-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-2 my-auto text-body-2">点検種別</span>
                  </v-col>
                  <v-col cols="5" class="d-flex">
                    <div class="ml-2" style="width: 90px">
                      <span class="my-auto text-body-2">予定日</span>
                    </div>
                    <div class="ml-2" style="width: 90px">
                      <span class="my-auto text-body-2">実施日</span>
                    </div>
                    <div class="ml-2">
                      <span class="my-auto text-body-2">実施担当</span>
                    </div>
                  </v-col>
                  <v-col cols="5" class="d-flex">
                    <span class="ml-2 my-auto text-body-2">対応内容</span>
                  </v-col>
                </v-row>
                <v-divider class="my-0"></v-divider>

                <v-row
                  v-for="(meisai, index) in inspect_yotei"
                  :key="index"
                  no-gutters class="mt-2 mb-0 mx-2"
                >
                  <v-col cols="2" class="yellow lighten-4">
                    <div class="ml-2">
                      <span>{{meisai.insp_type}}</span>
                    </div>
                  </v-col>
                  <v-col cols="5" class="d-flex yellow lighten-4">
                    <div class="ml-2" style="width: 90px">
                      <span>{{meisai.yotei_date}}</span>
                    </div>
                    <div class="ml-2" style="width: 90px">
                      <span>{{meisai.jissi_date}}</span>
                    </div>
                    <div class="ml-2">
                      <span>{{meisai.tanto_name}}</span>
                    </div>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <div class="ml-2" v-html="inspect_taiou[index]"></div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-flex>
          </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import dbinspection from './js/common/dbinspection.js'
//export
export default {
  mixins: [dbinspection],

  props:{
      "parameter": {},
  },

  created: function() {
    //点検番号をthis.parameter.viewparamから取得
    var insp_no = this.parameter.viewparam;
    //契約番号が一致するデータを読み出す
    //以下に格納
    //⇒this.inspection
    //⇒this.inspect_yotei
    this.getInspectionDetail(insp_no);
    this.m_createBreads();
  },
  computed: {
  },

  watch: {
    inspection: function(data) {
      if (data.insp_naiyou != null) {
        //点検内容の改行変換
        this.insp_naiyou = data.insp_naiyou.replace("\n", "<br>");
      }
    },
    inspect_yotei: function(data) {
      for (var meisai of data) {
        var taiou = "";
        if (meisai.taiou != null) {
          taiou = meisai.taiou.replace("\n", "<br>");
        }
        this.inspect_taiou.push(taiou);
      }
    }
  },

  methods: {
    backInspectList() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    editInspection() {
      var param = {
        insp_no: this.inspection.insp_no,
      };
      //「点検登録・編集」画面へ遷移
      this.$emit('viewChange', 'InspectEdit', param);
    },
    delInspection() {
      // 点検削除
      var res = confirm('当点検を削除します。よろしいですか？');
      if (res == true) {
        this.deleteInspection(this.inspection.insp_no);
      }
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,
      //点検情報
      inspection: {},
      //点検予定
      inspect_yotei: [],
      inspect_taiou: [],

      //編集項目
      insp_naiyou: "",
      breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap{
   white-space: nowrap;
}
</style>
