export default {
    methods: {
        dateFormatGet(date) {
            if (date)
            {
                //yyyy/mm/dd から yyyy-mm-dd に変換
                const [year, month, day] = date.split('/');
                return `${year}-${month}-${day}`;
            }
        },
        dateFormatSet(date) {
            if (date)
            {
                //yyyy-mm-dd から yyyy/mm/dd に変換
                const [year, month, day] = date.split('-');
                return `${year}/${month}/${day}`;
            }
        },
        timeFormatHHMM(time){
            if (time)
            {
                //hhhh:mm:ss から hhhh:mm に変換
                const [hour, minute, second] = time.split(':');
                return `${hour}:${minute}`;
            }
        },
        // 担当一覧取得
        getUserDetails() {
            axios.get('system/usercsv/list')
                .then(response => {
                    this.userDetails = response.data;
                    //console.log(response.data);
                })
                .catch(
                    // response => console.log(response)
                );
        },
        regTantoCsv() {

        },
        // 工事種別名リスト取得
        getGroupDetails() {
            axios.get('system/unicsv/list?id=' + encodeURIComponent(this.groupId))
                .then(response => {
                    this.groupDetails = response.data;
                    //console.log(response.data);
                })
                .catch(
                    // response => console.log(response)
                );
        },
        downloadUniCsv(){
            //念のため最新化
            this.getGroupDetails();
            let csv = '\ufeff' + 'グループID,ID,親ID,表示順,表示名称1,表示名称2,表示用コード,ステータス\n'
            this.groupDetails.forEach(el => {
                var line = 
                    (el['grp_id'] == null ? ',' : el['grp_id'] + ',') +
                    (el['rec_id'] == null ? ',' : el['rec_id'] + ',') + 
                    (el['parent_id'] == null ? ',' : el['parent_id'] + ',') + 
                    (el['disp_order'] == null ? ',' : el['disp_order'] + ',') + 
                    (el['disp_name1'] == null ? ',' : el['disp_name1'] + ',') + 
                    (el['disp_name2']  == null ? ',' : el['disp_name2'] + ',') + 
                    (el['disp_code'] == null ? ',' : el['disp_code'] + ',') + 
                    (el['status'] == null ? ',' : el['status']) + '\n'
                csv += line
            });
            let blob = new Blob([csv], { type: 'text/csv' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download =  this.groupName + '.csv';
            link.click();
        },
        downloadUserCsv(){
            //念のため最新化
            this.getUserDetails();
            let csv = '\ufeff' + 'ユーザーID,氏名,カナ,所属部署ID,ログインID,e-mail,パスワード,パスワード編集欄,営業担当,工事担当,システム担当,ステータス\n'
            this.userDetails.forEach(el => {
                //->select('user_id','user_name','user_kana','dept_id','login_id','email','password','eigyo_per_flg','kouji_per_flg','system_per_flg','status')
                var line = 
                    (el['user_id'] == null ? ',' : el['user_id'] + ',') +
                    (el['user_name'] == null ? ',' : el['user_name'] + ',') + 
                    (el['user_kana'] == null ? ',' : el['user_kana'] + ',') + 
                    (el['dept_id'] == null ? ',' : el['dept_id'] + ',') + 
                    (el['login_id'] == null ? ',' : el['login_id'] + ',') + 
                    (el['email']  == null ? ',' : el['email'] + ',') + 
                    (el['password'] == null ? ',' : el['password'] + ',') +
                    (',') + 
                    (el['eigyo_per_flg'] == null ? ',' : el['eigyo_per_flg'] + ',') + 
                    (el['kouji_per_flg'] == null ? ',' : el['kouji_per_flg'] + ',') + 
                    (el['system_per_flg'] == null ? ',' : el['system_per_flg'] + ',') +  
                    (el['status'] == null ? ',' : el['status']) + '\n'
                csv += line
            });
            let blob = new Blob([csv], { type: 'text/csv' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'users.csv';
            link.click();
        },
        getSearchConditionList() {
            axios.get('system/list?type=searchcond')
                .then(response => {
                    // アラート種別
                    this.alert_type_list = response.data.alert_type;
                    // 工事種別
                    this.kouji_type_list = response.data.kouji_type;
                    // 顧客名
                    this.cust_name_list = response.data.cust;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // アラート種類リスト取得
        getAlertTypeNameList() {
            axios.get('system/list?type=alert_type')
                .then(response => {
                    this.cust_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 顧客名リスト取得
        getCustomerNameList() {
            axios.get('system/list?type=cust')
                .then(response => {
                    this.cust_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事種別名リスト取得
        getKoujiTypeNameList() {
            axios.get('system/list?type=kouji_type')
                .then(response => {
                    this.cust_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 警報リスト取得
        getAlertList:function (alert_kind,cust_no,sumi_flg,kouji_type,mainte_flg,tenken_flg,nyukin_yotei_date){
            // URL作成
            var uri = 'system_alert'
            var qparam = ''
            if (alert_kind != null) {
                qparam += 'alert_kind=' + encodeURIComponent(alert_kind) + '&'
            }
            if (cust_no != null) {
                qparam += 'cust_no=' + encodeURIComponent(cust_no) + '&'
            }
            if (sumi_flg != null) {
                qparam += 'sumi_flg=' + encodeURIComponent(sumi_flg) + '&'
            }
            if (kouji_type != null) {
                qparam += 'kouji_type=' + encodeURIComponent(kouji_type) + '&'
            }
            if (mainte_flg != null) {
                qparam += 'mainte_flg=' + encodeURIComponent(mainte_flg) + '&'
            }
            if (tenken_flg != null) {
                qparam += 'tenken_flg=' + encodeURIComponent(tenken_flg) + '&'
            }
            if (nyukin_yotei_date != null) {
                qparam += 'nyukin_yotei_date=' + encodeURIComponent(nyukin_yotei_date) + '&'
            }

            uri += '?' + qparam
            // データ取得
            axios.get(uri)
                .then(response => {
                    this.alert_list = response.data;
                })
                .catch(
                 response => console.log(response)
                );
        },
        // CSVファイルの選択
        selectCsvFile(ev)
        {
            const files = ev;
            if((files != undefined) && (files != null)) {
                // this.status = 'cropping';
                var dataFile = files;
                this.$nextTick(() => {
                this.setFileData(dataFile)
                });
            }
        },
        // CSVファイル読み込み
        setFileData(dataFile) {
            let reader = new FileReader();
            let linesArr = [];
            reader.readAsText(dataFile);
            reader.onload = () => {
                // データバッファへ読み込み
                let lines = reader.result.split("\n");
                lines.shift();
                for (let i = 0; i < lines.length; i++) {
                linesArr[i] = lines[i].split(",");
                }
            }
            this.csvDatas = linesArr;
        },
        // CSV登録処理　汎用マスタ
        regUniCsv() 
        {
            this.message = ''; 
            // データ
            // URL作成
            var uri = 'system/unicsv'
            // データ格納(store)
            axios.post(uri, {'csvdata': this.csvDatas})
                .then(response => {
                    alert(response.data);
                    this.getGroupDetails();
                    this.showGroup();
                })
                .catch((error) => {
                    alert("登録が失敗しました");
                    //alert(error); 
                });
        },
        // CSV登録処理　担当マスタ
        regUserCsv() 
        {
            this.message = ''; 
            // データ
            // URL作成
            var uri = 'system/usercsv'
            // データ格納(store)
            axios.post(uri, {'csvdata': this.csvDatas})
                .then(response => {
                    alert(response.data);
                    this.showUser();
                })
                .catch((error) => {
                    alert("登録が失敗しました");
                    //alert(error); 
                });
        },
    },
}