<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
        <v-card 
          color="rgb(255, 0, 0, 0)" 
          class="outline-card ma-0 pa-0"
          elevation="0"
          height="100%"
        >
          <v-card
            color="rgb(255, 0, 0, 0)" 
            elevation="0"
            class="ma-0 pa-0"
          >
            <v-breadcrumbs :items="breads"></v-breadcrumbs>
          </v-card>
          <v-card
            class="mt-0 mb-4 ml-4 mr-4"
            :color="cssconsts.eigyo_bg_color_base_01"
            outlined
            elevation="10"
          >
            <v-card-actions>
              <span>
                <v-icon>
                  mdi-hammer-wrench
                </v-icon>
                工事詳細情報
              </span>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-1"
                color="indigo"
                dark
                width="100"
                @click="editKoji"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                編集
              </v-btn>
              <v-btn
                class="mx-1"
                color="orange darken-3"
                dark
                width="100"
                @click="delKoji"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                削除
              </v-btn>
              <v-btn
                class="mx-1"
                color="grey darken-1"
                dark
                width="100"
                @click="backPortal"
              >
                <v-icon left>
                  mdi-keyboard-return
                </v-icon>
                戻る
              </v-btn>
            </v-card-actions>
            <v-divider class="mt-0 mb-1"></v-divider>
              <!-- 基本情報 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">基本情報</div>
                </v-sheet>
                  
                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客番号</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{kouji.cust_no}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{kouji.cust_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事番号</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{kouji.kouji_no}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事名</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{kouji.kouji_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事場所</span>
                  </v-col>
                  <v-col cols="2">
                    <div class="yellow lighten-4" style="width: 120px">
                      <span>
                        {{kouji.post_cd}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <div>
                      {{kouji.addr}}
                      <br>{{kouji.addr2}}
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                      <input type="hidden" name="_token" :value="csrf">
                      <input type="hidden" name="mapdata" value="">
                    </form>
                    <v-btn
                      class="ml-2"
                      color="indigo darken-1"
                      small
                      dark
                      @click="showMap()"
                    >
                      <v-icon left>
                        mdi-map-marker
                      </v-icon>
                      地図
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">緯度</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span v-if="kouji">
                      {{kouji.addr_lat}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">経度</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span v-if="kouji">
                      {{kouji.addr_lng}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{kouji.area_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">施主種別</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{kouji.sesyu_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事種別</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span>
                      {{kouji.kouji_type_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">施工面積</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{editSekouArea(kouji.sekou_area)}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">契約日</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{order.keiyaku_date}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">契約金額</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      税込：{{numberToLocal(order.ukeoi_amt + order.ukeoi_tax)}}（税抜：{{numberToLocal(order.ukeoi_amt)}}）
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">営業担当</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{kouji.htg_tanto_name}}
                    </span>
                  </v-col>
                </v-row>
                
                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">見積担当</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{kouji.mtg_tanto_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事担当</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{kouji.ktg_tanto_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">自社施工物件</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{kouji.ckk_sekou_name}}
                    </span>
                  </v-col>
                  <template v-if="kouji.ckk_sekou != '1'">
                    <v-col cols="1" class="d-flex">
                      <span class="ml-auto mr-2 my-auto text-body-2">業者</span>
                    </v-col>
                    <v-col cols="5" class="yellow lighten-4">
                      <span>
                        {{kouji.gyosya_name}}
                      </span>
                    </v-col>
                  </template>
                </v-row>

                <v-row no-gutters class="mb-0 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">備考</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div v-html="bikou"></div>
                  </v-col>
                </v-row>
              </v-sheet>

              <!-- 工期情報 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">工期情報</div>
                </v-sheet>

                <v-row no-gutters class="mt-2 mb-0 mx-2">
                  <v-col cols="3" class="d-flex">
                    <span class="ml-2 mr-2 my-auto text-body-2">工期</span>
                  </v-col>
                  <v-col cols="3" class="d-flex">
                    <span class="ml-2 mr-2 my-auto text-body-2">予定日</span>
                  </v-col>
                  <v-col cols="3" class="d-flex">
                    <span class="ml-2 mr-2 my-auto text-body-2">実施日</span>
                  </v-col>
                </v-row>
                <v-divider class="my-0"></v-divider>

                <v-row no-gutters class="mt-2 mb-0 mx-2">
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>着工</span>
                    </div>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>{{kouji.start_yotei_date}}</span>
                    </div>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>{{kouji.start_date}}</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mb-0 mx-2">
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>完成</span>
                    </div>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>{{kouji.comp_yotei_date}}</span>
                    </div>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>{{kouji.comp_date}}</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mb-0 mx-2">
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>引渡</span>
                    </div>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>{{kouji.hndovr_yotei_date}}</span>
                    </div>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4 mr-2">
                    <div class="ml-2">
                      <span>{{kouji.hndovr_date}}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>

              <!-- 工事部位 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">工事部位</div>
                </v-sheet>
                <template
                  v-for="kojiPart in kojiParts"
                >
                  <v-row
                    no-gutters class="mt-2 mb-0 mx-2"
                    :key="kojiPart.partNo"
                  >
                    <v-col cols="2" class="d-flex">
                      <span class="ml-2 my-auto text-body-2">{{kojiPart.partName}}</span>
                    </v-col>
                    <v-col cols="10" class="d-flex flex-wrap">
                      <div
                        v-for="(model, index) in kojiPart.models"
                        :key="index"
                        class="ml-2 d-flex"
                      >
                        <v-icon
                          v-if="model.select === true"
                          class="mx-1"
                          color="grey darken-3"
                        >
                          mdi-checkbox-marked-outline
                        </v-icon>
                        <v-icon
                          v-else
                          class="mx-1"
                          color="grey lighten-1"
                        >
                          mdi-checkbox-blank-outline
                        </v-icon>
                        <span class="my-auto text-body-2">{{model.name}}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2 mb-0" :key="'d'+kojiPart.partNo"></v-divider>
                </template>
              </v-sheet>

            <!-- 工事工程 -->
            <v-flex class="d-flex">
              <v-sheet
                class="ml-2 mr-2 mt-1 mb-2 pb-1"
                color="white"
                outlined
                rounded
                style="width: 100%"
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">工事工程</div>
                </v-sheet>

                <!-- 明細 -->
                <v-sheet
                  v-for="(kjp, index) in kouji_proc"
                  :key="index"
                  class="d-flex align-stretch ma-2"
                  color="white"
                  tile
                >
                  <v-sheet
                    class="d-flex"
                    color="yellow lighten-3"
                    width="25px"
                    outlined
                    elevation="1"
                  >
                    <div class="ma-auto">{{index + 1}}</div>
                  </v-sheet>
                  <v-flex>
                    <v-card
                      color="white"
                      class="ml-1 pr-2"
                      outlined
                      tile
                      elevation="1"
                    >
                      <v-row no-gutters class="mb-2">
                        <v-col cols="2" class="d-flex">
                          <span class="ml-auto mr-2 my-auto text-body-2">工事部位</span>
                        </v-col>
                        <v-col cols="3" class="yellow lighten-4">
                          <span>
                            {{kjp.kj_part_name}}
                          </span>
                        </v-col>
                        <v-col cols="2" class="d-flex">
                          <span class="ml-auto mr-2 my-auto text-body-2">業者</span>
                        </v-col>
                        <v-col cols="5" class="yellow lighten-4">
                          <span>
                            {{kjp.gyosya}}
                          </span>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mb-2">
                        <v-col cols="2" class="d-flex">
                          <span class="ml-auto mr-2 my-auto text-body-2">工程</span>
                        </v-col>
                        <v-col cols="5" class="yellow lighten-4">
                          <span>
                            {{kjp.kj_proc_name}}
                          </span>
                        </v-col>
                        <v-col cols="2" class="d-flex">
                          <span class="ml-auto mr-2 my-auto text-body-2">ステータス</span>
                        </v-col>
                        <v-col cols="1" class="yellow lighten-4">
                          <span>
                            {{kjp.proc_status}}
                          </span>
                        </v-col>
                        <v-col cols="1" class="d-flex">
                          <span class="ml-auto mr-1 my-auto text-body-2">進捗率</span>
                        </v-col>
                        <v-col cols="1" class="yellow lighten-4">
                          <span>
                            {{kjp.prog_rate}}%
                          </span>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mb-2">
                        <v-col cols="2" class="d-flex">
                          <span class="ml-auto mr-2 my-auto text-body-2">予定日</span>
                        </v-col>
                        <v-col cols="4" class="yellow lighten-4">
                          <span>
                            {{kjp.yotei_date_from}} ～ {{kjp.yotei_date_to}}
                          </span>
                        </v-col>
                        <v-col cols="1" class="d-flex">
                          <span class="ml-auto mr-1 my-auto text-body-2">着工日</span>
                        </v-col>
                        <v-col cols="2" class="yellow lighten-4">
                          <span>
                            {{kjp.start_date}}
                          </span>
                        </v-col>
                        <v-col cols="1" class="d-flex">
                          <span class="ml-auto mr-1 my-auto text-body-2">完了日</span>
                        </v-col>
                        <v-col cols="2" class="yellow lighten-4">
                          <span>
                            {{kjp.comp_date}}
                          </span>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mb-2">
                        <v-col cols="2" class="d-flex">
                          <span class="ml-auto mr-2 my-auto text-body-2">備考</span>
                        </v-col>
                        <v-col cols="10" class="yellow lighten-4">
                          <span>
                            <div v-html="koujiProcs[index].bikouR"></div>
                          </span>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mb-2">
                        <v-col cols="2" class="d-flex">
                          <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                        </v-col>
                        <v-col cols="10" class="d-flex yellow lighten-4">
                          <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[kjp.kj_proc_no] != undefined) && (att_file_list[kjp.kj_proc_no] != null)">
                            <div 
                              v-for="(attfile, att_index) in att_file_list[kjp.kj_proc_no]"
                              :key="kjp.kj_proc_no + '_' + att_index"
                              class="mr-2 text-decoration-underline"
                              >
                              <a :href="'kouji/file?kouji_no='+kouji.kouji_no+'&kj_proc_no='+kjp.kj_proc_no+'&file_index='+att_index+'&filename='+att_file_list[kjp.kj_proc_no][att_index]">{{(att_file_list[kjp.kj_proc_no][att_index])}}</a>
                            </div>
                          </div>
                          
                        </v-col>
                      </v-row>

                    </v-card>
                  </v-flex>
                </v-sheet>

              </v-sheet>
            </v-flex>

          </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import cssconsts from './js/common/cssconsts';
import dbkouji from './js/common/dbkouji.js'
import gmap from './js/common/gmap.js'
//export
export default {
  mixins: [dbkouji, gmap],

  props:{
      "parameter": {},
  },
  created: function() {
    //工事番号をthis.parameter.viewparamから取得
    var kouji_no = this.parameter.viewparam;
    //工事番号が一致するデータを読み出す
    //以下に格納
    //⇒this.kouji
    //⇒this.order
    //⇒this.kouji_file
    //⇒this.kouji_part
    //⇒this.kouji_proc
    this.getKoujiDetail(kouji_no);
    // 添付ファイル名リスト読出し
    this.downloadAttachFileNameList(kouji_no);
    this.m_createBreads();
  },

  watch: {
    kouji: function(data) {
      //alert("customer changed");
      if (data.bikou != null) {
        this.bikou = data.bikou.replace("\n", "<br>");
      }
    },
    kouji_part: function(data) {
      this.kojiParts = [];
      var kj_part_id = 0;
      for (var elem of data) {
        if (elem.kj_part_id != kj_part_id) {
          this.kojiParts.push({
            partNo: elem.kj_part_id,
            partName: elem.kj_part_name,
            models: [],
          });
          kj_part_id = elem.kj_part_id;
        }
        this.kojiParts[this.kojiParts.length - 1].models.push({
          name: elem.model_name,
          select: (elem.choice == "1")
        });
      }
    },
    kouji_proc: function(data) {
      this.koujiProcs = [];
      for (var elem of data) {
        var bikouR = "";
        if (elem.bikou != null) {
          bikouR = elem.bikou.replace("\n", "<br>");
        }
        var attFiles = [];
        if (elem.att_file != null) {
          attFiles = elem.att_file.split(",");
        }
        this.koujiProcs.push({bikouR: bikouR, attFiles: attFiles})
      }
    },
  },

  methods: {
    editKoji() {
      //「工事情報登録・編集」画面へ遷移
      this.$emit('viewChange', 'KojiEdit', this.kouji.kouji_no);
    },
    delKoji() {
      // 顧客削除
      var res = confirm('当工事を削除します。よろしいですか？');
      if (res == true) {
        this.deleteKouji(this.kouji.kouji_no);
      }
    },
    backPortal() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    backKoujiList() {
        //「工事一覧」画面へ戻る
        this.$emit('viewChange', 'KojiList');
    },
    showMap() {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）
      // 顧客住所
      var cust_addr = ((this.kouji.cust_addr!=null)?this.kouji.cust_addr:'') + ((this.kouji.cust_addr2!=null)?this.kouji.cust_addr2:'');
      // 地図表示情報
      this.sendMapInfo(cust_addr);
    },
    // 地図表示情報
    sendMapInfo(cust_addr) {
      var searchaddress = ((this.kouji.addr!=null)?this.kouji.addr:'') + ((this.kouji.addr2!=null)?this.kouji.addr2:'')
      var title_kouji = '工事場所'
      var title_juusyo = '現住所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var lat_kouji = (this.kouji.addr_lat)?this.kouji.addr_lat:null
      var lng_kouji = (this.kouji.addr_lng)?this.kouji.addr_lng:null
      var lat_juusyo = (this.kouji.cust_addr_lat)?this.kouji.cust_addr_lat:null
      var lng_juusyo = (this.kouji.cust_addr_lng)?this.kouji.cust_addr_lng:null
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      i_data.value = JSON.stringify({
        'view_name': this.open_view_name,
        'cust_no': (this.kouji && this.kouji.cust_no)?this.kouji.cust_no:'',
        'cust_name': (this.kouji && this.kouji.cust_name)?this.kouji.cust_name:'',
        'markercount': (cust_addr)?2:1,
        'editindex': -1, // 編集禁止
        'markertitle': [title_kouji,title_juusyo],
        'markeraddress': [searchaddress,cust_addr],
        'markerlat': [lat_kouji,lat_juusyo],
        'markerlng': [lng_kouji,lng_juusyo],
      });
      postform.submit();

    },
    editSekouArea(area) {
      //施工面積編集
      var sekouArea = ""
      if (area != null) {
        sekouArea = Number(area).toLocaleString(undefined, {
          minimumFractionDigits: 2, maximumFractionDigits: 2});
        sekouArea += "㎡"
        //坪変換
        var tubo = 0;
        if (area != null) {
          var tubo = area / 3.30579;
        }
        sekouArea += "（"
        sekouArea += Number(tubo).toLocaleString(undefined, {
          minimumFractionDigits: 2, maximumFractionDigits: 2});
        sekouArea += "坪）"
      }
      return sekouArea;
    },
    numberToLocal(num) {
      if (num == null || isNaN(num)) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      //工事
      kouji: {},
      //契約
      order: {},

      //基幹サーバ工事情報
      kouji_file: [],

      //工事部位
      kouji_part: [],

      //工事工程
      kouji_proc: [],
      att_file_list: {}, // 添付ファイル名

      //編集項目
      bikou: "",
      kojiParts: [],  //工事部位
      koujiProcs: [], //工事工程
       breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap{
   white-space: nowrap;
}
</style>
