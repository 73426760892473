<template>
  <v-data-table
    calculate-widths
    :headers="headers"
    :items="customer_list"
    :height="height"
    :items-per-page="10"
    class="elevation-10"
    locale="jp-ja"
    loading-text="読込中"
    no-data-text="該当するデータがありません。"

    @click:row="clickRow"
  ></v-data-table>
</template>

<script>
  export default {
    data: function() {
      return {
        height: 500,
        headers: [
          { text: '顧客種別', value: 'cust_type', width: '100', class: 'px-1' },
          { text: 'ランク', value: 'rank_id', width: '90', class: 'px-1' },
          { text: '顧客', value: 'cust_name', width: '150', class: 'px-1' },
          { text: '住所', value: 'cust_addr', width: '200', class: 'px-1' },
          { text: 'TEL', value: 'tel_no', width: '150', class: 'px-1' },
          { text: 'DM', value: 'dm', width: '80', class: 'px-1' },
          { text: '工事場所', value: 'kouji_addr', width: '200', class: 'px-1' },
          { text: '顧客担当', value: 'tanto_name', width: '100', class: 'px-1' },
          { text: '地区', value: 'area', width: '100', class: 'px-1' },
        ],
      }
    },

    methods: {
      clickRow: function(event, row){
        //alert("顧客番号：" + row.item.cust_no);
        //画面遷移
        this.$emit('RowClick', row.item);
      }
    },
    props:{
      "customer_list": {},
    },
  }
</script>