<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
        <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
        <v-card
          class="mt-0 mb-4 ml-4 mr-4"
          :color="cssconsts.eigyo_bg_color_base_01"
          outlined
          elevation="10"
        >
          <v-card-actions>
            <div style="width: 270px">
              <v-icon>
                mdi-cog-outline
              </v-icon>
              メンテナンス登録・編集
            </div>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4">
              ※内容を変更した場合、登録ボタンを押してください
            </span>
            <v-btn
              class="mx-1"
              color="indigo"
              dark
              width="100"
              @click="regMaintenance"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              登録
            </v-btn>
            <v-btn
              class="mx-1"
              color="grey darken-1"
              dark
              width="100"
              @click="backPrevPage"
            >
              <v-icon left>
                mdi-keyboard-return
              </v-icon>
              戻る
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-1"></v-divider>
          <v-flex>
            <!-- 基本情報 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              color="white"
              outlined
              rounded
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">基本情報</div>
              </v-sheet>
              <v-form ref="basic_info" v-model="valid" lazy-validation>
                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2">
                    <v-subheader v-if="mode == 'reg'"
                      class="pb-2 red--text float-right"
                    >
                      顧客
                    </v-subheader>
                    <v-subheader v-else
                      class="pb-2 float-right"
                    >
                      顧客名
                    </v-subheader>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete v-if="mode == 'reg'"
                      v-model="cust_no"
                      :items="customer_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                    <v-text-field v-else
                      v-model="koujiInfo.cust_name"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader v-if="mode == 'reg'"
                      class="pb-2 red--text float-right"
                    >
                      工事
                    </v-subheader>
                    <v-subheader v-else
                      class="pb-2 float-right"
                    >
                      工事名
                    </v-subheader>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete v-if="mode == 'reg'"
                      v-model="custKouji"
                      :items="cust_kouji_list"
                      item-value="id"
                      item-text="name"
                      return-object
                      placeholder="工事を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                    <v-text-field v-else
                      v-model="koujiInfo.kouji_name"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">ﾒｲﾝﾃﾅﾝｽ名</v-subheader>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      v-model="maintenance.mainte_name"
                      placeholder="メインテナンス名を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">ﾒｲﾝﾃﾅﾝｽ内容</v-subheader>
                  </v-col>
                  <v-col cols="10">
                    <v-textarea
                      v-model="maintenance.mainte_naiyou"
                      placeholder="メインテナンス内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-1 mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">受付日</v-subheader>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field 
                      v-model="maintenance.recept_date"
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                      @click="dateClick('dpick_recept')"
                    >
                      <template v-slot:append>
                        <date-picker ref="dpick_recept" v-model="maintenance.recept_date"/>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">受付担当</v-subheader>
                  </v-col>
                  <v-col lg="3" md="4">
                    <v-autocomplete
                      v-model="maintenance.recept_tanto_id"
                      :items="tanto_list"
                      item-value="id"
                      item-text="name"
                      placeholder="受付担当を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">完了予定</v-subheader>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field 
                      v-model="maintenance.comp_yotei_date"
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('dpick_comp_yotei')"
                    >
                      <template v-slot:append>
                        <date-picker ref="dpick_comp_yotei" v-model="maintenance.comp_yotei_date"/>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">処理担当</v-subheader>
                  </v-col>
                  <v-col lg="3" md="4">
                    <v-autocomplete
                      v-model="maintenance.syori_tanto_id"
                      :items="tanto_list"
                      item-value="id"
                      item-text="name"
                      placeholder="処理担当を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">完了日</v-subheader>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field 
                      v-model="maintenance.comp_date"
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('dpick_comp')"
                    >
                      <template v-slot:append>
                        <date-picker ref="dpick_comp" v-model="maintenance.comp_date"/>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 float-right">対応内容</v-subheader>
                  </v-col>
                  <v-col cols="10">
                    <v-textarea
                      v-model="maintenance.taiou"
                      placeholder="対応内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>

            <!-- 工事部位 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              color="white"
              outlined
              rounded
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">工事部位</div>
              </v-sheet>
              <template
                v-for="kojiPart in kojiParts"
              >
                <v-row
                  no-gutters class="mt-2 mb-0 mx-2"
                  :key="kojiPart.partNo"
                >
                  <v-col cols="2" class="d-flex">
                    <span class="ml-2 my-auto text-body-2">{{kojiPart.partName}}</span>
                  </v-col>
                  <v-col cols="10" class="d-flex flex-wrap">
                    <div
                      v-for="(model, index) in kojiPart.models"
                      :key="index"
                      class="ml-4 d-flex"
                    >
                      <v-checkbox
                        v-model="model.select"
                        class="shrink my-auto"
                        color="blue darken-1"
                        hide-details
                      >
                        <template v-slot:label>
                          <span class="text-body-2">
                            {{model.name}}
                          </span>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mt-2 mb-0" :key="'d'+kojiPart.partNo"></v-divider>
              </template>
            </v-sheet>
          </v-flex>
        </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import cssconsts from './js/common/cssconsts';
import dbmaintenance from './js/common/dbmaintenance.js'
import DatePicker from "./DatePicker.vue";
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbmaintenance],

  props:{
      "parameter": {},
  },

  created: function() {
    this.getIdNameList("tanto");     // 担当者リスト取得

    //メンテナンス番号をthis.parameter.viewparamから取得
    var param = this.parameter.viewparam;
    var mainte_no = param.mainte_no;
    if (mainte_no) {
      //点検番号あり
      //編集v-
      this.koujiInfo.cust_name = param.cust_name
      this.koujiInfo.kouji_name = param.kouji_name
      this.getMaintenance(mainte_no);
      this.mode = "edit";
    } else {
      //新規登録
      if (param.kouji_no) {
        //工事に追加登録
        this.maintenance.kouji_no = param.kouji_no
        this.koujiInfo.cust_name = param.cust_name
        this.koujiInfo.kouji_name = param.kouji_name
        //工事部位読込
        this.getKoujiPartList(param.kouji_no);
        this.mode = "addReg";
      } else {
        this.getIdNameList("customer");  // 顧客リスト取得
        this.mode = "reg";
      }
    }
     this.m_createBreads();
  },
  mounted: function() {
    //エラーをリセット
    this.$refs.basic_info.resetValidation();
  },
  watch: {
    cust_no: function(data) {
      if (data != null) {
        //顧客工事リスト読込
        this.getCustKoujiList(data);
      }
    },
    custKouji: function(data) {
      if (data != null) {
        this.maintenance.kouji_no = data.id;
        //工事部位読込
        this.getKoujiPartList(data.id, data.kouji_type);
      }
    },
    kouji_part: function(data) {
      this.kojiParts = [];
      var kj_part_id = 0;
      for (var elem of data) {
        if (elem.kj_part_id != kj_part_id) {
          this.kojiParts.push({
            partNo: elem.kj_part_id,
            partName: elem.kj_part_name,
            models: [],
          });
          kj_part_id = elem.kj_part_id;
        }
        this.kojiParts[this.kojiParts.length - 1].models.push({
          no: elem.model_no,
          name: elem.model_name,
          select: (elem.choice == "1"),
          created_at: elem.created_at
        });
      }
    },
  },

  methods: {
    dateClick(dpickName, index = null) {
      //DatePicker を開く
      if (index == null) {
        this.$refs[dpickName].menu = true;
      } else {
        //v-forで生成したDatePicker用
        this.$refs[dpickName][index].menu = true;
      }
    },
    backPrevPage() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    regMaintenance() {
      //入力チェック
      //--基本情報
      this.valid = this.$refs.basic_info.validate();
      if (!this.valid) {
        alert("基本情報に入力不足、または間違いがあります");
        return;
      }
      //--工事部位
      var koujiPart = [];
      this.kojiParts.forEach(part => {
        part.models.forEach(model => {
          if (model.select == true) {
            koujiPart.push({
              kj_part_id: part.partNo,
              model_no: model.no,
              created_at: model.created_at
            });
          }
        });
      });
      if (koujiPart.length == 0) {
        alert("メンテナンス対象の工事部位を選択してください");
        return;
      }

      //DB更新
      this.setMaintenance(this.maintenance, koujiPart);
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      //基本情報
      koujiInfo: {},
      maintenance: {},
      //工事部位
      kouji_part: [],
      kojiParts: [],

      //顧客
      cust_no: null,
      customer_list: [],
      //顧客の工事
      custKouji: null,
      cust_kouji_list: [],
      //担当者
      tanto_list: [],
      breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap {
   white-space: nowrap;
}
</style>
