<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <v-card
        class="mt-0 mb-4 ml-4 mr-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="10"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-text-box-outline
            </v-icon>
            工事見積情報
          </span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="editConstrEst"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            編集
          </v-btn>
          <v-btn
            class="mx-1"
            color="orange darken-3"
            dark
            width="100"
            @click="deleteMitumori"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            削除
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backBtn"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-1"></v-divider>
        <!-- 基本情報 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <div class="mx-2 my-auto">基本情報</div>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-1 my-1"
              color="cyan darken-1"
              dark
              small
              @click.stop="showOrder(mitumori_data.mitumori.keiyaku_no)"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              受注情報表示／登録
            </v-btn>
          </v-sheet>
          <v-row no-gutters class="mt-2 mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">見積番号</span>
            </v-col>
            <v-col cols="2" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.mitumori_no}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">見積日</span>
            </v-col>
            <v-col cols="2" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.mitumori_date}}
              </span>
            </v-col>
          </v-row>
            
          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">顧客番号</span>
            </v-col>
            <v-col cols="2" class="yellow lighten-4">
              <span v-if="mitumori_data.hikiai">
                {{mitumori_data.hikiai.cust_no}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
            </v-col>
            <v-col cols="6" class="yellow lighten-4">
              <span v-if="mitumori_data.hikiai">
                {{mitumori_data.hikiai.cust_name}}（{{mitumori_data.hikiai.cust_kana}}）
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">引合内容</span>
            </v-col>
            <v-col cols="9" class="yellow lighten-4">
              <span v-if="mitumori_data.hikiai">
                {{mitumori_data.hikiai.hikiai_naiyou}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">工事種別</span>
            </v-col>
            <v-col cols="6" class="yellow lighten-4">
              <span v-if="mitumori_data.hikiai">
                {{mitumori_data.hikiai.kouji_type_name}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">引合備考</span>
            </v-col>
            <v-col cols="10" class="yellow lighten-4">
              <span v-if="mitumori_data.hikiai">
                {{mitumori_data.hikiai.bikou}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">工事名</span>
            </v-col>
            <v-col cols="10" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.kouji_name}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">プラン名</span>
            </v-col>
            <v-col cols="10" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.plan_name}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-0 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">工事場所</span>
            </v-col>
            <v-col cols="2">
              <div class="d-flex">
                <div class="yellow lighten-4">
                  <span>
                  〒
                  </span>
                  <span class="mr-1" v-if="mitumori_data.mitumori">
                    <span v-if="mitumori_data.mitumori.kouji_post_cd != undefined">
                      {{( mitumori_data.mitumori.kouji_post_cd ).substring(0,3)}}
                    </span>
                  </span>
                  <span class="mr-1">
                  -
                  </span>
                  <span class="mr-1" v-if="mitumori_data.mitumori">
                    <span v-if="mitumori_data.mitumori.kouji_post_cd != undefined">
                      {{( mitumori_data.mitumori.kouji_post_cd ).substring(3,7)}}
                    </span>
                  </span>
                </div>
                <div class="mr-1"></div>
              </div>
            </v-col>
            <v-col cols="8" class="pl-2 yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.kouji_addr}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="4">
            </v-col>
            <v-col cols="8" class="pl-2 yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.kouji_addr2}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">緯度</span>
            </v-col>
            <v-col cols="3" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.kouji_addr_lat}}
              </span>
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">経度</span>
            </v-col>
            <v-col cols="3" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.kouji_addr_lng}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
            </v-col>
            <v-col cols="3" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.area_name}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">営業担当</span>
            </v-col>
            <v-col cols="7" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.hikiai_tanto_name}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">見積担当</span>
            </v-col>
            <v-col cols="7" class="yellow lighten-4">
              <span v-if="mitumori_data.mitumori">
                {{mitumori_data.mitumori.mitumori_tanto_name}}
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-0 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">施工面積</span>
            </v-col>
            <v-col cols="4" class="yellow lighten-4">
              <span v-if="(mitumori_data.mitumori) && (mitumori_data.mitumori.sekou_area)">
                {{Number(mitumori_data.mitumori.sekou_area).toLocaleString()}}㎡ （{{Number((mitumori_data.mitumori.sekou_area* 0.3025).toFixed(2)).toLocaleString()}}坪）
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-2 mb-2 mr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">提示額</span>
            </v-col>
            <v-col cols="10">
              <v-row no-gutters>
                <v-col cols="1" class="d-flex">
                  <span class="ml-auto mr-2 my-auto text-body-2">税込</span>
                </v-col>
                <v-col cols="2" class="yellow lighten-4">
                  <span class="float-right" v-if="mitumori_data.mitumori">
                    <span v-if="mitumori_data.mitumori.pres_tax != undefined">
                      {{(mitumori_data.mitumori.pres_tax).toLocaleString()}}
                    </span>
                  </span>
                </v-col>
                <v-col cols="1" class="d-flex">
                  <span class="ml-auto mr-2 my-auto text-body-2">税抜</span>
                </v-col>
                <v-col cols="2" class="yellow lighten-4">
                  <span class="float-right" v-if="mitumori_data.mitumori">
                    <span v-if="mitumori_data.mitumori.pres_amt != undefined">
                      {{(mitumori_data.mitumori.pres_amt).toLocaleString()}}
                    </span>
                  </span>
                </v-col>
                <v-col cols="1" class="d-flex">
                  <span class="ml-auto mr-2 my-auto text-body-2">税額</span>
                </v-col>
                <v-col cols="2" class="yellow lighten-4">
                  <span class="float-right" v-if="mitumori_data.mitumori">
                    <span v-if="(mitumori_data.mitumori.pres_tax != undefined) && (mitumori_data.mitumori.pres_amt != undefined)">
                      {{(mitumori_data.mitumori.pres_tax - mitumori_data.mitumori.pres_amt).toLocaleString()}}
                    </span>
                  </span>
                </v-col>
                <v-col cols="1" class="d-flex">
                  <span class="ml-auto mr-2 my-auto text-body-2">税率</span>
                </v-col>
                <v-col cols="2" class="yellow lighten-4">
                  <span v-if="mitumori_data.mitumori">
                    {{mitumori_data.mitumori.tax_rate}}%
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-sheet>

        <!-- 見積明細 -->
        <v-sheet
          class="mx-2 mt-1 mb-2 pb-2"
          color="white"
          outlined
          rounded
        >
          <v-sheet
            class="ma-0 d-flex"
            :color="cssconsts.eigyo_bg_color_header_01"
            outlined
            rounded
            dark
          >
            <div class="mx-2 my-auto">見積明細</div>
          </v-sheet>

          <v-row no-gutters class="mt-0 mb-0 mx-2">
            <v-col cols="4">
              <span class="ml-2 text-body-2">工事部位</span>
            </v-col>
            <v-col cols="2">
              <span class="ml-2 text-body-2">数量</span>
            </v-col>
            <v-col cols="2">
              <span class="ml-2 text-body-2">単位</span>
            </v-col>
            <v-col cols="2">
              <span class="ml-2 text-body-2">単価</span>
            </v-col>
            <v-col cols="2">
              <span class="ml-2 text-body-2">金額</span>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-0 mb-0 mx-2">
            <v-col cols="4">
              <span class="ml-2 text-body-2">仕様・モデル</span>
            </v-col>
            <v-col cols="6">
              <span class="ml-2 text-body-2">備考</span>
            </v-col>
          </v-row>

          <div
            v-for="(estDtl, index) in mitumori_data.meisai"
            :key="index"
          >
            <v-divider class="my-0"></v-divider>

            <v-row no-gutters class="mt-1 mb-0 mx-2">
              <v-col cols="4" class="yellow lighten-4">
                <div class="ml-2">
                  <span>{{estDtl.kouji_part_name}}</span>
                </div>
              </v-col>
              <v-col cols="2" class="d-flex yellow lighten-4">
                <div class="ml-auto">
                  <span>{{estDtl.num}}</span>
                </div>
              </v-col>
              <v-col cols="2" class="yellow lighten-4">
                <div class="ml-2">
                  <span>{{estDtl.unit_name}}</span>
                </div>
              </v-col>
              <v-col cols="2" class="d-flex yellow lighten-4">
                <div class="ml-auto">
                  <span v-if="estDtl.price != undefined">
                    {{estDtl.price.toLocaleString()}}
                  </span>
                </div>
              </v-col>
              <v-col cols="2" class="d-flex yellow lighten-4">
                <div class="ml-auto">
                  <span v-if="(estDtl.price != undefined)&&(estDtl.num != undefined)">
                    {{(estDtl.price * estDtl.num).toLocaleString()}}
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-0 mb-1 mx-2">
              <v-col cols="4" class="yellow lighten-4">
                <div class="ml-2">
                  <span>{{estDtl.kouji_spec_model_name}}</span>
                </div>
              </v-col>
              <v-col cols="6" class="yellow lighten-4">
                <div class="ml-2">
                  <span>{{estDtl.bikou}}</span>
                </div>
              </v-col>
            </v-row>
          </div>

          <v-divider class="mt-0 mb-2"></v-divider>

          <v-row no-gutters class="mt-0 mb-0 mx-2">
            <v-col cols="8">
            </v-col>
            <v-col cols="2" class="d-flex">
              <span class="ml-auto pr-2 my-auto text-body-2">合計金額</span>
            </v-col>
            <v-col cols="2" class="d-flex yellow lighten-4">
              <div class="ml-auto">
                <span v-if="meisaiTotalAmt != undefined">
                  {{meisaiTotalAmt.toLocaleString()}}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
//export
export default {
  props:{
      "parameter": {},
  },
  created : function(){
    // 見積番号がthis.parameter.viewparamとして引き渡されてくる
    if (this.parameter.viewparam)
    {
      var mitumori_no = this.parameter.viewparam
      this.getMitsumoriData(mitumori_no)
    }
    this.m_createBreads();
  },
  computed:{
    meisaiTotalAmt()
    {
      var total = 0;
      if (this.mitumori_data.meisai)
      {
        for (var i=0; i<this.mitumori_data.meisai.length; i++)
        {
          total += this.mitumori_data.meisai[i].price * this.mitumori_data.meisai[i].num;
        }
      }
      return total;
    }
  },
  methods: {
    backBtn() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    backConstrEstList() {
      //「工事見積一覧」画面へ戻る
      this.$emit('viewChange', 'ConstrEstList');
    },
    editConstrEst() {
      //「工事見積登録・編集」画面へ遷移
      this.$emit('viewChange', 'ConstrEstEdit', this.mitumori_data);
    },

    deleteMitumori() {
      // 見積削除
      var res = confirm('この見積情報を削除します。よろしいですか？');
      if (res == true) {
        var mitumori_no = this.parameter.viewparam
        this.deleteConstrEst(mitumori_no);
      }
    },
    deleteConstrEst(mitumori_no){
     // URL作成
      var uri = 'constr_est'
      if (mitumori_no != null) {
        uri += '/' + mitumori_no
        // データ削除(destroy)
        axios.delete(uri)
          .then(response => {
            // console.log(response.data);
            this.backConstrEstList();  // 成功したら一覧に戻る
          })
          .catch((error) => {
            alert("工事見積の削除に失敗しました");
          });
      }
    },
    showOrder(keiyaku_no) {
      if (keiyaku_no != null) {
        //「受注情報」画面へ遷移
        this.$emit('viewChange', 'Order', keiyaku_no, 5, 'eigyo');
      } else {
        var param = {
          keiyaku_no: 0,
          mitumori_no: this.mitumori_data.mitumori.mitumori_no,
          cust_no: this.mitumori_data.hikiai.cust_no
        };
        //「受注登録・編集」画面へ遷移
        this.$emit('viewChange', 'OrderEdit', param, 5, 'eigyo');   //新規登録
      }
    },

    // 見積データ取得
    getMitsumoriData:function (mitumori_no) 
    {
      var uri = 'constr_est'
      if (mitumori_no != null) {
          uri += '/' + mitumori_no
      }
      // データ取得
      axios.get(uri)
        .then(response => {
          this.mitumori_data = response.data;
        })
        .catch(
          // response => console.log(response)
        );
    },
 
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      mitumori_data: [],
      breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>
