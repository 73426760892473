/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require("bootstrap-css-only/css/bootstrap.min.css");
require("mdbvue/lib/css/mdb.min.css");
require("@fortawesome/fontawesome-free/css/all.min.css");

window.Vue = require('vue');
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
Vue.use(Vuetify);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

// コンポーネント宣言
Vue.component('Home', require('./components/Home.vue').default);

//// 営業支援用
Vue.component('MenuHeader', require('./components/MenuHeader.vue').default); // メニュー
Vue.component('EigyoHome', require('./components/EigyoHome.vue').default); // 営業担当ホーム
Vue.component('CustomerList', require('./components/CustomerList.vue').default); // 顧客一覧
Vue.component('CustomerPortal', require('./components/CustomerPortal.vue').default); // 顧客ポータル
Vue.component('Customer', require('./components/Customer.vue').default); // 顧客詳細情報
Vue.component('CustomerEdit', require('./components/CustomerEdit.vue').default); // 顧客情報登録・編集
Vue.component('EigyoNippouList', require('./components/EigyoNippouList.vue').default); // 営業日報一覧
Vue.component('EigyoNippou', require('./components/EigyoNippou.vue').default); // 営業日報
Vue.component('EigyoNippouEdit', require('./components/EigyoNippouEdit.vue').default); // 営業日報登録・編集
Vue.component('InquiryList', require('./components/InquiryList.vue').default); // 引合一覧
Vue.component('InquiryDetail', require('./components/InquiryDetail.vue').default); // 引合情報詳細
Vue.component('InquiryEdit', require('./components/InquiryEdit.vue').default); // 引合情報登録・編集
Vue.component('ConstrEstList', require('./components/ConstrEstList.vue').default); // 工事見積一覧
Vue.component('ConstrEst', require('./components/ConstrEst.vue').default); // 工事見積情報
Vue.component('ConstrEstEdit', require('./components/ConstrEstEdit.vue').default); // 工事見積登録・編集
Vue.component('OrderList', require('./components/OrderList.vue').default); // 受注一覧
Vue.component('Order', require('./components/Order.vue').default); // 受注情報
Vue.component('OrderEdit', require('./components/OrderEdit.vue').default); // 受注登録・編集
Vue.component('CommaNumberField', require('./components/CommaNumberField.vue').default); // カンマ区切り数値

//// 工事施工管理用
Vue.component('KojiHome', require('./components/KojiHome.vue').default); // 工事担当ホーム
Vue.component('KojiNippouList', require('./components/KojiNippouList.vue').default); // 工事日報一覧
Vue.component('KojiNippou', require('./components/KojiNippou.vue').default); // 工事日報
Vue.component('KojiNippouEdit', require('./components/KojiNippouEdit.vue').default); // 工事日報登録・編集
Vue.component('KojiList', require('./components/KojiList.vue').default); // 工事一覧
Vue.component('KojiPortal', require('./components/KojiPortal.vue').default); // 工事ポータル
Vue.component('KojiDetail', require('./components/KojiDetail.vue').default); // 工事詳細情報
Vue.component('KojiEdit', require('./components/KojiEdit.vue').default); // 工事情報登録・編集
Vue.component('MainteList', require('./components/MainteList.vue').default); // メンテナンス一覧
Vue.component('Maintenance', require('./components/Maintenance.vue').default); // メンテナンス情報
Vue.component('MainteEdit', require('./components/MainteEdit.vue').default); // メンテナンス登録・編集
Vue.component('InspectList', require('./components/InspectList.vue').default); // 点検一覧
Vue.component('Inspection', require('./components/Inspection.vue').default); // 点検情報
Vue.component('InspectEdit', require('./components/InspectEdit.vue').default); // 点検登録・編集

//// システム管理用
Vue.component('SystemHome', require('./components/SystemHome.vue').default); // システム管理ホーム
Vue.component('AlertSrch', require('./components/AlertSrch.vue').default); // アラート検索
Vue.component('MasterMan', require('./components/MasterMan.vue').default); // マスタ管理メニュー
Vue.component('MstGeneral', require('./components/MstGeneral.vue').default); // 汎用マスタ
Vue.component('MstUser', require('./components/MstUser.vue').default); // 担当マスタ

//// ZENRIN地図用
// Vue.component('Znetmap', require('./components/Znetmap.vue').default); // 地図

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Vue from 'vue';
import ja from 'vuetify/es5/locale/ja';
import utils from './components/js/mixins/utils';
import validation from './components/js/mixins/validation';
Vue.mixin(utils);
Vue.mixin(validation);

const app = new Vue({
    el: '#app',
    vuetify: new Vuetify({
        lang: {
            locales: { ja },
            current: 'ja',
        },    
    }),
});
