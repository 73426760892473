<template>
  <v-app class='base'>
    <v-main>
      <v-form id="srch_form">
        <!-- 検索条件 -->
        <v-card
          color="green lighten-4"
          class="ma-4"
          outlined
          elevation="3"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-database
              </v-icon>
              担当者マスタ
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-divider class="mt-0 mb-2"></v-divider>

          <v-container mx-4 pa-0>
            <v-row no-gutters>
              <v-col cols="3" class="d-flex">
                <v-btn
                  class="ml-3 mb-2"
                  color="green accent-4"
                  dark
                  width="200"
                  @click="showUser()"
                >
                  <v-icon left>
                    mdi-eye
                  </v-icon>
                  最新の状態に更新
                </v-btn>
              </v-col>
              <v-col lg="9" md="12" class="pr-2 d-flex">
                <v-btn
                  class="ml-3 mr-2"
                  color="teal darken-1"
                  dark
                  width="120"
                  @click="downloadUserCsv()"
                >
                  <v-icon left>
                    mdi-download
                  </v-icon>
                  CSV出力
                </v-btn>
                <v-btn
                  class="mx-2"
                  color="indigo"
                  dark
                  width="120"
                  @click="regUserCsv"
                >
                  <v-icon left>
                    mdi-upload
                  </v-icon>
                  CSV登録
                </v-btn>
                <v-file-input
                  placeholder="ファイル選択後［CSV登録］をクリック"
                  dense
                  @change="selectCsvFile($event)"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>

        </v-card>
      </v-form>

      <!-- マスタ表示 -->
      <v-card
        color="lime lighten-4"
        class="ma-4"
        outlined
        elevation="3"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            <template>
              担当者一覧
            </template>
          </span>
          <v-spacer></v-spacer>
          <v-checkbox
            v-model="checkShowDel"
            class="shrink mx-2 my-auto"
            color="red darken-1"
            hide-details
            false-value="1"
            true-value="0"
          >
            <template v-slot:label>
              <span class="mr-2 text-body-2">
                削除も表示する
              </span>
            </template>
          </v-checkbox>
        </v-card-actions>
        <v-divider class="mt-0 mb-0"></v-divider>
        <v-flex v-if="userList == true" class="mt-2 mb-4 mx-4">
          <v-data-table
            calculate-widths
            height="530"
            :headers="userHeaders"
            :items="userDetails"
            :items-per-page="10"
            class="elevation-1"
            locale="jp-ja"
            loading-text="読込中"
            no-data-text="該当するデータがありません。"
            :search="checkShowDel"
            :custom-filter="hideDelItem"
          >
            <template v-slot:[`item.del`]="{ item }">
              <v-icon
                v-if="item.del === true"
                class="my-auto"
                color="red darken-1"
              >
                mdi-checkbox-marked
              </v-icon>
              <v-icon
                v-else
                class="my-auto"
                color="grey lighten-1"
              >
                mdi-checkbox-blank-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
//import
import dbsystem from './js/common/dbsystem.js'
//export
export default {
  
  mixins: [dbsystem], 
  methods: {
    showUser: function() {
      this.getUserDetails();
      this.userList = true;
    },
    hideDelItem: function(value,search, item) {
      if (search == "1") {
        return (item.status != "1");
      } else {
        return true;
      }
    },
  },
  created: function() {
    this.showUser();
  },
  data: function(){
    return {
      checkShowDel: "1",
     // showDel: "1",
      message:null,

      userList: false,

      userHeaders: [
        { text: '担当者ID', value: 'user_id', width: '80' },
        { text: '氏名', value: 'user_name', width: '80' },
        { text: 'カナ', value: 'user_kana', width: '200' },
        { text: '所属部署ID', value: 'dept_id', width: '80' },
        { text: 'ログインID', value: 'login_id', width: '80' },
        { text: 'メールアドレス', value: 'email', width: '80' },
        { text: 'パスワード', value: 'password', width: '80' },
        { text: '営業担当フラグ', value: 'eigyo_per_flg', width: '80' },
        { text: '工事担当フラグ', value: 'kouji_per_flg', width: '80' },
        { text: 'システム権限フラグ', value: 'system_per_flg', width: '80' },
        { text: 'ステータス', value: 'status', width: '80' },
      ],
      userDetails: [],
      csvDatas:[],
    }
  }
};
</script>

<style>
.right-input input {
  text-align: right;
}
</style>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}
</style>
