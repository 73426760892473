export default {
    methods: {
        // 各リスト取得
        getIdNameList: async function (id) {
            await axios.get('inquiry/list?type='+id)
                .then(response => {
                    if (id == "custType") {
                        this.cust_type_list = response.data;
                    } else if  (id == "rank") {
                        this.rank_list = response.data;
                    } else if  (id == "tanto") {
                        this.tanto_list = response.data;
                    } else if  (id == "area") {
                        this.area_list = response.data;
                    } else if  (id == "koujiType") {
                        this.kouji_type_list = response.data;
                    } else if  (id == "inquiryStatus") {
                        this.inq_status_list = response.data;
                    } else if  (id == "customer") {
                        this.customer_list = response.data;
                    }
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 工事部位取得
        getKoujiPartList: function (koujiTypeId) {
            axios.get('inquiry/kouji_part?koujiType='+koujiTypeId)
            .then(response => {
                this.kouji_part_list = response.data;
            })
            .catch(
                // response => console.log(response)
            );
        },

        // 引合一覧取得
        getInquiryList: function (
            cust_name, cust_type, cust_rank, inq_naiyou, eigyo_tanto, area_id, inq_date_from, inq_date_to,
            kouji_type, inq_status, odexp_dt_from, odexp_dt_to, inq_tanto, mitu_amt_from, mitu_amt_to) 
        {
            // URL作成
            var uri = 'inquiry';
            var qparam = [];
            if (cust_name != null) {
                qparam.push('cust_name=' + encodeURIComponent(cust_name));
            }
            if (cust_type != null) {
                qparam.push('cust_type=' + encodeURIComponent(cust_type));
            }
            if (cust_rank != null) {
                qparam.push('cust_rank=' + encodeURIComponent(cust_rank));
            }
            if (inq_naiyou != null) {
                qparam.push('inq_naiyou=' + encodeURIComponent(inq_naiyou));
            }
            if (eigyo_tanto != null) {
                qparam.push('eigyo_tanto=' + encodeURIComponent(eigyo_tanto));
            }
            if (area_id != null) {
                qparam.push('area_id=' + encodeURIComponent(area_id));
            }
            if (inq_date_from != null) {
                qparam.push('inq_date_from=' + encodeURIComponent(inq_date_from));
            }
            if (inq_date_to != null) {
                qparam.push('inq_date_to=' + encodeURIComponent(inq_date_to));
            }
            if (kouji_type != null) {
                qparam.push('kouji_type=' + encodeURIComponent(kouji_type));
            }
            if (inq_status != null) {
                qparam.push('inq_status=' + encodeURIComponent(inq_status));
            }
            if (odexp_dt_from != null) {
                qparam.push('odexp_dt_from=' + encodeURIComponent(odexp_dt_from));
            }
            if (odexp_dt_to != null) {
                qparam.push('odexp_dt_to=' + encodeURIComponent(odexp_dt_to));
            }
            if (inq_tanto != null) {
                qparam.push('inq_tanto=' + encodeURIComponent(inq_tanto));
            }
            if (mitu_amt_from != null) {
                qparam.push('mitu_amt_from=' + encodeURIComponent(mitu_amt_from));
            }
            if (mitu_amt_to != null) {
                qparam.push('mitu_amt_to=' + encodeURIComponent(mitu_amt_to));
            }

            uri += '?' + qparam.join('&');

            // データ取得
            axios.get(uri)
                .then(response => {
                    this.inquiry_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客データ取得
        getInquiry: function (hikiai_no) 
        {
            //alert("getCustomer:cust_no：" + cust_no);
            // データ取得
            axios.get('inquiry/' + hikiai_no)
                .then(response => {
                    this.hikiai = response.data['hikiai'];
                    this.kouji_part_list = response.data['kjPart'];
                    this.customer = response.data['customer'];
                    this.tantoGrp = response.data['tantoGrp'];
                    this.mitumori = response.data['mitumori'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 引合詳細データ（表示用）取得
        getInquiryDetail: function (hikiai_no) 
        {
            // データ取得
            axios.get('inquiry/detail?hikiai_no=' + hikiai_no)
                .then(response => {
                    this.hikiai = response.data['hikiai'];
                    this.kouji_part_list = response.data['kjPart'];
                    this.mitumori = response.data['mitumori'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 引合データ登録
        setInquiry: function (hikiai, koujiPart, tantoGrp) 
        {
            // URL作成
            var uri = 'inquiry'
            // データ格納
            axios.post(uri, {'hikiai':hikiai, 'koujiPart':koujiPart, 'tantoGrp':tantoGrp})
                .then(response => {
                    if (this.mode == "reg") {
                        alert("引合情報を登録しました");
                    } else {
                        alert("引合情報を更新しました");
                    }
                    //前画面に戻る
                    this.$emit('viewBack');
                })
                .catch((error) => {
                    alert("引合情報の登録・更新に失敗しました");
                });
        },

        // 引合削除
        deleteInquiry: function (hikiai_no) 
        {
            // データ削除
            axios.delete('inquiry/' + hikiai_no)
                .then(response => {
                    this.backInquiryList();  // 成功したら一覧に戻る
                })
                .catch((error) => {
                    alert("引合の削除に失敗しました");
                });
        },

    },
}