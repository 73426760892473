export default {
    methods: {
        // 各リスト取得
        getIdNameList: function (id) {
            axios.get('customer/list?type='+id)
                .then(response => {
                    if (id == "custType") {
                        this.cust_type_list = response.data;
                    } else if  (id == "rank") {
                        this.rank_list = response.data;
                    } else if  (id == "tanto") {
                        this.tanto_list = response.data;
                    } else if  (id == "area") {
                        this.area_list = response.data;
                    } else if  (id == "relation") {
                        this.relation_list = response.data;
                    } else if  (id == "houseType") {
                        this.house_type_list = response.data;
                    }
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客リスト取得
        getCustomerList: function (cust_name,tel_no1, tel_no2, tel_no3, addr, kj_addr, cust_type, cust_rank, dm_flg, cust_tanto, area_id) 
        {
            // URL作成
            var uri = 'customer';
            var qparam = [];
            if (cust_type != null) {
                qparam.push('cust_type=' + encodeURIComponent(cust_type));
            }
            if (cust_rank != null) {
                qparam.push('cust_rank=' + encodeURIComponent(cust_rank));
            }
            if (dm_flg != null) {
                qparam.push('dm_flg=' + encodeURIComponent(dm_flg));
            }
            if (cust_name != null) {
                qparam.push('cust_name=' + encodeURIComponent(cust_name));
            }
            if (addr != null) {
                qparam.push('addr=' + encodeURIComponent(addr));
            }
            if (kj_addr != null) {
                qparam.push('kj_addr=' + encodeURIComponent(kj_addr));
            }
            if (tel_no1 != null) {
                qparam.push('tel_no1=' + encodeURIComponent(tel_no1));
            }
            if (tel_no2 != null) {
                qparam.push('tel_no2=' + encodeURIComponent(tel_no2));
            }
            if (tel_no3 != null) {
                qparam.push('tel_no3=' + encodeURIComponent(tel_no3));
            }
            if (cust_tanto != null) {
                qparam.push('cust_tanto=' + encodeURIComponent(cust_tanto));
            }
            if (area_id != null) {
                qparam.push('area_id=' + encodeURIComponent(area_id));
            }

            uri += '?' + qparam.join('&');

            // データ取得
            axios.get(uri)
                .then(response => {
                    this.customer_srch_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客データ取得
        getCustomer: function (cust_no)
        {
            //alert("getCustomer:cust_no：" + cust_no);
            // データ取得
            axios.get('customer/' + cust_no)
                .then(response => {
                    this.customer = response.data['customer'];
                    this.family = response.data['family'];
                    this.tantoGrp = response.data['tantoGrp'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客基本データ取得
        getCustomerBasic: function (cust_no)
        {
            // データ取得
            axios.get('customer/basic?cust_no=' + cust_no)
                .then(response => {
                    this.customer = response.data[0];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客詳細データ（表示用）取得
        getCustomerDetail: function (cust_no)
        {
            // データ取得
            axios.get('customer/detail?cust_no=' + cust_no)
                .then(response => {
                    this.customer = response.data['customer'];
                    this.family = response.data['family'];
                    this.tanto = response.data['tanto'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客引合リスト取得
        getCustomerInquiryList: function (cust_no)
        {
            // データ取得
            axios.get('customer/list?type=inquiry&cust_no=' + cust_no)
                .then(response => {
                    this.inquiry_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客日報リスト取得
        getCustomerNippouList: function (cust_no)
        {
            // データ取得
            axios.get('customer/list?type=nippou&cust_no=' + cust_no)
                .then(response => {
                    this.nippou_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客工事リスト取得
        getCustomerKoujiList: function (cust_no)
        {
            // データ取得
            axios.get('customer/list?type=kouji&cust_no=' + cust_no)
                .then(response => {
                    this.kouji_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客メンテナンスリスト取得
        getCustomerMainteList: function (cust_no)
        {
            // データ取得
            axios.get('customer/list?type=mainte&cust_no=' + cust_no)
                .then(response => {
                    this.mainte_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客点検リスト取得
        getCustomerInspectList: function (cust_no)
        {
            // データ取得
            axios.get('customer/list?type=inspect&cust_no=' + cust_no)
                .then(response => {
                    this.inspect_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客データ登録
        setCustomer: function (customer, family, tantoGrp)
        {
            // URL作成
            var uri = 'customer'
            // データ格納
            axios.post(uri, {'customer':customer, 'family':family, 'tantoGrp':tantoGrp})
                .then(response => {
                    if (this.mode == "reg") {
                        alert("顧客情報を登録しました");
                    } else {
                        alert("顧客情報を更新しました");
                    }
                    //前画面に戻る
                    this.$emit('viewBack');
                })
                .catch((error) => {
                    alert("顧客情報の登録・更新に失敗しました");
                });
        },

        // 顧客削除
        deleteCustomer: function (cust_no) 
        {
            // データ削除
            axios.delete('customer/' + cust_no)
                .then(response => {
                    this.backCustomerList();  // 成功したら一覧に戻る
                })
                .catch((error) => {
                    alert("顧客の削除に失敗しました");
                });
        },

    },
}