export default {
    methods: {
        // 検索条件リスト一括取得
        getSearchConditionList() {
            axios.get('constr_est/list?type=searchcond')
                .then(response => {
                    // 営業担当者名
                    this.tanto_name_list = response.data.tanto;
                    // 顧客名
                    this.cust_name_list = response.data.cust;
                    // 地区
                    this.area_list = response.data.area;
                    // 顧客種別
                    this.custtype_list = response.data.custtype;
                    // ランク
                    this.custrank_list = response.data.custrank;
                    // 工事種別
                    this.koujitype_list = response.data.koujitype;

                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 営業担当者名リスト取得
        getEigyoTantoNameList() {
            axios.get('constr_est/list?type=tanto')
                .then(response => {
                    this.tanto_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 顧客名リスト取得
        getCustomerNameList() {
            axios.get('constr_est/list?type=cust')
                .then(response => {
                    this.cust_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 顧客名（フリガナ付）リスト取得
        getCustomerNameKanaList() {
            axios.get('constr_est/list?type=custkana')
                .then(response => {
                    this.cust_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 地区リスト取得
        getAreaNameList() {
            axios.get('constr_est/list?type=area')
                .then(response => {
                    this.area_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 単位リスト取得
        getUnitNameList() {
            axios.get('constr_est/list?type=unit')
                .then(response => {
                    this.unit_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客種別リスト取得
        getCustTypeList() {
            axios.get('constr_est/list?type=custtype')
                .then(response => {
                    this.custtype_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // ランクリスト取得
        getCustRankList() {
            axios.get('constr_est/list?type=custrank')
                .then(response => {
                    this.custrank_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事種別リスト取得
        getKoujiTypeList() {
            axios.get('constr_est/list?type=koujitype')
                .then(response => {
                    this.koujitype_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 工事部位リスト取得
        async getKoujiPartList(parent){
            await axios.get('constr_est/list?type=kjpart&parent='+parent)
                .then(response => {
                    this.kj_part_list=response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 仕様モデルリスト取得
        getSpecModelList(parent) {
            axios.get('constr_est/list?type=specmodel&parent='+parent)
                .then(response => {
                    this.$set(this.spec_model_list, parent, response.data)
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 引合リスト取得
        getInquiryNameList(cust_no) {
            axios.get('constr_est/list?type=inquiry&cust_no=' + encodeURIComponent(cust_no))
                .then(response => {
                    this.$set(this.inquiry_list, cust_no, response.data)
                })
                .catch(
                    // response => console.log(response)
                );            
        },

    },
}