<template>
  <v-data-table
    calculate-widths
    :headers="headers"
    :items="nippou_list"
    :height="height"
    :items-per-page="10"
    sort-by="rept_date"
    sort-desc
    class="elevation-1"
    dense
    locale="jp-ja"
    loading-text="読込中"
    no-data-text="該当するデータがありません。"

    @click:row="clickRow"
  ></v-data-table>
</template>

<script>
  export default {
    data: function() {
      return {
        height: 500,
        headers: [
          { text: '日付', value: 'rept_date', width: '100px', class: 'px-1' },
          { text: '顧客', value: 'customer_name', width: '150px', class: 'px-1' },
          { text: 'アクション', value: 'action_name', width: '150px', class: 'px-1' },
          { text: 'アプローチ', value: 'approach_name', width: '100px', class: 'px-1' },
          { text: '相手の名前', value: 'person_name', width: '150px', class: 'px-1' },
          { text: '内容', value: 'naiyou', width: '200px', class: 'px-1' },
          { text: '担当者', value: 'user_name', width: '100px', class: 'px-1' },
        ],
      }
    },

    created : function(){
    },
    mounted() {
    },
    methods: {
      clickRow: function(event, row){
        //alert("顧客：" + row.item.customer);
        //画面遷移
        // this.$emit('RowClick', row.item.customer);
        this.$emit('RowClick', row.item);
        //this.$emit('viewChange', 'EigyoNippou');
      }
    },
    props:{
      "nippou_list": {},
    },
  }
</script>