export default {
    methods: {
        // 各リスト取得
        getIdNameList: function (id) {
            axios.get('inspection/list?type='+id)
                .then(response => {
                    if (id == "inspType") {
                        this.insp_type_list = response.data;
                    } else if  (id == "tanto") {
                        this.tanto_list = response.data;
                    } else if  (id == "customer") {
                        this.customer_list = response.data;
                    }
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客工事リスト取得
        getCustKoujiList: function (cust_no) {
            axios.get('inspection/list?type=custKouji&custNo='+cust_no)
            .then(response => {
                this.cust_kouji_list = response.data;
            })
            .catch(
                // response => console.log(response)
            );
        },

        // 点検リスト取得
        getInspectionList: function (
            insp_tanto, insp_name, insp_type, cust_name, kouji_name,
            yotei_date_from, yotei_date_to, jissi_date_from, jissi_date_to, insp_naiyou, only_alert) 
        {
            // URL作成
            var uri = 'inspection';
            var qparam = [];
            if (insp_tanto != null) {
                qparam.push('insp_tanto=' + encodeURIComponent(insp_tanto));
            }
            if (insp_name != null) {
                qparam.push('insp_name=' + encodeURIComponent(insp_name));
            }
            if (insp_type != null) {
                qparam.push('insp_type=' + encodeURIComponent(insp_type));
            }
            if (cust_name != null) {
                qparam.push('cust_name=' + encodeURIComponent(cust_name));
            }
            if (kouji_name != null) {
                qparam.push('kouji_name=' + encodeURIComponent(kouji_name));
            }
            if (yotei_date_from != null) {
                qparam.push('yotei_date_from=' + encodeURIComponent(yotei_date_from));
            }
            if (yotei_date_to != null) {
                qparam.push('yotei_date_to=' + encodeURIComponent(yotei_date_to));
            }
            if (jissi_date_from != null) {
                qparam.push('jissi_date_from=' + encodeURIComponent(jissi_date_from));
            }
            if (jissi_date_to != null) {
                qparam.push('jissi_date_to=' + encodeURIComponent(jissi_date_to));
            }
            if (insp_naiyou != null) {
                qparam.push('insp_naiyou=' + encodeURIComponent(insp_naiyou));
            }
            if (only_alert != null && only_alert == "yes") {
                qparam.push('alert=yes');
            }

            uri += '?' + qparam.join('&');

            // データ取得
            axios.get(uri)
                .then(response => {
                    this.inspection_srch_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 点検データ（表示用）取得
        getInspectionDetail: function (insp_no)
        {
            // データ取得
            axios.get('inspection/detail?insp_no=' + insp_no)
                .then(response => {
                    this.inspection = response.data['inspection'];
                    this.inspect_yotei = response.data['inspect_yotei'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 点検データ取得
        getInspection: function (insp_no)
        {
            // データ取得
            axios.get('inspection/' + insp_no)
                .then(response => {
                    this.koujiInfo = response.data['koujiInfo'];
                    this.inspection = response.data['inspection'];
                    this.inspect_mei = response.data['inspect_mei'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 点検データ登録
        setInspection: function (inspection, inspect_mei) 
        {
            // URL作成
            var uri = 'inspection'
            // データ格納
            axios.post(uri, {'inspection':inspection, 'inspect_mei':inspect_mei})
                .then(response => {
                    if (this.mode == "reg") {
                        alert("点検情報を登録しました");
                    } else if (this.mode == "addReg") {
                        alert("点検情報を登録しました");
                    } else {
                        alert("点検情報を更新しました");
                    }
                    //前画面に戻る
                    this.$emit('viewBack');
                })
                .catch((error) => {
                    alert("点検情報の登録・更新に失敗しました");
                });
        },

        // 点検削除
        deleteInspection: function (insp_no) 
        {
            // データ削除
            axios.delete('inspection/' + insp_no)
                .then(response => {
                    this.backInspectList();  // 成功したら一覧に戻る
                })
                .catch((error) => {
                    alert("点検の削除に失敗しました");
                });
        },

    },
}