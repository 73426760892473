export default {
        eigyo_bg_color_base_01: 'lime lighten-4',
        eigyo_bg_color_header_01: 'teal darken-4',
        alert_text_color: 'red--text',
        search_keys_bg_color_01: 'green lighten-4',
        search_result_bg_color_01: 'lime lighten-4',
        edit_bg_color_base_01: 'lime lighten-4',
        edit_bg_color_header_01: 'teal darken-4',
        system_bg_color_base_01: 'lime lighten-4',
        system_bg_color_header_01: 'teal darken-4',
}
