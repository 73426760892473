<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
      <v-card
        color="rgb(255, 0, 0, 0)" 
        elevation="0"
        class="ma-0 pa-0"
      >
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
      </v-card>
      <!-- 営業日報 ヘッダー部 -->
      <v-card
        class="mt-0 mb-4 ml-4 mr-4"
        :color="cssconsts.eigyo_bg_color_base_01"
        outlined
        elevation="3"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-file
            </v-icon>
            営業日報【
          </span>
          <span class="mx-1 yellow lighten-5">{{nippou_rept_date}}</span>
          <span>】</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-1"
            color="indigo"
            dark
            width="100"
            @click="editNippou"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            編集
          </v-btn>
          <v-btn
            class="mx-1"
            color="orange darken-3"
            dark
            width="100"
            @click="deleteNippou"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            削除
          </v-btn>
          <v-btn
            class="mx-1"
            color="grey darken-1"
            dark
            width="100"
            @click="backBtn"
          >
            <v-icon left>
              mdi-keyboard-return
            </v-icon>
            戻る
          </v-btn>
        </v-card-actions>
        <v-divider class="mt-0 mb-2"></v-divider>

        <v-flex>
          <v-row no-gutters class="mb-2 pr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">担当者</span>
            </v-col>
            <v-col cols="3" class="yellow lighten-5">
            <span>{{nippou_user_name}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2 pr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">目標</span>
            </v-col>
            <v-col cols="8" class="yellow lighten-5" style="white-space:pre-wrap; word-wrap:break-word;">
            <span>{{nippou_mokuhyou}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2 pr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">成果</span>
            </v-col>
            <v-col cols="8" class="yellow lighten-5" style="white-space:pre-wrap; word-wrap:break-word;">
            <span>{{nippou_seika}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2 pr-2">
            <v-col cols="2" class="d-flex">
              <span class="ml-auto mr-2 my-auto text-body-2">課題</span>
            </v-col>
            <v-col cols="8" class="yellow lighten-5" style="white-space:pre-wrap; word-wrap:break-word;">
            <span>{{nippou_kadai}}</span>
            </v-col>
          </v-row>
        </v-flex>
      </v-card>

      <!-- 日報明細 -->
      <v-card
        :color="cssconsts.eigyo_bg_color_base_01"
        class="ma-4"
        outlined
        elevation="3"
      >
        <div class="mt-2 ml-2">
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            日報明細
          </span>
        </div>
        <v-divider class="mt-1 mb-0"></v-divider>

        <!-- 明細 -->
        <div v-for="(mei_item, mei_index) in eigyo_nippou_data.meisai" :key="mei_index">
          <!-- test表示 -->
          <!-- <li>{{mei_item}}</li> -->
          <!-- test表示 -->
          <v-sheet
            class="d-flex align-stretch ma-2"
            :color="cssconsts.eigyo_bg_color_base_01"
            tile
          >
            <v-sheet
              class="d-flex"
              color="yellow lighten-3"
              width="25px"
              outlined
              elevation="1"
            >
            <!-- 明細index -->
            <div class="ma-auto">{{mei_index+1}}</div>
            </v-sheet>

            <!-- 営業アクションIDにより表示形式選択 -->
            <!-- ↓商談 -->
            <v-flex v-if="mei_item.act_id == 703">
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【商談】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}～{{timehhmm(mei_item.time_to)}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">相手　名前</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">部署・役職</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_post}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.customer_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">商談内容</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.area_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">成果／見込み</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{mei_item.seika}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">アプローチ</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.approach_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">今後の対応</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{mei_item.yotei}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">フェーズ</span>
                  </v-col>
                  <v-col cols="4" class="yellow lighten-4">
                    <span>
                      {{mei_item.syodan_phase}}
                    </span>
                  </v-col>
                  <v-col cols="3" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">次回対応期日時</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span>
                      {{mei_item.yotei_date}}
                      {{timehhmm(mei_item.yotei_time)}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">引合情報</span>
                  </v-col>
                  <v-col  cols="9" class="d-flex">
                    <div class="yellow lighten-4" style="width: 100%">
                      {{mei_item.hikiai_naiyou}}
                    </div>
                    <v-btn
                      class="ml-1"
                      color="cyan darken-1"
                      dark
                      small
                      :disabled="!mei_item.hikiai_no"
                      @click="showInquiry(mei_item.hikiai_no)"
                    >
                      <v-icon left>
                        mdi-eye
                      </v-icon>
                      参照
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.egnp_mei_no] != undefined) && (att_file_list[mei_item.egnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.egnp_mei_no]"
                        :key="mei_item.egnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'eigyo_nippou/file?egnp_no='+mei_item.egnp_no+'&egnp_mei_no='+mei_item.egnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.egnp_mei_no][att_index]">{{(att_file_list[mei_item.egnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-flex>
            <!-- ↑商談 -->
            <!-- ↓クレーム対応 -->
            <v-flex v-if="mei_item.act_id == 704">
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【クレーム対応】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}～{{timehhmm(mei_item.time_to)}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">クレーム者</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">部署・役職</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_post}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.customer_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">クレーム内容</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.area_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">対応・結果</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{mei_item.seika}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">アプローチ</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.approach_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">状況</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{status_text(mei_item.status)}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">次回対応期日</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.yotei_date}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.egnp_mei_no] != undefined) && (att_file_list[mei_item.egnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.egnp_mei_no]"
                        :key="mei_item.egnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'eigyo_nippou/file?egnp_no='+mei_item.egnp_no+'&egnp_mei_no='+mei_item.egnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.egnp_mei_no][att_index]">{{(att_file_list[mei_item.egnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>

              </v-card>
            </v-flex>
            <!-- ↑クレーム対応 -->
            <!-- ↓その他 -->
            <v-flex v-if="mei_item.act_id == 705">
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【その他】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}～{{timehhmm(mei_item.time_to)}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">相手　名前</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">部署・役職</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_post}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.customer_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">分類</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.other_cat_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.area_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">内容</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">アプローチ</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.approach_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.egnp_mei_no] != undefined) && (att_file_list[mei_item.egnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.egnp_mei_no]"
                        :key="mei_item.egnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'eigyo_nippou/file?egnp_no='+mei_item.egnp_no+'&egnp_mei_no='+mei_item.egnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.egnp_mei_no][att_index]">{{(att_file_list[mei_item.egnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-flex>
            <!-- ↑その他 -->
            <!-- ↓測量 -->
            <v-flex v-if="mei_item.act_id == 702">
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【測量】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}～{{timehhmm(mei_item.time_to)}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">相手　名前</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">部署・役職</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_post}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.customer_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">内容</span>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.area_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">アプローチ</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.approach_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.egnp_mei_no] != undefined) && (att_file_list[mei_item.egnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.egnp_mei_no]"
                        :key="mei_item.egnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'eigyo_nippou/file?egnp_no='+mei_item.egnp_no+'&egnp_mei_no='+mei_item.egnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.egnp_mei_no][att_index]">{{(att_file_list[mei_item.egnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>

              </v-card>
            </v-flex>
            <!-- ↑測量 -->
            <!-- ↓アポ -->
            <v-flex v-if="mei_item.act_id == 701">
              <v-card
                color="white"
                class="ml-1"
                outlined
                tile
                elevation="1"
              >
                <v-card-title class="pa-1">
                  <span class="text-subtitle-2">【アポ】</span>
                </v-card-title>
                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">時刻</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{timehhmm(mei_item.time_from)}}～{{timehhmm(mei_item.time_to)}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">結果</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span>
                      {{appo_res_text(mei_item.appo_res)}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.customer_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">相手　名前</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_name}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">部署・役職</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.person_post}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.area_name}}
                    </span>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">日時</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span>
                      {{mei_item.yotei_date}}
                      {{timehhmm(mei_item.yotei_time)}}
                    </span>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">場所</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span>
                      {{mei_item.yotei_place}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">アプローチ</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{mei_item.approach_name}}
                    </span>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">目的</span>
                  </v-col>
                  <v-col cols="7" class="yellow lighten-4">
                    <span>
                      {{mei_item.naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 pr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">添付ファイル</span>
                  </v-col>
                  <v-col cols="10" class="yellow lighten-4">
                    <div class="d-flex" v-if="(att_file_list != null) && (att_file_list[mei_item.egnp_mei_no] != undefined) && (att_file_list[mei_item.egnp_mei_no] != null)">
                      <div 
                        v-for="(attfile, att_index) in att_file_list[mei_item.egnp_mei_no]"
                        :key="mei_item.egnp_mei_no + '_' + att_index"
                        class="mr-2 text-decoration-underline"
                        >
                        <a :href="'eigyo_nippou/file?egnp_no='+mei_item.egnp_no+'&egnp_mei_no='+mei_item.egnp_mei_no+'&file_index='+att_index+'&filename='+att_file_list[mei_item.egnp_mei_no][att_index]">{{(att_file_list[mei_item.egnp_mei_no][att_index])}}</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>

              </v-card>
            </v-flex>
            <!-- ↑アポ -->
          </v-sheet>
        </div>

      </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import dbeigyonippou from './js/common/dbeigyonippou.js'
//export
export default {
  mixins: [dbeigyonippou],
  props:{
      "parameter": {},
  },
  computed:{
    nippou_rept_date: function() {
      if (this.eigyo_nippou_data.nippou)
      {
        return this.eigyo_nippou_data.nippou.rept_date
      }
      else 
      {
        return null
      }
    },
    nippou_user_name: function() {
      if (this.eigyo_nippou_data.nippou)
      {
        return this.eigyo_nippou_data.nippou.user_name
      }
      else 
      {
        return null
      }
    },

    nippou_mokuhyou: function() {
      if (this.eigyo_nippou_data.nippou)
      {
        return this.eigyo_nippou_data.nippou.mokuhyou
      }
      else 
      {
        return null
      }
    },
    nippou_seika: function() {
      if (this.eigyo_nippou_data.nippou)
      {
        return this.eigyo_nippou_data.nippou.seika
      }
      else 
      {
        return null
      }
    },
    nippou_kadai: function() {
      if (this.eigyo_nippou_data.nippou)
      {
        return this.eigyo_nippou_data.nippou.kadai
      }
      else 
      {
        return null
      }
    },
    timehhmm: function() {
      return function(timevalue) {
        return this.timeFormatHHMM(timevalue)
      }
    },
    status_text: function() {
      return function(status) {
        var statustext = null
        if (status==1)
        {
          statustext = '処理中'
        }
        else if (status==9)
        {
          statustext = '処理済み'
        }
        return statustext
      }
    },
    appo_res_text: function() {
      return function(res) {
        var restext = null
        if (res==1)
        {
          restext = 'OK'
        }
        else if (res==9)
        {
          restext = 'NG'
        }
        return restext
      }
    },
  },
  created : function(){
    // 営業日報番号がthis.parameter.viewparamとして引き渡されてくる
    if (this.parameter.viewparam)
    {
      var egnp_no = this.parameter.viewparam
      this.getEigyoNippouData(egnp_no)  // 対象営業日報番号に該当するデータを読み出し
      // 添付ファイル名リスト再読出し
      this.downloadAttachFileNameList(egnp_no)
    }
    this.m_createBreads();
  },
  methods: {
    backBtn() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    backNippouList() {
        //「営業日報一覧」画面へ戻る
        this.$emit('viewChange', 'EigyoNippouList');
    },
    editNippou() {
        //「営業日報登録・編集」画面へ遷移
        var data = {}
        data.nippou = this.eigyo_nippou_data.nippou
        data.meisai = this.eigyo_nippou_data.meisai
        data.att_file = this.att_file_list
        this.$emit('viewChange', 'EigyoNippouEdit', data);
    },
    showInquiry: function(event, row) {
      //「引合詳細情報」画面へ遷移
      this.$emit('viewChange', 'InquiryDetail',event, 3, 'eigyo');
    },
    deleteNippou() {
      // 営業日報削除
      var res = confirm('この営業日報を削除します。よろしいですか？');
      if (res == true) {
        var egnp_no = this.parameter.viewparam
        this.deleteEigyoNippou(egnp_no);
      }
    },

    // 営業日報データ取得
    getEigyoNippouData:function (egnp_no) 
    {
      // URL作成
      var uri = 'eigyo_nippou'
      if (egnp_no != null) {
        uri += '/' + egnp_no
      }
      // データ取得
      axios.get(uri)
        .then(response => {
          this.eigyo_nippou_data = response.data;
        })
        .catch(
          // response => console.log(response)
        );
    },
    // 営業日報削除
    deleteEigyoNippou(egnp_no)
    {
      // URL作成
      var uri = 'eigyo_nippou'
      if (egnp_no != null) {
        uri += '/' + egnp_no
        // データ削除(destroy)
        axios.delete(uri)
          .then(response => {
            // console.log(response.data);
            this.backNippouList();  // 成功したら一覧に戻る
          })
          .catch((error) => {
            alert("営業日報の削除に失敗しました");
          });
      }
    },

  },
  data: function(){
    return {
      cssconsts: cssconsts,
      eigyo_nippou_data: {},
      att_file_list: {}, // 添付ファイル名
      breads: [],
    }
  }
};
</script>
<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

</style>
<style>
</style>
