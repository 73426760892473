<template>
  <v-app class="base">
    <v-main>
      <v-layout justify-center>
        <v-card 
          color="rgb(255, 0, 0, 0)" 
          class="outline-card ma-0 pa-0"
          elevation="0"
          height="100%"
        >
          <v-card
            color="rgb(255, 0, 0, 0)" 
            elevation="0"
            class="ma-0 pa-0"
          >
            <v-breadcrumbs :items="breads"></v-breadcrumbs>
          </v-card>
          <v-form id="srch_form">
            <!-- 検索条件 -->
            <v-card
              color="green lighten-4"
              class="mt-0 mb-4 ml-4 mr-4"
              outlined
              elevation="3"
            >
              <v-card-actions>
                <span>
                  <v-icon>
                    mdi-text-box-search-outline
                  </v-icon>
                  検索条件
                </span>
                <v-spacer></v-spacer>
                <span class="text-body-2 mx-4 mt-2">詳細検索</span>
                <v-switch
                  v-model="dtl_srch"
                  class="mt-1"
                  color="primary"
                  inset
                  dense
                  hide-details
                  @change="dtl_srch_sw(dtl_srch)"
                ></v-switch>
                <v-btn
                  class="mx-1"
                  color="blue-grey darken-1"
                  dark
                  width="100"
                  @click="srchMaintenance"
                >
                  <v-icon left>
                    mdi-magnify
                  </v-icon>
                  検索
                </v-btn>
                <v-btn
                  class="mx-1"
                  color="light-blue darken-1"
                  dark
                  width="100"
                  @click="regMaintenance"
                >
                  新規登録
                </v-btn>
              </v-card-actions>
              <v-divider class="mt-0 mb-2"></v-divider>

              <!-- 1行目 -->
              <v-container mx-4 pa-0>
                <v-row no-gutters>
                  <v-col lg="2" md="2">
                    <v-subheader class="pl-0 pb-2 float-right">処理担当者</v-subheader>
                  </v-col>
                  <v-col lg="3" md="3">
                    <v-autocomplete
                      v-model="tanto_select"
                      :items="tanto_list"
                      item-value="id"
                      item-text="name"
                      placeholder="担当者を選択"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col lg="2" md="2">
                    <v-subheader v-if="$vuetify.breakpoint.smAndDown"
                      class="pl-0 pr-2 pb-2 float-right"
                    >メンテナンス名</v-subheader>
                    <v-subheader v-else
                      class="pb-2 float-right"
                    >メンテナンス名</v-subheader>
                  </v-col>
                  <v-col lg="5" md="5" class="pr-3">
                    <v-text-field
                      v-model="mainte_name_input"
                      placeholder="メンテナンス名を入力"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <template v-if="dtl_srch === true">
                <!-- 2行目 -->
                <v-container mx-4 pa-0>
                  <v-row no-gutters>
                    <v-col lg="1" md="2">
                      <v-subheader class="pb-2 float-right">顧客名</v-subheader>
                    </v-col>
                    <v-col lg="5" md="10" class="pr-3">
                      <v-text-field
                        v-model="cust_name_input"
                        placeholder="顧客名かカナを入力"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col lg="1" md="2">
                      <v-subheader class="pb-2 float-right">工事名</v-subheader>
                    </v-col>
                    <v-col lg="5" md="10" class="pr-3">
                      <v-text-field
                        v-model="kouji_name_input"
                        placeholder="工事名を入力"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- 3行目 -->
                <v-container mx-4 pa-0>
                  <v-row no-gutters>
                    <v-col lg="1" md="2">
                      <v-subheader class="pb-2 float-right">受付日</v-subheader>
                    </v-col>
                    <v-col lg="6" md="8" class="pr-3">
                      <v-row no-gutters>
                        <v-col cols="5">
                          <v-text-field 
                            v-model="recept_date_from"
                            placeholder="日付(From)"
                            solo
                            readonly
                            clearable
                            dense
                            hide-details
                            @click="dateClick('recept_date_from')"
                          >
                            <template v-slot:append>
                              <date-picker ref="recept_date_from" v-model="recept_date_from"/>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-subheader class="pb-2">
                            <div class="mx-auto">～</div>
                          </v-subheader>
                        </v-col>
                        <v-col cols="5">
                        <v-text-field 
                            v-model="recept_date_to"
                            placeholder="日付(To)"
                            solo
                            readonly
                            clearable
                            dense
                            hide-details
                            @click="dateClick('recept_date_to')"
                          >
                            <template v-slot:append>
                              <date-picker ref="recept_date_to" v-model="recept_date_to"/>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col lg="auto" md="2">
                    </v-col>
                    <v-col lg="1" md="2">
                      <v-subheader class="pl-0 pr-2 pb-2 float-right">
                        <span v-if="$vuetify.breakpoint.smAndDown">
                          メンテナンス内容
                        </span>
                        <span v-else>
                          メンテ内容
                        </span>
                      </v-subheader>
                    </v-col>
                    <v-col lg="4" md="10" class="pr-3">
                      <v-text-field
                        v-model="mainte_naiyou_input"
                        placeholder="メンテナンス内容を入力"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- 4行目 -->
                <v-container mx-4 pa-0>
                  <v-row no-gutters>
                    <v-col lg="1" md="2">
                      <v-subheader class="pb-2 float-right">完了日</v-subheader>
                    </v-col>
                    <v-col lg="6" md="8" class="pr-3">
                      <v-row no-gutters>
                        <v-col cols="5">
                        <v-text-field 
                            v-model="comp_date_from"
                            placeholder="日付(From)"
                            solo
                            readonly
                            clearable
                            dense
                            hide-details
                            @click="dateClick('comp_date_from')"
                          >
                            <template v-slot:append>
                              <date-picker ref="comp_date_from" v-model="comp_date_from"/>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-subheader class="pb-2">
                            <div class="mx-auto">～</div>
                          </v-subheader>
                        </v-col>
                        <v-col cols="5">
                        <v-text-field 
                            v-model="comp_date_to"
                            placeholder="日付(To)"
                            solo
                            readonly
                            clearable
                            dense
                            hide-details
                            @click="dateClick('comp_date_to')"
                          >
                            <template v-slot:append>
                              <date-picker ref="comp_date_to" v-model="comp_date_to"/>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col lg="auto" md="2">
                    </v-col>
                    <v-col lg="1" md="2">
                      <v-subheader class="pl-0 pb-2 float-right">アラート</v-subheader>
                    </v-col>
                    <v-col lg="1" md="2">
                      <v-checkbox
                        v-model="alert_ari"
                        class="mt-1"
                        color="red darken-1"
                        hide-details
                      >
                        <template v-slot:label>
                          <span>
                            あり
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>

              </template>

            </v-card>
          </v-form>

          <!-- 検索結果 -->
          <v-card
            :color="cssconsts.search_result_bg_color_01"
            class="ma-4"
            outlined
            elevation="3"
          >
            <v-card-actions>
              <div class="mt-2 ml-2">
                <span>
                  <v-icon>
                    mdi-view-list
                  </v-icon>
                  検索結果
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-checkbox
                v-model="checkHideComp"
                class="shrink mx-2 my-auto"
                color="blue darken-1"
                false-value="0"
                true-value="1"
                hide-details
              >
                <template v-slot:label>
                  <span>
                    実施済みを表示しない
                  </span>
                </template>
              </v-checkbox>
            </v-card-actions>
            <v-divider class="mt-1 mb-0"></v-divider>
            <v-flex class="mt-2 mb-4 mx-4">
              <v-data-table
                calculate-widths
                :height="mainteTableHeight"
                :headers="mainteHeaders"
                :items="mainte_srch_list"
                :items-per-page="10"
                :sort-by.sync="mainteSortBy"
                sort-desc
                class="elevation-1"
                locale="jp-ja"
                loading-text="読込中"
                no-data-text="該当するデータがありません。"
                :search="checkHideComp"
                :custom-filter="filterHideComp"
                @click:row="showMaintenance"
              >
                <template v-slot:[`header.cust_name`]="{ header }">
                  <span>{{ header.text }}</span><br>
                  <span class="red--text">アラート</span>
                </template>
                <template v-slot:[`item.cust_name`]="{ item }">
                  <span>{{ item.cust_name }}</span><br>
                  <span class="red--text">{{ item.alert_msg }}</span>
                </template>
              </v-data-table>
            </v-flex>
          </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
//import
import cssconsts from './js/common/cssconsts';
import dbmaintenance from './js/common/dbmaintenance.js'
import DatePicker from "./DatePicker.vue";
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbmaintenance],

  props:{
      "parameter": {},
  },

  created: function() {
    if (this.parameter.homeparam.loginuser != null) {
      //ログインユーザーIDを取得
      this.loginUser = this.parameter.homeparam.loginuser.user_id
    }
    this.getIdNameList("tanto");     // 実施担当者リスト取得

    //ログインユーザーの点検を読み出す
    this.getMaintenanceList(this.loginUser);
    this.m_createBreads();
  },
  mounted() {
  },

  watch: {
    tanto_list: function(data) {
      if (this.loginUser != null) {
        this.tanto_select = this.loginUser;
      }
    }
  },

  methods: {
    dtl_srch_sw(sw) {
      //this.mainteTableHeight = (sw ? 380 : 500);
    },
    dateClick(dpickName) {
      //DatePicker を開く
      this.$refs[dpickName].menu = true;
    },
    srchMaintenance() {
      //メンテナンスを検索
      var mainte_tanto = this.tanto_select;
      var mainte_name = this.mainte_name_input;
      var cust_name = this.cust_name_input;
      var kouji_name = this.kouji_name_input;
      var recept_date_from = this.recept_date_from;
      var recept_date_to = this.recept_date_to;
      var comp_date_from = this.comp_date_from;
      var comp_date_to = this.comp_date_to;
      var mainte_naiyou = this.mainte_naiyou_input;
      var only_alert = (this.alert_ari == true ? "yes" : "no");
      if (this.dtl_srch) {
        //詳細検索
        this.getMaintenanceList(
            mainte_tanto, mainte_name, cust_name, kouji_name,
            recept_date_from, recept_date_to, comp_date_from, comp_date_to, mainte_naiyou, only_alert
        );
      } else {
        //簡易検索
        this.getMaintenanceList(
          mainte_tanto, mainte_name
        );
      }
    },
    filterHideComp (value, search, item) {
      if (search == "1") {
        //実施済みを表示しない
        return (item.comp_date == null);
      } else {
        return true;
      }
    },
    regMaintenance() {
      var param = {
        mainte_no: 0,
        kouji_no: null,
        kouji_name: null,
        cust_name: null
      };
      //「メンテナンス登録・編集」画面へ遷移
      this.$emit('viewChange', 'MainteEdit', param);    //新規登録
    },
    showMaintenance: function(event, row) {
      //「メンテナンス情報」画面へ遷移
      this.$emit('viewChange', 'Maintenance', row.item.mainte_no);
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      loginUser: null,
      tanto_select: null,
      mainte_name_input: null,
      cust_name_input: null,
      kouji_name_input: null,
      recept_date_from: null,
      recept_date_to: null,
      comp_date_from: null,
      comp_date_to: null,
      mainte_naiyou_input: null,
      alert_ari: false,

      checkHideComp: "1",

      dtl_srch: false,
      mainteTableHeight: 500,

      //実施担当者
      tanto_list: [],

      //メンテナンス一覧
      mainteSortBy: 'comp_yotei_date',
      mainteHeaders: [
        { text: '顧客', value: 'cust_name', width: '200px', class: 'px-1' },
        { text: '工事名', value: 'kouji_name', width: '200px', class: 'px-1' },
        { text: 'メンテナンス名', value: 'mainte_name', width: '150px', class: 'px-1' },
        { text: 'メンテナンス内容', value: 'mainte_naiyou', width: '200px', class: 'px-1' },
        { text: '予定日', value: 'comp_yotei_date', width: '100px', class: 'px-1' },
        { text: '完了日', value: 'comp_date',  width: '100px', class: 'px-1' },
        { text: '対応内容', value: 'taiou',  width: '200px', class: 'px-1' },
        { text: '処理担当', value: 'tanto_name',  width: '100px', class: 'px-1' },
      ],
      mainte_srch_list: [],
      breads: [],
    }
  }
};
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap {
   white-space: nowrap;
}

td.text-start,td.text-end {
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.right-input input {
  text-align: right;
}
</style>
