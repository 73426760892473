<template>
    <header>
        <v-app-bar
            app
            color="light-green lighten-2"
        >
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-toolbar-title></v-toolbar-title>
            <v-tabs v-model="menuTab">
                <v-tab
                    v-for="(menuItem, index) in menuItems"
                    :key="index"
                    @click="viewChange(menuItem.viewName, index)"
                >
                    {{ menuItem.name }}
                </v-tab>
            </v-tabs>
            <div class="mt-4 mr-1">
                <v-select
                    v-model="modeValue"
                    :items="modeItems"
                    item-text="text"
                    item-value="value"
                    label="機能切替"
                    @input="modeChange"
                    dense
                    hide-details
                ></v-select>
            </div>
            <div>
                <v-btn color="teal darken-1" dark block v-on:click="logout">ログアウト</v-btn>
                <form id="logout-form" action="logout" method="POST" style="display: none;">
                    <input type="hidden" name="_token" :value="csrf">
                </form>
            </div>
            <div>
                <a href="./docs/manual_011.pdf" target="_blank">
                <v-img 
                    :src="manualImg"
                    max-width="70" 
                />
                </a>
            </div>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            color="light-green lighten-5"
        >
            <v-list
                nav
                dense
            >
                <v-list-item-group>
                    <v-list-item
                        v-for="(menuItem, index) in menuItems"
                        :key="index"
                        @click="viewChange(menuItem.viewName, index),drawer = false"
                    >
                        <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </header>
</template>

<script>
import constants from './js/common/constants';

export default {
    
    props:{
      "parameter": {},
    },
    computed:{
        manualImg() {
            return require("../../images/manual.png")
        },
        manualPdf() {
            return url("../../docs/manual_01.pdf")
        },
    },
    created: function() {
        if (this.parameter.homeparam.loginuser != null) {
            //ログインユーザーIDを取得
            this.loginUser = this.parameter.homeparam.loginuser;
            this.eigyo_per_flg = this.loginUser.eigyo_per_flg;
            this.kouji_per_flg = this.loginUser.kouji_per_flg;
            this.system_per_flg = this.loginUser.system_per_flg;
            this.status = this.loginUser.status;

            if(this.eigyo_per_flg == 1){
                this.modeValue = 'eigyo';
            }else if(this.kouji_per_flg == 1){
                this.modeValue = 'koji';
            }else if(this.system_per_flg == 1){
                this.modeValue = 'system';
            }else{
                //権限なしは強制ログアウト　mountedで実施
            }

            // 文言変更に対応できるようにリストから取得
            let eigyo_str = this.modeItems[0].text;
            let koji_str = this.modeItems[1].text;
            let system_str = this.modeItems[2].text;

            // 権限のないリスト削除
            if(this.eigyo_per_flg == 0 || this.status != 0){
                let idx = this.modeItems.findIndex(x => x.text == eigyo_str);
                if(idx > -1)this.modeItems.splice(idx,1);
            }
            if(this.kouji_per_flg == 0 || this.status != 0){
                let idx = this.modeItems.findIndex(x => x.text == koji_str);
                if(idx > -1)this.modeItems.splice(idx,1);
            }
            if(this.system_per_flg == 0 || this.status != 0){
                let idx = this.modeItems.findIndex(x => x.text == system_str);
                if(idx > -1)this.modeItems.splice(idx,1);
            }
        }
    },
    mounted: function() {
        if((this.eigyo_per_flg == 0 && this.kouji_per_flg == 0 && this.system_per_flg == 0) || this.status != 0){
            alert("ご利用中のユーザに、本システム利用権限がないか、休止状態になっています。管理者にお問い合わせください。");
            this.logout();
        }
    },
    methods: {
        viewChange: function(view_name, menuTab){
            this.$emit('viewChange',view_name, null, menuTab, this.modeValue);
        },
        tabChange: function(tab_id){
            this.menuTab = tab_id;
        },
        menuChange: function(modeValue){
            // メニューを入れ替える
            if(modeValue == 'eigyo'){
                this.menuItems = constants.eigyo_menuItems;
            }else if(modeValue == 'koji'){
                this.menuItems = constants.koji_menuItems;
            }else{
                this.menuItems = constants.sys_menuItems;
            }
        },
        modeChange: function(){
            this.menuChange(this.modeValue);    // メニューを入れ替える
            
            // 一個目のメニューを表示する
            this.$emit('viewChange',this.menuItems[0].viewName, null, 0, this.modeValue);
            this.menuTab = 0;
        },
        logout: function() {
            document.getElementById('logout-form').submit();
        },
    },
    data() {
        return { 
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            drawer: false,

            loginuser: null,
            eigyo_per_flg: 0,
            kouji_per_flg: 0,
            system_per_flg: 0,

            menuItems: constants.eigyo_menuItems,
            modeItems: constants.modeItems,
            menuTab: 0,
            modeValue: null,
        }
    }    
}
</script>
 
<style lang="scss" scoped>
@import './styles/common/common.scss';

.v-toolbar__title {
  overflow: visible !important;
  margin-right: 50px !important;
}
 
.v-app-bar__nav-icon {
  @include display_pc {
    display: none !important;
  }
}
 
.v-tabs {
  display: none;
 
  @include display_pc {
    display: block !important;
  }
}

.pc_only {
  display: none;
  
  @include display_pc {
    display: block !important;
  }
}
</style>
 