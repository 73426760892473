<template>
    <v-app>
        <menu-header v-bind:parameter="{homeparam: homeparam}" @viewChange="viewChange" ref="menuHeader"></menu-header>
        <!-- <br> -->
        <!-- <br> -->
        <!-- <br> -->
        <!-- <div> -->
            <!-- <v-btn -->
                <!-- color="red darken-1" -->
                <!-- small -->
                <!-- fab -->
                <!-- dark -->
                <!-- elevation="2" -->
                <!-- @click="viewBack()" -->
            <!-- > -->
            <!-- もどるtest -->
            <!-- </v-btn> -->
            <!-- test -->
            <!-- {{viewHistory}} -->
            <!-- test -->
        <!-- </div> -->
        <div class="content">
            <component v-bind:parameter="{homeparam: homeparam, viewparam: viewParam, viewHistory: viewHistory, viewState: viewState}" @viewChange="viewChange" @viewBack="viewBack" :is="currentView"></component>
        </div>
    </v-app>
</template>
<script>
import constants from './js/common/constants';

export default {
    components: {
    },
    methods: {
        viewChange: function(view_name, viewparam, menuTab, modeValue) {
            // 画面遷移先がメニュー直下でないか判定
            var eigyomenu = constants.eigyo_menuItems.findIndex((v) => (v.viewName) == this.toKebabCase(view_name));
            var kojimenu = constants.koji_menuItems.findIndex((v) => (v.viewName) == this.toKebabCase(view_name));
            var sysmenu = constants.sys_menuItems.findIndex((v) => (v.viewName) == this.toKebabCase(view_name));
            if ((eigyomenu == -1) && (kojimenu == -1) && (sysmenu == -1))
            {
                this.viewHistory.push(this.viewState)   // 遷移履歴を保存
            }
            else 
            {
                this.viewHistory.length = 0   // 遷移履歴を破棄
            }

            this.updateViewState(view_name, viewparam, menuTab, modeValue)　// 遷移状態更新
            this.changeContents(view_name, viewparam, menuTab, modeValue)   // 表示更新
        },
        toKebabCase: function(str) 
        {
            str = str.replace(/^ *?[A-Z]/, function(allStr) { return allStr.toLowerCase(); });
            str = str.replace(/_/g, '-');
            str = str.replace(/ *?[A-Z]/g, function(allStr, i) { return '-' + allStr.replace(/ /g, '').toLowerCase(); });
            return str;
        },
        changeContents : function(view_name, viewparam, menuTab, modeValue) {
            //　画面表示
            this.viewParam = viewparam;
            this.currentView = view_name;

            // メニュー切替
            if ((modeValue != undefined) && (modeValue != null))
            {
                this.$refs.menuHeader.modeValue = modeValue
                this.$refs.menuHeader.menuChange(modeValue)
            }
            if ((menuTab != undefined) && (menuTab != null))
            {
                this.$refs.menuHeader.tabChange(menuTab);
            }
        },
        updateViewState: function(view_name, viewparam, menuTab, modeValue) {
            // menuTab, modeValue未指定の場合は現在値とする
            var savemenuTab;
            var savemodeValue;
            if ((modeValue != undefined) && (modeValue != null))
            {
                savemenuTab = menuTab
            }
            else 
            {
                savemenuTab = this.$refs.menuHeader.menuTab
            }
            if ((menuTab != undefined) && (menuTab != null))
            {
                savemodeValue = modeValue
            }
            else 
            {
                savemodeValue = this.$refs.menuHeader.modeValue
            }

            var dispTitleIndex = constants.dispTiltes.findIndex((v) => (this.toKebabCase(v.view_name)) == this.toKebabCase(view_name));
            let dispName = '';
            if (dispTitleIndex >= 0)
            {
                dispName = constants.dispTiltes[dispTitleIndex].view_disp_name;
            }

            var histotyitem = {
                'view_name':view_name,
                'viewparam':(viewparam == undefined) ? null : viewparam,
                'menuTab':savemenuTab,
                'modeValue':savemodeValue,
                'dispName':dispName,
            }
            this.viewState = histotyitem
        },
        // 前画面に戻る
        viewBack: function() {
            var histotyitem = null;
            if (this.viewHistory.length > 0)
            {
                histotyitem = this.viewHistory.pop();
            }

            if (histotyitem != null)
            {
                var view = null
                var param ;
                var tab = null;
                var mode = null;

                if (histotyitem.modeValue != null)
                {
                    mode = histotyitem.modeValue
                }
                if (histotyitem.menuTab != null)
                {
                    tab = histotyitem.menuTab
                }
                param = histotyitem.viewparam;
                view = histotyitem.view_name

                this.changeContents(view, param, tab, mode)
                this.updateViewState(view, param, tab, mode)

            }
        }
    },
    props:{
        // bladeから渡されるデータ
        "homeparam": {},
    },
    created : function(){
        history.pushState(null, "", location.href.split("?")[0]); // アドレスからクエリ文字列を削除
        // homeparamを使う
        if (this.homeparam != null)
        {
            var view = null;
            var param = null;
            var tab = null;
            var mode = null;

            // 初期画面
            if (this.homeparam.loginuser.eigyo_per_flg != 0) {
                view='eigyo-home'
                param = null;
                tab = 0;
                mode = 'eigyo';
            }
            else if (this.homeparam.loginuser.kouji_per_flg != 0) {
                view='koji-home'
                param = null;
                tab = 0;
                mode = 'koji';
            }
            else if (this.homeparam.loginuser.system_per_flg != 0) {
                view='alert-srch'
                param = null;
                tab = 0;
                mode = 'sys';
            }

            if ((this.homeparam.mode != undefined) && (this.homeparam.mode != null))
            {
                mode = this.homeparam.mode;
            }
            if ((this.homeparam.tab != undefined) && (this.homeparam.tab != null))
            {
                tab = Number(this.homeparam.tab);
            }
            if (this.homeparam.param != undefined)
            {
                param = this.homeparam.param;
            }
            if ((this.homeparam.view != undefined) && (this.homeparam.view != null))
            {
                view = this.homeparam.view;
            }
            this.$nextTick(() => {
                this.updateViewState(view, param, tab, mode);
                this.viewChange(view, param, tab, mode);
            })
        }
    },
    data() {
        return { 
            drawer: false,
            currentView: '', 
            viewParam: null ,
            viewState: {} ,
            viewHistory: [] ,
        }
    }    
}
</script>

<style lang="scss" scoped>
</style>
