<template>
  <v-app class='base'>
    <v-main>
      <v-form id="srch_form">
        <!-- 検索条件 -->
        <v-card
          color="green lighten-4"
          class="ma-4"
          outlined
          elevation="3"
        >
          <v-card-actions>
            <span>
              <v-icon>
                mdi-database
              </v-icon>
              汎用マスタ
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-divider class="mt-0 mb-2"></v-divider>

          <v-container mx-4 pa-0>
            <v-row no-gutters>
              <v-col lg="1" md="2">
                <v-subheader class="pl-0 pb-2 float-right">グループ</v-subheader>
              </v-col>
              <v-col lg="4" md="5">
                <v-select
                  v-model="groupId"
                  :items="groups"
                  item-value="id"
                  item-text="name"
                  placeholder="グループを選択"
                  solo
                  dense
                  hide-details
                  @change="getGroup"
                ></v-select>
              </v-col>
              <v-col cols="3" class="d-flex">
                <v-btn
                  class="ml-4"
                  color="green accent-4"
                  dark
                  width="100"
                  @click="showGroup"
                >
                  <v-icon left>
                    mdi-eye
                  </v-icon>
                  表示
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col lg="1" md="auto">
              </v-col>
              <v-col lg="11" md="12" class="pr-2 d-flex">
                <v-btn
                  class="mr-2"
                  color="teal darken-1"
                  dark
                  width="120"
                  @click="downloadUniCsv()"
                >
                  <v-icon left>
                    mdi-download
                  </v-icon>
                  CSV出力
                </v-btn>
                <v-btn
                  class="mx-2"
                  color="indigo"
                  dark
                  width="120"
                  @click="regUniCsv"
                >
                  <v-icon left>
                    mdi-upload
                  </v-icon>
                  CSV登録
                </v-btn>
                <v-file-input
                  placeholder="ファイル選択後［CSV登録］をクリック"
                  dense
                  @change="selectCsvFile($event)"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>

        </v-card>
      </v-form>

      <!-- マスタ表示 -->
      <v-card
        color="lime lighten-4"
        class="ma-4"
        outlined
        elevation="3"
      >
        <v-card-actions>
          <span>
            <v-icon>
              mdi-view-list
            </v-icon>
            <template v-if="groupList == true">
              {{ groupName }}
            </template>
          </span>
          <v-spacer></v-spacer>
          <v-checkbox
            v-model="checkShowDel"
            class="shrink mx-2 my-auto"
            color="red darken-1"
            hide-details
            false-value="1"
            true-value="0"
          >
            <template v-slot:label>
              <span class="mr-2 text-body-2">
                削除も表示する
              </span>
            </template>
          </v-checkbox>
        </v-card-actions>
        <v-divider class="mt-0 mb-0"></v-divider>
        <v-flex v-if="groupList == true" class="mt-2 mb-4 mx-4">
          <v-data-table
            calculate-widths
            height="530"
            :headers="groupHeaders"
            :items="groupDetails"
            :items-per-page="10"
            class="elevation-1"
            locale="jp-ja"
            loading-text="読込中"
            no-data-text="該当するデータがありません。"
            :search="checkShowDel"
            :custom-filter="hideDelItem"
          >
            <template v-slot:[`item.del`]="{ item }">
              <v-icon
                v-if="item.del === true"
                class="my-auto"
                color="red darken-1"
              >
                mdi-checkbox-marked
              </v-icon>
              <v-icon
                v-else
                class="my-auto"
                color="grey lighten-1"
              >
                mdi-checkbox-blank-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
//import
import dbsystem from './js/common/dbsystem.js'
//export
export default {
  mixins: [dbsystem], 
  methods: {
    getGroup: function(id) {
      //グループID
      //console.log(id);
      //グループ名取得
      var target = this.groups.find((group) => {
        return (group.id == id);
      });
      
      this.getGroupDetails();

      //console.log(target.name);
      this.groupName = target.name;
      this.groupList = false;
    },

    showGroup: function() {
      if (this.groupId == null) {
        return;
      }
      this.groupList = true;
    },
    hideDelItem: function(value,search, item) {
      if (search == "1") {
        return (item.status != "1");
      } else {
        return true;
      }
    },
  },

  data: function(){
    return {
      checkShowDel: "1",
      message:null,

      //グループ
      groups: [
        { id: '010', name: '顧客種別' },
        { id: '020', name: '顧客ランク' },
        { id: '030', name: '続柄' },
        { id: '040', name: '地区' },
        // { id: '050', name: '地区-市町村' },
        { id: '060', name: '住居種別' },
        { id: '070', name: '営業アクション' },
        { id: '080', name: '営業アプローチ' },
        { id: '090', name: '商談フェーズ' },
        { id: '100', name: '日報その他分類' },
        { id: '110', name: '引合結果' },
        { id: '120', name: '工事種別' },
        { id: '125', name: '施主種別' },
        { id: '130', name: '工事部位' },
        { id: '140', name: '工事部位-工程' },
        { id: '150', name: '工事部位-仕様・モデル' },
        { id: '160', name: '工事日報種別' },
        { id: '170', name: '点検種別' },
        { id: '180', name: '自社施工物件' },
        { id: '190', name: '工事全体ステータス' },
        { id: '200', name: '工事工程ステータス' },
        { id: '220', name: '入金種別' },
        { id: '230', name: 'アラート種類' },
        { id: '240', name: '単位' },
        { id: '250', name: 'RFM計算用閾値' },
        { id: '260', name: 'RFMアラート用閾値' },
        { id: '300', name: '所属部署' },
      ],
      groupId: null,
      groupName: null,
      groupList: false,
      showDel: "1",

      //グループデータ
      groupHeaders: [
        { text: 'グループID', value: 'grp_id', width: '80' },
        { text: 'ID', value: 'rec_id', width: '80' },
        { text: '親ID', value: 'parent_id', width: '80' },
        { text: '表示順', value: 'disp_order', width: '80' },
        { text: '表示名称1', value: 'disp_name1', width: '150' },
        { text: '表示名称2', value: 'disp_name2', width: '100' },
        { text: '表示用コード', value: 'disp_code', width: '100' },
        { text: 'ステータス', value: 'status', width: '80' },
      ],
      groupDetails: [],
      csvDatas:[],
    }
  }
};
</script>

<style>
.right-input input {
  text-align: right;
}
</style>

<style lang="scss" scoped>
@import './styles/common/common.scss';

.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}
</style>
