export default {    // exportする
    data() {
        return {
            //Validation用
            valid: true,
            validRules: {
                required: value => !!value || '必須入力です',
                arrRequired: value => (value.length > 0) || '必須入力です',
                objRequired: value => ((value != null) && (Object.keys(value).length > 0)) || '必須入力です',
                isNumber: value => /^[0-9]*$/.test(value||'0') || '数字のみ入力可',
                isNumberSigned: value => /^[-+]?[0-9]*$/.test(value||'0') || '数字のみ入力可',
                isNumberCommaSigned: value => /^[-+]?[0-9,]*$/.test(value||'0') || '数字のみ入力可',
                isDecimal: value => /^[0-9]+(\.[0-9]+)?$/.test(value||'0') || '数字のみ入力可（小数可）',
                isDecimalSigned: value => /^[-+]?[0-9]+(\.[0-9]+)?$/.test(value||'0') || '数字のみ入力可（小数可）',
                isDecimalComma: value => /^[0-9,]+(\.[0-9]+)?$/.test(value||'0') || '数字のみ入力可（小数可）',
                isDecimalCommaSigned: value => /^[-+]?[0-9,]+(\.[0-9]+)?$/.test(value||'0') || '数字のみ入力可（小数可）',
                isAlphaNum: value => /^[A-Za-z0-9]*$/.test(value) || '半角英数字のみ入力可',
                isAlphaSymNum: value => /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/.test(value) || '半角英数記号のみ入力可',
                postCd3: value => !value || (value.length == 3) || '3桁必要',
                postCd4: value => !value || (value.length == 4) || '4桁必要',
                email: value => !value || /.+@.+\..+/.test(value) || 'メールアドレス書式エラー',
            },
        }
    }
}