export default {
    methods: {
        // 各リスト取得
        getIdNameList: function (id) {
            axios.get('kouji/list?type='+id)
                .then(response => {
                    if (id == "koujiType") {
                        this.kouji_type_list = response.data;
                    } else if (id == "koujiStatus") {
                        this.kouji_status_list = response.data;
                    } else if (id == "ckkBukken") {
                        this.ckk_bukken_list = response.data;
                    } else if (id == "area") {
                        this.area_list = response.data;
                    } else if (id == "tanto") {
                        this.tanto_list = response.data;
                    } else if (id == "customer") {
                        this.customer_list = response.data;
                    } else if (id == "sesyu") {
                        this.sesyu_list = response.data;
                    }
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客の契約取得
        getCustomerOrderList: function (cust_no) {
            axios.get('kouji/list?type=order&cust_no='+cust_no)
            .then(response => {
                this.cust_order_list = response.data;
            })
            .catch(
                // response => console.log(response)
            );
        },

        // 工事部位取得
        getKoujiPartList: function (koujiTypeId) {
            axios.get('kouji/list?type=koujiPart&kouji_type_id='+koujiTypeId)
            .then(response => {
                this.kouji_part = response.data;
            })
            .catch(
                // response => console.log(response)
            );
        },

        // 工事工程取得
        getKoujiPartProcList: function (koujiTypeId) {
            axios.get('kouji/list?type=koujiProc&kouji_type_id='+koujiTypeId)
            .then(response => {
                this.kouji_part_proc = response.data;
            })
            .catch(
                // response => console.log(response)
            );
        },

        // 工事リスト取得
        getKoujiList: function (
            kouji_tanto, kouji_kikan_from, kouji_kikan_to, cust_name, kouji_name,
            kouji_type, kouji_status, ckk_bukken, area_id, only_alert,
            tel_no1, tel_no2, tel_no3, kj_addr)
        {

            // URL作成
            var uri = 'kouji';
            var qparam = [];
            if (kouji_tanto != null) {
                qparam.push('kouji_tanto=' + encodeURIComponent(kouji_tanto));
            }
            if (kouji_kikan_from != null) {
                qparam.push('kouji_kikan_from=' + encodeURIComponent(kouji_kikan_from));
            }
            if (kouji_kikan_to != null) {
                qparam.push('kouji_kikan_to=' + encodeURIComponent(kouji_kikan_to));
            }
            if (cust_name != null) {
                qparam.push('cust_name=' + encodeURIComponent(cust_name));
            }
            if (kouji_name != null) {
                qparam.push('kouji_name=' + encodeURIComponent(kouji_name));
            }
            if (kouji_type != null) {
                qparam.push('kouji_type=' + encodeURIComponent(kouji_type));
            }
            if (kouji_status != null) {
                qparam.push('kouji_status=' + encodeURIComponent(kouji_status));
            }
            if (ckk_bukken != null) {
                qparam.push('ckk_bukken=' + encodeURIComponent(ckk_bukken));
            }
            if (area_id != null) {
                qparam.push('area_id=' + encodeURIComponent(area_id));
            }
            if (only_alert != null && only_alert == "yes") {
                qparam.push('alert=yes');
            }
            if (kj_addr != null) {
                qparam.push('kj_addr=' + encodeURIComponent(kj_addr));
            }
            if (tel_no1 != null) {
                qparam.push('tel_no1=' + encodeURIComponent(tel_no1));
            }
            if (tel_no2 != null) {
                qparam.push('tel_no2=' + encodeURIComponent(tel_no2));
            }
            if (tel_no3 != null) {
                qparam.push('tel_no3=' + encodeURIComponent(tel_no3));
            }

            uri += '?' + qparam.join('&');

            // データ取得
            axios.get(uri)
                .then(response => {
                    this.kouji_srch_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 工事データ取得
        getKouji: function (kouji_no) 
        {
            // データ取得
            axios.get('kouji/' + kouji_no)
                .then(response => {
                    this.kouji = response.data['kouji'];
                    this.order = response.data['order'];
                    this.tantoGrp = response.data['tantoGrp'];
                    this.otherTantoGrp = response.data['otherTantoGrp'];
                    this.kouji_part = response.data['kouji_part'];
                    this.kouji_file = response.data['kouji_file'];
                    this.kouji_proc = response.data['kouji_proc'];
                    this.customer = response.data['customer'];

                    this.kouji_proc.forEach(function(elem, index) {
                        // 工事工程明細ストレージディレクトリ名
                        if (elem.kj_proc_no) this.kouji_proc[index].storage_dirname_mei = elem.kj_proc_no;
                    }.bind(this));
                    // 工事ストレージディレクトリ名
                    if (kouji_no)
                    {
                        this.storage_dirname_kouji = kouji_no;
                    }
                    if (this.kouji && this.kouji.sekou_area)
                    {
                        this.kouji.sekou_area = Number(this.kouji.sekou_area);
                    }
              
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 工事基本データ取得
        getKoujiBasic: function (kouji_no)
        {
            // データ取得
            axios.get('kouji/basic?kouji_no=' + kouji_no)
                .then(response => {
                    this.kouji = response.data['kouji'];
                    this.order = response.data['order'];
                    this.nyukin = response.data['nyukin'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 工事基本データ取得
        getKoujiDetail: function (kouji_no)
        {
            // データ取得
            axios.get('kouji/detail?kouji_no=' + kouji_no)
                .then(response => {
                    this.kouji = response.data['kouji'];
                    this.order = response.data['order'];
                    this.kouji_file = response.data['kouji_file'];
                    this.kouji_part = response.data['kouji_part'];
                    this.kouji_proc = response.data['kouji_proc'];
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 顧客工事リスト取得
        getCustomerKoujiList: function (cust_no)
        {
            // データ取得
            axios.get('customer/list?type=kouji&cust_no=' + cust_no)
                .then(response => {
                    this.kouji_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 工事メンテナンスリスト取得
        getKoujiMainteList: function (kouji_no)
        {
            // データ取得
            axios.get('kouji/list?type=mainte&kouji_no=' + kouji_no)
                .then(response => {
                    this.mainte_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 工事点検リスト取得
        getKoujiInspectList: function (kouji_no)
        {
            // データ取得
            axios.get('kouji/list?type=inspect&kouji_no=' + kouji_no)
                .then(response => {
                    this.inspect_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 工事データ登録
        setKouji: function (kouji, koujiPart, kouji_file, kouji_proc, tantoGrp, storage_kj, storage_mei) 
        {
            // URL作成
            var uri = 'kouji'
            // データ格納
            axios.post(uri, {'kouji':kouji, 'koujiPart':koujiPart, 'kouji_file':kouji_file, 'kouji_proc':kouji_proc, 'tantoGrp':tantoGrp, 'storage_kj':storage_kj, 'storage_mei':storage_mei})
                .then(response => {
                    if (this.mode == "reg") {
                        alert("工事情報を登録しました");
                    } else {
                        alert("工事情報を更新しました");
                    }
                    //前画面に戻る
                    this.$emit('viewBack');
                })
                .catch((error) => {
                    alert("工事情報の登録・更新に失敗しました");
                });
        },

        // 工事削除
        deleteKouji: function (kouji_no) 
        {
            // データ削除
            axios.delete('kouji/' + kouji_no)
                .then(response => {
                    this.backKoujiList();  // 成功したら一覧に戻る
                })
                .catch((error) => {
                    alert("工事の削除に失敗しました");
                });
        },

        // 添付ファイル名リスト読出
        downloadAttachFileNameList(kouji_no) 
        {
            // データ
            // URL作成
            var uri = 'kouji/filelist?kouji_no='+kouji_no
            axios.get(uri)
                .then(response => {
                    if (response.data.file_list != null)
                    {
                        this.att_file_list = response.data.file_list;
                    }
                    else 
                    {
                        this.att_file_list = {};
                    }
                // console.log(response.data);
                })
                .catch((error) => {
                // alert(error); 
                });
        },    
    },
}
