<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
        <v-card 
          color="rgb(255, 0, 0, 0)" 
          class="outline-card ma-0 pa-0"
          elevation="0"
          height="100%"
        >
        <v-card
          color="rgb(255, 0, 0, 0)" 
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-breadcrumbs :items="breads"></v-breadcrumbs>
        </v-card>
        <v-card
          class="mt-0 mb-4 ml-4 mr-4"
          :color="cssconsts.eigyo_bg_color_base_01"
          outlined
          elevation="10"
          height="100%"
        >
          <v-card-actions>
            <div style="width: 200px">
              <v-icon>
                mdi-hammer-wrench
              </v-icon>
              工事情報登録・編集
            </div>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4">
              ※内容を変更した場合、登録ボタンを押してください
            </span>
            <v-btn
              class="mx-1"
              color="indigo"
              dark
              width="100"
              @click="regKouji"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              登録
            </v-btn>
            <v-btn
              class="mx-1"
              color="grey darken-1"
              dark
              width="100"
              @click="backPrevPage"
            >
              <v-icon left>
                mdi-keyboard-return
              </v-icon>
              戻る
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-1"></v-divider>
          <!-- 基本情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">基本情報</div>
            </v-sheet>

            <v-form ref="basic_info" v-model="valid" lazy-validation>
              <v-row v-if="mode == 'reg'"
                no-gutters class="mt-2 mr-2"
              >
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">顧客</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-autocomplete
                    v-model="cust_no"
                    :items="customer_area_list"
                    item-value="id"
                    item-text="name"
                    placeholder="顧客を選択"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row v-else
                no-gutters class="mt-2 mr-2"
              >
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">顧客番号</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="kouji.cust_no"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">顧客名</v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="customer.cust_name"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="mode == 'reg'"
                no-gutters class="mt-2 mr-2"
              >
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">契約</v-subheader>
                </v-col>
                <v-col cols="7">
                  <v-autocomplete
                    v-model="keiyaku_select"
                    :items="cust_order_list"
                    item-value="id"
                    item-text="name"
                    return-object
                    placeholder="契約を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">工事番号</v-subheader>
                </v-col>
                <v-col v-if="mode == 'reg'"
                  cols="2"
                >
                  <v-text-field
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                    value="新規登録"
                  >
                  </v-text-field>
                </v-col>
                <v-col v-else
                  cols="2"
                >
                  <v-text-field
                    v-model="kouji.kouji_no"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">工事名</v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="kouji.kouji_name"
                    placeholder="工事名を入力"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">工事場所</v-subheader>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex">
                    <span class="mt-2">
                      〒
                    </span>
                    <v-text-field
                      v-model="postcd_input1"
                      class="mx-2"
                      solo
                      dense
                      maxlength="3"
                      hide-details="auto"
                      :rules="[validRules.required, validRules.isNumber, validRules.postCd3]"
                    ></v-text-field>
                    <span class="mt-2">
                      －
                    </span>
                    <v-text-field
                      v-model="postcd_input2"
                      class="mx-2"
                      solo
                      dense
                      maxlength="4"
                      hide-details="auto"
                      :rules="[validRules.required, validRules.isNumber, validRules.postCd4]"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="6" class="d-frex">
                  <v-btn
                    class="ml-1"
                    color="light-blue darken-1"
                    small
                    dark
                    @click="copyAddr()"
                  >
                    現住所転記
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    color="green darken-1"
                    small
                    dark
                    @click="convertPostalCode()"
                  >
                    〒 ⇔ 住所
                  </v-btn>
                  <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                    <input type="hidden" name="_token" :value="csrf">
                    <input type="hidden" name="mapdata" value="">
                  </form>
                  <v-btn
                    class="ml-1"
                    color="indigo darken-1"
                    small
                    dark
                    @click="showMap()"
                  >
                    <v-icon left>
                      mdi-map-marker
                    </v-icon>
                    地図
                  </v-btn>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="kouji.addr"
                    placeholder="住所を入力"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-2 mr-2">
                <v-col cols="2">
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="kouji.addr2"
                    placeholder="建物名などを入力"
                    solo
                    clearable
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2" align="center">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">緯度</v-subheader>
                </v-col>
                <v-col lg="4" md="10">
                  <v-text-field
                    v-model="kouji.addr_lat"
                    placeholder="緯度を入力"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.isDecimalSigned]"
                  ></v-text-field>
                </v-col>
                <v-col lg="2" md="2">
                  <v-subheader class="pb-2 float-right">経度</v-subheader>
                </v-col>
                <v-col lg="4" md="10">
                  <v-text-field
                    v-model="kouji.addr_lng"
                    placeholder="経度を入力"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.isDecimalSigned]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-2 mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">地区</v-subheader>
                </v-col>
                <v-col cols="3" >
                  <v-autocomplete v-if="mode == 'reg'"
                    v-model="area_id"
                    :items="area_list"
                    item-value="id"
                    item-text="name"
                    placeholder="地区を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                  <v-autocomplete v-else
                    v-model="kouji.area_id"
                    :items="area_list"
                    item-value="id"
                    item-text="name"
                    placeholder="地区を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">施主種別</v-subheader>
                </v-col>
                <v-col cols="3" >
                  <v-autocomplete
                    v-model="kouji.sesyu_type"
                    :items="sesyu_list"
                    item-value="id"
                    item-text="name"
                    placeholder="施主種別を選択"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
              

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">工事種別</v-subheader>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="kouji_type_id"
                    :items="kouji_type_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工事種別を選択"
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">施工面積</v-subheader>
                </v-col>
                <v-col cols="4" class="d-flex">
                  <div style="width: 120px">
                    <comma-number-field
                      v-model.number="kouji.sekou_area"
                      class="right-input"
                      solo
                      clearable
                      dense
                      maxlength="8"
                      hide-details="auto"
                      :rules="[validRules.isDecimalCommaSigned]"
                    >
                    </comma-number-field>
                  </div>
                  <div class="mx-1 mt-2">㎡</div>
                  <div style="width: 120px">
                    <comma-number-field
                      v-model.number="sekou_area_tubo"
                      class="right-input"
                      solo
                      clearable
                      dense
                      maxlength="8"
                      hide-details="auto"
                      :rules="[validRules.isDecimalCommaSigned]"
                    >
                    </comma-number-field>
                  </div>
                  <div class="mx-1 mt-2">坪</div>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">契約日</v-subheader>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="order.keiyaku_date"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-subheader class="pb-2 pr-0 float-right">契約金額</v-subheader>
                </v-col>
                <v-col cols="5" class="d-flex">
                  <div class="d-flex" style="width: 50px">
                    <span class="ml-auto mr-1 mt-2">税込</span>
                  </div>
                  <div style="width: 120px">
                    <v-text-field
                      class="right-input"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                      :value="numberToLocal(order.ukeoi_amt + order.ukeoi_tax)"
                    ></v-text-field>
                  </div>
                  <div class="d-flex" style="width: 50px">
                    <span class="ml-auto mr-1 mt-2">税抜</span>
                  </div>
                  <div style="width: 120px">
                    <v-text-field
                      class="right-input"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                      :value="numberToLocal(order.ukeoi_amt)"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">営業担当</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="otherTantoGrp.htg_tanto_name"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">見積担当</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="otherTantoGrp.mtg_tanto_name"
                    background-color="yellow lighten-4"
                    solo
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 red--text float-right">工事担当</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="koujiTanto"
                    :items="tanto_list"
                    item-value="id"
                    item-text="name"
                    placeholder="工事担当を選択"
                    multiple chips deletable-chips
                    solo
                    clearable
                    dense
                    hide-details="auto"
                    :rules="[validRules.arrRequired]"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pl-0 pb-2 float-right">自社施工物件</v-subheader>
                </v-col>
                <v-col lg="3" md="3">
                  <v-autocomplete
                    v-model="kouji.ckk_sekou"
                    :items="ckk_bukken_list"
                    item-value="id"
                    item-text="name"
                    :placeholder="$vuetify.breakpoint.smAndDown ? '自社施工' : '自社施工を選択'"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <template v-if="kouji.ckk_sekou != '1'">
                  <v-col cols="1">
                    <v-subheader class="pl-0 pb-2 float-right">業者</v-subheader>
                  </v-col>
                  <v-col lg="6" md="6">
                    <v-text-field
                      v-model="kouji.gyosya_name"
                      placeholder="業者名を入力"
                      solo
                      clearable
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>

              <v-row no-gutters class="mr-2">
                <v-col cols="2">
                  <v-subheader class="pb-2 float-right">備考</v-subheader>
                </v-col>
                <v-col cols="10">
                  <v-textarea
                    v-model="kouji.bikou"
                    placeholder="備考を入力"
                    auto-grow
                    rows="1"
                    solo
                    clearable
                    dense
                    hide-details
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-sheet>

          <!-- 工期情報 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">工期情報</div>
            </v-sheet>

            <v-row no-gutters class="mt-2 mb-0 mx-2">
              <v-col cols="4" class="d-flex">
                <span class="ml-2 my-auto text-body-2">工期</span>
              </v-col>
              <v-col cols="4" class="d-flex">
                <span class="ml-2 my-auto red--text text-body-2">予定日</span>
              </v-col>
              <v-col cols="4" class="d-flex">
                <span class="ml-2 my-auto text-body-2">実施日</span>
              </v-col>
            </v-row>
            <v-divider class="my-0"></v-divider>

            <v-form ref="kouki_info" v-model="valid" lazy-validation>
              <v-row no-gutters class="mt-2 mb-0 mx-2">
                <v-col cols="3" class="d-flex">
                  <div class="ml-2 my-auto">
                    <span>着工</span>
                  </div>
                </v-col>
                <v-col cols="4" class="d-flex pr-2">
                  <div style="width: 180px">
                    <v-text-field
                      v-model="kouji.start_yotei_date" 
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                      @click="dateClick('start_yotei_date')"
                    >
                      <template v-slot:append>
                        <date-picker ref="start_yotei_date" v-model="kouji.start_yotei_date"/>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col cols="4" class="d-flex pr-2">
                  <div style="width: 180px">
                    <v-text-field
                      v-model="kouji.start_date" 
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('start_date')"
                    >
                      <template v-slot:append>
                        <date-picker ref="start_date" v-model="kouji.start_date"/>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-0"></v-divider>

              <v-row no-gutters class="mt-2 mb-0 mx-2">
                <v-col cols="3" class="d-flex">
                  <div class="ml-2 my-auto">
                    <span>完成</span>
                  </div>
                </v-col>
                <v-col cols="4" class="d-flex pr-2">
                  <div style="width: 180px">
                    <v-text-field
                      v-model="kouji.comp_yotei_date" 
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                      @click="dateClick('comp_yotei_date')"
                    >
                      <template v-slot:append>
                        <date-picker ref="comp_yotei_date" v-model="kouji.comp_yotei_date"/>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col cols="4" class="d-flex pr-2">
                  <div style="width: 180px">
                    <v-text-field
                      v-model="kouji.comp_date" 
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('comp_date')"
                    >
                      <template v-slot:append>
                        <date-picker ref="comp_date" v-model="kouji.comp_date"/>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-0"></v-divider>

              <v-row no-gutters class="mt-2 mb-0 mx-2">
                <v-col cols="3" class="d-flex">
                  <div class="ml-2 my-auto">
                    <span>引渡</span>
                  </div>
                </v-col>
                <v-col cols="4" class="d-flex pr-2">
                  <div style="width: 180px">
                    <v-text-field
                      v-model="kouji.hndovr_yotei_date" 
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                      @click="dateClick('hndovr_yotei_date')"
                    >
                      <template v-slot:append>
                        <date-picker ref="hndovr_yotei_date" v-model="kouji.hndovr_yotei_date"/>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col cols="4" class="d-flex pr-2">
                  <div style="width: 180px">
                    <v-text-field
                      v-model="kouji.hndovr_date" 
                      placeholder="日付を選択"
                      solo
                      readonly
                      clearable
                      dense
                      hide-details
                      @click="dateClick('hndovr_date')"
                    >
                      <template v-slot:append>
                        <date-picker ref="hndovr_date" v-model="kouji.hndovr_date"/>
                      </template>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-0"></v-divider>
            </v-form>
          </v-sheet>

          <!-- 工事部位 -->
          <v-sheet
            class="mx-2 mt-1 mb-2 pb-2"
            color="white"
            outlined
            rounded
          >
            <v-sheet
              class="ma-0 d-flex"
              :color="cssconsts.eigyo_bg_color_header_01"
              outlined
              rounded
              dark
            >
              <div class="mx-2 my-auto">工事部位</div>
            </v-sheet>
            <template
              v-for="kojiPart in kojiParts"
            >
              <v-row
                no-gutters class="mt-2 mb-0 mx-2"
                :key="kojiPart.partNo"
              >
                <v-col cols="2" class="d-flex">
                  <span class="ml-2 my-auto text-body-2">{{kojiPart.partName}}</span>
                </v-col>
                <v-col cols="10" class="d-flex flex-wrap">
                  <div
                    v-for="(model, index) in kojiPart.models"
                    :key="index"
                    class="ml-4 d-flex"
                  >
                    <v-checkbox
                      v-model="model.select"
                      class="shrink my-auto"
                      color="blue darken-1"
                      hide-details
                    >
                      <template v-slot:label>
                        <span class="text-body-2">
                          {{model.name}}
                        </span>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2 mb-0" :key="'d'+kojiPart.partNo"></v-divider>
            </template>
          </v-sheet>

          <!-- 工事工程 -->
          <v-flex class="d-flex">
            <v-sheet
              class="ml-2 mr-2 mt-1 mb-2 pb-1"
              color="white"
              outlined
              rounded
              style="width: 100%"
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">工事工程</div>
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-1"
                  color="cyan darken-1"
                  dark
                  @click="addKoujiProc()"
                >
                  <v-icon left>
                    mdi-plus-circle
                  </v-icon>
                  工程追加
                </v-btn>
              </v-sheet>

              <v-form ref="proc_info" v-model="valid" lazy-validation>
                <!-- 明細 -->
                <v-sheet
                  v-for="(kjp, index) in kouji_proc"
                  :key="index"
                  class="d-flex align-stretch ma-2"
                  color="white"
                  tile
                >
                  <v-sheet
                    class="d-flex"
                    color="yellow lighten-3"
                    width="30"
                    outlined
                    elevation="1"
                  >
                    <div class="ma-auto">{{index + 1}}</div>
                  </v-sheet>
                  <v-flex>
                    <v-card
                      color="white"
                      class="ml-1 py-2"
                      outlined
                      tile
                      elevation="1"
                    >
                      <v-row no-gutters class="mr-2">
                        <v-col cols="2">
                          <v-subheader class="pb-2 red--text float-right">工事部位</v-subheader>
                        </v-col>
                        <v-col cols="3" >
                          <v-autocomplete
                            v-model="kjp.kj_part_id"
                            :items="kojiParts"
                            item-value="partNo"
                            item-text="partName"
                            :placeholder="$vuetify.breakpoint.smAndDown ? '工事部位' : '工事部位を選択'"
                            solo
                            clearable
                            dense
                            hide-details="auto"
                            :rules="[validRules.required]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="1">
                          <v-subheader class="pl-0 pb-2 float-right">業者</v-subheader>
                        </v-col>
                        <v-col cols="6" class="d-flex">
                          <div class="mr-4" style="width: 100%">
                            <v-text-field
                              v-model="kjp.gyosya"
                              placeholder="業者名を入力"
                              solo
                              clearable
                              dense
                              hide-details
                            ></v-text-field>
                          </div>
                          <v-btn
                            class="mt-1 ml-auto mr-0"
                            color="red darken-1"
                            x-small
                            fab
                            dark
                            elevation="2"
                            @click="delKoujiProc(index)"
                          >
                            <v-icon>
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mr-2">
                        <v-col cols="2">
                          <v-subheader class="pb-2 red--text float-right">工程</v-subheader>
                        </v-col>
                        <v-col lg="6" md="7">
                          <v-autocomplete
                            v-model="kjp.kj_proc_id"
                            :items="part_proc_list[kjp.kj_part_id]"
                            item-value="id"
                            item-text="name"
                            placeholder="工程を選択"
                            solo
                            clearable
                            dense
                            hide-details="auto"
                            :rules="[validRules.required]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col lg="auto" md="3">
                        </v-col>
                        <v-col cols="2">
                          <v-subheader class="pb-2 float-right">ステータス</v-subheader>
                        </v-col>
                        <v-col lg="2" md="3">
                          <v-autocomplete
                            v-model="kjp.proc_status"
                            :items="kouji_status_list"
                            item-value="id"
                            item-text="name"
                            placeholder="ステータス"
                            solo
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mr-2">
                        <v-col cols="2">
                          <v-subheader class="pb-2 red--text float-right">予定日</v-subheader>
                        </v-col>
                        <v-col lg="7" md="8" class="d-flex">
                          <div style="width: 180px">
                            <v-text-field 
                              v-model="kjp.yotei_date_from"
                              placeholder="日付(From)"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details="auto"
                              :rules="[validRules.required]"
                              @click="dateClick('proc_yotei_date_from', index)"
                            >
                              <template v-slot:append>
                                <date-picker ref="proc_yotei_date_from" v-model="kjp.yotei_date_from"/>
                              </template>
                            </v-text-field>
                          </div>
                          <v-subheader class="pb-2">
                            <div class="mx-auto">～</div>
                          </v-subheader>
                          <div style="width: 180px">
                            <v-text-field
                              v-model="kjp.yotei_date_to"
                              placeholder="日付(To)"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details="auto"
                              :rules="[validRules.required]"
                              @click="dateClick('proc_yotei_date_to', index)"
                            >
                              <template v-slot:append>
                                <date-picker ref="proc_yotei_date_to" v-model="kjp.yotei_date_to"/>
                              </template>
                            </v-text-field>
                          </div>
                        </v-col>
                        <v-col lg="auto" md="2">
                        </v-col>
                        <v-col lg="1" md="2">
                          <v-subheader v-if="$vuetify.breakpoint.smAndDown"
                            class="pb-2 float-right"
                          >
                            進捗率
                          </v-subheader>
                          <v-subheader v-else
                            class="pl-0 pr-2 pb-2 float-right"
                          >
                            進捗率
                          </v-subheader>
                        </v-col>
                        <v-col lg="2" md="2">
                          <v-autocomplete
                            v-model="kjp.prog_rate"
                            :items="progressRates"
                            item-value="val"
                            item-text="name"
                            placeholder="進捗率"
                            solo
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mr-2">
                        <v-col cols="2">
                          <v-subheader class="pb-2 float-right">着工日</v-subheader>
                        </v-col>
                        <v-col cols="3">
                          <div style="width: 180px">
                            <v-text-field
                              v-model="kjp.start_date"
                              placeholder="日付"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details
                              @click="dateClick('proc_start_date', index)"
                            >
                              <template v-slot:append>
                                <date-picker ref="proc_start_date" v-model="kjp.start_date"/>
                              </template>
                            </v-text-field>
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <v-subheader class="pb-2 float-right">完了日</v-subheader>
                        </v-col>
                        <v-col cols="3">
                          <div style="width: 180px">
                            <v-text-field
                              v-model="kjp.comp_date"
                              placeholder="日付"
                              solo
                              readonly
                              clearable
                              dense
                              hide-details
                              @click="dateClick('proc_comp_date', index)"
                            >
                              <template v-slot:append>
                                <date-picker ref="proc_comp_date" v-model="kjp.comp_date"/>
                              </template>
                            </v-text-field>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mr-2">
                        <v-col cols="2">
                          <v-subheader class="pb-2 float-right">備考</v-subheader>
                        </v-col>
                        <v-col cols="10">
                          <v-textarea
                            v-model="kjp.bikou"
                            placeholder="備考を入力"
                            auto-grow
                            rows="1"
                            solo
                            clearable
                            dense
                            hide-details
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row no-gutters class="mt-2 mr-2">
                        <v-col cols="2">
                          <v-subheader class="pl-0 pb-2 float-right">添付ファイル</v-subheader>
                        </v-col>
                        <v-col cols="8">
                          <v-file-input
                            v-if="selatt_disable[kjp.storage_dirname_mei] != true"
                            placeholder="ファイル選択後［送信］をクリック"
                            solo
                            clearable
                            dense
                            hide-details
                            truncate-length="32"
                            show-size
                            prepend-icon="mdi-paperclip"
                            @change="selectAttachFile($event, kjp.storage_dirname_mei)"
                          >
                          </v-file-input>
                        </v-col>
                        <v-col cols="1">
                          <v-btn
                            class="my-auto mx-2"
                            color="cyan darken-1"
                            dark
                            @click="regAttachFile(kjp.storage_dirname_mei)"
                          >
                            <v-icon left>
                              mdi-plus-circle
                            </v-icon>
                            送信
                          </v-btn>
                        </v-col>
                      </v-row>

                      <div 
                        v-for="(attfile, att_index) in att_file_list[kjp.storage_dirname_mei]"
                        :key="kjp.kj_proc_no + '_' + att_index"
                        >
                        <v-row no-gutters>
                          <v-col cols="2"></v-col>
                          <v-col cols="8">
                            <v-text-field
                              background-color="grey  lighten-5"
                              solo
                              dense
                              hide-details
                              readonly
                              prepend-icon=""
                              :value="att_file_list[kjp.storage_dirname_mei][att_index]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-btn
                              class="my-auto mx-2"
                              color="orange darken-1"
                              dark
                              @click="deleteAttachFile(kjp.storage_dirname_mei,att_index)"
                            >
                              <v-icon left>
                                mdi-minus-circle
                              </v-icon>
                              削除
                            </v-btn>
                          </v-col>

                        </v-row>
                      </div>

                    </v-card>
                  </v-flex>
                </v-sheet>
              </v-form>
            </v-sheet>
          </v-flex>

        </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import cssconsts from './js/common/cssconsts';
import dbkouji from './js/common/dbkouji.js'
import DatePicker from "./DatePicker.vue";
import gmap from './js/common/gmap.js'
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbkouji, gmap],

  props:{
      "parameter": {},
  },
  created: function() {
    window.document.addEventListener("setlatlng", this.setlatlngHandler); // 緯度経度更新用
    this.getIdNameList("koujiType");      // 工事種別リスト取得
    this.getIdNameList("area");           // 地区リスト取得
    this.getIdNameList("sesyu");          // 施主種別取得
    this.getIdNameList("ckkBukken");      // 自社施工物件取得
    this.getIdNameList("tanto");          // 担当者リスト取得
    this.getIdNameList("koujiStatus");    // 工事ステータスリスト取得

    // 工事番号がthis.parameter.viewparamとして引き渡されてくる
    if (this.parameter.viewparam) {
      var kouji_no = this.parameter.viewparam;
      //編集
      this.mode = "edit";
      // 工事番号が一致するデータを読み出す
      //以下に格納
      //⇒this.kouji
      //⇒this.order
      //⇒this.tantoGrp
      //⇒this.otherTantoGrp
      //⇒this.kouji_part
      //⇒this.kouji_file
      //⇒this.kouji_proc
      //⇒this.customer
      this.getKouji(kouji_no);
      // 添付ファイル名リスト読出し
      this.downloadAttachFileNameList(kouji_no);
    } else {
      //新規登録
      this.mode = "reg";
      this.getIdNameList("customer");   // 顧客リスト取得
      // 工事ストレージディレクトリ名
      var date = new Date();
      var timestamp = date.getTime();
      this.storage_dirname_kouji = 'kj'+timestamp;

    }
    this.m_createBreads();
  },
  mounted: function() {
    //エラーをリセット
    this.$refs.basic_info.resetValidation();
    this.$refs.kouki_info.resetValidation();
    this.$refs.proc_info.resetValidation();
  },
  watch: {
    kouji: function(data) {
      //郵便番号を分割して表示
      this.kouji_post_cd = data.post_cd;
      if (this.mode == 'edit') {
        this.kouji_type_id = data.kouji_type;
      }
    },
    tantoGrp: function(data) {
      this.koujiTanto = [];
      for (var elem of data) {
        this.koujiTanto.push(elem.tanto_id)
      }
    },
    customer_list: function(data) {
      //顧客リストをコピー
      this.customer_area_list = data.slice();
    },
    cust_no: function(data) {
      //顧客住所を取得
      axios.get('customer/basic?cust_no=' + data + '&onlyAddr=yes')
          .then(response => {
              this.customer = response.data[0];
          })
          .catch(
              // response => console.log(response)
          );
      //顧客の契約を取得
      this.getCustomerOrderList(data);
    },
    keiyaku_select: function(data) {
      //工事情報設定
      this.$set(this.kouji, 'kouji_name', data.name)
      this.kouji_post_cd = data.kouji_post_cd;
      this.$set(this.kouji, 'addr', data.kouji_addr)
      this.$set(this.kouji, 'addr2', data.kouji_addr2)
      this.$set(this.kouji, 'addr_lat', data.kouji_addr_lat)
      this.$set(this.kouji, 'addr_lng', data.kouji_addr_lng)
      this.$set(this.kouji, 'kouji_type', data.kouji_type)
      this.kouji_type_id = data.kouji_type;
      //契約情報設定
      this.$set(this.kouji, 'keiyaku_no', data.id)
      this.$set(this.order, 'keiyaku_date', data.keiyaku_date)
      this.$set(this.order, 'ukeoi_amt', data.ukeoi_amt)
      this.$set(this.order, 'ukeoi_tax', data.ukeoi_tax)
      //工事部位
      if (data.hikiai_kouji_part)
      {
        this.kouji_part = data.hikiai_kouji_part;
      }
      //その他の情報
      if (data.sekou_area != null) {
        this.$set(this.kouji, 'sekou_area', Number(data.sekou_area))
      }
      if (data.area_id != null) {
        this.area_id = data.area_id;
      }
      this.otherTantoGrp.htg_tanto_name = data.htg_tanto_name;
      this.otherTantoGrp.mtg_tanto_name = data.mtg_tanto_name;
    },
    area_id: function(data) {
      if (data) {
        //顧客リストを地区でフィルタリング
        this.customer_area_list = this.customer_list.filter(
          cust => cust.area_id == data
        );
      } else {
        //顧客リストを元に戻す
        this.customer_area_list = this.customer_list.slice();
      }
    },
    kouji_part: function(data) {
      this.kojiParts = [];
      var kj_part_id = 0;
      for (var elem of data) {
        if (elem.kj_part_id != kj_part_id) {
          this.kojiParts.push({
            partNo: elem.kj_part_id,
            partName: elem.kj_part_name,
            models: [],
          });
          kj_part_id = elem.kj_part_id;
        }
        this.kojiParts[this.kojiParts.length - 1].models.push({
          no: elem.model_no,
          name: elem.model_name,
          select: (elem.choice == "1"),
          created_at: elem.created_at
        });
      }
    },
    kouji_file: function(data) {
      for (var elem of data) {
        var targetType = this.kouji_file_type.find((type) => {
          return (type.id == elem.kj_file_type);
        });
        targetType.files.push(elem.kj_file_url);
      }
    },
    kouji_proc: function(data) {
      if (this.kouji_proc_watched) {
        return;
      }
      for (var elem of data) {
        // this.kouji_proc_attFile.push({
        //   attFile: []
        // });
        // var attFiles = [];
        // if (elem.att_file != null) {
        //   attFiles = elem.att_file.split(",");
        // }
        // for (var fileName of attFiles) {
        //   this.kouji_proc_attFile[this.kouji_proc_attFile.length - 1].attFile.push(
        //     fileName
        //   );
        // }
      }
      this.kouji_proc_watched = true;
    },
    kouji_type_id: function(data) {
      if (data != null) {
        if (this.kouji.kouji_type != data) {
          this.kouji.kouji_type = data;
          //工事部位読込
          this.getKoujiPartList(data);
        }
        //工事部位別工程読込
        this.getKoujiPartProcList(data);
      } else {
        this.kouji_part = [];
        this.part_proc_list = [];
        this.kouji.kouji_type = null;
      }
    },
    kouji_part_proc: function(data) {
      this.part_proc_list = [];
      var kj_part_id = 0;
      for (var elem of data) {
        if (elem.kj_part_id != kj_part_id) {
          kj_part_id = elem.kj_part_id;
          this.part_proc_list[kj_part_id] = [];
        }
        this.part_proc_list[kj_part_id].push({
          id: elem.proc_no,
          name: elem.proc_name,
        });
      }
    },
  },
  computed: {
    kouji_post_cd: {
      get: function() {
        var post_cd = "";
        post_cd += (this.postcd_input1 ? this.postcd_input1 : "");
        post_cd += (this.postcd_input2 ? this.postcd_input2 : "");
        return post_cd;
      },
      set: function(post_cd) {
        if (post_cd != null) {
          this.postcd_input1 = post_cd.substr(0, 3);
          this.postcd_input2 = post_cd.substr(3, 4);
        }
      }
    },
    sekou_area_tubo: {
      cache: false,
      get: function() {
        var tubo = 0;
        if (this.kouji.sekou_area != null) {
          var tubo = Math.round(this.kouji.sekou_area * 100 / 3.30579) / 100;
        }
        return tubo;
      },
      set: function(tubo) {
        var sekou_area = 0;
        if (tubo) {
          sekou_area = Math.round(tubo * 3.30579 * 100) / 100;
        }
        this.kouji.sekou_area = sekou_area;
      }
    },
  },

  methods: {
    dateClick(dpickName, index = null) {
      //console.log(this.$refs);
      //DatePicker を開く
      if (index == null) {
        this.$refs[dpickName].menu = true;
      } else {
        //v-forで生成したDatePicker用
        this.$refs[dpickName][index].menu = true;
      }
    },
    copyAddr() {
      //住所転記
      this.kouji_post_cd = this.customer.post_cd;
      this.kouji.addr = this.customer.addr;
      this.kouji.addr2 = this.customer.addr2;
      this.kouji.addr_lat = this.customer.addr_lat;
      this.kouji.addr_lng = this.customer.addr_lng;
      // this.area_id = this.customer.area_id;
    },
    editKoji() {
      //「工事情報登録・編集」画面へ遷移
      this.$emit('viewChange', 'KojiEdit');
    },
    backPrevPage() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    convertPostalCode()
    {
      var postalcode = ((this.postcd_input1!=null)?this.postcd_input1:'') + ((this.postcd_input2!=null)?this.postcd_input2:'')
      var searchaddress = ((this.kouji.addr!=null)?this.kouji.addr:'') + ((this.kouji.addr2!=null)?this.kouji.addr2:'')

      this.searchPostalcodeAddress(postalcode+searchaddress,(newaddr,newpostcd)=>{
        this.$set(this.kouji, 'addr', newaddr)
        this.postcd_input1 = newpostcd.substring(0,3)
        this.postcd_input2 = newpostcd.substring(3,7)
        this.$set(this.kouji, 'addr_lat', null)
        this.$set(this.kouji, 'addr_lng', null)
      });
      this.kouji.addr2 = '';

    },
    showMap() {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）
      // 顧客住所
      var cust_addr = ((this.customer.addr!=null)?this.customer.addr:'') + ((this.customer.addr2!=null)?this.customer.addr2:'');
      // 地図表示情報
      this.sendMapInfo(cust_addr);
    },
    // 地図表示情報
    sendMapInfo(cust_addr) {
      var searchaddress = ((this.kouji.addr!=null)?this.kouji.addr:'') + ((this.kouji.addr2!=null)?this.kouji.addr2:'')
      var title_kouji = '工事場所'
      var title_juusyo = '現住所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var lat_kouji = (this.kouji.addr_lat)?this.kouji.addr_lat:null
      var lng_kouji = (this.kouji.addr_lng)?this.kouji.addr_lng:null
      var lat_juusyo = (this.customer.addr_lat)?this.customer.addr_lat:null
      var lng_juusyo = (this.customer.addr_lng)?this.customer.addr_lng:null
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      i_data.value = JSON.stringify({
        'view_name': this.open_view_name,
        'cust_no': (this.kouji && this.kouji.cust_no)?this.kouji.cust_no:'',
        'cust_name': (this.customer && this.customer.cust_name)?this.customer.cust_name:'',
        'markercount': (cust_addr)?2:1,
        'editindex': 0, // 編集可
        'markertitle': [title_kouji,title_juusyo],
        'markeraddress': [searchaddress,cust_addr],
        'markerlat': [lat_kouji,lat_juusyo],
        'markerlng': [lng_kouji,lng_juusyo],
      });
      postform.submit();

    },
    setlatlngHandler(e)
    {
      if (this.open_view_name == e.detail.view_name)
      {
        if (e.detail.title == '工事場所')
        {
          // 工事場所緯度経度更新
          this.$set(this.kouji, 'addr_lat', e.detail.lat)
          this.$set(this.kouji, 'addr_lng', e.detail.lng)
        }
      }
    },
    numberToLocal(num) {
      if (num == null || isNaN(num)) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
    addSvrFile(file_type_id) {
      //基幹サーバファイル追加
    },
    delSvrFile(file_type_id, index) {
      //基幹サーバファイル削除
      var targetType = this.kouji_file_type.find((type) => {
        return (type.id == file_type_id);
      });
      targetType.files.splice(index, 1);
    },
    addKoujiProc() {
      var date = new Date();
      var timestamp = date.getTime();
      //工事工程追加
      this.kouji_proc.push({
        kj_proc_no: null,
        kouji_no: null,
        kj_part_id: null,
        kj_proc_id: null,
        gyosya: null,
        yotei_date_from: null,
        yotei_date_to: null,
        proc_status: null,
        prog_rate: null,
        start_date: null,
        comp_date: null,
        bikou: null,
        att_file: null,
        storage_dirname_mei: 'mei'+timestamp, // ストレージディレクトリ名(一時)
        created_at: null,
        updated_at: null,
      });
      // this.kouji_proc_attFile.push({
      //   attFile: []
      // });
    },
    delKoujiProc(index) {
      //工事工程削除
      if (index < this.kouji_proc.length) {
        this.kouji_proc.splice(index, 1);
        // this.kouji_proc_attFile.splice(index, 1);
      }
    },
    // delAttFile(index, index2) {
    //   //添付ファイル削除
    //   if (index < this.kouji_proc_attFile.length) {
    //     if (index2 < this.kouji_proc_attFile[index].attFile.length) {
    //       this.kouji_proc_attFile[index].attFile.splice(index2, 1);
    //     }
    //   }
    // },
    regKouji() {
      //入力チェック
      //--基本情報
      this.valid = this.$refs.basic_info.validate();
      if (!this.valid) {
        alert("基本情報に入力不足、または間違いがあります");
        return;
      }
      if (this.kouji.sekou_area != null) {
        //施工面積
        if (this.kouji.sekou_area > 9999.99) {
          alert("施工面積の最大値（9999.99）をオーバーしています");
          return;
        }
      }
      if (this.mode == "reg") {
        this.kouji.cust_no = this.cust_no;
        this.kouji.area_id = this.area_id;
      }
      this.kouji.post_cd = this.kouji_post_cd
      this.kouji.kouji_type = this.kouji_type_id

      //--工期情報
      this.valid = this.$refs.kouki_info.validate();
      if (!this.valid) {
        alert("入力されていない工期予定日があります");
        return;
      }

      //--工事部位
      var koujiPart = [];
      this.kojiParts.forEach(part => {
        part.models.forEach(model => {
          if (model.select == true) {
            koujiPart.push({
              kj_part_id: part.partNo,
              model_no: model.no,
              created_at: model.created_at
            });
          }
        });
      });
      if (koujiPart.length == 0) {
        alert("工事部位を選択してください");
        return;
      }

      //--工事工程
      if (this.kouji_proc.length == 0) {
        alert("工事工程を入力してください");
        return;
      }
      this.valid = this.$refs.proc_info.validate();
      if (!this.valid) {
        alert("工事工程に入力不足、または間違いがあります");
        return;
      }

      //--工事担当
      var tantoGrp = [];
      this.koujiTanto.forEach((element, index) => {
        var tanto = {};
        tanto.tanto_id = element;
        var hitTanto = this.tantoGrp.find((g) => g.tanto_id == tanto.tanto_id);
        if (hitTanto) {
          //工事担当グループに該当担当者あり
          tanto.created_at = hitTanto.created_at;
        } else {
          tanto.created_at = null;
        }
        tantoGrp.push(tanto);
      });

      //--基幹サーバ工事情報

      //--工事工程添付ファイル
      var storage_dirname_meisai = [];
      for (let index=0; index<this.kouji_proc.length; index++)
      {
        var elem = this.kouji_proc[index];
        storage_dirname_meisai.push(elem.storage_dirname_mei)
        delete this.kouji_proc[index].storage_dirname_mei
      };

      //DB更新
      this.setKouji(this.kouji, koujiPart, this.kouji_file, this.kouji_proc, tantoGrp, this.storage_dirname_kouji, storage_dirname_meisai);
    },

    // 添付ファイルデータ登録
    uploadAttachFileData(kouji_no, filename, data, kj_proc_no, file_index) 
    {
      // データ
      // URL作成
      var uri = 'kouji/file'
      // データ格納(store)
      axios.post(uri, {
        'kouji_no':kouji_no, 
        'filename':filename, 
        'data':data, 
        'kj_proc_no':kj_proc_no, 
        'file_index': file_index,
        })
        .then(response => {
          // console.log(response.data);
          // ファイル名リスト再読出し
          this.downloadAttachFileNameList(kouji_no);
        })
        .catch((error) => {
          // alert(error); 
        });
    },    

    // 添付ファイルの選択
    selectAttachFile(ev,kj_proc_no)
    {
      const files = ev;
      if((files != undefined) && (files != null)) {
        var dataFile = files;
        this.$nextTick(() => {
          this.setFileData(dataFile,kj_proc_no)
        });
      }
    },

    setFileData(dataFile,kj_proc_no) {
      const reader = new FileReader();
      reader.onload = () => {
        // データバッファへ読み込み
        var datauri = reader.result
        var base64EncodedFile = datauri.replace(/^data:.*?;base64,/, '');
        this.att_file_data_buffer[kj_proc_no] = base64EncodedFile
        this.att_file_name_buffer[kj_proc_no] = dataFile.name
      }
      reader.readAsDataURL(dataFile);
    },

    // 添付ファイルの登録（アップロード送信）
    regAttachFile(kj_proc_no)
    {
      if ((this.att_file_name_buffer[kj_proc_no] == undefined) || (this.att_file_name_buffer[kj_proc_no] == null))
      { 
        // ファイル未選択の場合
        return
      }

      var file_index = null
      if ((this.att_file_list[kj_proc_no] == undefined) || (this.att_file_list[kj_proc_no] == null))
      {
        file_index = 0
      }
      else 
      {
        var keyarray = Object.keys(this.att_file_list[kj_proc_no]);
        var newindex = Math.max.apply(null,keyarray) + 1;
        file_index = newindex;
      }
      // アップロード
      this.uploadAttachFileData(this.storage_dirname_kouji, this.att_file_name_buffer[kj_proc_no], this.att_file_data_buffer[kj_proc_no], kj_proc_no, file_index) // 添付ファイルをアップロード
      // ファイル選択をリセット
      this.att_file_data_buffer[kj_proc_no] = null
      this.att_file_name_buffer[kj_proc_no] = null
      this.$set(this.selatt_disable, kj_proc_no, true)
      this.$nextTick(function () {
        this.$set(this.selatt_disable, kj_proc_no, false)
      });
    },

    deleteAttachFile(kj_proc_no, att_index)
    {
      var res = confirm('このファイルを削除します。よろしいですか？');
      if (res == true) {
        this.deleteAttachFileData(this.storage_dirname_kouji, kj_proc_no, att_index) 
      }
    },

    // 添付ファイルデータ削除
    deleteAttachFileData(kouji_no, kj_proc_no, file_index) 
    {
      // URL作成
      var uri = 'kouji/file?kouji_no='+kouji_no+'&kj_proc_no='+kj_proc_no+'&file_index='+file_index
      // データ格納(store)
      axios.delete(uri)
        .then(response => {
          // console.log(response.data);
          // ファイル名リスト再読出し
          this.downloadAttachFileNameList(kouji_no);
        })
        .catch((error) => {
          // alert(error); 
        });
    },    

  },

  data: function(){
    return {
      cssconsts: cssconsts,
      mode: null,

      //工事
      kouji: {},
      kouji_type_id: null,  //工事種別
      postcd_input1: null,  //郵便番号 上３桁
      postcd_input2: null,  //郵便番号 下４桁
      tubo_input: null,     //坪
      //契約
      order: {},
      cust_order_list: [],  //顧客契約リスト
      keiyaku_select: {},   //選択した契約
      //工事担当者
      tantoGrp: [],
      koujiTanto: [],
      //営業担当、見積担当
      otherTantoGrp: {},
      //工事部位
      kouji_part: [],
      kojiParts: [],
      //基幹サーバ工事情報
      kouji_file: [],
      kouji_file_type: [
        { id: '1', name: '設計書', files: [] },
        { id: '2', name: '仕様書', files: [] },
        { id: '3', name: '完成図書', files: [] },
        { id: '4', name: '施工計画', files: [] },
      ],
      //工事工程
      kouji_proc: [],
      kouji_proc_attFile: [],
      kouji_proc_watched: false,
      storage_dirname_kouji: null, // 工事ストレージディレクトリ名
      att_file_list: {}, // 添付ファイル名
      selatt_disable: {},
      att_file_name_buffer: {},  // 添付ファイル名送信用バッファ
      att_file_data_buffer: {},  // 添付ファイル送信用バッファ

      //顧客
      cust_no: null,
      customer: {},
      customer_list: [],
      customer_area_list: [],
      //地区
      area_list: [],
      area_id: null,
      //施主種別
      sesyu_list: [],
      sesyu_type: null,
      //工事種別
      kouji_type_list: [],
      //自社施工物件
      ckk_bukken_list: [],
      //担当者
      tanto_list: [],

      //工事部位別工程
      kouji_part_proc: [],
      part_proc_list: [],

      //ステータス
      kouji_status_list: [],
      //進捗率
      progressRates: [
        { val: 0, name: '0%' },
        { val: 5, name: '5%' },
        { val: 10, name: '10%' },
        { val: 20, name: '20%' },
        { val: 30, name: '30%' },
        { val: 40, name: '40%' },
        { val: 50, name: '50%' },
        { val: 60, name: '60%' },
        { val: 70, name: '70%' },
        { val: 80, name: '80%' },
        { val: 90, name: '90%' },
        { val: 100, name: '100%' },
      ],
      breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
}

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}

span.nowrap {
   white-space: nowrap;
}

td.text-start, td.text-end {
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.right-input input {
  text-align: right;
}
</style>
