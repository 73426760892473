export default {
    methods: {
        timeFormatHHMM(time){
            if (time)
            {
                //hhhh:mm:ss から hhhh:mm に変換
                const [hour, minute, second] = time.split(':');
                return `${hour}:${minute}`;
            }
        },
        // 検索条件リスト一括取得
        getSearchConditionList() {
            axios.get('eigyo_nippou/list?type=searchcond')
                .then(response => {
                    // 営業担当者名
                    this.tanto_name_list = response.data.tanto;
                    // 顧客名
                    this.cust_name_list = response.data.cust;
                    // 営業アプローチ
                    this.apr_list = response.data.apr;
                    // 営業アクションリスト取得
                    this.act_list = response.data.act;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 営業担当者名リスト取得
        getEigyoTantoNameList() {
            axios.get('eigyo_nippou/list?type=tanto')
                .then(response => {
                    this.tanto_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 顧客名リスト取得
        getCustomerNameList() {
            axios.get('eigyo_nippou/list?type=cust')
                .then(response => {
                    this.cust_name_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 営業アプローチリスト取得
        getApproachNameList() {
            axios.get('eigyo_nippou/list?type=apr')
                .then(response => {
                    this.apr_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 営業アクションリスト取得
        getActionNameList() {
            axios.get('eigyo_nippou/list?type=act')
                .then(response => {
                    this.act_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 地区リスト取得
        getAreaNameList() {
            axios.get('eigyo_nippou/list?type=area')
                .then(response => {
                    this.area_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 商談フェーズリスト取得
        getPhaseNameList() {
            axios.get('eigyo_nippou/list?type=phase')
                .then(response => {
                    this.phase_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // その他分類リスト取得
        getOtherCatNameList() {
            axios.get('eigyo_nippou/list?type=othercat')
                .then(response => {
                    this.othercat_list = response.data;
                })
                .catch(
                    // response => console.log(response)
                );
        },
        // 引合リスト取得
        getInquiryNameList(cust_no) {
            axios.get('eigyo_nippou/list?type=inquiry&cust_no=' + encodeURIComponent(cust_no))
                .then(response => {
                    this.$set(this.inquiry_list, cust_no, response.data)
                })
                .catch(
                    // response => console.log(response)
                );
        },

        // 添付ファイル名リスト読出
        downloadAttachFileNameList(egnp_no) 
        {
            // データ
            // URL作成
            var uri = 'eigyo_nippou/filelist?egnp_no='+egnp_no
            axios.get(uri)
                .then(response => {
                if (response.data.file_list != null)
                {
                    this.att_file_list = response.data.file_list;
                }
                else 
                {
                    this.att_file_list = {};
                }
                // console.log(response.data);
                })
                .catch((error) => {
                // alert(error); 
                });
        },    

    },
}