<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
        <v-card 
          color="rgb(255, 0, 0, 0)" 
          class="outline-card ma-0 pa-0"
          elevation="0"
          height="100%"
        >
        <v-card
          color="rgb(255, 0, 0, 0)" 
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-breadcrumbs :items="breads"></v-breadcrumbs>
        </v-card>
          <v-card
            class="mt-0 mb-4 ml-4 mr-4"
            :color="cssconsts.eigyo_bg_color_base_01"
            outlined
            elevation="10"
          >
            <v-card-actions>
              <span>
                <v-icon>
                  mdi-text-box-check-outline
                </v-icon>
                受注情報
              </span>
              <v-spacer></v-spacer>
              <v-btn
                class="mx-1"
                color="indigo"
                dark
                width="100"
                @click="editOrder"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                編集
              </v-btn>
              <v-btn
                class="mx-1"
                color="orange darken-3"
                dark
                width="100"
                @click="delOrder"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                削除
              </v-btn>
              <v-btn
                class="mx-1"
                color="grey darken-1"
                dark
                width="100"
                @click="backOderList"
              >
                <v-icon left>
                  mdi-keyboard-return
                </v-icon>
                戻る
              </v-btn>
            </v-card-actions>
            <v-divider class="mt-0 mb-1"></v-divider>
            <v-flex>
              <!-- 基本情報 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">基本情報</div>
                </v-sheet>

                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">見積番号</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{order.mitumori_no}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">見積日</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{order.mitumori_date}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">引合内容</span>
                  </v-col>
                  <v-col cols="9" class="yellow lighten-4">
                    <span>
                      {{order.hikiai_naiyou}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事種別</span>
                  </v-col>
                  <v-col cols="4" class="yellow lighten-4">
                    <span>
                      {{order.kouji_type}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事名</span>
                  </v-col>
                  <v-col cols="9" class="yellow lighten-4">
                    <span>
                      {{order.kouji_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">プラン名</span>
                  </v-col>
                  <v-col cols="9" class="yellow lighten-4">
                    <span>
                      {{order.plan_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事場所</span>
                  </v-col>
                  <v-col cols="2">
                    <div class="yellow lighten-4" style="width: 120px">
                      <span>
                        〒{{editPostCd(order.kouji_post_cd)}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <div>
                      {{order.kouji_addr}}
                      <br>{{order.kouji_addr2}}
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <form target="_gmap" id="post-form" action="gmap" method="POST" style="display: none;">
                      <input type="hidden" name="_token" :value="csrf">
                      <input type="hidden" name="mapdata" value="">
                    </form>
                    <v-btn
                      class="ml-2"
                      color="indigo darken-1"
                      small
                      dark
                      @click="showMap(1)"
                    >
                      <v-icon left>
                      mdi-map-marker
                      </v-icon>
                      地図
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">緯度</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span v-if="order">
                      {{order.kouji_addr_lat}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">経度</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span v-if="order">
                      {{order.kouji_addr_lng}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-0 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">工事内容</span>
                  </v-col>
                  <v-col cols="9" class="yellow lighten-4">
                    <div v-html="kouji_naiyou"></div>
                  </v-col>
                </v-row>
              </v-sheet>

              <!-- 顧客情報 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">顧客情報</div>
                </v-sheet>
                  
                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客番号</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{customer.cust_no}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客名</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{customer.cust_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客種別</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{customer.cust_type}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">フリガナ</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{customer.cust_kana}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">現住所</span>
                  </v-col>
                  <v-col cols="2">
                    <div class="yellow lighten-4" style="width: 120px">
                      <span>
                      〒{{editPostCd(customer.post_cd)}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="6" class="yellow lighten-4">
                    <div>
                      {{customer.addr}}
                      <br>{{customer.addr2}}
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="ml-2"
                      color="indigo darken-1"
                      small
                      dark
                      @click="showMap(2)"
                    >
                      <v-icon left>
                      mdi-map-marker
                      </v-icon>
                      地図
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">緯度</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span v-if="customer">
                      {{customer.addr_lat}}
                    </span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">経度</span>
                  </v-col>
                  <v-col cols="3" class="yellow lighten-4">
                    <span v-if="customer">
                      {{customer.addr_lng}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">地区</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{customer.area_name}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">TEL</span>
                  </v-col>
                  <v-col cols="3">
                    <div class="yellow lighten-4" style="width: 170px">
                      <span class="mr-1">
                        {{customer.tel_no}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">FAX</span>
                  </v-col>
                  <v-col cols="3">
                    <div class="yellow lighten-4" style="width: 170px">
                      <span class="mr-1">
                        {{customer.fax_no}}
                      </span>
                    </div>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">携帯</span>
                  </v-col>
                  <v-col cols="3">
                    <div class="yellow lighten-4" style="width: 170px">
                      <span class="mr-1">
                        {{customer.cell_no}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">Eﾒｰﾙ</span>
                  </v-col>
                  <v-col cols="5" class="yellow lighten-4">
                    <span>
                      {{customer.email}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">顧客担当</span>
                  </v-col>
                  <v-col cols="9" class="yellow lighten-4">
                    <span>
                      {{customer.tanto_name}}
                    </span>
                  </v-col>
                </v-row>
              </v-sheet>

              <!-- 契約情報 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">契約情報</div>
                </v-sheet>

                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">契約日</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span>
                      {{order.keiyaku_date}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">請負金額</span>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">税込</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span class="float-right">
                      {{numberToLocal(order.ukeoi_amt + order.ukeoi_tax)}}
                    </span>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mt-2 mb-2 mr-2">
                  <v-col cols="2">
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">税抜</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span class="float-right">
                      {{numberToLocal(order.ukeoi_amt)}}
                    </span>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">税額</span>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <span class="float-right">
                      {{numberToLocal(order.ukeoi_tax)}}
                    </span>
                  </v-col>
                  <v-col cols="1" class="d-flex">
                    <span class="ml-auto mr-2 my-auto text-body-2">税率</span>
                  </v-col>
                  <v-col cols="1" class="yellow lighten-4">
                    <span class="float-right">
                      {{order.tax_rate}}
                    </span>
                  </v-col>
                  <v-col cols="1">
                    <span class="ml-1 text-body-2">%</span>
                  </v-col>
                </v-row>
              </v-sheet>

              <!-- 入金予定・実績 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">入金予定・実績</div>
                </v-sheet>

                <v-row no-gutters class="mt-2 mb-0 mx-2">
                  <v-col cols="2" class="d-flex">
                    <span class="ml-2 my-auto text-body-2">入金予定日</span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-2 my-auto text-body-2">入金種別</span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto my-auto text-body-2">金額</span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="mx-auto my-auto text-body-2">入金</span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-2 my-auto text-body-2">入金日</span>
                  </v-col>
                  <v-col cols="2" class="d-flex">
                    <span class="ml-auto my-auto text-body-2">入金額</span>
                  </v-col>
                </v-row>
                <v-divider class="my-0"></v-divider>

                <v-row
                  v-for="(nk, index) in nyukin"
                  :key="index"
                  no-gutters class="mt-2 mb-0 mx-2"
                >
                  <v-col cols="2" class="yellow lighten-4">
                    <div class="ml-2">
                      <span>
                        {{nk.yotei_date}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <div class="ml-2">
                      <span>
                        {{nk.nyukin_type}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <div class="ml-2 float-right">
                      <span>
                        {{numberToLocal(nk.yotei_kingaku)}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <div class="d-flex">
                      <span class="mx-auto">
                        {{nk.nyukin_state}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <div class="ml-2">
                      <span>
                        {{nk.nyukin_date}}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <div class="ml-2 float-right">
                      <span>
                        {{numberToLocal(nk.nyukin_kingaku)}}
                      </span>
                    </div>
                  </v-col>
                </v-row>

                <v-divider class="mt-2 mb-0"></v-divider>

                <v-row no-gutters class="mt-2 mb-0 mx-2">
                  <v-col cols="4" class="d-flex">
                    <div class="ml-auto mr-2 my-auto text-body-2">
                      <span>入金予定残高</span>
                    </div>
                  </v-col>
                  <v-col cols="2" class="yellow lighten-4">
                    <div class="ml-2 float-right">
                      {{numberToLocal(total_nyukin_zan)}}
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-flex>
          </v-card>
        </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import cssconsts from './js/common/cssconsts';
import dborder from './js/common/dborder.js'
import gmap from './js/common/gmap.js'
//export
export default {
  mixins: [dborder, gmap],

  props:{
      "parameter": {},
  },

  created: function() {
    //契約番号をthis.parameter.viewparamから取得
    var keiyaku_no = this.parameter.viewparam;
    //契約番号が一致するデータを読み出す
    //以下に格納
    //⇒this.order
    //⇒this.customer
    //⇒this.nyukin
    this.getOrderDetail(keiyaku_no);
    this.m_createBreads();
  },
  computed: {
  },
  watch: {
    order: function(data) {
      if (data.kouji_naiyou != null) {
        this.kouji_naiyou = data.kouji_naiyou.replace("\n", "<br>");
      }
    },
    nyukin: function(data) {
      this.total_nyukin_zan = 0;
      for (var elem of data) {
        this.total_nyukin_zan += (elem.yotei_kingaku - elem.nyukin_kingaku)
      }
    },
  },
  methods: {
    editPostCd(postCd) {
      if (postCd == null) {
        return null;
      } else {
        return postCd.substr(0, 3) + " - " + postCd.substr(3, 4);
      }
    },
    numberToLocal(num) {
      if (num == null) {
        return "";
      } else {
        return num.toLocaleString();
      }
    },
    editOrder() {
      var param = {
        keiyaku_no: this.order.keiyaku_no
      };
      //「受注登録・編集」画面へ遷移
      this.$emit('viewChange', 'OrderEdit', param, 5, 'eigyo');
    },
    delOrder() {
      // 顧客削除
      var res = confirm('当受注を削除します。よろしいですか？');
      if (res == true) {
        this.deleteOrder(this.order.keiyaku_no);
      }
    },
    backOderList() {
      // 戻るボタン
      this.$emit('viewBack');
    },

    showMap(map_sel) {
      window.open('', '_gmap')  //　先にウインドウを開く（ポップアップブロック対策）
      // 顧客住所
      var cust_addr = ((this.customer.addr!=null)?this.customer.addr:'') + ((this.customer.addr2!=null)?this.customer.addr2:'');
      // 地図表示情報
      this.sendMapInfo(cust_addr, map_sel);
    },
    // 地図表示情報
    sendMapInfo(cust_addr, map_sel) {
      var searchaddress = ((this.order.kouji_addr!=null)?this.order.kouji_addr:'') + ((this.order.kouji_addr2!=null)?this.order.kouji_addr2:'')
      var title_kouji = '工事場所'
      var title_juusyo = '現住所'
      var postform = document.forms['post-form'];
      var i_data = postform.elements['mapdata'];
      var lat_kouji = (this.order.kouji_addr_lat)?this.order.kouji_addr_lat:null
      var lng_kouji = (this.order.kouji_addr_lng)?this.order.kouji_addr_lng:null
      var lat_juusyo = (this.customer.addr_lat)?this.customer.addr_lat:null
      var lng_juusyo = (this.customer.addr_lng)?this.customer.addr_lng:null
      let now = new Date()
      this.open_view_name = this.parameter.viewState.view_name + String(now.getTime());
      if (map_sel == 1)
      {
        // 工事場所
        i_data.value = JSON.stringify({
          'view_name': this.open_view_name,
          'cust_no': (this.customer.cust_no)?this.customer.cust_no:'',
          'cust_name': (this.customer.cust_name)?this.customer.cust_name:'',
          'markercount': 1, //工事場所のみ
          'editindex': -1, // 編集禁止
          'markertitle': [title_kouji,title_juusyo],
          'markeraddress': [searchaddress,cust_addr],
          'markerlat': [lat_kouji,lat_juusyo],
          'markerlng': [lng_kouji,lng_juusyo],
        });
        postform.submit();
      }
      else if (map_sel == 2)
      {
        // 現住所
        i_data.value = JSON.stringify({
          'view_name': this.open_view_name,
          'cust_no': (this.customer.cust_no)?this.customer.cust_no:'',
          'cust_name': (this.customer.cust_name)?this.customer.cust_name:'',
          'markercount': 1, //現住所のみ
          'editindex': -1, // 編集禁止
          'markertitle': [title_juusyo, title_kouji],
          'markeraddress': [cust_addr, searchaddress],
          'markerlat': [lat_juusyo, lat_kouji],
          'markerlng': [lng_juusyo, lng_kouji],
        });
        postform.submit();
      }
    },
  },

  data: function(){
    return {
      cssconsts: cssconsts,

      //受注データ
      order: {},
      customer: {},
      nyukin: [],

      //編集・計算項目
      kouji_naiyou: "",
      total_nyukin_zan: 0,
      breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap{
   white-space: nowrap;
}
</style>
