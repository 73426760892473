<template>
  <v-app class='base'>
    <v-main>
      <v-layout justify-center>
      <v-card 
        color="rgb(255, 0, 0, 0)" 
        class="outline-card ma-0 pa-0"
        elevation="0"
        height="100%"
      >
        <v-card
          color="rgb(255, 0, 0, 0)" 
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-breadcrumbs :items="breads"></v-breadcrumbs>
        </v-card>
        <v-card
          class="mt-0 mb-4 ml-4 mr-4"
          :color="cssconsts.eigyo_bg_color_base_01"
          outlined
          elevation="10"
        >
          <v-card-actions>
            <div style="width: 200px">
              <v-icon>
                mdi-home-search-outline
              </v-icon>
              点検登録・編集
            </div>
            <v-spacer></v-spacer>
            <span class="text-body-2 mx-4">
              ※内容を変更した場合、登録ボタンを押してください
            </span>
            <v-btn
              class="mx-1"
              color="indigo"
              dark
              width="100"
              @click="regInspection"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              登録
            </v-btn>
            <v-btn
              class="mx-1"
              color="grey darken-1"
              dark
              width="100"
              @click="backPrevPage"
            >
              <v-icon left>
                mdi-keyboard-return
              </v-icon>
              戻る
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-0 mb-1"></v-divider>
          <v-flex>
            <v-form ref="inspect_info" v-model="valid" lazy-validation>
              <!-- 顧客・工事 -->
              <v-sheet
                class="mx-2 mt-1 mb-2"
                color="white"
                outlined
                rounded
              >
                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2">
                    <v-subheader v-if="mode == 'reg'"
                      class="pb-2 red--text float-right"
                    >
                      顧客
                    </v-subheader>
                    <v-subheader v-else
                      class="pb-2 float-right"
                    >
                      顧客名
                    </v-subheader>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete v-if="mode == 'reg'"
                      v-model="cust_no"
                      :items="customer_list"
                      item-value="id"
                      item-text="name"
                      placeholder="顧客を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                    <v-text-field v-else
                      v-model="koujiInfo.cust_name"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-0 mr-2">
                  <v-col cols="2">
                    <v-subheader v-if="mode == 'reg'"
                      class="pb-2 red--text float-right"
                    >
                      工事
                    </v-subheader>
                    <v-subheader v-else
                      class="pb-2 float-right"
                    >
                      工事名
                    </v-subheader>
                  </v-col>
                  <v-col cols="7">
                    <v-autocomplete v-if="mode == 'reg'"
                      v-model="inspection.kouji_no"
                      :items="cust_kouji_list"
                      item-value="id"
                      item-text="name"
                      placeholder="工事を選択"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-autocomplete>
                    <v-text-field v-else
                      v-model="koujiInfo.kouji_name"
                      background-color="yellow lighten-4"
                      solo
                      dense
                      hide-details
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-sheet>

              <!-- 点検対象 -->
              <v-sheet
                class="mx-2 mt-1 mb-2 pb-2"
                color="white"
                outlined
                rounded
              >
                <v-sheet
                  class="ma-0 d-flex"
                  :color="cssconsts.eigyo_bg_color_header_01"
                  outlined
                  rounded
                  dark
                >
                  <div class="mx-2 my-auto">点検対象</div>
                </v-sheet>
                  
                <v-row no-gutters class="mt-2 mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">名称</v-subheader>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      v-model="inspection.insp_name"
                      placeholder="名称を入力"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="mb-0 mr-2">
                  <v-col cols="2">
                    <v-subheader class="pb-2 red--text float-right">点検内容</v-subheader>
                  </v-col>
                  <v-col cols="10">
                    <v-textarea
                      v-model="inspection.insp_naiyou"
                      placeholder="点検内容を入力"
                      auto-grow
                      rows="1"
                      solo
                      clearable
                      dense
                      hide-details="auto"
                      :rules="[validRules.required]"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-form>

            <!-- 点検予定 -->
            <v-sheet
              class="mx-2 mt-1 mb-2 pb-2"
              color="white"
              outlined
              rounded
            >
              <v-sheet
                class="ma-0 d-flex"
                :color="cssconsts.eigyo_bg_color_header_01"
                outlined
                rounded
                dark
              >
                <div class="mx-2 my-auto">点検予定</div>
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-1 my-1"
                  color="cyan darken-1"
                  dark
                  small
                  @click="addTenken()"
                >
                  <v-icon left>
                    mdi-plus-circle
                  </v-icon>
                  追加
                </v-btn>
              </v-sheet>

              <v-row no-gutters class="mt-1 mb-0 mx-2">
                <v-col lg="4" md="4" class="d-flex">
                  <span class="ml-2 my-auto red--text text-body-2">点検種別</span>
                </v-col>
                <v-col lg="8" md="8" class="d-flex">
                  <div class="ml-2" style="width: 180px">
                    <span class="my-auto red--text text-body-2">予定日</span>
                  </div>
                  <div class="ml-2" style="width: 180px">
                    <span class="my-auto text-body-2">実施日</span>
                  </div>
                </v-col>
                <v-col lg="4" md="4" class="d-flex">
                  <span class="ml-2 my-auto text-body-2">実施担当</span>
                </v-col>
                <v-col lg="8" md="8" class="d-flex">
                  <span class="ml-2 my-auto text-body-2">対応内容</span>
                </v-col>
              </v-row>
              <v-divider class="my-0"></v-divider>

              <v-form ref="yotei_info" v-model="valid" lazy-validation>
                <template v-for="(meisai, index) in inspect_mei" >
                  <v-row
                    :key="'row1'+index"
                    no-gutters class="mt-2 mb-0 mx-2"
                  >
                    <v-col lg="4" md="4" class="pr-2">
                      <v-autocomplete
                        v-model="meisai.insp_type"
                        :items="insp_type_list"
                        item-value="id"
                        item-text="name"
                        placeholder="点検種別を選択"
                        solo
                        clearable
                        dense
                        hide-details="auto"
                        :rules="[validRules.required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="8" md="8" class="d-flex pr-2">
                      <div class="mr-2" style="width: 180px">
                        <v-text-field
                          v-model="meisai.yotei_date"
                          placeholder="日付を選択"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details="auto"
                          :rules="[validRules.required]"
                          @click="dateClick('yoteiDate', index)"
                        >
                          <template v-slot:append>
                            <date-picker ref="yoteiDate" v-model="meisai.yotei_date"/>
                          </template>
                        </v-text-field>
                      </div>
                      <div class="mr-2" style="width: 180px">
                        <v-text-field
                          v-model="meisai.jissi_date"
                          placeholder="日付を選択"
                          solo
                          readonly
                          clearable
                          dense
                          hide-details
                          @click="dateClick('jissiDate', index)"
                        >
                          <template v-slot:append>
                            <date-picker ref="jissiDate" v-model="meisai.jissi_date"/>
                          </template>
                        </v-text-field>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="my-auto"
                        color="red darken-1"
                        x-small
                        fab
                        dark
                        elevation="2"
                        @click="delTenken(index)"
                      >
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    :key="'row2'+index"
                    no-gutters class="mt-2 mb-0 mx-2"
                  >
                    <v-col lg="4" md="4" class="pr-2">
                      <v-autocomplete
                        v-model="meisai.jissi_tanto_id"
                        :items="tanto_list"
                        item-value="id"
                        item-text="name"
                        placeholder="実施担当を選択"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col lg="8" md="8" class="pr-2">
                      <v-textarea
                        v-model="meisai.taiou"
                        placeholder="対応内容を入力"
                        auto-grow
                        rows="1"
                        solo
                        clearable
                        dense
                        hide-details
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </template>
              </v-form>
            </v-sheet>
          </v-flex>
        </v-card>
      </v-card>
      </v-layout>
    </v-main>
  </v-app>
</template>
<script>
//import
import cssconsts from './js/common/cssconsts';
import dbinspection from './js/common/dbinspection.js'
import DatePicker from "./DatePicker.vue";
//export
export default {
  components: {
    DatePicker,
  },
  mixins: [dbinspection],

  props:{
      "parameter": {},
  },

  created: function() {
    this.getIdNameList("inspType");  // 点検種別リスト取得
    this.getIdNameList("tanto");     // 実施担当者リスト取得

    //点検番号をthis.parameter.viewparamから取得
    var param = this.parameter.viewparam;
    var insp_no = param.insp_no;
    if (insp_no) {
      //点検番号あり
      //編集
      this.mode = "edit";
      //契約番号が一致するデータを読み出す
      //⇒this.koujiInfo
      //⇒this.inspection
      //⇒this.inspect_mei
      this.getInspection(insp_no);
    } else {
      //新規登録
      if (param.kouji_no) {
        //工事に追加登録
        this.inspection.kouji_no = param.kouji_no
        this.koujiInfo.cust_name = param.cust_name
        this.koujiInfo.kouji_name = param.kouji_name
        this.mode = "addReg";
      } else {
        this.getIdNameList("customer");  // 顧客リスト取得
        this.mode = "reg";
      }
    }
    this.m_createBreads();
  },

  mounted: function() {
    //エラーをリセット
    this.$refs.inspect_info.resetValidation();
    this.$refs.yotei_info.resetValidation();
  },

  watch: {
    cust_no: function(data) {
      if (this.mode == 'reg') {
        //顧客工事リスト読込
        this.getCustKoujiList(data);
      }
    },
  },

  methods: {
    dateClick(dpickName, index = null) {
      //DatePicker を開く
      if (index == null) {
        this.$refs[dpickName].menu = true;
      } else {
        //v-forで生成したDatePicker用
        this.$refs[dpickName][index].menu = true;
      }
    },
    backPrevPage() {
      // 戻るボタン
      this.$emit('viewBack');
    },
    regInspection() {
      //入力チェック
      //--顧客・工事・点検対象情報
      this.valid = this.$refs.inspect_info.validate();
      if (!this.valid) {
        alert("顧客・工事・点検対象に入力不足、または間違いがあります");
        return;
      }
      //--点検予定
      if (this.inspect_mei.length == 0) {
        alert("点検予定を入力してください");
        return;
      }
      this.valid = this.$refs.yotei_info.validate();
      if (!this.valid) {
        alert("点検予定に入力不足、または間違いがあります");
        return;
      }

      //DB更新
      this.setInspection(this.inspection, this.inspect_mei);
    },
    addTenken() {
      // 点検明細追加
      this.inspect_mei.push(
        {
          insp_mei_no: null,
          insp_no: null,
          insp_type: null,
          yotei_date: null,
          jissi_date: null,
          jissi_tanto_id: null,
          taiou: null,
          created_at: null,
        }
      );
    },
    delTenken(index) {
      // 点検明細削除
      if (index < this.inspect_mei.length) {
        this.inspect_mei.splice(index, 1);
      }
    },

  },

  data: function(){
    return {
      cssconsts: cssconsts,

      //点検種別
      insp_type_list: [],
      //実施担当者
      tanto_list: [],
      //点検情報
      koujiInfo: {},
      inspection: {},
      inspect_mei: [],

      //顧客
      cust_no: null,
      customer_list: [],

      //顧客の工事
      cust_kouji_list: [],

      breads: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/common/common.scss';
.base {
    background-image: url("../../images/bg02.jpg");
    background-size: cover;
};

.outline-card {
  max-width: $md + px;
  min-width: $menu-border;
}
span.nowrap {
   white-space: nowrap;
}
</style>
